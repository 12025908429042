export default {
    light: {
        name:"armor_light",
    },
    medium: {
        name:"armor_medium",
    },
    heavy: {
        name:"armor_heavy",
    },
    shields: {
        name:"armor_shields",
    }
}