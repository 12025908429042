export const barbarian =
"варвар";

export const barbarian_details =
'<p>Попри різноманітність, усіх варварів поєднує одне — їхня лють. Неприборканий, незгасний і бездумний гнів. Це не просто емоція: їхня лють як скаженість загнаного в кут хижака, як безжальний удар урагану, як штормові вали океану.'+
'<br /><br />Лють деяких з них випливає зі спілкування з лютими духами тварин. Інші черпають її зі злості на дійсність, повну болі та страждання. Але для кожного варвара лють — це джерело не лише бойового божевілля, а й неймовірних рефлексів, стійкості, а також неперевершеної сили.</p>'+
'<h3>Первісні інстинкти</h3>'+

// NOTE — зупинився тут
'<p>Жителі селищ і міст настільки пишаються своєю цивілізованістю, що відрізняє їх від тварин, немов заперечення власної природи підкреслює їхню перевагу. Варвари вважають цивілізованість проявом слабості. Звʼязок між їхніми тваринними інстинктами, первісною енергетикою та скаженою люттю дуже сильний. Варвари почуваються незатишно в оточенні стін або в натовпі, але розкриваються в рідних диких просторах, тундрі, джунглях або степах, де їхні племена живуть і полюють.'+
'<br /><br />Найкраще варвари проявляють себе серед хаосу битви. Вони можуть впасти в стан берсерка, втративши контроль над власною люттю, і натомість одержуючи нелюдську силу і стійкість. Варвар може лише кілька разів скористатися резервами власного гніву, перш ніж йому знадобиться відпочинок, але зазвичай цих кількох разів вистачає, щоб упоратися з будь-якою загрозою, яку зустріли на шляху.</p>'+
'<h3>Життя, повне небезпек</h3>'+
'<p>Не всі члени племен, яких у суспільстві називають варварами, мають клас «варвар». Справжній варвар серед цих людей рідкісний, як досвідчений воїн у місті, і він виконує схожу роль захисника людей і військового лідера. Життя в диких місцях приховує у собі небезпеку: племена, що суперничають, смертельно небезпечна погода і жахливі чудовиська. І варвар кидається у боротьбу з цими небезпеками, захищаючи своїх людей.'+
'<br /><br />Сміливість перед небезпекою робить варвара чудовим кандидатом у шукачі пригод. Кочовий спосіб життя часто звичний для примітивних племен, і непосидюче життя авантюристів не складає труднощів для варвара. Деякі варвари сумують за згуртованим сімейним укладом своїх племен, але врешті-решт знаходять заміну в узах, що звʼязують членів загону.</p>';

export const berserker = "берсерк";

export const berserker_details = "Для деяких варварів лють є способом досягнення мети, і цією метою є насильство. Шлях берсерка це шлях, залитий кровʼю, шлях нестримної люті. Впадаючи в лють берсерка, ви кидаєте себе в хаос бою, не дбаючи більше про своє здоровʼя.";

export const totem_warrior = "тотемний воїн";

export const totem_warrior_details = 
"Дух звіра стає для варвара наставником, захисником та натхненником. У бою дух тотема дарує вам надприродну міць, наповнюючи вашу лють магічною силою. Більшість варварських племен повʼязують свої клани з конкретними духами-покровителями, і мати кілька тотемних парфумів вважається незвичайним, хоча такі винятки і трапляються.";

export const totem_spirit = "тотемний дух";

export const totem_spirit_details = "Оберіть свій тотемний дух і отримайте його вміння. Ви можете набути незначних фізичних змін, що нагадують вашу тотемну тварину. Воно може бути природним для вашої рідної місцевості. Наприклад, замість орла може бути яструб чи гриф.";

export const totem_aspect = "аспект звіра";

export const totem_aspect_details = "Оберіть властивість, що залежить від обраної вами тотемної тварини. Ви можете вибрати ту саму тварину, що й раніше, або іншу.";

export const totem_harmony = "гармонія тотема";

export const rogue = "пройдисвіт";

export const rogue_details = 
'<p>Пройдисвіти покладаються на майстерність, непомітність і вразливі місця ворогів, щоб перемогти у будь-якій ситуації. У них досить вправності, щоб знайти рішення в будь-якій ситуації, демонструючи винахідливість та гнучкість, які є наріжним каменем будь-якої успішної групи шукачів пригод.</p>'+
'<h3>Навички та точність</h3>'+
'<p>Пройдисвіти докладають зусиль для освоєння різних навичок, а також удосконалюють свої бойові здібності, що дають їм великий набір прийомів, яким мало хто може похвалитися. Багато пройдисвітів зосереджуються на непомітності та обмані, інші ж вдосконалюють навички, що допомагають їм у підземеллях, такі як лазіння, пошук та знешкодження пасток, та відкриття замків.'+
'<br /><br />У бою пройдисвіти віддають перевагу хитрості, а не грубій силі. Пройдисвіту достатньо зробити один точний удар туди, де напад найбільше зашкодить ціль. Пройдисвіти мають майже надприродну спритність для уникнення небезпек, а деякі навчилися магічним трюкам на додаток до своїх здібностей.</p>'+
'<h3>Темне життя</h3>'+
'<p>У кожному місті та селищі є своя частка пройдисвітів. Більшість із них живе згідно з найгіршими стереотипами класу, заробляючи на життя пограбуваннями, вбивствами, зрізанням гаманців та шахрайством. Часто ці негідники обʼєднуються у гільдії крадіїв чи злочинні клани. Багато пройдисвітів діють самостійно, але й вони іноді беруть учнів, які допомагають у шахрайстві та пограбуванні. Деякі пройдисвіти живуть чесним життям слюсарів, слідчих або щуроловів, робота яких дуже небезпечна через живих у каналізації лютих щурів-перевертнів.'+
'<br /><br />Пройдисвіти-шукачі пригод зустрічаються по обидва боки закону. Частина з них — затяті злочинці, які вирішили шукати удачу та скарби, а інша частина живе життям шукачів пригод, щоб уникнути закону. Деякі здобули та вдосконалили свої навички з метою проникнення в стародавні руїни та приховані склепи у пошуках скарбів.</p>';

export const thief = "крадій";

export const thief_details = "Ви відточили навички крадійського мистецтва. Грабіжники, бандити, кишенькові злодії та інші злочинці, як правило, дотримуються цього архетипу, але його ж вибирають і пройдисвіти, які вважають за краще думати про себе як про професійних шукачів скарбів, дослідників, посильних і шукачів. На додаток до поліпшення спритності та хитрості, ви дізнаєтесь навички, які стануть у нагоді під час вивчення стародавніх руїн, читання незнайомих мов, використання магічних предметів, які зазвичай ви не можете використовувати.";

export const assasin = "вбивця";

export const assasin_details = "Ви зосередили своє навчання на похмурому мистецтві смерті. Ті, хто дотримуються цього архетипу, різноманітні: наймані вбивці, шпигуни, мисливці за головами і навіть особливо помазані священики, навчені знищувати ворогів свого божества. Непомітність, отрута та маскування допоможуть у боротьбі з ворогами зі смертоносною ефективністю.";

export const paladin = "паладин";

export const paladin_details = 
'<p>Незалежно від походження та місії, паладинів обʼєднує їхня клятва протистояти силам зла. Чи принесена перед вівтарем бога і завірена священиком, чи на священній галявині перед духами природи і феями, чи в момент розпачу і горя смерті, присяга паладина — могутній договір. Це джерело сили, яке перетворює побожного воїна на благословенного героя.</p>'+
'<h3>Джерело праведності</h3>'+
'<p>Паладин присягається захищати справедливість і праведність, відстоювати чесноти миру перед вторгненнями темряви, і полювати на сили зла, де б вони не ховалися. Різні паладини зосереджуються на різних аспектах праведності, але всі вони повʼязані клятвою, яка дає їм сили для здійснення їхньої священної справи. Багато паладинів віддані богам добра, але сила паладина походить скоріше від власного прагнення до справедливості, ніж від божества.'+
'<br /><br />Паладини тренуються роками, щоб навчитися навичкам бою, освоюючи володіння різними видами зброї та обладунків. Проте їхні військові навички вторинні порівняно з магічною силою, якою вони володіють: силою зцілювати хворих та поранених, карати нечестивців та невмерлих, захищати невинних і тих, хто приєднався до них у боротьбі за справедливість.</p>'+
'<h3>За мирським життям</h3>'+
'<p>Життя паладина це життя шукача пригод. Поки важкі травми не зроблять їх інвалідами, паладини перебувають на лінії фронту світової боротьби зі злом. У лавах ополчень та армій світу мало представників класу воїн, і ще менша кількість простих людей можуть претендувати на справжнє звання паладина. Почувши поклик, ці воїни залишають колишнє ремесло та беруть до рук зброю, щоб боротися зі злом. Іноді їхні клятви ведуть їх до служби панам як лідерів елітних груп лицарів, але навіть тоді їхня вірність насамперед віддана справі праведності, а не короні чи країні.'+
'<br /><br />паладини, що стали шукачами пригод, ставляться до своєї справи серйозно. Похід у стародавні руїни або запилений склеп може бути обумовлений пошуком вищої мети, ніж похід за скарбами. Зло таїться в підземеллях та дрімучих лісах, і навіть майменьші перемоги над ним можуть утримати світову рівновагу від небуття.</p>';

export const oath_of_devotion = "клятва відданості"

export const oath_of_devotion_details = 
'<p><i>Чесність. Честь. Обовʼязок. Відвага. Співчуття.</i>'+
'<br/><br/>Лицар у сяючих обладунках. Звʼязок з піднесеними ідеалами справедливості, чесноти та порядку. Дії з честю у прагненні до справедливості та вищого добра. Підтримує найвищі норми поведінки, і деякі, на краще чи гірше, ведуть світ до тих самих стандартів. Багато хто дав цю клятву, шанують богів добра і закону, і слідують їх догматам як доказ своєї відданості. Вшановують ангелів — досконалих слуг добра, і зображують ангельські крила на шоломах та гербах.</p>';

export const oath_of_antients = "клятва давніх"

export const oath_of_antients_details = 
'<p><i>Справами милосердя, доброти та прощення розпалюй світло надії у світі, руйнуючи ниць розпач. Зберігай добро, красу, кохання та сміх навколо. Захоплюйся піснею, сміхом, красою та мистецтвом, зберігаючи світло у собі. Будь маяком світла для тих, хто живе у розпачі.</i>'+
'<br/><br/>Зелений лицар. Паладини, які дали цю клятву, повʼязують свою долю зі стороною світла у боротьбі проти темряви, тому що вони люблять гарні та життєдайні речі у світі, а не тому, що вірять у принципи честі, відваги та справедливості. Вони прикрашають свої обладунки та одяг зображеннями рослинності, рогів та квітів.</p>';

export const oath_of_vengence = "клятва помсти"

export const oath_of_vengence_details = 
'<p><i>Мої вороги сіють розруху у світі, бо мені ще не вдалося їх зупинити. Допомагаючи тим, хто постраждав від їхніх злодіянь, мої докори совісті не можуть стати на шляху винищення моїх ворогів за всяку ціну. Між боротьбою із заклятими ворогами та боєм із меншим злом, я вибираю більше зло. Звичайні вороги можуть розраховувати на пощаду, але не мої закляті вороги.</i>'+
'<br/><br/>Темний лицар. Зобовʼязання карати тих, хто вчинив тяжкий гріх. Коли сили зла вирізають безпорадних селян, коли цілий народ відвертається від волі богів, коли гільдія злодіїв стає надто жорстокою та сильною, коли дракон тероризує сільську місцевість — зʼявляються паладини, що дали клятву помсти, щоб встановити, що правильно, а що помилково. Для цих паладинів власна чистота не так важлива, як створення правосуддя.</p>';

export const bard = "бард";

export const bard_details = 
'<p>Неважливо, ким є бард: вченим, скальдом чи шахраєм; він плете магію зі слів і музики, надихаючи союзників, деморалізуючи противників, маніпулюючи свідомістю, створюючи ілюзії, і навіть зцілюючи рани.</p>'+
'<h3>Музика та магія</h3>'+
'<p>У світах D&D слова і музика це не просто коливання повітря, тут вони містять силу. І бард є майстром промов, пісень, і чарів, закладених в них. Барди кажуть, що весь мультивсесвіт був викликаний до існування і сформований словами, які промовили боги. І відголоски цих первородних слів Творіння досі звучать у космосі. Музика бардів — це спроба вловити ці відгомони і тонко вплести їх у свої чари.'+
'<br /><br />Найбільш сильною рисою бардів є їхня виняткова різнобічність. Багато бардів вважають за краще триматися не на передовій у бою, використовуючи свою магію для натхнення союзників і перешкоджання противникам на відстані. Але барди здатні захищати себе і в ближньому бою, за необхідності використовуючи магію для зміцнення своїх клинків та обладунків. Їхні чари найчастіше призначені для зачарування та створення ілюзій, а не для створення явних руйнувань. Вони мають великі знання в безлічі тематик і природні здібності, які дозволяють їм робити добре практично все. Барди стають майстрами в тих талантах, яких прагнуть їхні уми, відточуючи їх до досконалості, від музичних виступів до езотеричних знань.</p>'+
'<h3>Навчання на досвіді</h3>'+
'<p>Справжні барди не є повсякденною річчю у світі. Не кожен менестрель, який співає у таверні, або блазень, що скаче при королівському дворі, є бардом. Щоб виявити приховану в музиці магію, потрібне важке навчання та деякий ступінь природного таланту, чого більшості трубадурів та жонглерів бракує. Проте визначити різницю між цими виконавцями може бути важко. Життя барда проходить у мандрах по землях і присвячене збору знань, розповіді історій та існуванню за рахунок подяки публіки, як і в будь-якого іншого артиста. Але глибина знань, рівень музичної майстерності та звʼязок з магією ставить бардів на інший щабель від їхніх побратимів.'+
'<br /><br />Барди рідко живуть довго в одному місці, і їх природний потяг до мандрів повʼязаний з пошуком нових оповідей, навчанням нових навичок і відкриттям нових горизонтів, що робить карʼєру шукача пригод для них природним покликанням. Кожна пригода — це можливість чомусь навчитися, попрактикувати різні навички, проникнути в давно занедбані гробниці, виявити втрачені твори магії, розшифрувати старі фоліанти, пройтися незнайомими місцями, або зіткнутися з екзотичними істотами. Барди люблять супроводжувати героїв, щоб засвідчити їх звершення з перших рук.'+
'<br /><br />Бард, який може розповісти вражаючу історію з особистого досвіду, заробляє популярність серед інших бардів. Більше того, після розповіді такої великої кількості історій про великі звершення героїв, багато бардів приймають їх близько до серця і хочуть взяти роль героя самі.</p>';

export const college_valor = "коллегія звитяги"

export const college_valor_details = 
'<p>Барди колегії звитяги — це відважні скальди, чиї оповіді не дають забути великих героїв минулого та надихають на подвиги нинішніх героїв. Такі барди збираються в медових залах або навколо похідних вогнищ, щоб оспівати великі звершення минулого та сьогодення.'+
'<br/><br/>Вони мандрують, щоб стати очевидцями великих подій і не дати памʼяті про них згаснути. Своїми піснями вони надихають досягнення, гідні памʼяті предків.</p>';

export const college_lore = "колегія знань"

export const college_lore_details = 
'<p>Барди колегії знань потроху знають про все на світі. Вони збирають крихти знань звідусіль, чи то наукові праці, чи то селянські байки. Чи співають вони народні балади у таверні, чи виконують вишукані композиції при дворі, ці барди використовують свій талант, щоб зачарувати публіку. І коли змовкають оплески, публіка може застати себе розповіді всіх своїх секретів, починаючи зі пліток місцевого приходу, і закінчуючи подробицями своєї вірності королеві.'+
'<br/><br/>Лояльність таких бардів базується на прагненні краси і правди, а не на вірності монархам чи прихильності релігійним догматам. Дворяни, які містять таких бардів як радників чи герольдів, добре знають, що бард швидше скаже неприємну правду, ніж розсудливо промовчить.'+
'<br/><br/>Члени колегії збираються в бібліотеках, а іноді навіть у коледжах, зі справжніми класами та гуртожитками. Там вони діляться одне з одним знаннями. Вони також відвідують фестивалі та громадські збори, де можуть виявляти розтратників, викривати брехунів або виставляти на посміховисько самовпевнених політиків.</p>';

export const fighter = "боєць";

export const fighter_details = 
'<p>Мандрівні лицарі, воєначальники-завойовники, королівські чемпіони, елітна піхота, броньовані найманці і королі розбою — будучи бійцями, всі вони майстерно володіють зброєю, обладунками та прийомами ведення бою. А ще вони добре знайомі зі смертю — вони несуть її самі, і часто дивляться у її холодні очі.</p>'+
'<h3>Різнобічні спеціалісти</h3>'+
'<p>Бійці володіють основами всіх бойових стилів. Кожен боєць може рубати сокирою, фехтувати рапірою, володіє довгим і дворучним мечем, може стріляти з лука і навіть при певній вправності здатний зловити супротивника сіткою. Крім цього, воїни добре знайомі з використанням щита та будь-яких обладунків. Окрім загальних знань, кожен боєць спеціалізується на певному стилі бою. Деякі концентруються на стрільбі з лука, інші на битві зі зброєю у кожній руці, а є ті, хто свої військові здібності посилює чарами. Поєднання широких загальних навичок та поглибленої спеціалізації робить бійців неперевершеними на полі бою.</p>'+
'<h3>Готові до небезпеки</h3>'+
'<p>Не всі члени міської варти, сільського ополчення чи королівської армії є бійцями. Переважно це просто навчені солдати, які володіють лише основними військовими навичками. Солдати-ветерани, офіцери, навчені охоронці, присвячені лицарі та схожі персони, як правило, є бійцями.'+
'<br /><br />Деякі бійці відчувають потребу використовувати свою підготовку як шукачі пригод. Дослідження підземель, вбивство чудовиськ, та інша небезпечна робота, звичайна для шукачів пригод, є другою натурою бійця, і не так сильно відрізняється від життя, залишеного в минулому. Ризик тут, можливо, і вищий, але й нагорода значно більша — наприклад, воїни у міській варті навряд чи можуть знайти меч язик полумʼя.</p>';

export const battle_master = "Майстер бойових мистецтв";

export const battle_master_details = 
'<p>Боєць, що покладається на техніку, випрацьовану поколіннями бійців.'+
'<br/><br/>Для такого бійця битва схожа на академічне завдання, і часто включає речі, далекі від бою, на кшталт ковальської майстерності або каліграфії. Не всі бійці здатні ввібрати уроки історії, теорію та артистизм, відображені в архетипі майстра бойових мистецтв, але ті, хто зміг зробити це, є добре підготовленими воїнами, які мають прекрасні навички та знання.</p>';

export const eldritch_knight = "Містичний лицар";

export const eldritch_knight_details = 
'<p>Військове мистецтво, поєднане з ретельним вивченням чаклунства. Містичні лицарі використовують прийоми, схожі на практики магів. Але вони зосередилися на вивченні двох із восьми шкіл магії: Втілення та Перешкоджання. Чари Перешкоджання забезпечують містичним лицарям додатковий захист у бою, а Втілення завдають шкоди відразу кільком противникам за раз, збільшуючи можливості лицаря у бою.'+
'<br/><br/>Ці лицарі мають відносно невелику кількість заклинань, що дозволяє постійно тримати їх у памʼяті і не використовувати книгу заклинань.</p>';

export const champion = "Чемпіон";

export const champion_details = 
'<p>Зазвичай, чемпіони фокусуються на використанні чистої фізичної сили, відточеної до смертельної досконалості. Той, хто вибрав для себе архетип чемпіона, поєднує суворі тренування з фізичною досконалістю, що дозволяє завдавати смертоносних ударів.</p>';

export const wizard = "Чарівник";

export const wizard_details = 
'<p>Чарівники — адепти вищої магії, що обʼєднуються по типу своїх заклять. Тримаючись на тонкі плетіння магії, що пронизує всесвіт, чарівники здатні створювати чари вибухового вогню, яскравих блискавок, тонкого обману і грубого контролю над свідомістю. Їхня магія викликає чудовиськ з інших планів буття, передбачає майбутнє і звертає повалених ворогів у зомбі. Їхні могутні чари можуть перетворювати одну речовину на іншу, викликати метеорити з небес і відкривати портали в інші світи.</p>'+
'<h3>Адепти магії</h3>'+
'<p>Дика і загадкова, різноманітна за формою та функціями магія приваблює учнів, які прагнуть досягти майстерності в її таємницях. Дехто хоче уподобитися богам, змінювати саму реальність. І хоча для створення простих чарів потрібна лише вимова кількох дивних слів, здійснення пари скороминучих жестів, а іноді щіпка чи грудок екзотичних матеріалів, ці поверхові прояви неспроможні показати досвід, досягнутий довгими годинами учнівства і численними годинами досліджень. Чарівники живуть і вмирають зі своїми чарами. Все інше вторинне. Вони вчать нові чари, експериментуючи і стаючи досвідченішими. Вони також можуть дізнатися їх від інших чарівників, з давніх томів або сувоїв, і від давніх істот (таких як феї), які занурені в магію.</p>'+
'<h3>Спокуса знань</h3>'+
'<p>Життя чарівників рідко буває повсякденним. Найбільш близькі до простого життя, ймовірно, ті з магів, які працюють знавцями чи лекторами у бібліотеці університету, навчаючи інших секретів мультивсесвіту. Інші чарівники пропонують свої послуги як віщунів, служать у збройних силах, або проводять життя у злочинах та прагненні до влади.'+
'<br /><br />Але спокуса знань і сили кличе навіть найобділеніших сміливістю чарівників, і витаскує з безпеки їхніх бібліотек та лабораторій у зруйновані руїни та втрачені міста. Більшість чарівників вважають, що їхні колеги з давніх цивілізацій знали секрети магії, які були втрачені навіки, і ті секрети, що відкрили, зможуть володіти силами, недоступними нині.</p>';

export const school_of_evocation = "Школа Втілення";
export const school_of_evocation_details =
'<p>Ви зосередили свої дослідження на магії, яка створює могутні стихійні ефекти, такі як крижаний холод, пекуче полумʼя, грім, тріскаючі блискавки і їдка кислота.'+
'<br /><br />Деякі чарівники школи Втілення знаходять своє покликання в армії, йдучи на службу до артилерії та знищуючи ворожі армії здалеку. Інші використовують свої вражаючі можливості для захисту слабких. Інші ж шукають свою власну вигоду на терені бандита, шукача пригод або тирана-початківця.</p>'; // перекласти

export const school_of_conjuration = "Школа Зʼявлення";
export const school_of_conjuration_details =
'<p>Як викликач, ви віддаєте перевагу чарам, які створюють предмети та істоти з повітря. Ви можете силою думки викликати хмари, вбивчий туман або закликати звідки завгодно істот для битви на вашому боці. Зі зростанням майстерності ви вивчите чари переміщення і зможете миттєво телепортуватися на величезні відстані, або навіть в інші плани існування.</p>';

export const school_of_illusion = "Школа Ілюзії";
export const school_of_illusion_details =
'<p>Ви навчаєтесь магії, яка обманює відчуття, затьмарює розум і дурить навіть наймудріших. Ваша магія витончена, але ілюзії, зіткані вашим гострим розумом, змушують неможливе здаватися реальним. Деякі ілюзіоністи, включаючи багатьох чарівників гномів, є трюкачами, які використовують свої чари у виставах. Інші, зловісніші майстри обману, використовують свої ілюзії, щоб лякати і обманювати інших для власної вигоди.</p>';

export const school_of_necromancy = "Школа Некромантії";
export const school_of_necromancy_details =
'<p>Школа Некромантії вивчає вселенські сили життя, смерті та не-життя. Якщо ви зосередите свої дослідження в цьому напрямку, то дізнаєтеся, як маніпулювати тією енергією, яка живить усі живі організми. У міру збільшення ваших знань ви навчитеся витягувати життєві сили істот, руйнуючи своєю магією їх тіла, трансформуючи їх у магічну енергію, якою можете маніпулювати.'+
'<br /><br />Більшість звичайних людей бачить у некромантах загрозу або навіть вселенське зло завдяки їх тісному звʼязку зі смертю. Але не всі некроманти злі. Просто сили, якими вони керують, є табу для суспільства.</p>';

export const school_of_abjuration = "Школа Перешкоджання";
export const school_of_abjuration_details =
'<p>Школа Перешкоджання зосереджена на магії блокування, вигнання та захисту. Противники цієї школи кажуть, що її традиції у запереченні, а не у позитивному ствердженні. Однак припинення дії жахливих ефектів, захист слабких і відплата злу може бути чим завгодно, але не філософською порожнечею. Це горде та шановне покликання.'+
'<br /><br />Члени цієї школи, огороджувачі, стають затребуваними, коли злі духи потребують екзорцизму, коли важливі місця повинні бути захищені від магічного шпигунства, і коли портали на інші плани буття повинні бути закриті.</p>';

export const school_of_enchantment = "Школа Зачаровування";
export const school_of_enchantment_details =
'<p>Як адепт школи Чарів, ви знаєте, як за допомогою магії зачарувати і обдурити інших істот і чудовиськ. Деякі чарівники є миротворцями, які підкоряють ворожих істот, щоб ті склали зброю або жорстокі проявили милосердя. Інші є тиранами, що зачаровують тих, хто не бажає йти до них на службу. Існує також безліч інших магів, які представляють щось середнє.</p>';

export const school_of_transmutation = "Школа Перетворення";
export const school_of_transmutation_details =
'<p>Ви були навчені чарам, що змінюють енергію та матерію. Для вас світ не є чимось постійним, навпаки, він дуже мінливий, і ви насолоджуєтеся своєю роллю агента змін. Ви вмієте маніпулювати сировиною створення та можете змінювати як фізичну форму, так і ментальні якості. Ваша магія дає вам можливість стати ковалем у кузні реальності.'+
'<br /><br />Деякі перетворювачі є пустунами, що перетворюють людей на жаб і перетворюють мідь на срібло заради забави та випадкової наживи. Інші проводять свої магічні дослідження з усією можливою серйозністю, шукаючи силу богів, щоб створювати та руйнувати світи.</p>';

export const school_of_divination = "Школа Віщування";
export const school_of_divination_details =
'<p>Радник-віщун популярний у знаті і простолюдинів, тому що всі хочуть знати про минуле, сьогодення та майбутнє. Як віщун, ви прагнете зняти покриви простору, часу та свідомості, щоб бачити більш ясно. Ви працюєте над освоєнням заклинань інтуїції, віддаленого спостереження, надприродного знання та передбачення.</p>';

export const druid = "друїд";

export const druid_details = 
'<p>Виликаючи стихії або наслідуючи тварин, друїди втілюють непорушність, пристосовність та гнів природи. Вони ні в якому разі не володарі природи — натомість друїди відчувають себе частиною її непереборної волі.</p>'+
'<h3>Сила природи</h3>'+
'<p>Вище чогось іншого друїди шанують природу. Саме вона є джерелом всіх їх заклять і магічних здібностей, безпосередньо або через природне божество. Багато хто віддає перевагу духовному шляху кінцевого єднання з природою, але є й ті, хто служить богам диких просторів, тварин чи стихій. Традиції друїдів, що тривають споконвіку, також називають Старою Вірою, протиставляючи їх поклонінню в храмах і біля вівтарів.'+
'<br /><br />Чари друїдів діють через тварин та навколишню природу. Це сила ікла та кігтя, сонця та місяця, вогню та бурі. Також друїди вчаться приймати вигляд тварин, і деякі настільки заглиблюються в це вміння, що віддають перевагу тваринному вигляду.</p>'+
'<h3>Збереження рівноваги</h3>'+
'<p>Для друїдів природа є хиткою рівновагою. Повітря, земля, вогонь і вода — ті чотири стихії, що становлять основу світу, повинні врівноважувати один одного. Якщо одна зі стихій набуде переваги, сам світ може припинити існування, притягнутий стихійним виміром і розірваний на елементарні ті, що залишають. Щоб не допустити цього, друїди протистоять сектам Стихійного Зла та всім, хто прагне наділити будь-яку зі стихій переважаючою силою. Також друїдів турбує тонка екологічна рівновага, необхідна флорі та фауні, і готовність цивілізованих поселень жити в гармонії з природою, а не протиставляти себе їй. Жорстокість природи – частина природного порядку речей, проте друїди не терплять все чужорідне, включаючи аберації (іллітиди та бехолдери) та неменртвих (зомбі та вампіри). Іноді друїди роблять вилазки на таких істот, особливо якщо ті наближаються до територій.'+
'<br /><br />Священна земля та області незайманої природи нерідко охороняються друїдом. Втім, при достатній загрозі природній рівновазі або підзахисній землі друїд може перейти до активної боротьби з напастком, ступивши на дорогу шукача пригод.</p>';

export const circle_of_the_land = "Круг Землі"

export const circle_of_the_land_details = 
'<p>Круг Землі складається з містиків і мудреців, що поколіннями передають із вуст у вуста складну систему древніх знань та обрядів в кільці дерев або мегалітів. Наймудріші представники Круга служать духовними лідерами та радниками вождів у громадах, які проповідують Стару Віру.';

export const circle_of_the_land_arctic = "Тундра";
export const circle_of_the_land_arctic_details = 'Причетність до кола Землі наділяє магію друїда силою тих місць, де персонажа посвятили у священні обряди. Для вас це крижана тундра.';

export const circle_of_the_land_coast = "Узбережжя";
export const circle_of_the_land_coast_details = 'Причетність до кола Землі наділяє магію друїда силою тих місць, де персонажа посвятили у священні обряди. Для вас це камʼянисте узбережжя.';

export const circle_of_the_land_desert = "Пустеля";
export const circle_of_the_land_desert_details = 'Причетність до кола Землі наділяє магію друїда силою тих місць, де персонажа посвятили у священні обряди. Для вас це пекуча пустеля.';

export const circle_of_the_land_forest = "Ліс";
export const circle_of_the_land_forest_details = 'Причетність до кола Землі наділяє магію друїда силою тих місць, де персонажа посвятили у священні обряди. Для вас це густій ліс.';

export const circle_of_the_land_grassland = "Луг";
export const circle_of_the_land_grassland_details = 'Причастность к кругу Земли наделяет магию друида силой тех мест, где персонажа посвятили в священньіе таинства. Для вас єто зелений луг.';

export const circle_of_the_land_mountain = "Гори";
export const circle_of_the_land_mountain_details = 'Причастность к кругу Земли наделяет магию друида силой тех мест, где персонажа посвятили в священньіе таинства. Для вас єто високі гори.';

export const circle_of_the_land_swamp = "Болото";
export const circle_of_the_land_swamp_details = 'Причастность к кругу Земли наделяет магию друида силой тех мест, где персонажа посвятили в священньіе таинства. Для вас єто підступне болото.';

export const circle_of_the_land_underdark = "Підземелля";
export const circle_of_the_land_underdark_details = 'Причастность к кругу Земли наделяет магию друида силой тех мест, где персонажа посвятили в священньіе таинства. Для вас єто темне підземелля.';

export const circle_of_the_moon = "Круг Місяця";
export const circle_of_the_moon_details = 
'<p>Друїди кругу Місяця – непримиренні варти дикої природи. Їхні збори проходять під повним місяцем для обміну новинами та застереженнями. Вартові нишпорять у самій дрімучій глушині, де можна тижнями не зустріти іншого гуманоїда, не кажучи вже про друїда. Мінливі як сам місяць і дикі до глибини душі, друїди цього кругу то крадуться кішкою, то парять орлом, то ломляться у зовнішності ведмедя, лякаючи зачудливе чудовисько.</p>';

export const cleric = "Клірик";
export const cleric_details = 
'<p>Клірики є посередниками між світом смертних і далекими світами богів. Такі ж різні, наскільки боги, яким вони служать, Клірики втілюють роботу своїх божеств. На відміну від звичайного проповідника, Клірик наділений божественною магією.</p>'+
'<h3>Цілителі та воїни</h3>'+
'<p>Божественна магія, як випливає з назви, є силою богів, що випливає з них у світ. Клірики є провідниками цієї енергії, що проявляється у вигляді чудотворних ефектів. Боги не дають такої влади всім, хто звертається до них, а тільки обраним виконувати високе покликання.'+
'<br /><br />Ті, хто використовують божественну магію, не покладаються на навчання чи тренування. Клірик може вивчити шаблонні молитви та давні ритуали, але здатність читати чари залежить від відданості та інтуїтивного відчуття волі божества.'+
'<br /><br />Клірики поєднують корисну магію зцілення та натхнення союзників із чарами, які шкодять і перешкоджають ворогам. Вони можуть викликати страх і жах, накласти прокляття чи хвороби, отруїти і навіть закликати полумʼя з небес, щоб знищити своїх супротивників. Зустрівшись із грішниками, яким допоможе лише удар булавою по голові, Клірики покладаються на свою бойову підготовку, що дозволяє їм вступити у ближній бій із силами богів на своєму боці.</p>'+
'<h3>Божественні посередники</h3>'+
'<p>Не кожен послушник чи священнослужитель у храмі чи святилищі є Кліриком. Деякі проповідники покликані до простого життя службовця у храмі, виконуючи волю своїх богів за допомогою молитви та жертв, а не за допомогою магії та сили зброї. У деяких містах духовенство займає політичні посади, або розглядається як трамплін для вищих посад, і взагалі не спілкується з богом. Справжні Клірики — рідкість у більшості ієрархій.'+
'<br /><br />Якщо Клірик приходить до життя шукача пригод, то, як правило, через те, що його бог вимагає цього. Переслідуючи цілі богів, коли часто потрапляєш у небезпечні ситуації поза стінами цивілізацій, доводиться карати зло та шукати святі реліквії у стародавніх гробницях. Багато Кліриків також повинні захищати послідовників своїх божеств, що може означати битву з шаленими орками, ведення переговорів про мир між державами, що воюють, або закриття порталу, через який у світ може пройти князь демонів.'+
'<br /><br />Більшість мандрівних Кліриків підтримує деякий звʼязок з храмами та орденами, що розділяють їхню віру. Храм може попросити допомоги у Клірика, а вищий священик має право просто вимагати її.</p>';

export const domain_thunder = "Домен Бурі";
export const domain_thunder_details = 
'<p>Боги, чиї портфоліо мають домен бурі — у тому числі Талос, Амберлі, Корд, Зебоім, Поглинач, Зевс і Тор — правлять бурями, морями та небесами. Серед них боги грому та блискавок, боги землетрусів, деякі боги вогню та насильства, фізичної сили та безстрашності.'+
'<br /><br />У деяких пантеонах боги цього домену правлять іншими божествами, і відомі швидким судом, що закінчується ударом блискавки. У пантеонах мореплавців боги цього домену є божествами океану та покровителями моряків. Боги бурі відправляють своїх кліриків вселяти страх простим смертним, або наставляючи людей на праведний шлях, або змушуючи їх приносити жертви, щоб уникнути божественного гніву.</p>';

export const domain_war = "Домен Війни";
export const domain_war_details = 
'<p>Війна має багато проявів. Вона може зробити героїв із звичайних людей. Вона може бути відчайдушною та жахливою, з проявами жорстокості та боягузтво, що затьмарюють приклади майстерності та безстрашності. У будь-якому разі, боги війни стежать за воїнами та винагороджують за великі звершення.'+
'<br /><br />клірики таких богів відрізняються у битві, надихаючи інших боротися чи закликаючи до насильства у своїх молитвах. Серед богів війни є чемпіони честі та лицарства (такі як Торм, Хіронеус та Кірі-Джоліт), а також боги руйнування та мародерства (такі як Ерітнул, Лють, Груумш та Арес) та боги завоювань та підкорення (такі як Бейн, Хекстор та Маглибієт) ). Інші боги війни (такі як Темпус, Ніка та Нуада) займають більш нейтральну позицію, сприяючи війні у всіх її проявах та підтримуючи воїнів за будь-яких обставин.</p>';

export const domain_life = "Домен Життя";
export const domain_life_details = 
'<p>Домен життя зосереджений на позитивній енергії — одній з фундаментальних сил всесвіту, який підтримує життя. Боги життя сприяють життєздатності та здоровʼю, зцілюючи хворих і поранених, піклуючись про нужденних, і відганяючи невмерлих і сили смерті.'+
'<br /><br />Майже будь-яке не зле божество може претендувати на вплив у цьому домені, особливо божества землеробства (такі як Чонтіа, Аравай і Деметра), боги сонця (такі як Латандер, Пелор і Ра-Хорахті), боги зцілення та витривалості (такі як Ільматер, Мішакаль , Аполлон і Діан Кехт) і боги домівки і громад (такі як Гестія, Хоар і Болдрей).</p>';

export const domain_knowledge = "Домен Знань";
export const domain_knowledge_details = 
'<p>Боги знань, зокрема Огма, Боккоб, Гілеан, Ореон і Той, цінують освіченість і розуміння насамперед. Деякі вчать збирати знання та робити їх доступними в бібліотеках та університетах, або розвивати практичні знання ремесел та винаходів. Деякі божества збирають знання і зберігають їх у таємниці. А деякі обіцяють своїм послідовникам, що вони матимуть величезну владу, якщо розкриють таємниці мультивсесвіту.'+
'<br /><br />Послідовники цих богів вивчають езотеричні знання, збирають старі фоліанти, досліджують таємні куточки землі та вивчають усе, що зможуть. Деякі боги розвивають практичні знання ремесла та винаходи, у тому числі боги-ковалі Гонд, Реоркс, Онатар, Морадін, Гефест та Гоібніу.</p>';

export const domain_trickery = "Домен Лукавства";
export const domain_trickery_details = 
'<p>Боги лукавства, такі як Тимора, Бешаба, Олідаммара, Мандрівник, Гарл Златоблиск та Локі — інтригани та підбурювачі, які постійно кидають виклик прийнятому порядку серед богів та смертних. Це покровителі злодіїв, негідників, азартних гравців, бунтівників та визволителів.'+
'<br /><br />Їхні клірики представляють руйнівні сили світу, що пронизують гордість, висміюють тиранів, крадуть у багатих, звільняють полонених і зневажають безглузді традиції. Замість прямого протистояння вони обирають хитрощі, витівки, обман і злодійство.</p>';

export const domain_nature = "Домен Природи";
export const domain_nature_details = 
'<p>Боги природи настільки ж різноманітні, як і дика природа, від незбагненних богів глибоких лісів (таких як Сільванус, Обад-Хай, Числев, Балінор і Пан) до доброзичливих божеств, ототожнюваних з джерелами і гаями (таких як Ельдат).'+
'<br /><br />Але багато хто з цих богів мають окрім друїдів також і кліриків — героїв, які виконують активнішу роль просуванні інтересів конкретного бога природи. Ці жерці можуть полювати на злих чудовиськ, що псують лісові угіддя, благословляти врожай віруючих, або висушувати посіви тих, хто прогнівав їхніх богів.</p>';

export const domain_light = "Домен Світла";
export const domain_light_details = 
'<p>Боги світла — у тому числі Хельм, Латандер, Фолтус, Бранчала, Срібне Полумʼя, Беленус, Аполлон і Ра-Хорахті — представляють ідеали відродження та оновлення, істини, пильності та краси, часто використовуючи символ сонця. Деякі з цих богів самі зображуються як сонце чи візник, що веде сонце через небо. Інші — як невтомні вартові, чиї очі пронизують будь-яку тінь і бачать будь-який обман. Деякі є божествами краси та артистизму, які вчать, що мистецтво — це засіб вдосконалення душі.'+
'<br /><br />Клірики бога світла — просвітлені душі, сповнені сяйвом і силою проникливості свого божества, що відганяють геть брехню і спопеляють пітьму.</p>';

export const warlock = "чаклун";

export const warlock_details = 
'<p>Чаклуни — шукачі знань, що ховаються в тканині мультивсесвіту. Через договір, укладений з таємничими істотами надприродної сили, чаклуни відкривають собі магічні ефекти, як ледь вловимі, так і вражаючі. Чаклуни підживлюють свої сили стародавніми знаннями таких істот як феї, демони, дияволи, карги та чужорідні істоти Далекої Межі.</p>'+
'<h3>Клятва та обовʼязок</h3>'+
'<p>Чаклун укладає договір з потойбічною істотою. Іноді відносини між чаклунем та його покровителем схожі на відносини клірика та його божества, але істоти, що виступають у ролі покровителів, зовсім не боги. Чаклун може очолити культ, присвячений принцу демонів, архідияволу або зовсім чужорідній істоті — створінню, яка зазвичай не має кліриків. Набагато частіше їхні взаємини схожі на відносини майстра та учня. Знання чаклуна та його сила зростають у міру надання послуг покровителю.'+
'<br /><br />Магія, якій наділений чаклун, може вносити незначні зміни до його сутності (наприклад, здатність бачити в темряві або читати будь-якою мовою), а також дарує можливість користуватися потужними чарами. На відміну від начитаних магів, чаклуни можуть доповнювати магію деякими елементами рукопашного бою. Вони добре почуваються в легкому обладунку, і знають, як користуватися простою зброєю.</p>'+
'<h3>Пошуки таємниць</h3>'+
'<p>Чаклунами керує ненаситна жага до знань і влади, це змушує їх укладати договори і визначає їхнє майбутнє життя. Історії про чаклунів, які повʼязали себе з виродками, широко відомі. Але не всі покровителі чаклунів — демонічні сутності. Іноді мандрівник знаходить у нетрях лісу гарну вежу, зустрічає її господаря чи господиню — фею, і виявляється втягнутим у договір, не отримавши належної інформації. А іноді, заглибившись у товсті томи книг із забороненими знаннями, учень відкриває свій розум — блискучий, але божевільний, для реальності, яка знаходиться за межами матеріального світу в порожнечі, в якій мешкають чужорідні істоти.'+
'<br /><br />Як тільки договір укладено, жага чаклуна до знань і влади не може бути вгамована звичайним навчанням та дослідженнями. Ніхто не укладає договір із могутнім покровителем без наміру використати набуту силу.'+
'<br /><br />Переважна більшість чаклунів проводить свої дні в гонитві за власними цілями. Як правило, це різного роду пригоди. Крім того, вимоги покровителів ведуть чаклунів назустріч пригодам.</p>';

export const archifey = "архіфея";

export const archifey_details = 
'<p>Ваш покровитель це лорд чи леді фей. Істота з легенд, що зберігає секрети, забуті ще до виникнення смертних рас. Мотиви, які керовані такими сутностями, незбагненні, химерні, і можуть диктуватися прагненням найбільших магічних знань чи віковими образами.'+
'<br /><br />На безмежних просторах дикої території Королівств все ще можна знайти звʼязки з Країною Фей. Це перехрестя фей, місця загадкової природної краси світу, що майже ідеально збігаються з такими місцями в Країні Фей. Перейти перехрестя фей можна увійшовши на галявину, увійшовши в ставок, вступивши в грибне «відьоме коло» або пролізти крізь коріння дерева. Деякі чаклуни шукають таких місць, щоб виторгувати в Архіфей тієї країни силу.</p>';

export const fiend = "погань";

export const fiend_details = 
'<p>Ви уклали договір із поганню із нижніх планів буття — істотою, що має злі прагнення, навіть якщо самі ви не поділяєте їх. Ці сутності прагнуть осквернення та руйнування всього сущого, особливо вас самих.'+
'<br /><br />Незліченні угоди укладаються поганню зі смертними чаклунами по всіх Королівствах — і їх так багато, що «чаклун» став майже синонімом пекельних сил на Фаеруні. Погань включає Архидьяволів Девʼяти Преісподен та їх найбільш впливових герцогів, Демонічних Лордів Безодні та ультралотів, що правлять арміями юголотів. Втім, такі угоди не обовʼязково мають бути укладені з такими потужними сутностями безпосередньо. Часто, слабша погань служить посередниками, і чаклун може навіть не знати кому він служить</p>';

export const great_old_one = "древній";

export const great_old_one_details = 
'<p>Ваш покровитель — таємнича сутність, природа якої зовсім далека від тканини реальності. Він може прийти з Далекої Межі, простору за межами реальності, або це може бути один із стародавніх богів, відомих лише у легендах. Його мотиви незрозумілі смертним, і його знання настільки великі й давні, що навіть найбільші бібліотеки тьмяніють порівняно з секретами, що є в нього. Великий Стародавній може не знати про ваше існування або бути повністю байдужим до вас, але секрети, які ви дізналися, дозволяють черпати магію з цього джерела.'+
'<br /><br />Поза планами існування, відомих магам і мудрецям, лежить Далека Країна, де живуть Древні — непізнавані істоти поза часом і простором. Потрапити туди можна лише через нечестиві ритуали і в снах небагатьох, хто черпає силу таких істот.</p>';

export const monk = "монах";

export const monk_details = 
'<p>Незалежно від обраної дисципліни, всіх монахів обʼєднує одне — можливість керувати енергією, що тече у їхніх тілах. Незалежно від того, чи вона проявляється видатними бойовими здібностями, чи трохи помітним посиленням захисту та швидкості, ця енергія впливає на все, що робить чернець.</p>'+
'<h3>Магія Чі</h3>'+
'<p>Монахи ретельно вивчають магічну енергію, яка у більшості монастирських традицій називається Чі. Ця енергія є складовою магії, що пронизує мультивсесвіт, а точніше, тією її частиною, яка протікає крізь тіла живих. Монахи навчилися керувати енергією, що протікає крізь їхні тіла, підвищуючи фізичні здібності та завдаючи атаки, здатні перешкоджати течії Чі в тілах супротивників. Використовуючи цю енергію, монахи наділяють удари своїх голих рук та ніг неприродною силою та швидкістю. З досвідом їх бойові тренування і тренування володінням Чі дають їм все більше контролю над власними тілами і над тілами ворогів.</p>'+
'<h3>Тренування та аскеза</h3>'+
'<p>Маленькі обгороджені монастирі розкидані світами D&D. Це крихітні притулки, що дозволяють сховатися від потоку повсякденного життя, де саме час, здається, завмирає. Монахи, що живуть тут, шукають шлях до особистої досконалості через споглядання і виснажливі тренування. Багато хто потрапляє до монастиря дітьми, відправленими туди, якщо батькам не вистачало їжі прогодувати їх, якщо батьки померли, або на знак подяки за надану монахами допомогу.'+
'<br /><br />Деякі монахи живуть відокремлено від оточуючих, огородившись від усього здатного перешкоджати їхньому духовному зростанню. Інші дали обітницю ізоляції, зʼявляючись лише як шпигуни чи вбивці, діючи за наказом своїх лідерів, благородних покровителів чи інших, смертних чи божественних сил.'+
'<br /><br />Однак більшість монахів не уникають своїх сусідів і часто відвідують найближчі містечка та селища, обмінюючи свої послуги на їжу та інші товари. Будучи універсальними воїнами, монахи нерідко виявляються захисниками сусідів від чудовиськ чи загарбників.'+
'<br /><br />Для монаха становлення шукачем пригод означає кінець упорядкованого життя у комуні. Це може бути важким кроком, і монахи приймають таке рішення нелегко. Ті, хто покинув свій монастир, ставляться до цього кроку дуже серйозно, сприймаючи свої мандри як випробування свого фізичного та духовного розвитку. Як правило, монахи мало дбають про матеріальні багатства, і ними рухає бажання досягти більшого, ніж просто вбити чудовисько і заволодіти його скарбами.</p>'+
'<h3>Монаські ордени</h3>'+
'<p>Світи D&D містять безліч монастирів та монаських традицій. У землях із азіатським культурним ухилом, таких як Шу Лунь на сході Забутих Королівств, ці монастирі повʼязані з філософськими традиціями та практикою бойових мистецтв. Школа залізної руки, Школа пʼяти зірок, Школа північного кулака та Школа південної зірки з Шу Луня навчають різних підходів до фізичних, психічних та духовних дисциплін монаха. Деякі з цих монастирів поширили свої традиції і на західних землях Фаеруна, особливо в місцях з великими громадами іммігрантів із Шу, таких як Теск та Західна Брама.'+
'<br /><br />Інші монаські традиції повʼязані з божествами, які навчають фізичної досконалості та ментальних практик. У Забутих Королівствах орден Темного місяця складається з монахів, службовців Куля (богиня поневірянь), які підтримують таємні громади у віддалених горах, глухих провулках та підземних сховищах. Монастирі Ільматера (бога витривалості) названі на честь квіток, та їх ордени несуть імена великих героїв віри; Адепти Святого Соллара Двічі-замученого належать монастирю Жовтої Троянди поблизу Дамари. Монастирі Еберрона поєднують вивчення бойових мистецтв із пізнанням. Більшість із них присвячена Верховним Владикам. У світі Саги про Спис більшість монахів віддані Маджері, богу медитації та мислення. У світі Сірого Яструба багато монастирів присвячені Цан Яй, богині сутінків і переваги розуму над матерією, або Цуокену, богу психічної та фізичної майстерності.'+
'<br /><br />Злі монахи Алого Братства у світі Сірого Яструба черпають фанатичну старанність не з відданості богу, а з відданості принципам своєї нації та раси, вважаючи, що цивілізація Суел покликана правити світом.</p>';

export const way_of_the_open_hand = "Відкритої долоні";

export const way_of_the_open_hand_details = 
'<p>Монахи, які обрали цей шлях, є неперевершеними майстрами бойових мистецтв, незалежно від того, чи вони озброєні. Вони вивчають техніки, що дозволяють штовхати та перекидати супротивника, а також лікувати себе, маніпулюючи енергією Чі. Використовувані ними медитації дозволяють захиститися від пошкоджень.</p>';

export const way_of_the_shadow = "Тіні";

export const way_of_the_shadow_details = 
'<p>Монахи, що йдуть по Шляху тіні, цінують хитрість і хитрощі. Ці монахи, яких називають ніндзя або тіньовими танцюристами, служать шпигунами і найманими вбивцями. Іноді члени такого монастиря, будучи однією сімʼєю, утворюють клани та зберігають у таємниці своє мистецтво та цілі. Інші монастирі більше схожі на гільдії злодіїв, надаючи свої послуги знаті багатим купцям і всім, хто може заплатити. Незалежно від методів, керівники цих монастирів очікують беззаперечної покірності своїх учнів.</p>';

export const way_of_the_four_elements = "Чотирьох стихій";

export const way_of_the_four_elements_details = 
'<p>Ви дотримуєтеся монастирської традиції, яка навчає керувати чотирма стихіями. Сфокусувавши свою Чі, ви можете налаштуватися на сили світобудови та підпорядкувати чотири стихії своїй волі, використовуючи їх як продовження власного тіла. Деякі члени цієї традиції повʼязують себе з одним елементом, інші ж поєднують їх. Багато монахів цієї традиції покривають свої тіла татуюваннями, що відображають їхню енергію Чі. Зазвичай це дракони, що звиваються, але зустрічаються також фенікси, риби, рослини, гори і пінисті хвилі.</p>';

export const arcane_trickster = "Містичний спритник";

export const arcane_trickster_details = 
'<p>Деякі пройдисвіти посилюють свої чудово відточені навички непомітності та спритності магією, навчаючись премудростям чар та ілюзій. Цими пройдисвітами стають не тільки кишенькові крадії та грабіжники, а й жартівники, інтригани та достатня кількість шукачів пригод.</p>';

export const ranger = "рейнджер";

export const ranger_details = 
'<p>Вдалині від метушні міст і селищ, за огорожами, які захищають найвіддаленіши ферми від жахів дикої природи, серед дерев, що стоять щільно, бездоріжжя лісів і на просторах неосяжних рівнин рейнджери несуть свою нескінченну варту.</p>'+
'<h3>Смертоносні мисливці</h3>'+
'<p>Бійці диких земель, рейнджери, що спеціалізуються на полюванні на монстрів, таких як люті звірі, жахливі створіння, страшні велетні та смертоносні дракони, що загрожують нападом на цивілізовані землі гуманоїдів. Вони вміють відстежувати здобич подібно до хижака, потай пересуваючись через нетрі, ховаючись серед кущів і каміння. Тренуючись битися, рейнджери віддають перевагу бойовій підготовці на битвах з обраними ворогами.'+
'<br /><br />Завдяки близькості з дикою природою, рейнджери набувають здатності творити заклинання, закликаючи до сил природи подібно до друїдів. Їхні чари, як і бойові навички, роблять акцент на швидкість, скритність та полювання. Таланти та можливості рейнджера відточуються з концентрацією на захисті прикордонних земель.</p>'+
'<h3>Незалежні шукачі пригод</h3>'+
'<p>Хоча рейнджер може заробити на життя як мисливець чи провідник, його справжнім покликанням є захист прикордонних земель від руйнівних дій чудовиськ і гуманоїдних орд, які приходять з диких земель. У деяких місцях рейнджери збираються в таємні ордени або поєднують зусилля з колами друїдів. Однак, багато рейнджерів незалежні, тому що знають, що якщо нападе дракон або загін орків, воно буде першою (і найчастіше останньою) лінією оборони.'+
'<br /><br />Ця шалена незалежність робить рейнджерів придатними для пригод, оскільки вони звикли жити далеко від комфорту сухого ліжка та гарячої ванни. Зіткнувшись із вихованими у місті шукачами пригод, які ниють про тяготи життя в дикій природі, рейнджери реагують на це з сумішшю веселощів, розчарування та співчуття. Але вони розуміють, що інші шукачі пригод, які роблять свій внесок у справу боротьби з ворогами цивілізації, варті додаткових зусиль. Зніжені городяни можуть не знати, як прогодувати себе або знайти прісну воду в дикій природі, але компенсують це незнання своїми навичками.</p>';

export const hunter = "мисливець";

export const hunter_details = 
'<p>Вибір архетипу Мисливця означає прийняття своєї опорної ролі між цивілізацією і жахіттями диких земель. Обравши цей шлях, ви опановуєте спеціалізовані техніки боротьби проти загроз, із якими стикаєтеся — від несамовитих огрів та оркської орди до височезних велетнів та жахливих драконів.</p>';

export const beastmaster = "тримач звірів";

export const beastmaster_details = 
'<p>Архетип тримача звірів втілює у собі дружбу між цивілізацією та звірами дикого світу. Поєднуючи зусилля, звір і рейнджер працюють як одне ціле, борючись із жахливими ворогами, що загрожують цивілізації та дикій природі. Наслідування шляху тримача звірів означає посвяту себе цьому ідеалу, співробітництво з твариною як супутник і друг.</p>';

export const sorcerer = "Маг"

export const sorcerer_details = '<p>Маги є носіями магії, дарованої їм при народженні їх екзотичного родоводу, впливом потойбічних або невідомих вселенських сил. Ніхто не може навчитися чародійству, як, наприклад, вивчити мову, або як прожити легендарне життя. Ніхто не може обрати шлях чародійства, сила сама вибирає носія.</p>'+
'<h3>Груба магія</h3>'+
'<p>Магія є частиною кожного мага, наповнюючи тіло, розум і дух прихованою силою, яка чекає, коли її використають. Деякі маги володіють магією, яка походить із стародавнього родоводу, повʼязаного з магією драконів. Інші ж несуть у собі сиру, неконтрольовану силу, хаотичний шторм, який може виплеснутися несподіваним чином.'+
'<br /><br />Прояв сил мага вкрай непередбачуваний. Деякі родоводи драконів отримують рівно одного мага в кожному поколінні, в інших же родоводів кожен індивід може бути магом. Найчастіше здатність до чародійства виникає випадково. Деякі маги не можуть назвати джерело своєї сили, тоді як інші повʼязують його з дивними подіями у своєму житті. Дотик демона, благословення дріади при народженні, або вода з містичного джерела — це може розпалити іскру чародійського дару. Це може бути дар божества магії, вплив стихійних сил із Внутрішніх Планів, дотик до божевільного хаосу Лімбо чи розуміння внутрішнього устрою реальності.'+
'<br /><br />Маги не використовують книги заклять, на які покладаються маги, і, на відміну від чаклунів, не покладаються на високого покровителя, котрий давав їм чари. Навчившись використовувати і спрямовувати власну вроджену магію, вони можуть відкрити для себе нові приголомшливі способи вивільнення цієї сили.</p>'+
'<h3>Незʼясовні сили</h3>'+
'<p>Маги досить рідко зустрічаються у світі, і зовсім незвичайно зустріти мага, якимось чином не залученого в пригоди. Люди з магічними силами в їх жилах, незабаром виявляють, що ці сили не можуть постійно спати. Чародійська магія вимагає використання, і має тенденцію вириватися непередбачуваним чином, якщо її ігнорувати.'+
'<br /><br />Найчастіше у магів туманні мотиви, що штовхають їх на пригоди. Деякі шукають глибшого розуміння магічних сил, що впливають на них, або розгадок їхнього таємничого походження. Інші ж сподіваються знайти спосіб позбавлення свого дару, або розкриття його повного потенціалу. Незалежно від своїх цілей, маги так само корисні в загоні шукачів пригод, як і маги. Хоча маги і не володіють безліччю заклинань, це з лишком компенсується великою гнучкістю у використанні тих чарів, що їм відомі.</p>'


export const sorcerer_dragon_blood = "спадщина дракона"

export const sorcerer_dragon_blood_details = 'Ваша вроджена магія походить з магії драконів, що примішалася до вашої крові, або успадкованої вами. Найчастіше, маги з таким джерелом сили можуть простежити свій родовід до могутніх магів давнини, які укладали угоди з драконами або навіть драконів, що навіть оголошували себе нащадками.'+
'<br /><br />Деякі з таких спадкових гілок добре відомі, але більшість із них приховані. Або Маг може бути родоначальником такої гілки, отримавши силу внаслідок договору чи інших надприродних обставин.'

export const sorcerer_wild_magic = "дика магія"

export const sorcerer_wild_magic_details = 'Ваша магія походить від диких сил хаосу, що лежать в основі світобудови. Ви, можливо, зазнали впливу грубої магії у тій чи іншій формі. Можливо, вона просочилася через портал, що веде в Лімбо, Стихійні Плани або таємничий Далекий Межа. Можливо, ви були благословенні могутньою феєю або відзначені демоном. Або ваша магія може бути випадковістю вашого народження без очевидної причини. Незалежно від її походження, хаотична магія вирує всередині вас, готова вирватися назовні.'