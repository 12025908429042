export const armor = {

    light: "легкі",
    light_details: "—",

    medium: "середні",
    medium_details: "—",

    heavy: "важкі",
    heavy_details: "—",

    shields: "щити",
    shields_details: "—",

}