export const once_a_day = 
'Можна використовувати раз на день.';

export const once_a_day_expanded = 
'Використавши це закляття, ви повинні закінчити тривалий відпочинок, перш ніж зможете накласти це закляття ще раз.';

export const as_ritual_details = ' Каст без мани +10хв.';
export const as_ritual_expanded = '<br/><br/>Можна використовувати без мани на найнижчому рівні, але Каст стає на 10хв довше.';

export const thaumaturgy = 
"Дивні дива";

export const thaumaturgy_details = 
'До трьох еффектів одночасно:<br/>'+
'• Зробити свій голос утричі голосніше<br/>' +
'• Змінити вигляд своїх очей<br/>' +
'• Змусити полумʼя мерехтіти, змінити яскравість, колір<br/>' +
'• Викликати нешкідливе тремтіння підлоги<br/>' +
'• Створити зловісний звук із вибраної точки<br/>' +
'• Відчинити або зачинити незачинене вікно<br/>' + 
'• або будь-що інше такого ж роду';

export const thaumaturgy_expanded = 
'Ви творите невелике диво, знак надприродної сили. Ви створюєте один із наступних магічних ефектів у межах дистанції:<br/><br/>' +
'• Ваш голос протягом 1 хвилини звучить утричі голосніше.<br/>' +
'• ви змушуєте полумʼя протягом 1 хвилини мерехтіти, світити яскравіше або тьмяніше, або змінювати колір.<br/>' +
'• ви викликаєте нешкідливе тремтіння в підлозі протягом 1 хвилини.<br/>' +
'• ви створюєте миттєвий звук, що виходить з обраної вами точки в межах дистанції, такий як гуркіт грому, крик ворона або зловісний шепіт.<br/>' +
'• ви змушуєте незачинене вікно або двері миттєво відчинитися або зачинитися.<br/>' +
'• ви на 1 хвилину змінюєте вигляд своїх очей.<br/><br/>' +
'Якщо ви накладаєте це закляття кілька разів, у вас може бути до трьох активних ефектів з тривалістю 1 хвилину, і ви можете закінчувати такі ефекти Основною дією.'

// --------------------

export const hellish_rebuke = 
"Пекельна помста";

export const hellish_rebuke_tiefling = 
"Пекельна помста";

export const hellish_rebuke_details = 
"Оточує полум'ям обрану істоту, яка завдала вам пошкодження.";

export const hellish_rebuke_expanded = 
'Ви вказуєте пальцем, і істота, яка завдала вам пошкодження, миттєво оточується полумʼям. Істота має пройти Випробування Спритності. Вона отримує пошкождення вогнем 2d10 при провалі, або половину цього пошкодження при успіху.' +
'На більш високих рівнях: Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d10 за кожний рівень слоту вище за перший.'

// --------------------

export const hellish_rebuke_tiefling_details = 
"Оточує полум'ям обрану істоту, яка завдала вам пошкодження.";

export const hellish_rebuke_tiefling_expanded = 
'Ви вказуєте пальцем, і істота, яка завдала вам пошкодження, миттєво оточується полумʼям. Істота має пройти Випробування Спритності. Вона отримує пошкождення вогнем 3d10 при провалі, або половину цього пошкодження при успіху. <br/><br/>Складність Випробування для цього закляття залежить від модифікатора Харизми.<br/><br/>' + once_a_day_expanded;

// --------------------

export const darkness =
"Темрява";

export const darkness_tiefling =
"Темрява";

export const darkness_tiefling_details = 
'Сфера темряви із вибраної точки чи предмета. Темний зір не працює, немагічне світло не потрапляє. Розсіює магічне світло 2 рівня. Якщо предмет накрити, темрява буде заблокована.';

export const darkness_tiefling_expanded = 
'З точки, вибраної вами в межах дистанції, розповзається магічна темрява сферою з радіусом 15 футів. Темрява огинає кути. Істоти з темним зором не можуть бачити крізь цю темряву, і немагічне світло не може її висвітлити.<br/><br/>' + 
'Якщо вибрана точка знаходиться на обʼєкті, який ви тримаєте, або на обʼєкті, який не носять або не тримають інші істоти, то темрява виходить з предмета і переміщається разом з ним. Якщо повністю накрити джерело темряви непрозорим предметом, наприклад чашею або шоломом, темрява буде заблокована.<br/><br/>' +
'Якщо частина зони цього закляття накриває зону світла, створеного закляттям з рівнем не вище 2, закляття, що створило світло, розсіюється.<br/><br/>' + once_a_day_expanded;;

export const darkness_details = 
'Сфера темряви із вибраної точки чи предмета. Темний зір не працює, немагічне світло не потрапляє. Розсіює магічне світло 2 рівня. Якщо предмет накрити, темрява буде заблокована.';

export const darkness_expanded = 
'З точки, вибраної вами в межах дистанції, розповзається магічна темрява сферою з радіусом 15 футів. Темрява огинає кути. Істоти з темним зором не можуть бачити крізь цю темряву, і немагічне світло не може її висвітлити.<br/><br/>' + 
'Якщо вибрана точка знаходиться на обʼєкті, який ви тримаєте, або на обʼєкті, який не носять або не тримають інші істоти, то темрява виходить з предмета і переміщається разом з ним. Якщо повністю накрити джерело темряви непрозорим предметом, наприклад чашею або шоломом, темрява буде заблокована.<br/><br/>' +
'Якщо частина зони цього закляття накриває зону світла, створеного закляттям з рівнем не вище 2, закляття, що створило світло, розсіюється.';

// --------------------

export const tinsmith_expanded = 
'Ви можете створити крихітний механічний пристрій (Броня 5, 1 НР). Цей пристрій перестає працювати через 24 години (якщо ви не витратите 1 годину на підтримку його роботи). Ви можете Основною дією розібрати його; у цьому випадку ви можете отримати використані матеріали. Одночасно ви можете мати не більше трьох таких пристроїв.<br/><br/>';

export const toy =
"Створити заводну іграшку";

export const toy_details =
'Поставлена на землю, вона проходить 5 футів у випадковому напрямку за кожен ваш хід, видаючи звуки, що відповідають зображенній істоті.';

export const toy_expanded = 
tinsmith_expanded + 'Заводна іграшка зображує тварину, чудовисько або істоту, на зразок жаби, миші, птиці, дракона або солдатика. Поставлена на землю, вона проходить 5 футів у випадковому напрямку за кожний ваш хід, видаючи звуки, що відповідають зображенній істоті.'

// --------------------

export const lighter =
"Створити запальничку";

export const lighter_details =
'Пристрій, який виробляє мініатюрний вогник, за допомогою якого можна запалити свічку, смолоскип або багаття. Використання цього пристрою потребує Основну дію.'

export const lighter_expanded =
tinsmith_expanded + 'Цей пристрій виробляє мініатюрний вогник, за допомогою якого можна запалити свічку, смолоскип або багаття. Використання цього пристрою потребує Основну дію.'

// --------------------

export const music_box =
"Створити музичну скриньку";

export const music_box_details = 
'Під час відкриття програє мелодію середньої гучності. Перестає грати, якщо мелодія закінчилася або якщо її закрили.';

export const music_box_expanded =
tinsmith_expanded + 'Під час відкриття програє мелодію середньої гучності. Скринька перестає грати, якщо мелодія закінчилася або якщо її закрили.' 

// --------------------

export const minor_illusion =
"Мала ілюзія";

export const minor_illusion_gnome =
"Мала ілюзія";

export const minor_illusion_details =
'Ви створюєте звук або зображення предмета. Ілюзія закінчується, якщо ви відпустите її Основною дією або використовуєте це закляття ще раз.';

export const minor_illusion_expanded =
'Ви створюєте звук або образ предмета в межах дистанції, що існує поки закляття активне. Ілюзія також закінчується, якщо ви відпустите її Основною дією або використовуєте це закляття ще раз.<br/><br/>' +
'Якщо ви створюєте звук, його гучність може бути як шепотом, так і криком. Це може бути ваш голос, чийсь інший голос, левовий рик, бій барабанів чи будь-який інший звук. Звук звучить всю тривалість закляття, або ви можете створювати окремі звуки в різний час, доки закляття активне.<br/><br/>' +
'Якщо ви створюєте образ предмета — наприклад, стілець, відбиток у бруді, або невелику скриню — він повинен уміщатися в куб з довжиною ребра 5 футів. Образ не може видавати звуки, світло, запах чи інші сенсорні ефекти. Образ не можна переміщати. Фізична взаємодія з образом дає зрозуміти, що це ілюзія, тому що крізь неї все проходить.<br/><br/>' +
'Якщо істота дією досліджує звук чи образ, вона може зрозуміти, що це ілюзія, зробивши успішну перевірку Розслідування проти Складності ваших заклять. Якщо істота розпізнає ілюзію, вона стає для нього нечіткою.<br/><br/>' +
'Фокус для створення закляття можна замінити на шматок вовни.';

// --------------------

export const enlargement_self = 
"Збільшення себе";

export const enlargement_self_details = 
'Розмір подвоюються по всіх вимірах, а вага збільшується у вісім разів. Перевірки та Випробування Сили з перевагою. Додаткове пошкодження зброєю 1d4.';

export const enlargement_self_expanded = 
'Ви збільшуєте себе на час дії закляття. Все, що ви носите та несете, змінює розмір разом з вами. Все, що ви кинете, відразу набуває свого природного розміру.<br/><br/>' +
'Розміри подвоюються по всіх вимірах, а вага збільшується у вісім разів. Це збільшує розмір на одну категорію — від Середнього до Великого. Якщо не вистачає простору, набувається максимально можливий розмір. Поки закляття активне, ви здійснюєте з перевагою перевірки та Випробування Сили. Зброя теж збільшується. Атаки збільшеною зброєю завдають додаткове пошкодження 1d4.<br/><br/>' +
'Ви не можете накладати закляття під прямим сонячним промінням, втім, сонячні промені не впливають, якщо ви наклали закляття раніше.<br/><br/>' + once_a_day_expanded;

// --------------------

export const invisibility_self = 
"Невидимість";

export const invisibility_self_details = 
'Ви та все що ви носите та несете, стає невидимим. Невидимість припиняється, якщо ціль атакує чи створює закляття.';

export const invisibility_self_expanded =
'Ви стаєте невидимим до закінчення дії закляття. Все, що ви носите і несете, стає невидимим, доки залишається у вас. закляття закінчується, якщо ви робите атаку або створюєте закляття.<br/><br/>' +
'Ви не можете накладати закляття під прямим сонячним промінням, втім, сонячні промені не впливають, якщо ви наклали закляття раніше.<br/><br/>' + once_a_day_expanded;

// --------------------

export const dancing_lights =
"Танцюючі вогники";

export const dancing_lights_details =
'Створення до чотирьох вогників розміром із смолоскип, що ширяють у повітрі. Ви можете також обʼєднати чотири вогники в одну людиноподібну фігуру Середнього розміру, що світиться.';

export const dancing_lights_expanded =
'Ви створюєте до чотирьох вогників розміром зі смолоскип у межах дистанції, роблячи їх схожими на смолоскипи, ліхтарі або сфери, що світяться та ширяють у повітрі. Ви можете також обʼєднати чотири вогники в одну людиноподібну фігуру Середнього розміру, що світиться. Яку б форму ви не вибрали, кожен вогник випромінює тьмяне світло в радіусі 10 футів.<br/><br/>' +
'Ви можете Бонусною дією у свій хід перемістити вогники на 60 футів у нове місце в межах дистанції. Кожен вогник повинен знаходитися в межах 20 футів від іншого вогника, створеного цим закляттям, і вогник тухне, якщо виявляється за межами дистанції закляття.<br/><br/>' +
'Фокус для створення закляття можна замінити на шматочок фосфору, гнилячку або світлячку.';

// --------------------

export const faerie_fire_0 =
"Вогники фей";

export const faerie_fire =
"Вогники фей";

export const faerie_fire_0_details =
'<span class="buff">Перевага</span> на всі атаки по істотах та предметах у вибраній області, якщо атакуючий бачить їх. Ці істоти та предмети підсвічуються та не отримують переваг від невидимості.';

export const faerie_fire_0_expanded =
'Всі предмети в кубі з довжиною ребра 20 футів у межах дистанції підсвічуються синім, зеленим або фіолетовим кольором (на ваш вибір).<br/><br/>' +
'Усі істоти у цій області теж підсвічуються, якщо провалюють спаскидок Спритності. На час тривалості закляття підсвічені предмети та істоти випромінюють тьмяне світло в радіусі 10 футів.<br/><br/>' +
'Усі кидки атаки по залученим істотам і предметам відбуваються з перевагою, якщо атакуючий бачить їх, і ці істоти та предмети не отримують переваг від невидимості.<br/><br/>Складність Випробування для цього закляття залежить від модифікатора Харизми.<br/><br/>' + once_a_day_expanded;

// --------------------

export const fire_breath =
"Подих 🔥 Вогнем";

export const fire_breath_cone =
"Подих 🔥 Вогнем: конус";

export const fire_breath_line =
"Подих 🔥 Вогнем: лінія";

export const cold_breath =
"Подих ❄️ Холодом";

export const electricity_breath =
"Подих ⚡️ Струмом";

export const acid_breath =
"Подих ⚗️ Кислотою";

export const poison_breath =
"Подих 🧪 Отрутою";

export const breath_details =
'Видих руйнівної енергії.';

export const breath_expanded = 
'Ви можете Основною дією видихнути руйнівну енергію. Ваша спадщина драконів визначає розмір, форму та вид пошкодження вашого видиху. Коли ви використовуєте зброю подиху, всі істоти в зоні видиху повинні пройти Випробування вид якого визначається вашою спадщиною. Істоти отримують 2d6 подшкодження у разі провалу, або половину цього пошкодження, у разі успіху.<br/><br/>' +
'Пошкодження збільшується до 3d6 на 6 рівні, до 4d6 на 11, і до 5d6 на 16 рівні.<br/><br/>' +
'Після використання зброї подиху ви не можете використовувати її повторно, доки не завершите короткий або тривалий відпочинок.'

// ---------------------------------

export const acid_splash = 'Бризки кислоти';

export const acid_splash_details = 'Кидок кислотної кульки в істоту або двох істот поруч.';

export const acid_splash_expanded = 'Ви кидаєте кислотну кульку. Виберіть одну істоту в межах дистанції або дві істоти в межах дистанції, що знаходяться в межах 5 футів одна від одної. Ціль повинна досягти успіху в Випробуванні Спритності, інакше вона отримує пошкодження ⚗️ Кислотою 1d6.<br/><br/>' +
'Пошкодження цього закляття збільшується на 1d6, коли ви досягаєте 5 рівня (2d6), 11 рівня (3d6) та 17 рівня (4d6).';

export const blade_ward = 'Захист від зброї';

export const blade_ward_details = '<span class="buff">Стійкість</span> до  🔨 Дробильного, 🗡 Колотого та 🪓 Рубаного пошкодження від зброї.';

export const blade_ward_expanded = 'Ви простягаєте руку і малюєте в повітрі огороджувальний знак. Ви отримуєте до кінця свого наступного ходу стійкість до 🔨 Дробильного, 🗡 Колотого та 🪓 Рубаного пошкодження, що завдано атаками зброєю.';

export const chill_touch = 'Моторошний дотик';

export const chill_touch_details = 'Примарна рука скелета хапається за істоту. Ціль не може лікуватися до кінця вашого наступного ходу. Якщо ціль — невмерлий, накладає <span class="buff">перешкоду</span> на атаку цілі по вам до кінця вашого наступного ходу.';

export const chill_touch_expanded = 'Ви створюєте примарну руку скелета у просторі істоти, що знаходиться в межах дистанції. Здійсніть далекобійну атаку закляттям по цілі, щоб огорнути його могильним холодом. При попаданні ціль отримує пошкодження 💀 Некротичною енергією 1d8, і не може відновлювати здоровʼя до початку наступного ходу. Весь цей час рука тримається за ціль.<br/><br/>' +

'Якщо ви влучаєте по невмерлому, то він до кінця вашого наступного ходу кидає атаки з перешкодою.<br/><br/>' +

'Пошкодження від цього закляття збільшується на 1d8, коли ви досягаєте 5 рівня (2d8), 11 рівня (3d8), 17 рівня (4d8).';

export const fire_bolt = 'Вогняний снаряд';

export const fire_bolt_details = 'Атака згустком вогню, що запалює горючі предмети.';

export const fire_bolt_expanded = 'Ви кидаєте згусток вогню у істоту чи предмет у межах дистанції. Здійсніть далекобійну атаку закляттям по цілі. При влучанні ціль отримує пошкодження 🔥 Вогнем 1d10. Горючі предмети, по яким влучило це закляття, спалахують, якщо їх ніхто не несе і не носить.<br/><br/>'+

'Пошкодження від цього закляття збільшується на 1d10, коли ви досягаєте 5 рівня (2d10), 11 рівня (3d10) та 17 рівня (4d10).';

export const friends = 'Дружба';

export const friends_details = '<span class="buff">Перевага</span> на всі перевірки Харизми в бік однієї не ворожої істоти. Після завершення закляття воно стає ворожим.';

export const friends_expanded = 'Поки закляття активне, ви здійснюєте з перевагою всі перевірки Харизми, спрямовані на одну обрану вами істоту, не ворожу до вас. Коли закляття закінчується, істота розуміє, що ви впливали на її ставлення за допомогою магії, і стає ворожим до вас. Істота, схильна до насильства, може напасти на вас. Інші можуть вимагати іншої відплати (вирішує Майстер), залежно від відносин, що склалися між вами.<br/><br/>' +
'Фокус для створення закляття можна замінити на невелику кількість гриму, що наноситься на обличчя.';

export const light = 'Світло';

export const light_details = 'Змусити предмет яскраво світитися будь-яким вибраним кольором.';

export const light_expanded = 'Ви торкаєтеся одного предмета, довжина якого у жодному вимірі не перевищує 10 футів. Поки закляття активне, предмет випромінює яскраве світло в радіусі 20 футів і тьмяне світло в межах ще 20 футів. Світло може бути будь-якого обраного кольору. Повне покриття предмета чимось непрозорим блокує світло.<br/><br/>'+
'закляття закінчується, якщо ви накладете його знову або закінчите Основною дією. Якщо ви націлюєтеся на предмет, що ворожа істота несе або носить, ця істота повинна досягти успіху у Випробуванні Спритності, щоб уникнути закляття.<br/><br/>' +
'Фокус для створення закляття можна замінити на світлячка або фосфоруючий мох.';

export const mage_hand = 'Чарівна рука';

export const mage_hand_details = 'Примарна рука, яку ви можете контролювати Основною дією.';

export const mage_hand_expanded = 'У точці, обраній вами в межах дистанції, зʼявляється примарна ширяюча рука. Рука існує, поки закляття активне, або поки ви не відпустите її Основною дією. Рука зникає, якщо опинитсья більш ніж за 30 футів від вас, або якщо ви повторно використовуєте це закляття.<br/><br/>'+

'Ви можете Основною дією контролювати руку. Ви можете з її допомогою маніпулювати предметами, відкривати незачинені двері та контейнери, прибирати предмети у відкриті контейнери та діставати їх звідти, або виливати вміст флаконів. При кожному використанні руки можна перемістити її на 30 футів.<br/><br/>'+

'Рука не може здійснювати атаки, активувати магічні предмети та переносити понад 10 фунтів.';

export const mending = 'Полагодження';

export const mending_details = 'Виправлення на предметі однієї поломки до 1 фута.';

export const mending_expanded = 'Це закляття лагодить одне пошкодження або розрив на предметі, якого торкаєтеся, наприклад: розірвану ланка ланцюга, дві половинки зламаного ключа, порваний плащ, бурдюк, що протікає. Якщо пошкодження або розрив не більше 1 фута в довжину — лагодить його, не залишаючи слідів. Це закляття може фізично полагодити магічний предмет або конструкта, але не може відновити магію в таких предметах.<br/><br/>' +
'Фокус для створення закляття можна замінити на два магнетити.';

export const message = 'Повідомлення';

export const message_details = 'Коротке телепатичне послання, яке почує лише одержувач. Дозволяє отримати таку ж телепатичну відповідь.';

export const message_expanded = 'Ви вказуєте пальцем на істоту, що знаходиться в межах дистанції, і шепочете послання. Ціль (і тільки ціль) чує його, і може відповісти шепотом, який почуєте тільки ви. Ви можете використовувати це закляття крізь тверді перешкоди, якщо ви знайомі з ціллю і знаєте, що вона перебуває за барʼєром<br/><br/>' +
'Магічна тиша, 1 фут каменю, 1 дюйм звичайного металу, тонкий лист свинцю або 3 фути дерева блокують закляття. Закляття не повинно йти по прямій лінії, і може огинати кути та проходити через отвори.<br/><br/>' +
'Фокус для створення закляття можна замінити на шматочок мідного дроту.';

export const poison_spray = 'Бризки отрути';

export const poison_spray_details = 'Атака хмарою токсичного газу.';

export const poison_spray_expanded = 'Ви простягаєте руку до істоти, видимої в межах дистанції, і випускаєте з долоні клуби токсичного газу. Ця істота повинна досягти успіху у Випробуванні Статури, інакше вона отримає пошкодження отрутою 1d12.<br/><br/>' +
'Пошкодження від цього закляття збільшується на 1d12, коли ви досягаєте 5 рівня (2d12), 11 рівня (3d12), 17 рівня (4d12).';

export const prestidigitation = 'Фокуси';

export const prestidigitation_details = 
'До трьох еффектів одночасно:<br/>'+
'• Створити нешкідливий сенсорний ефект<br/>'+
'• Запалити або згасити свічку, смолоскип, багаття<br/>'+
'• Почистити або замарати поломку до 1ф на предметі<br/>'+
'• Нагріти/остудити/надати запах 1ф предмету на 1 годину<br/>'+
'• Створити на предметі маленьку мітку на 1 годину<br/>'+
'• Створити дрібничку розміром з долоню на 1 раунд';

export const prestidigitation_expanded = 'Невеликий магічний трюк, на якому практикуються заклиначі-початківці. Ви створюєте один із наступних магічних ефектів у межах дистанції:<br/><br/>'+
'• ви створюєте миттєвий нешкідливий сенсорний ефект, такий як сніп іскор, порив вітру, тиху мелодію або незвичайний запах.<br/>'+
'• ви миттєво запалюєте або гасите свічку, смолоскип або невелике багаття.<br/>'+
'• ви миттєво чистите або мараєте предмет, що розмірами не перевищує 1 кубічний фут.<br/>'+
'• ви остуджуєте, нагріваєте чи надаєте запах 1 кубічному футу неживої матерії на 1 годину.<br/>'+
'• ви створюєте на поверхні або предметі маленьку кольорову мітку або символ на 1 годину.<br/>'+
'• ви створюєте немагічний дрібничку або ілюзорний предмет, що уміститься у вашу долоню, і існуючий до кінця вашого наступного ходу.<br/><br/>'+
'Якщо ви накладаєте це закляття кілька разів, ви можете мати не більше трьох миттєвих ефектів одночасно, і можете дією закінчити один з цих ефектів.';

export const ray_of_frost = 'Промінь холоду';

export const ray_of_frost_details = 'Атака променем холоду. <span class="buff">Сповільнює</span> істоту на 10 футів.';

export const ray_of_frost_expanded = 'Холодний синьо-білий промінь прямує до цілі, що знаходиться в межах дистанції. Здійсніть далекобійну атаку закляттям по цілі. При влучанні ціль отримує пошкодження ❄️ Холодом 1d8, а швидкість до початку вашого наступного ходу зменшується на 10 футів.<br/><br/>' +

'Пошкодження від цього закляття збільшується на 1d8, коли ви досягаєте 5 рівня (2d8), 11 рівня (3d8), 17 рівня (4d8).';

export const shocking_grasp = 'Електрошок';

export const shocking_grasp_details = 'Атака блискавкою. <span class="buff">Перевага</span> на атаку по цілі в обладунку з металу. Ціль не може здійснювати Реакції до початку свого наступного ходу.';

export const shocking_grasp_expanded = 'Блискавка зістрибує з вашої руки і вдаряє істоту, яку ви намагалися торкнутися. Здійсніть ближню атаку закляттям по цілі. Ви робите кидок атаки з перевагою, якщоцільносить обладунок з металу. При влучанні ціль отримує пошкодження ⚡ Струмом 1d8, і до початку наступного ходу не може здійснювати Реакції.<br/><br/>' +
'Пошкодження від цього закляття збільшується на 1d8, коли ви досягаєте 5 рівня (2d8), 11 рівня (3d8), 17 рівня (4d8).';

export const true_strike = 'Влучний удар';

export const true_strike_details = 'Прицілювання, що дозволяє отримати перевагу на атаку по цілі у наступному ході.';

export const true_strike_expanded = 'Ви витягуєте руку і вказуєте пальцем на ціль, що знаходиться в межах дистанції. Ваша магія дає загальне розуміння захисту цілі. У своєму наступному ході ви здійснюєте з перевагою перший кидок атаки по цілі, за умови, що закляття на той момент не закінчиться.';

// --------------------

export const rage = 
'Лють';

export const rage_details = 
'<span class="buff">• Перевага</span> на перевірки та Випробування Сили<br/>'+
'<span class="buff">• Бонусне пошкодження</span> ближньою зброєю<br/>' +
'<span class="buff">• Стійкість</span> до механічних видів пошкодження<br/>' +
'<span class="debuff">• Не можна</span> використовувати закляття';

export const rage_expanded = 
'У бою ви воюєте з первісною скаженістю. У свій хід ви можете Бонусною дією увійти до стану Люті. У стані Люті ви отримуєте такі переваги, якщо не носите важку броню:<br/><br/>' +

'• ви здійснюєте з перевагою перевірки та Випробування Сили.<br/>' +
'• Якщо ви здійснюєте атаку ближньою зброєю, використовуючи Силу, ви отримуєте бонус до кидка пошкодження, що відповідає вашому рівню варвара, як показано в колонці «Пошкодження Люті» таблиці «Варвар» у Книзі Гравця.<br/>' +
'• ви отримуєте стійкість до Дробильного, Колотого, Рубаного пошкодження<br/>' +
'• Якщо би ви могли використовувати закляття, то ви не можете використовувати або концентруватися на закляттях, поки перебуваєте в стані люті.<br/><br/>' +

'Ваша Лють триває 1 хвилину. Вона припиняється раніше:<br/><br/>' +

'• Якщо ви знепритомніли,<br/>' +
'• Якщо ви закінчили хід, не атакувавши ворожу істоту з моменту закінчення минулого ходу,<br/>' +
'• Якщо ви з моменту закінчення минулого ходу не отримали пошкоджень.<br/>' +
'• Якщо ви припинили її Бонусною дією.<br/><br/>' +

'Якщо ви впадали в стан Люті максимальну кількість разів для вашого рівня, то ви повинні зробити тривалий відпочинок, перш ніж зможете використовувати Лють ще раз.';

export const reckless_attack =
'Шалена атака';

export const reckless_attack_details =
'<span class="buff">• Перевага</span> на атаки ближньою зброєю з Силою<br/>'+
'<span class="debuff">• Вразливість</span> до ворожих атак до наступного ходу';

export const reckless_attack_expanded =
'Починаючи з 2 рівня ви здатні відкинути будь-яку турботу про захист, щоб атакувати запекло і безрозсудно.<br/>'+
'Коли ви здійснюєте першу атаку у свій хід, ви можете вирішити, що атакуватимете шалено. Зважившись на це, ви в цьому ході здійснюєте атаки ближньою зброєю, що використовують Силу, з перевагою, але всі кидки атаки по вас до наступного ходу теж здійснюються з перевагою.';

export const frenzy = 'Сказ';

export const frenzy_details = 
'<span class="buff">• +1 Атака Бонусною дією</span> при Люті<br/>'+
'<span class="debuff">• +1 Виснаження</span> після закінчення Люті';

export const frenzy_expanded =
'Перебуваючи в стані Люті, ви можете впасти в Сказ. У цьому випадку, поки ваша Лють не припинилася, ви можете в кожен свій хід після поточного здійснювати Бонусною дією одну атаку ближньою зброєю. Після закінчення Люті ви отримуєте один рівень виснаження.';

export const intimidating_presence = 
'Лякаюча присутність';

export const intimidating_presence_details = 
'😱 Перелякати вибрану істоту у 30ф до кінця вашого наступного ходу.';

export const intimidating_presence_expanded =
'Ви можете Основною дією лякати інших своїм жахливим виглядом.<br/><br/>'+

'Щоб зробити це, оберіть істоту в межах 30 футів від себе, яку ви можете бачити. Якщо вона може бачити або чути вас, вона має зробити успішне Випробування Мудрості (Складність дорівнює 8 + ваш бонус майстерності + ваш модифікатор Харизми), інакше стане переляканою вами до кінця вашого наступного ходу.<br/><br/>'+

'У наступні ходи ви можете Основною дією підтримувати цей ефект до кінця свого наступного ходу. Ефект закінчується, якщо ціль опинилася поза зором, або далі ніж за 60 футів від вас. Якщо істота досягла успіху в Випробуванні, ви не можете використовувати на неї це вміння наступні 24 години.';

export const retaliation = 'Удар у відповідь';

export const retaliation_details = 
'Миттєва атака у відповідь ближньою зброєю по цілі поруч.';

export const retaliation_expanded = 
'Починаючи з 14 рівня, при отриманні пошкодження від істоти, що знаходиться в межах 5 футів від вас, ви можете Реакцією зробити по ньому атаку ближньою зброєю.';

export const beast_sense = 'Чуття звіра';
export const beast_sense_ritual = 'Чуття звіра: ритуал';

export const beast_sense_ritual_details = 'Переключитися на органи відчуттів згодної тварини.';

export const beast_sense_ritual_expanded = 'Ви торкаєтеся згодного звіра. Поки закляття активне, ви можете подивитися очима цього звіра і почути те, що чує він. Ефект продовжується, поки ви не перемкнетеся Основною дією назад на свої відчуття.<br/><br/>'+
'Сприймаючи відчуттями звіра, ви користуєтеся перевагами всіх його особливих відчуттів, хоча для свого оточення ви вважаєтеся засліпленим та оглухлим.';

export const beast_sense_details = 'Переключитися на органи відчуттів згодної тварини.';

export const beast_sense_expanded = 'Ви торкаєтеся згодного звіра. Поки закляття активне, ви можете подивитися очима цього звіра і почути те, що чує він. Ефект продовжується, поки ви не перемкнетеся Основною дією назад на свої відчуття.<br/><br/>'+
'Сприймаючи відчуттями звіра, ви користуєтеся перевагами всіх його особливих відчуттів, хоча для свого оточення ви вважаєтеся засліпленим та оглухлим.';

export const speak_with_animals = 'Розмова з тваринами';

export const speak_with_animals_ritual = 'Розмова з тваринами: ритуал';

export const speak_with_animals_ritual_details = 'Можливість розуміти звірів та спілкуватися з ними усно, в міру їхніх інтелектуальних здібностей.';

export const speak_with_animals_ritual_expanded = 'Ви отримуєте на час дії закляття можливість розуміти звірів та спілкуватися з ними усно. Знання та свідомість багатьох звірів обмежені їх інтелектом, але вони як мінімум можуть дати інформацію про найближчі місця та чудовиськ, включаючи тих, кого вони бачили за останній день. На розсуд Майстра ви можете спробувати переконати звіра надати вам невелику допомогу.';

export const speak_with_animals_details = 'Можливість розуміти звірів та спілкуватися з ними усно, в міру їхніх інтелектуальних здібностей.';

export const speak_with_animals_expanded = 'Ви отримуєте на час дії закляття можливість розуміти звірів та спілкуватися з ними усно. Знання та свідомість багатьох звірів обмежені їх інтелектом, але вони як мінімум можуть дати інформацію про найближчі місця та чудовиськ, включаючи тих, кого вони бачили за останній день. На розсуд Майстра ви можете спробувати переконати звіра надати вам невелику допомогу.';

export const commune_with_nature = 'Спілкування з природою';
export const commune_with_nature_ritual = 'Спілкування з природою: ритуал';

export const commune_with_nature_ritual_details = 'Ви отримуєте детальне знання про довкілля, в межах 3 миль на поверхні, або 300ф під землею. Не діє у містах чи підвалах.';

export const commune_with_nature_ritual_expanded = 'Ви ненадовго зливаєтеся з природою та отримуєте знання про навколишню територію. На відкритому повітрі закляття дає вам знання про землі близько 3 миль. У печерах та природному підземному оточенні радіус 300 футів. закляття не діє там, де природа замінена конструкціями, наприклад, у містах та підвалах.<br/><br/>'+
'Ви миттєво отримуєте знання про три факти на ваш вибір про такі особливості місцевості:<br/><br/>'+
'• Місцевість та водойми<br/>'+
'• Переважаючі рослини, мінерали, тварини та розумні істоти<br/>'+
'• Потужна погань, небожителі, невмерлі, феї та елементали<br/>'+
'• Вплив інших планів існування<br/>'+
'• Будівлі<br/><br/>'+
'Наприклад, ви можете визначити місцезнаходження потужного невмерлого, місцезнаходження чистої води для пиття та місцезнаходження навколишніх поселень.';

export const commune_with_nature_details = 'Ви отримуєте детальне знання про довкілля, в межах 3 миль на поверхні, або 300ф під землею. Не діє у містах чи підвалах.';

export const commune_with_nature_expanded = 'Ви ненадовго зливаєтеся з природою та отримуєте знання про навколишню територію. На відкритому повітрі закляття дає вам знання про землі близько 3 миль. У печерах та природному підземному оточенні радіус 300 футів. закляття не діє там, де природа замінена конструкціями, наприклад, у містах та підвалах.<br/><br/>'+
'Ви миттєво отримуєте знання про три факти на ваш вибір про такі особливості місцевості:<br/><br/>'+
'• Місцевість та водойми<br/>'+
'• Переважаючі рослини, мінерали, тварини та розумні істоти<br/>'+
'• Потужна погань, небожителі, невмерлі, феї та елементали<br/>'+
'• Вплив інших планів існування<br/>'+
'• Будівлі<br/><br/>'+
'Наприклад, ви можете визначити місцезнаходження потужного невмерлого, місцезнаходження чистої води для пиття та місцезнаходження навколишніх поселень.';

export const wolf_knock = 'Удар Вовка';

export const wolf_knock_details = 'При Люті та влучанні атакою ближньою зброєю, збити з ніг істоту великого і меншого розміру.';

export const wolf_knock_expanded = 'Поки ви в стані Люті, ви можете у свій хід Бонусною дією збити з ніг істоту Великого або меншого розміру, коли влучаєте по ньому атакою ближньою зброєю.'

export const elk_knock = 'Прохід Лося';

export const elk_knock_details = 'При Люті, під час руху збити з ніг істота Великого і меншого розміру і завдати пошкодження.';

export const elk_knock_expanded = 'Коли ви перебуваєте в стані Люті, ви можете використовувати Бонусну дію під час пересування, щоб пройти через місце, яке займає істота Великого або меншого розміру. Ця істота повинна успішний пройти Випробування Сили (Складність 8 + ваш бонус Сили + ваш бонус майстерності) або буде збитим з ніг і отримає 1d12 + модифікатор Сили дробильного пошкоджнення.';

export const tiger_strike = 'Стрибок Тигра';

export const tiger_strike_details = 'При Люті, підбігши до істоти Великого і меншого розміру, завдати додаткову атаку ближньою зброєю.';

export const tiger_strike_expanded = 'Коли ви перебуваєте в стані Люті, при переміщенні по прямій як мінімум на 20 футів до цілі Великого або меншого розміру і до атаки ближньою зброєю, ви можете витратити Бонусну дію на здійснення додаткової атаки ближньою зброєю по цій цілі.';

export const sneaky_attack = 'Підступна атака';

export const sneaky_attack_details = 'Один раз в хід, додаткове пошкодження після влучання атаки з перевагою далекобійною або фехтувальною зброєю.';

export const sneaky_attack_expanded = 'Ви знаєте, як точно завдавати удару та використати відволікання ворога. Один раз в хід ви можете заподіяти додаткове пошкодження 1d6 одній з істот, по якому ви влучили атакою з перевагою до кидка атаки. Атака повинна використовувати далекобійну або фехтувальну зброю.<br/><br/>'+

'Вам не потрібно мати перевагу при кидку атаки, якщо інший ворог цілі знаходиться в межах 5 футів від неї. Цей ворог не повинен бути недієздатним, і у вас не повинно бути перешкод для цього кидка атаки.<br/><br/>'+

'Додаткове пошкодження збільшується кожні 2 рівні';

export const cunning_action = 'Хитра дія';

export const cunning_action_details = 'Ривок, Відхід або Засідка.';

export const cunning_action_expanded = 'Ваше мислення та спритність дозволяють рухатися та діяти швидше. Ви можете в кожному ході бою здійснювати Бонусну дію. Ця дія може бути використана лише для Ривка, Відходу або Засідки.';

export const steady_aim = 'Точне прицілювання';

export const steady_aim_details = '<span class="buff">Перевага</span> на кидок атаки у цьому ході, якщо ви не рухалися.';

export const steady_aim_expanded = 'Бонусною дією ви можете дати собі перевагу на наступний кидок атаки в поточному ходу. Ви можете використовувати цю Бонусну дію, тільки якщо ви не рухалися під час цього ходу, і після використання Бонусної дії ваша швидкість буде рівною 0 до кінця поточного ходу.';

export const uncanny_dodge = 'Неймовірне ухилення';

export const uncanny_dodge_details = 'Зменшити вдвоє пошкодження від атаки, завдану вам видимою істотою.';

export const uncanny_dodge_expanded = 'Коли істота, яку ви можете бачити, влучає по вам атакою, ви можете використати Реакцію, щоб зменшити вдвічі пошкодження, викликане цією атакою.';

export const fast_hands = 'Швидкі руки';

export const fast_hands_details = 'Використати предмет у бою.';

export const fast_hands_expanded = 'Ви можете Бонусною дією здійснити перевірку Спритності рук, використати крадійськи інструменти, щоб знешкодити пастку або розкрити замок, або здійснити дію Використання предмета.';

export const fake_identity = 'Підроблена особистість';

export const fake_identity_details = 'Створити собі достовірну і переконливу хибну особистість.';

export const fake_identity_expanded = 'Ви можете створювати хибну біографію. Ви повинні витратити сім днів та 25 зм, щоб зробити її достовірною та встановити потрібні звʼязки. Ви не можете взяти біографію, що належить іншій особі.<br/><br/>'+

'Наприклад, ви можете придбати відповідний одяг, рекомендаційні листи та офіційного вигляду сертифікати, щоб позначити себе як член торгового дому з далекого міста, або переконати, що ви з групи багатих торговців.<br/><br/>'+

'Після прийняття нової особистості, як маскування, інші істоти вірять, що ви є цією особистістю, поки не подасте очевидний привід вирішити протилежне.';

export const fake_presence = 'Імітація';

export const fake_presence_details = 'Безпомилково імітувати чужий голос, почерк і звички.';

export const fake_presence_expanded = 'Ви отримуєте здатність безпомилково імітувати чужий голос, почерк і звички. Ви повинні провести не менше трьох годин вивчення цих трьох компонентів поведінки істоти, слухаючи її промову, розглядаючи почерк та спостерігаючи за звичками. Ваші хитрощі не видно для випадкового спостерігача. Якщо обережна істота підозрює щось недобре, ви робите з перевагою всі перевірки Обману, щоб уникнути виявлення.';

export const divine_sense = "Божественне відчуття";

export const divine_sense_details = 'Дізнатися про місцезнаходження всіх породжень, небожителів, невмерлих, освячених і осквернених місць і предметів у межах дальності.';

export const divine_sense_expanded = 
'Присутність сильного зла сприймається вашими почуттями як неприємний запах, а могутнє добро звучить як небесна музика у ваших вухах.<br/><br/>' +  
'Ви можете відкрити свою свідомість для виявлення таких сил. Ви до кінця свого наступного ходу знаєте місцезнаходження всіх породжень, небожителів та невмерлих в межах 60 футів, що не мають повного укриття. Ви знаєте тип (погань, небожитель, невмерлий) будь-якої істоти, чию присутність ви відчуваєте, але не можете визначити, хто це конкретно.<br/><br/>' +  
'У цьому ж радіусі ви також виявляєте присутність місць і предметів, які були освячені або осквернені, наприклад, закляттям Святилище.<br/><br/>' +  
'Ви можете використовувати це вміння кількість разів, що дорівнює 1 + модифікатор Харизми.<br/><br/>' +  
'Коли ви закінчуєте тривалий відпочинок, ви відновлюєте усі витрачені використання.';

export const lay_on_hands = "Накладання рук";

export const lay_on_hands_details = 'Вилікувати рани, хворобу чи отруту. Можна використовувати запас частково.';

export const lay_on_hands_expanded = 
'Ваш благословенний дотик може лікувати рани. У вас є запас лікувальної сили, що відновлюється після тривалого відпочинку. За допомогою цього запасу ви можете відновлювати кількість здоровʼя, що дорівнює рівню паладина, помноженому на 5. Ви можете дією торкнутися істоти і, зачерпнувши силу із запасу, відновити кількість здоровʼя цієї істоти на будь-яке число, аж до максимуму, що залишився у вашому запасі. В якості альтернативи, ви можете витратити 5 пунктів з вашого запасу здоровʼя для лікування цілі від однієї хвороби або одного отрути, що діє на неї. Ви можете усунути кілька ефектів хвороби та отрут одним використанням Накладення рук, витрачаючи пункти окремо для кожного ефекту. Це вміння не має жодного ефекту на невмерлих і конструктів.';

export const paladin_divine_smite = "Божественна кара";

export const paladin_divine_smite_details = 'При влучанні ближньою зброєю завдати додаткове пошкодженя. Пошкодження збільшується на 1d8, якщо ціль — невмерлий або погань.';

export const paladin_divine_smite_expanded = 
'Якщо ви влучаєте по цілі атакою ближньою зброєю, ви можете витратити один слот мани для нанесення цілі пошкодження світлом, який додасться до пошкодження від зброї. Додаткове пошкодження дорівнює 2d8 за слот 1 рівня, плюс 1d8 за кожен рівень слоту вище за перший, до максимуму 5d8. Пошкодження збільшується на 1d8, якщо ціль — невмерлий або погань, до максимуму 6d8.';

//0

export const shillelagh = 'Дубинка';

export const shillelagh_details = 'Ваша дубинка або посох стає магічним, базова Здібність заклять використовується для атаки та пошкодження.';

export const shillelagh_expanded = 'Дерево дубинки або бойового посоху, який ви тримаєте, наповнюється силою природи. Поки закляття активне, ви можете використовувати свою базову Здібність замість Сили для кидків ближньої атаки та пошкодження при використанні цієї зброї, і куб пошкодження стає рівним d8. Якщо зброя не була магічною, вона стає нею. Закляття закінчується, якщо ви накладете його ще раз або випустіте зброю з рук.<br/><br/>' +
'Фокус для створення закляття можна замінити на омелу та лист конюшини.';

export const vicious_mockery = 'Глум';

export const vicious_mockery_details = 'Магічна образа, яка ранить буквально, і накладає <span class="buff">перешкоду</span> на першу атаку ворога в його наступному ході.';

export const vicious_mockery_expanded = 'Ви пускаєте на істоту, видиму в межах дистанції, потік образ упереміш з тонкою магією. Якщо ціль чує вас (при цьому вона не зобовʼязана вас розуміти), вона повинна досягти успіху у Випробуванні Мудрості, інакше отримає пошкодження психічною енергією 1d4, і наступний кидок атаки до кінця свого наступного ходу зробить з перешкодою.<br/><br/>'+

'Пошкодження від цього закляття збільшується на 1d4, коли ви досягаєте 5 рівня (2d4), 11 рівня (3d4) та 17 рівня (4d4).';

export const druidcraft = 'Мистецтво друїдів';

export const druidcraft_details = 
'• Передбачити погоду на наступні 24 години<br/>'+
'• Розпустити квітку, проростити насіння<br/>'+
'• Створити нешкідливий природний ефект<br/>'+
'• Запалити або згасити свічку, смолоскип, багаття';

export const druidcraft_expanded = 'Пошепотівши із духами природи, ви створюєте один із наступних ефектів у межах дистанції:<br/><br/>'+
'• ви створюєте крихітний нешкідливий відчутний ефект, який прогнозує погоду в поточному місці протягом наступних 24 годин. Це може бути золота кулька, що означає ясну погоду, хмара, що означає дощ, сніжинка, і так далі. Ефект триває 1 раунд.<br/>'+
'• ви миттєво змушуєте квітку розпуститися, насіння прорости, або бруньку розкритися.<br/>'+
'• ви створюєте миттєвий нешкідливий відчутний ефект, такий як листя, що падає, порив вітру, звук маленької тварини, або слабкий запах скунса. Ефект повинен бути в кубі з довжиною ребра 5 футів.<br/>'+
'• ви миттєво запалюєте або гасите свічку, смолоскип або невелике багаття.';

export const eldritch_blast = 'Містичний заряд';

export const eldritch_blast_details = 'Атака променем тріскаючої енергії. Кількість променів додається із рівнями.';

export const eldritch_blast_expanded = 'До істоти, що знаходиться в межах дистанції, спрямовується промінь енергії, що потріскує. Здійсніть далекобійну атаку закляттям по цілі. При влучанні ціль отримує пошкодження силовим полем 1d10.<br/><br/>'+

'Закляття створює ще один промінь, коли ви досягаєте більших рівнів: два промені на 5 рівні, три промені на 11 рівні чотири промені на 17 рівні. Ви можете спрямувати промені в одну ціль або в різні. Для кожного променя здійсніть свій кидок атаки.';

export const sacred_flame = 'Святе полумʼя';

export const sacred_flame_details = 'Атака вогняним сяйвом, здатним діставатися до цілі в укритті.';

export const sacred_flame_expanded = 'Схоже на вогонь сяйво сходить на істоту, яку ви бачите в межах дистанції. Ціль повинна досягти успіху у Випробуванні Спритності, інакше вона отримує пошкодження Світлом 1d8. Для цього Випробування ціль не отримує переваг від укриття.<br/><br/>' +
'Пошкодження від цього закляття збільшується на 1d8, коли ви досягаєте 5 рівня (2d8), 11 рівня (3d8), 17 рівня (4d8).';

export const resistance = 'Опір';

export const resistance_details = 'Бонус союзнику до одного будь-якого майбутнього Випробування.';

export const resistance_expanded = 'Ви торкаєтеся однієї згодної істоти. Поки закляття активне, ціль може один раз кинути d4 і додати число, що випало до одного Випробування на свій вибір. Цей куб можна кинути до або після Випробування. Після цього закляття закінчується.<br/><br/>' +
'Фокус для створення закляття можна замінити на крихітний плащ.';

export const produce_flame = 'Створення полумʼя';

export const produce_flame_details = 'Мерехтливе полумʼя у вашій долоні, яке можна тримати, або кинути у ворога.';

export const produce_flame_expanded = 'У вашій долоні зʼявляється полумʼя, що мерехтить. Воно залишається там, поки закляття активне, і не шкодить ні вам, ні вашому спорядженню. Вогонь випромінює яскраве світло в радіусі 10 футів і тьмяне світло в межах ще 10 футів. Закляття закінчується, якщо ви закінчуєте його Основною дією або накладаєте ще раз.<br/><br/>'+
'Ви можете атакувати цим полумʼям, але це теж закінчує закляття. Коли ви накладаєте це закляття, або іншою Основною дією в одному з наступних ходів, ви можете метнути полумʼя в істоту, що знаходиться в межах 30 футів від вас. Здійсніть далекобійну атаку закляттям. При влучанні ціль отримує пошкодження вогнем 1d8.<br/><br/>' +
'Пошкодження від цього закляття збільшується на 1d8, коли ви досягаєте 5 рівня (2d8), 11 рівня (3d8), 17 рівня (4d8).';

export const thorn_whip = 'Терновий батіг';

export const thorn_whip_details = 'Атака шипованим батогом, яка притягує ціль, за розміром не більше за Середню, на 10 футів.';

export const thorn_whip_expanded = 'Ви створюєте довгий, схожий на лозу батіг, покритий шипами, істота, що бʼє по вашій команді, що знаходиться в межах дистанції. Здійсніть ближню атаку закляттям по цілі. Якщо атака влучає, істота отримує Колоте пошкодження 1d6, і якщо розмір істоти не більше Великого, ви підтягуєте істоту на 10 футів до себе.<br/><br/>' +
'Пошкодження цього закляття збільшується на 1d6, коли ви досягаєте 5 рівня (2d6), 11 рівня (3d6), 17 рівня (4d6).<br/><br/>' +
'Фокус для створення закляття можна замінити на стебло рослини із шипами.';

export const guidance = 'Провід';

export const guidance_details = 'Бонус союзнику до одної будь-якої перевірки Здібності.';

export const guidance_expanded = 'Ви торкаєтеся однієї згодної істоти. Один раз, поки закляття активне, ціль може кинути d4 і додати число, що випало, до однієї перевірки Здібності на свій вибір. Цей куб можна кинути до або після перевірки. Після цього закляття закінчується.';

export const spare_the_dying = 'Догляд за помираючим';

export const spare_the_dying_details = 'Стабілізація істоти з 0 здоровʼя.';

export const spare_the_dying_expanded = 'Ви торкаєтеся живої істоти, яка має 0 хітів. Воно стає стабілізованим. Це закляття не має жодного ефекту на невмерлих і конструкти.';

export const silent_image = 'Безмовний образ';

export const silent_image_details = 'Створення проникного образу — зорової ілюзії, яка може переміщуватися та змінювати зовнішність.';

export const silent_image_expanded = 'Ви створюєте образ предмета, істоти або іншого видимого явища, що уміститься в куб із довжиною ребра 15 футів. Образ зʼявляється в точці, яку ви бачите в межах дистанції, і існує, поки закляття активне. Це виключно зорова ілюзія, яка не супроводжується звуками, запахами або іншими сенсорними ефектами.<br/><br/>' +
'Ви можете змусити образ переміститися в будь-яке місце в межах дистанції. Поки образ змінює місце розташування, ви можете змінювати його зовнішність, щоб рух виглядав природним. Наприклад, якщо ви створюєте образ істоти та переміщаєте його, ви можете змінити образ, щоб здавалося, що воно йде.<br/><br/>' +
'Фізична взаємодія з образом дає зрозуміти, що це ілюзія, тому що крізь неї все проходить. Істоти, що досліджують образ Основною дією, можуть визначити, що це ілюзія, зробивши успішну перевірку Розслідування проти Складності ваших заклять. Якщо істота розпізнає ілюзію, то вона може бачити крізь неї.<br/><br/>' +
'Фокус для створення закляття можна замінити на шматок вовни.';

export const bless = 'Благословіння';

export const bless_details = 'Бонус союзникам до будь-якого кидка атаки або Випробування.';

export const bless_expanded = 'Ви благословляєте до трьох істот на свій вибір у межах дистанції. Щоразу, коли до закінчення закляття ціль робить кидок атаки чи Випробування, вона може кинути d4 і додати число, що випало до результату.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за перший.';

export const divine_favor = 'Божественна милість';

export const divine_favor_details = 'Бонус на атаки зброєю.';

export const divine_favor_expanded = 'Ваша молитва сповнює вас божественною енергією. Поки закляття активне, ваші атаки зброєю завдають при влучанні додаткове пошкодження 1d4.';

export const witch_bolt = 'Відьомський снаряд';

export const witch_bolt_details = 'Атака блискавкою, що звʼязує вас з ціллю і дозволяє завдавати пошкодження кожен хід, доки звʼязок активний.';

export const witch_bolt_expanded = 'Промінь тріскаючої синюватої енергії спрямовується до цілі в межах дистанції, формуючи між вами і ціллю сталий дуговий розряд. Здійсніть далекобійну атаку закляттям по цій цілі. При влучанні ціль отримує пошкодження Струмом 1d12, і поки закляття активне, ви можете в кожен свій хід Основною дією автоматично завдавати цілі пошкодження електрикою 1d12. Це закляття закінчується, якщо ви дією зробите щось інше. Закляття також закінчується, якщо ціль опиниться за межами дистанції закляття або отримає від вас повне укриття.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, початкове пошкодження збільшується на 1d12 для кожного рівня слоту вище першого.<br/><br/>' +
'Фокус для створення закляття можна замінити на гілочку дерева, в яке вдарила блискавка.';

export const thunderwave = 'Хвиля грому';

export const thunderwave_details = 'Гучним звуком гуркоту грому, завдавши пошкодження, відкинути від вас на 10ф всіх істот та предмети в області.';

export const thunderwave_expanded = 'Від вас виходить хвиля громової сили. Всі істоти в кубі з довжиною ребра 15 футів, що виходить від вас, повинні зробити спасинок Статури. При провалі істота отримує шкоду звуком 2d8 і штовхається на 10 футів від вас. При успіху істота отримує половину пошкодження і не штовхається.<br/><br/>' +
'Крім того, незакріплені предмети, що опинилися повністю в області ефекту, автоматично штовхаються на 10 футів від вас ефектом закляття, і закляття видає громовий гуркіт, який чути на відстані 300 футів.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище за перший.';

export const magic_missile = 'Чарівні стріли';

export const magic_missile_details = 'Магічні дротики, що самонаводяться.';

export const magic_missile_expanded = 'Ви з магічної сили створюєте три дротики, що світяться. Кожний дротик влучає в істоту на ваш вибір, видиму в межах дистанції. Кожен дротик завдає пошкодження Силовим полем 1d4 + 1. Всі дротики атакують одночасно, і ви можете спрямувати їх як в одну істоту, так і в різні.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, закляття створює по одному додатковому дротику за кожен рівень слоту вище за перший.';

export const compelled_duel = 'Виклик на дуель';

export const compelled_duel_details = 'Притягнути істоту, накласти на неї <span class="buff">перешкоду</span> на атаки по іншим істотам, і не відпускати без Випробування далі ніж на 30ф.';

export const compelled_duel_expanded = 'Ви викликаєте істоту на дуель. Одна істота, яку ви бачите в межах дистанції, має пройти Випробування Мудрості. При провалі істота притягується до вас, підкоряючись вашій божественній вимозі. Поки закляття активне, ця істота здійснює з перешкодою кидки атаки по всіх істотах крім вас, і має проходити Випробування Мудрості щоразу, коли намагається переміститися в простір, що знаходиться більш ніж за 30 футів від вас; у разі успіху це закляття не обмежує переміщення цілі у цей хід.<br/><br/>' +
'Закляття закінчується, якщо ви атакуєте іншу істоту, якщо ви активуєте закляття, націлене на ворожу істоту, але не на ціль, якщо дружня вам істота завдає цілі пошкодження або накладає на неї шкідливе закляття, або якщо ви закінчуєте хід далі, ніж у 30 фу цілі.';

export const heroism = 'Героїзм';

export const heroism_details = 'Надати союзнику <span class="buff">імунітет</span> проти 😱 Переляку та тимчасове здоровʼя кожен хід.';

export const heroism_expanded = 'Згодна істота, яку ви торкаєтеся, сповнюється хоробрістю. Поки закляття активне, істота отримує імунітет до стану 😱 Переляку, і на початку кожного свого ходу отримує кільцість тимчасового здоровʼя, що дорівнює модифікатору вашої базової Здібності. Коли це закляття закінчується, ціль втрачає все тимчасове здоровʼя, що залишилися від цього закляття.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви можете зробити ціль одну додаткову істоту за кожен рівень слоту вище за перший.';

export const wrathful_smite = 'Гнівна кара';

export const wrathful_smite_details = 'Ваша атака ближньою зброєю завдає додаткове пошкодження і викликає 😱 Переляк.';

export const wrathful_smite_expanded = 'Наступного разу, коли ви влучите атакою ближньою зброєю, поки активне це закляття, ваша атака завдає додаткове пошкодження Психічною енергією 1d6. Крім того, якщо ціль — істота, вона повинна пройти Випробування Мудрості, інакше вона стане зляканою до закінчення дії закляття. Істота може здійснити перевірку Мудрості проти Складності ваших заклять, щоб заспокоїтися і закінчити це закляття.';

export const hail_of_thorns = 'Град шипів';

export const hail_of_thorns_details = 'Зарядити в наступну влучну далекобійну атаку додаткове пошкодження по невеликій області.';

export const hail_of_thorns_expanded = 'Наступного разу, коли ви влучите по цілі далекобійною атакою зброєю, поки активне це закляття, з далекобійної зброї чи боєприпасу вилетить град шипів. На додаток до звичайного ефекту атаки, ціль і всі істоти в межах 5 футів від неї, повинні пройти Випробування Спритності. При провалі істота отримує Колоте пошкодження 1d10, або половину цього пошкодження при успіху.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d10 за кожен рівень слоту вище за перший (до максимуму 6d10).';

export const thunderous_smite = 'Громова кара';

export const thunderous_smite_details = 'Наступна ваша успішна атака ближньою зброєю завдасть додаткове пошкодження. Якщо ціль провалить Випробування, вона буде відкинута на 10ф і збита з ніг.';

export const thunderous_smite_expanded = 'Вперше, коли ви влучаєте атакою ближньою зброєю, поки закляття активне, ваша зброя видає громовий гуркіт, чутний з відстані 300 футів, і атака завдає цілі додаткове пошкодження Звуком 2d6.<br/><br/>' +
'Крім того, якщо ціль — істота, вона повинна досягти успіху у Випробуванні Сили, інакше вона буде відкинута на 10 футів від вас і збита з ніг.';

export const dissonant_whispers = 'Скрипучий шепіт';

export const dissonant_whispers_details = 'Нестрункий наспів пошепки, який завдає буквального болю і змушує тікати від нього.';

export const dissonant_whispers_expanded = 'Ви пошепки співаєте безладну мелодію, яку чує лише обрана вами істота в межах дистанції, і яка завдає йому страшного болю. Ціль має пройти Випробування Мудрості. У разі провалу вона отримує пошкодження Психічною енергією 3d6 і повинна негайно Реакцією переміститися геть від вас на відстань, що дорівнює своїй швидкості.Істота не буде входити до явно небезпечних місць, таких як вогонь або яма. При успішному Випробуванні ціль отримує половину пошкодження і не повинна відходити. Глуха істота автоматично досягає успіху у Випробуванні.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище за перший.';

export const armor_of_agathys = 'Обладунок Агатіса';

export const armor_of_agathys_details = 'Примарна паморозь, що дає вам додаткове здоровʼя і стільки ж пошкодження ❄️ Холодом істотам, що влучили по вам акатою.';

export const armor_of_agathys_expanded = 'Вас оточує захисне магічне поле, що виглядає як примарна паморозь, що покрила вас і ваше спорядження. Ви отримуєте 5 пунктів тимчасового здоровʼя на період дії закляття.<br/><br/>' +
'Якщо істота влучає по вам ближньоє атакою, поки у вас є це здоровʼя, вона отримує пошкодження Холодом 5.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, і тимчасове здоровʼя та пошкодження холодом збільшуються на 5 за кожен рівень слоту вище за перший.<br/><br/>' +
'Фокус для закляття можна замінити на склянку води.';

export const mage_armor = 'Обладунок мага';

export const mage_armor_details = 'Магічне захисне поле, що огортає згодну істоту без обладунку.';

export const mage_armor_expanded = 'Ви доторкаєтеся до згодної істоти, що не носить обладунок, і на час дії закляття його огортає захисне магічне поле. Базова Броня істоти дорівнює 13 + його модифікатор Спритності. закляття закінчується, якщо ціль надягає обладунок або ви закінчуєте його Основною дією.<br/><br/>' +
'Фокус для створення закляття можна замінити на шматок виробленої шкіри.'

export const animal_friendship = 'Дружба з тваринами';

export const animal_friendship_details = 'Накласти 😍 Зачарування на тварину.';

export const animal_friendship_expanded = 'Це закляття дозволяє переконати звіра, що ви не маєте наміру завдавати йому пошкодження. Виберіть звіра, якого ви бачите в межах дистанції. Він має бачити і чути вас.<br/><br/>' + 
'Якщо у звіра Інтелект 4 та більше, закляття провалюється. В іншому випадку звір повинен досягти успіху у Випробуванні Мудрості, інакше він стане Зачарованим на час дії закляття. Якщо ви або один із ваших супутників завдасть цілі пошкодження, закляття закінчиться.<br/><br/>' + 
'Фокус для створення закляття можна замінити на шматочок їжі.';

export const tashas_hideous_laughter = 'Моторошний сміх Таші';

export const tashas_hideous_laughter_details = 'Змусити істоту впасти від сміху і не мати змоги піднятися.';

export const tashas_hideous_laughter_expanded = 'Істота на ваш вибір, видима в межах дистанції, сприймає все неймовірно смішним, і корчиться від сміху, якщо закляття на нього діє. Ціль повинна досягти успіху у Випробуванні Мудрості, інакше вона падає ниць, стає недієздатною і протягом дії закляття не може встати. Істоти зі значенням Інтелекту 4 і менше не підпадають під дію цього закляття.<br/><br/>' + 
'В кінці кожного свого ходу, і кожного разу при отриманні пошкодження ціль може знову пройти Випробування Мудрості. Воно відбувається з перевагою, якщо він був викликаний отриманням пошкодження. При успіху закляття закінчується.<br/><br/>' + 
'Фокус для створення закляття можна замінити на маленькі тістечка та перо, яким потрібно махати у повітрі.';

export const protection_from_evil_and_good = 'Захист від добра та зла';

export const protection_from_evil_and_good_details = 'Аберації, погань, небожителі, невмерлі, феї чи елементали атакують союзника з перешкодою, і не можуть Перелякати, Зачарувати чи зробити Одержимим.';

export const protection_from_evil_and_good_expanded = 'Поки закляття активне, одна згодна істота, яку ви торкнулися, захищена від певного виду істот: аберації, погань, небожителі, невмерлі, феї чи елементали.<br/><br/>' + 
'Захист надає кілька переваг. Істоти цих видів здійснюють із перешкодою кидки атаки по цілі. Ціль також не може бути Зачарована, Перелякана і Одержима ними. Якщо ціль вже Зачарована, Перелякана або Одержима істотою такого виду, ціль робить наступні Випробування від таких ефектів з перевагою.';

export const healing_word = 'Цілюще слово';

export const healing_word_details = 'Відновити трохи здоровʼя союзникові.';

export const healing_word_expanded = 'Істота на ваш вибір, видима в межах дистанції, відновлює кількість здоровʼя, що дорівнює 1d4 + ваш модифікатор базової Здібності. Це закляття не має жодного ефекту на невмерлих і конструктів.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, кількість хітів, що відновлюються, збільшується на 1d4 за кожен рівень слоту вище першого.';

export const cure_wounds = 'Лікування ран';

export const cure_wounds_details = 'Відновити частину здоровʼя союзникові поряд.';

export const cure_wounds_expanded = 'Істота, до якої ви доторкаєтесь, відновлює кількість здоровʼя, що дорівнює 1d8 + ваш модифікатор базової Здібності. Це закляття не має жодного ефекту на невмерлих і конструктів.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, кількість хітів, що відновлюються, збільшується на 1d8 за кожен рівень слоту вище першого.';

export const ray_of_sickness = 'Промінь хвороби';

export const ray_of_sickness_details = 'Атака променем ригачки. Якщо ціль провалила Випробування, вона стає 🤢 Отруєною ';

export const ray_of_sickness_expanded = 'Промінь нудотно-зеленої енергії спрямовується до істоти, що знаходиться в межах дистанції. Здійсніть далекобійну атаку закляттям по цілі. При попаданні ціль отримує пошкодження Отрутою 2d8 і має зробити Випробування Статури. При провалі вона стає Отруєною до кінця вашого наступного ходу.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище за перший.';

export const disguise_self = 'Маскування';

export const disguise_self_details = 'Значно змінити свій вигляд на суттєвий час.';

export const disguise_self_expanded = 'Ви змушуєте себе — включно з одягом, обладунками, зброєю та іншим майном — виглядати інакше, доки закляття не скінчиться, або доки ви не скінчите його передчасно, Основною дією. Ви можете виглядати на 1 фут (30 сантиметрів) вище або нижче, а також стати худим, товстим або отримати середню статуру. Ви не можете змінювати основу тіла, тому у нової форми має бути такий же набір кінцівок. В усьому іншому характер ілюзії визначаєте ви.<br/><br/>' + 
'Зміни, спричинені цим закляттям, не витримують перевірку фізичним дослідженням. Наприклад, якщо ви додали до свого спорядження капелюх, предмети проходитимуть крізь нього, і всі, хто до нього торкнуться, або нічого не відчують, або відчують вашу голову та волосся. Якщо ви стаєте худшими, то рука того, хто до вас тягнеться, натрапить на вас на півдорозі, ще в повітрі.<br/><br/>' + 
'Для виявлення того, що ви замасковані, істота повинна Основною дією обстежити вашу зовнішність і досягти успіху в перевірці Розслідування проти вашої Складності заклять.';

export const hunters_mark = 'Мисливський знак';

export const hunters_mark_details = 'Додаткове пошкодження по вибраній істоті, а також <span class="buff">перевага</span> на його пошук. При ліквідації цілі можна Бонусною дією вибрати нову ціль.';

export const hunters_mark_expanded = 'Ви вибираєте істоту, видиму в межах дистанції, і оголошує її своєю здобиччю. Поки закляття активне, ви завдаєте цілі додаткове пошкодження 1d6 щоразу, коли влучаєте по ній атакою зброєю, і ви здійснюєте з перевагою перевірки Уважності та Виживання для її пошуку.<br/><br/>' + 
'Якщо здоровʼя цілі опускається до 0, поки закляття активне, ви можете у свій наступний хід Бонусною дією вибрати ціллю нову істоту.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 або 4 рівня, ви можете підтримувати концентрацію 8 годин. Якщо ви використовуєте слот мани 5 або вище рівня, ви можете підтримувати концентрацію 24 години.';

// — 40%

export const inflict_wounds = 'Нанесення ран';

export const inflict_wounds_details = 'Ближня атака прокляттям.';

export const inflict_wounds_expanded = 'Здійсніть ближню атаку закляттям по цілі в межах досяжності. При попаданніцільотримує пошкодження Некротичною енергією 3d10.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d10 за кожен рівень слоту вище за перший.';

export const guiding_bolt = 'Спрямований снаряд';

export const guiding_bolt_details = 'Атака спалахом світла. <span class="buff">Перевага</span> на наступну атаку тієї ж цілі.';

export const guiding_bolt_expanded = 'Спалах світла спрямовується до обраної вами істоти в межах дистанції. Здійсніть далекобійну атаку закляттям по цілі. При попаданні ціль отримує пошкодження Світлом 4d6, і наступний кидок атаки по цілі до кінця вашого наступного ходу, відбувається з перевагою, завдяки містичному тьмяному світлу, що залишається недовго на цілі.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище за перший.';

export const illusory_script = 'Невидимий лист';

export const illusory_script_details = 'Магічне шифрування, яке можуть прочитати лише зазначені істоти.';

export const illusory_script_expanded = 'Ви пишете на пергаменті, папері або іншому придатному матеріалі, і накриваєте його ілюзією, що існує, поки закляття активне. Для вас і всіх істот, зазначених вами при накладанні закляття, текст здаються звичайним, написаним вашою рукою, і передає той самий зміст, який вкладали ви.<br/><br/>' + 
'Ви можете змусити текст змінити сенс, або зробити так, щоб вони виглядали написаними іншою рукою або іншою мовою, але мова при цьому повинна бути тією, яку ви знаєте. Якщо закляття розсіяти, зникає і вихідний лист, і ілюзія. Істота із істинним зором може читати приховане послання.';

export const unseen_servant = 'Невидимий слуга';

export const unseen_servant_details = 
'Помічник, здатний виконувати прості доручення.<br/>'+
'• Віддати команду коштує Бонусної дії<br/>'+
'• Броня 10, Швидкість 15ф, Здоровʼя 1, Сила 2<br/>'+
'• Не може атакувати<br/>';

export const unseen_servant_expanded = 'Це закляття створює невидиму, несвідому і безформну силу Середнього розміру, що виконує прості доручення, що віддаються вами. Слуга зʼявляється у вільному просторі на землі в межах дистанції. Він має Броню 10, 1 пункт здоровʼя, Силу 2, і він не може атакувати.<br/><br/>' + 
'Якщо його здоровʼя опускаються до 0, закляття закінчується. Один раз у кожному своєму ходу ви можете Бонусною дією віддати команду слузі переміститися на 15 футів і здійснити маніпуляції з будь-яким предметом. Слуга може виконувати прості завдання, доступні людині, такі як підношення предметів, чищення, ремонт та складання одягу, розпалювання багаття, подача їжі та розливання вина.<br/><br/>' + 
'Отримавши команду, слуга починає її виконувати, намагаючись щосили, поки не виконає, після чого починає чекати вашу наступну команду. Якщо ви віддаєте слузі доручення, через яке той виявиться далі ніж за 60 футів від вас, закляття закінчується.<br/><br/>' + 
'Фокус для створення закляття можна замінити на частину тятиви та шматочок дерева.';

export const detect_poison_and_disease = 'Виявлення хвороб і 🧪 Отрути';

export const detect_poison_and_disease_details = 'Знайти та визначити 🧪 Отруйну істоту або субстанцію.';

export const detect_poison_and_disease_expanded = 'Поки закляття активне, ви можете відчувати присутність та місцезнаходження отрут, отруйних істот та хвороб у межах 30 футів від себе. Ви також розпізнаєте вид отрути, отруйної істоти чи хвороби.<br/><br/>' + 
'Закляття проходить крізь більшу частину барʼєрів, але блокується 1 футом каменю, 1 дюймом звичайного металу, тонким листом свинцю або 3 футами дерева чи землі.<br/><br/>' + 
'Фокус для створення закляття можна замінити на лист тиса.';

export const detect_evil_and_good = 'Виявлення добра та зла';

export const detect_evil_and_good_details = 'Знайти аберації, погань, небожителів, невмерлих, фей, елементалей або освячені/нечисті предмети.';

export const detect_evil_and_good_expanded = 'Поки закляття активне, ви знаєте, чи є в межах 30 футів від вас аберації, погань, небожителі, невмерлі, феї або елементали, а також їхнє розташування. Крім того, ви знаєте, чи є в межах 30 футів від вас місце або предмет, який був магічно освячений або опоганений.<br/><br/>' + 
'Закляття проходить крізь більшу частину барʼєрів, але блокується 1 футом каменю, 1 дюймом звичайного металу, тонким листом свинцю або 3 футами дерева чи землі.';

export const detect_magic = 'Виявлення магії';

export const detect_magic_details = 'Знайти та розпізнати магію та істот/предмети, повʼязані з нею.';

export const detect_magic_expanded = 'Поки закляття активне, ви відчуваєте присутність магії в межах 30 футів. Якщо ви відчули за рахунок цього закляття присутність магії, ви можете Основною дією побачити слабку ауру навколо видимої істоти або предмета, що несе на собі магію, а також дізнатися про школу цієї магії, якщо вона є.<br/><br/>' + 
'Закляття проходить крізь більшу частину барʼєрів, але блокується 1 футом каменю, 1 дюймом звичайного металу, тонким листом свинцю або 3 футами дерева чи землі.';

export const burning_hands = 'Палаючі долоні';

export const burning_hands_details = 'Стовп полумʼя з долонь, що спалює все перед собою.';

export const burning_hands_expanded = 'Зʼєднавши долоні та розкривши пальці, ви випускаєте з них тонкі язики полумʼя. Усі істоти в межах 15-футового конуса повинні пройти Випробування Спритності. Істота отримує пошкодження вогнем 3d6 у разі провалу і половину цього пошкодження у разі успіху. Полумʼя підпалює всі горючі предмети, які ніхто не тримає і не носить.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище за перший.';

export const identify = 'Впізнання';

export const identify_details = 'Дізнатися про властивості, деталі, стан, спосіб використання вибранного предмета.';

export const identify_expanded = 'Ви вибираєте один предмет, не припиняючи торкатися до нього під час всього накладання закляття. Якщо це магічний предмет, ви дізнаєтеся його властивості, спосіб їх використання, чи потрібне для використання налаштування на нього, і скільки залишилося зарядів, якщо вони є. Ви дізнаєтеся, чи діють в даний момент закляття на цей предмет, і що це за закляття. Якщо предмет був створений закляттям, ви дізнаєтесь, що це за закляття. Якщо ви натомість під час накладання закляття торкаєтеся істоти, ви дізнаєтеся, які закляття в даний момент діють на нього.<br/><br/>' + 
'Фокус для створення закляття можна замінити на перо сови.';

export const entangle = 'Обплутування';

export const entangle_details = 'Чіпкі трави і лози, 🪢 Обплутуючі всіх в області, хто провалив Випробування.';

export const entangle_expanded = 'З точки в межах дистанції на площі квадрата з довжиною в 20 футів виростають чіпкі трави і лози. Поки закляття активне, ці рослини перетворюють землю на важкопрохідну місцевість. Істоти, що знаходяться в цій області, коли ви накладаєте закляття, істоти повинні досягти успіху у Випробуванні Сили, інакше вони стануть обплутаними рослинами до закінчення дії закляття.<br/><br/>' + 
'Обплутана рослинами істота може дією здійснювати перевірки Сили проти Складності ваших заклять. У разі успіху воно звільняється. Коли закляття закінчується, викликані рослини вʼянуть.';

export const ensnaring_strike = 'Обплутуючий удар';

export const ensnaring_strike_details = 'Наступне ваше влучання зброєю викликає 🪢 Обплутування цілі шипами, якщо вона провалила Випробування. Шипи завдають цілі пошкодження кожен хід.';

export const ensnaring_strike_expanded = 'Коли ви наступного разу влучите атакою зброєю, поки закляття активне, в точці удару зʼявляється маса рослин, що звивається, з шипами, і ціль повинна досягти успіху у Випробуванні Сили, інакше вона стане обплутана магічними лозами, поки закляття активне.<br/><br/>' + 
'Обплутана цим закляттям, ціль отримує Колоте пошкодження 1d6 на початку кожного свого ходу. Якщо ціль досягає успіху у Випробуванні, лози всихають.<br/><br/>' + 
'Обплутана істота або істота, здатна торкнутися її, може Основною дією перевірити Силу проти Складності ваших заклять. За успіху ціль звільняється.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище за перший.';

export const charm_person = 'Зачарування особи';

export const charm_person_details = '😍 Зачарувати гуманоїда у межах дистанції.';

export const charm_person_expanded = 'Ви намагаєтесь зачарувати гуманоїда, якого бачите у межах дистанції. Він повинен пройти Випробування Мудрості, з перевагою якщо ви або ваші супутники бʼєтеся з ним. Якщо він провалює Випробування, він зачарований вами, поки закляття активне або поки ви чи ваші супутники не завдасте йому пошкодження. Зачарована істота вважає вас своїм другом. Коли закляття закінчується, істота знає, що було зачароване вами.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за перший. Ці істоти повинні знаходитися в межах 30 футів один від одного, щоб ви могли на них націлитись.';

// — 45%

export const purify_food_and_drink = 'Очищення їжі та пиття';

export const purify_food_and_drink_details = 'Позбавити отрут і хвороб всю їжу та пиття у вибраній невеликій області.';

export const purify_food_and_drink_expanded = 'Вся немагічна їжа та пиття в межах сфери з радіусом 5 футів з центром на точці, обраній вами в межах дистанції, очищується та позбавляється отрут і хвороб.';

export const feather_fall = 'Мʼяке падіння';

export const feather_fall_details = 'Запобігти болючому падінню себе або союзників.';

export const feather_fall_expanded = 'Виберіть до пʼяти падаючих істот у межах дистанції. До закінчення дії закляття їхня швидкість падіння зменшується до 60 футів в раунд. Якщо така істота приземлиться до закінчення закляття, вона не отримує пошкодження від падіння і може приземлитися на ноги, і тоді закляття закінчує дію на неї.<br/><br/>' + 
'Фокус для створення закляття можна замінити невеликим пером або шматочком пуху.';

export const searing_smite = 'Пекуча кара (або Курча Карі)';

export const searing_smite_details = 'Наступне ваше ближнє влучання підпалює ціль. Вогонь завдає цілі пошкодження кожен хід, якщо вона провалює Випробування.';

export const searing_smite_expanded = 'Коли ви наступного разу влучите по цілі ближньою атакою зброєю, поки закляття активне, ваша зброя спалахує яскравим білим світлом, і атака завдає цілі додаткову пошкодження Вогнем 1d6 і підпалює її.<br/><br/>'+
'Поки закляття активне, ціль на початку кожного свого ходу повинна проходити Випробування Статури. При провалі вона отримує пошкодження Вогнем 1d6. При успіху закляття закінчується.<br/><br/>'+
'Якщо ціль або істота, що знаходиться в межах 5 футів від неї, витратить Основну дію на гасіння полумʼя, або якщо якийсь інший ефект згасить полумʼя (наприклад, занурення у воду), закляття закінчиться.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, початкове додаткове пошкодження від атаки збільшується на 1d6 за кожен рівень слоту вище за перший.';

export const find_familiar = 'Виклик фамільяра';

export const find_familiar_details = 'Фея/небожитель/погань помічник у вигляді невеликої тварини.<br/>'+
'• Діє незалежно, але завжди кориться командам<br/>' +
'• Може спілкуватися з вами телепатично (до 100ф)<br/>'+
'• Може ділитися даними своїх органів чуття<br/>'+
'• Можна сховати у кишеньковому вимірі<br/>'+
'• У бою має окрему ініціативу та хід<br/>'+
'• Не може атакувати, але може бути джерелом заклять';

export const find_familiar_expanded = 'Ви отримуєте на службу фамільяра — духа, що приймає вигляд обраної вами невеликої тварини. Зʼявившись у вільному просторі в межах дистанції, фамільяр набуває характеристик обраної форми, хоча він не звір, а небожитель, фея чи погань (на ваш вибір).<br/><br/>'+
'Ваш фамільяр діє незалежно від вас, але завжди кориться вашим командам. У битві він визначає свою ініціативу та діє у свій хід. Фамільяр не може атакувати, але може здійснювати інші дії як завжди. Коли здоровʼя фамільяра опускаються до 0 він зникає, не залишаючи фізичного тіла. Він повертається, коли ви використовуєте це закляття ще раз.<br/><br/>'+
'Поки фамільяр знаходиться в межах 100 футів, ви можете спілкуватися з ним телепатично. Крім того, ви можете почати дивитися очима фамільяра і слухати його вухами до початку свого наступного ходу, отримуючи перевагу від особливих відчуттів, якими може мати фамільяр. В цей час для своїх власних почуттів ви сліпі та глухі.<br/><br/>'+
'Якщо ви накладаєте закляття з умовою «Дотик цілі», його може передати фамільяр, ніби це він його накладав. Ваш фамільяр повинен при цьому знаходитись в межах 100 футів від вас, і він використовує свою Реакцію, коли ви накладаєте його. Якщо закляття вимагає кидка атаки, ви використовуєте свій модифікатор атаки.<br/><br/>'+
'Ви можете тимчасово відпустити свого фамільяра. Той зникає в кишеньковому вимірі, де чекатиме, доки ви його не покликаєте. Якщо фамільяр несе якісь речі, то вони залишаються на місці зникнення після його відходу в кишеньковий вимір. Поки фамільяр тимчасово відпущений, ви можете повернути його у вільний простір в межах 30 футів від себе. Як альтернатива, ви можете відпустити його назовсім.<br/><br/>'+
'У вас не може бути більше одного фамільяра одночасно. Якщо ви накладаєте це закляття, вже маючи фамільяра, ви змушуєте прийняти новий вигляд. Виберіть один із описаних вище видів. Ваш фамільяр стає цією істотою.';

export const comprehend_languages = 'Розуміння мов';

export const comprehend_languages_details = 'Розпізнання буквального сенсу всього почутого незалежно від мови.';

export const comprehend_languages_expanded = 'Поки закляття активне, ви розумієте буквальний зміст усіх почутих промов будь-якими мовами. Ви також розумієте письмена будь-якими мовами, але ви повинні торкатися поверхні, на якій записані слова. Потрібно приблизно 1 хвилину для читання однієї сторінки тексту. Це закляття не розшифровує таємні послання в текстах та символах, таких як магічні знаки, що не є частиною тексту.<br/><br/>' + 
'Фокус для створення закляття можна замінити на щіпку сажі та солі.';

export const bane = 'Пристріт';

export const bane_details = 'Зменшити цілі кидки атаки та Випробувань на 1d4.';

export const bane_expanded = 'До трьох істот у межах дистанції, яких ви бачите, повинні пройти випробування Харизми. Поки закляття активне, кожен раз, коли ціль, що провалила це Випробування, робить кидок атаки або Випробування, вона повинна кидати d4 і віднімати результат, що випав з кидка атаки або Випробування.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за перший.<br/><br/>' + 
'Фокус для закляття можна замінити на краплю крові.';

export const bane_warlock = 'Пристріт *';

export const bane_warlock_details = 'Зменшити цілі кидки атаки та Випробувань на 1d4. Можна використовувати один раз у тривалий відпочинок.';

export const bane_warlock_expanded = 'До трьох істот у межах дистанції, яких ви бачите, повинні пройти випробування Харизми. Поки закляття активне, кожен раз, коли ціль, що провалила це Випробування, робить кидок атаки або Випробування, вона повинна кидати d4 і віднімати результат, що випав з кидка атаки або Випробування.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за перший.<br/><br/>' + 
'Фокус для закляття можна замінити на краплю крові.<br/><br/>' + 
'<span class="rare-text">* Ви можете один раз створити закляття Пристріт, використовуючи слот мани. Ви не можете зробити це повторно, поки не закінчите тривалий відпочинок.</span>';

export const expeditious_retreat = 'Стрімкий відступ';

export const expeditious_retreat_details = 'Можливість використовувати Ривок Бонусною дією.';

export const expeditious_retreat_expanded = 'Це закляття дозволяє переміщатися із неймовірною швидкістю. Коли ви накладаєте це закляття, а також у наступні ходи доки активне закляття, ви можете Бонусною дією здійснювати Ривок.';

export const command = 'Наказ';

export const command_details = 'Змусити ціль виконати не шкідливу для неї команду з одного слова зрозумілою їй мовою. Наприклад Кинь, Падай, Підійди, Стій, Втікай.';

export const command_expanded = 'Ви промовляєте команду з одного слова істоті, яку бачите в межах дистанції. Ціль повинна досягти успіху у Випробуванні Мудрості, інакше в свій наступний хід виконуватиме цю команду. закляття не має ефекту, якщо ціль — невмерлий, якщо ціль не розуміє вашу мову, або якщо ваша команда їй безпосередньо шкодить. Нижче описані типові команди та їх ефекти.<br/><br/>'+
'Кинь. Ціль кидає те, що тримає, і закінчує хід.<br/><br/>'+
'Падай. Ціль падає ниць і закінчує хід.<br/><br/>'+
'Підійди. Ціль переміщується ближче до вас найкоротшим і найпрямішим маршрутом, закінчуючи хід, якщо опиняється в межах 5 футів від вас.<br/><br/>'+
'Стій. Ціль не переміщається і не здійснює жодних дій. Летюча істота продовжує летіти за умови, що може це робити. Якщо вона має летіти, щоб не впасти, то пролітає мінімально необхідну відстань.<br/><br/>'+
'Тікай. Ціль витрачає хід на переміщення геть від вас найшвидшим способом.<br/><br/>'+
'Ви можете віддавати інші команди. В цьому випадку поведінку цілі визначає Майстер. Якщо ціль не може виконати вашу команду, закляття закінчується.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви можете впливати на одну додаткову істоту за кожен рівень слоту вище за перший. Істоти повинні бути в межах 30 футів один від одного, щоб стати цілями.';

export const jump = 'Стрибок';

export const jump_details = 'Подовжити стрибок цілі втричі.';

export const jump_expanded = 'Ви торкаєтесь істоти. Поки закляття активне, відстань стрибка цієї істоти збільшується втричі.';

export const false_life = 'Хибне життя';

export const false_life_details = 'Додаткові некроманські життєві сили.';

export const false_life_expanded = 'Зміцнивши себе некромантською подобою життя, ви отримуєте 1d4 + 4 тимчасового здоровʼя на час тривалості закляття.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви отримуєте 5 додаткових тимчасового здоровʼя за кожен рівень слоту вище за перший.<br/><br/>' + 
'Фокус для створення закляття можна замінити на невелику кількість алкоголю або дистильованого спирту.';

export const arms_of_hadar = 'Руки Хадара';

export const arms_of_hadar_details = 'Вдарити щупальцями темної енергії всіх істот поблизу і позбавити Реакції при провалі Випробування.';

export const arms_of_hadar_expanded = 'Ви звертаєтеся до сили Хадара, Темного Алкателя. З вас вириваються щупальця з темної енергії, що бʼють усіх істот в межах 10 футів. Усі істоти у цій області мають пройти Випробування Сили. У разі провалу ціль отримує пошкодження Некротичною енергією 2d6 і у наступному ході не може здійснювати Реакції. У разі успіху істота отримує половину пошкодження, та інших ефектів немає.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище за перший.';

// — 50%

export const color_spray = 'Кольорові бризки';

export const color_spray_details = '😵‍💫 Осліпити найкволіших істот в області.';

export const color_spray_expanded = 'З вашої руки вилітають яскраві різнокольорові промені. Киньте 6d10; результат покаже, скільки здоровʼя істот підпадає під ефект. Істоти в 15 футовому конусі, що йде від вас, потрапляють під ефект у порядку збільшення поточного здоровʼя (ігноруючи тих, хто непритомний і не може бачити).<br/><br/>'+
'Починаючи з істоти з найменшою кількістю поточного здоровʼя, всі істоти, що потрапили під дію закляття, стають засліпленими до кінця закляття. Віднімайте з залишку здоровʼя вже засліплених істот і переходьте до наступних. Здоровʼя істоти не повинні перевищувати залишок, щоб ця істота потрапила під дію закляття.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, кидайте додатково 2d10 за кожен рівень слоту вище за перший.<br/><br/>' + 
'Фокус для створення закляття можна замінити на щіпку порошку або піску, пофарбованого в червоний, жовтий та синій колір.';

export const hex = 'Прокльон';

export const hex_details = 'Додаткове пошкодження цілі при влучанні атакою. <span class="buff">Перешкода</span> цілі на перевірки вибраною характеристикою. При ліквідації цілі можна Бонусною дією вибрати нову ціль.';

export const hex_expanded = 'Ви проклинаєте істоту, яку бачите у межах дистанції. Поки закляття активне, ви завдаєте цілі пошкодження Некротичною енергією 1d6 щоразу, коли влучаєте по ній атакою. Крім того, оберіть одну Здібність, коли накладаєте закляття. Ціль робить із перешкодою перевірки обраної Здібності. Якщо здоровʼя цілі опускається до 0, поки закляття активне, ви можете у свій наступний хід Бонусною дією проклясти нову істоту. Зняття прокляття, накладене на ціль, закінчує це закляття передчасно.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 3 або 4 рівня, ви можете підтримувати концентрацію на ньому до 8 годин. Якщо ви використовуєте слот мани 5 або вище рівня, ви можете підтримувати концентрацію на ньому до 24 годин.<br/><br/>' + 
'Фокус для створення закляття можна замінити на скамʼяніле око тритону.';

export const alarm = 'Сигналізація';

export const alarm_details = 'Тривога, яка повідомляє вас телепаично або звуком про рух як мінімум Крихітних обʼєктів у вибраній області.';

export const alarm_expanded = 'Ви встановлюєте сигналізацію на випадок вторгнення. Виберіть двері, вікно або область в межах дистанції не більше куба з довжиною ребра 20 футів. До закінчення дії закляття тривога повідомляє вас щоразу, коли до області, що охороняється, доторкається або входить істота з розміром не менше Крихітного. При накладанні закляття ви можете вказати істот, які не будуть викликати спрацювання тривоги. Ви також вибираєте, телепатичною буде тривога чи звуковою.<br/><br/>'+
'Телепатична тривога попереджає вас дзвоном у свідомості, якщо ви знаходитесь в межах 1 милі від області, що охороняється. Цей дзвін будить вас, якщо ви спите.<br/><br/>' + 
'Звукова тривога видає дзвін дзвіночка протягом 10 секунд в межах 60 футів.<br/><br/>' + 
'Фокус для створення закляття можна замінити на дзвіночок і срібний дріт.';

export const grease = 'Слизьке мастило';

export const grease_details = 'Зробити невелику область слизькою та жирною.';

export const grease_expanded = 'Слизький жир покриває підлогу в квадраті з довжиною боку 10 футів із центром на точці в межах дистанції, роблячи цю місцевість важкопрохідною, поки закляття активне. Коли жир зʼявляється, всі істоти, що стоять у цій області, повинні досягти успіху у Випробуванні Спритності, інакше вони падають ниць.<br/><br/>'+
'Істоти, що входять в цю область або закінчують там хід, теж мають досягти успіху у Випробуванні Спритності, щоб не впасти.<br/><br/>' + 
'Фокус для створення закляття можна замінити на шматок масла чи сала.';

export const longstrider = 'Скорохід';

export const longstrider_details = 'Прискорити істоту на 10 футів.';

export const longstrider_expanded = 'Ви доторкаєтесь до істоти. Поки закляття активне, швидкість істоти збільшується на 10 футів.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за перший.<br/><br/>' + 
'Фокус для створення закляття можна замінити на щіпку землі.';

export const create_or_destroy_water = 'Створення | знищення води';

export const create_or_destroy_water_details = 'Створити або знищити воду в області.';

export const create_or_destroy_water_expanded = 'Ви створюєте або знищуєте воду.<br/><br/>'+
'Створення води. Ви створюєте до 10 галонів (40 літрів) чистої води в межах дистанції у відкритому контейнері. Як альтернатива, вода випадає дощем у кубі з довжиною ребра 30 футів в межах дальності, загасивши відкрите полумʼя.<br/><br/>' + 
'Знищення води. Ви знищуєте до 10 галонів (40 літрів) води у відкритому контейнері в межах дистанції. Як альтернатива, ви знищуєте туман у кубі з довжиною ребра 30 футів в межах дистанції.<br/><br/>' + 
'На більш високих рівнях: Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, ви створюєте або знищуєте 10 додаткових галонів (40 літрів) води, і довжина ребра куба збільшується на 5 футів за кожен рівень слоту вище за перший.<br/><br/>' + 
'Фокус для створення закляття можна замінити на краплю води, якщо вода створюється, або кілька піщин, якщо вода знищується.';

export const tensers_floating_disk = 'Диск Тензера';

export const tensers_floating_disk_details = 'Створити невеликий диск із 🌈 Силового поля, що ширяє за вами і здатний утримувати на собі до 250 кг.';

export const tensers_floating_disk_expanded = 'Це закляття створює круглий горизонтальний диск із Силового поля діаметром 3 фути і товщиною 1 дюйм (2,5 см), що ширяє в 3 футах над підлогою у вільному просторі на ваш вибір, видимому в межах дистанції.<br/><br/>' + 
'Диск існує, поки закляття активне, і може винести у собі 500 фунтів (250 кг). Якщо на нього помістити більшу вагу, закляття закінчиться, і все, що було на диску, падає на підлогу. Диск нерухомий, поки ви знаходитесь в межах 20 футів від нього. Якщо ви переміститеся від нього на відстань, що перевищує 20 футів, диск слідує за вами, залишаючись в межах 20 футів. Він може перелітати по нерівній поверхні, переміщатися сходами, схилами та подібними перешкодами, але не може перетинати перепади по висоті в 10 футів і більше. Наприклад, диск не зможе перелетіти над 10-футовою ямою, і не зможе з неї вибратися, якщо буде створено на її дні.<br/><br/>' + 
'Якщо ви переміститеся більш ніж на 100 футів від диска (наприклад, якщо він через перешкоду не зможе піти за вами), закляття закінчується.<br/><br/>' + 
'Фокус для закляття можна замінити на краплю ртуті.';

export const fog_cloud = 'Туманна хмара';

export const fog_cloud_details = 'Створити сильно заслонену місцевість в області.';

export const fog_cloud_expanded = 'Ви створюєте сферу з радіусом 20 футів із туману з центром на точці в межах дистанції. Сфера оминає кути, і її простір — сильно заслонена місцевість. Вона існує до закінчення дії закляття, або поки її не розвіє вітер (зі швидкістю щонайменше 10 миль на годину).<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, радіус туману збільшується на 20 футів за кожен рівень слоту вище за перший.';

export const sanctuary = 'Прикриття';

export const sanctuary_details = 'Захистити ціль від атак, накладаючи на атакуючих Випробування.';

export const sanctuary_expanded = 'Ви захищаєте одну істоту в межах дистанції від атак. Поки закляття активне, всі істоти, що націлюються на захищену істоту атаками або шкідливими закляттями, повинні спочатку пройти Випробування Мудрості. При провалі істота має вибрати нову ціль або втратить атаку чи закляття.<br/><br/>' + 
'Це закляття не захищає від ефектів, що діють на область, таких як вибух вогняної кулі. Якщо захищена істота здійснює атаку, накладає закляття, що впливає на ворожу істоту або завдає пошкодження іншій істоті, це закляття закінчується.<br/><br/>' + 
'Фокус для створення закляття можна замінити на маленьке срібне дзеркало.';

export const sleep = 'Сон';

export const sleep_details = '🥱 Приспати найкволіших істот в області.';

export const sleep_expanded = 'Це закляття посилає істот у магічний Cон. Киньте 5d8 — цей результат буде кількістю здоровʼя істот, на яких це закляття може вплинути. Істоти в межах 20 футів від точки, обраної вами в межах дистанції, підпадають під дію в порядку збільшення їх поточного здоровʼя (ігноруючи тих, що непритомні). Починаючи з істоти з найменшою кількістю поточного здоровʼя, всі істоти, що потрапляють під дію цього закляття, втрачають свідомість до закінчення дії закляття, поки не отримають пошкодження, або поки хтось інший не розбудить їх, витративши Основну дію на тряску або ляпас. Віднімайте здоровʼя кожної істоти із загальної суми, після чого переходьте до наступної істоти з найменшою кількістю здоровʼя.<br/><br/>' + 
'Для того щоб істота потрапила під дію закляття, потрібно щоб кількість її поточних пунктів здоровʼя не перевищувала суму, що залишилася. невмерлі і істоти, що мають імунітет до Зачарування, не підпадають під дію цього закляття.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 2 рівня або вище, кидайте додатково 2d8 за кожен рівень слоту вище за перший.<br/><br/>' + 
'Фокус для створення закляття можна замінити на щіпку дрібного піску, пелюстки троянди або цвіркуна.';

export const chromatic_orb = 'Хроматична куля';

export const chromatic_orb_details = 'Атака снарядом пошкодження на вибір.';

export const chromatic_orb_expanded = 'Ви кидаєте 4-дюймову (10-сантиметрову) сферу енергії у істоту, яку бачите в межах дистанції. Виберіть Звук, Кислоту, Вогонь, Холод, Струм або Отруту при створенні сфери, а потім здійсніть дальнобійну атаку закляттям по цілі. Якщо атака влучає, істота отримує пошкодження 3d8 вибраного виду.<br/><br/>' + 
'Якщо ви накладаєте це  закляття, використовуючи слот 2 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище за перший.';

// — 55% ----

export const goodberry = 'Диво-ягоди';

export const goodberry_details = 'Невеликі магічні плоди, що відновлюють здоровʼя.';

export const goodberry_expanded = 'У вашій руці зʼявляються десять ягід, наповнених магією. Будь-яка істота може Основною дією зʼїсти одну ягоду. Це відновлює один пункт здоровʼя, і ягода настільки поживна, що насичує істоту на весь день. Ягоди втрачають силу, якщо їх не зʼїсти через 24 години після створення.<br/><br/>' + 
'Фокус для закляття можна замінити на гілку омели.';

export const shield = 'Щит';

export const shield_details = 'Магічний барʼєр, що реактивно захищає вас.';

export const shield_expanded = 'Невидимий барʼєр із магічної сили зʼявляється, захищаючи вас. Ви отримуєте до початку свого наступного ходу +5 Броні, у тому числі й проти атаки, що викликала спрацювання. Поки закляття активне, ви не отримуєте пошкодження від закляття Чарівна стріла.';

export const shield_of_faith = 'Щит віри';

export const shield_of_faith_details = 'Магічний барʼєр, що захищає вибрану істоту.';

export const shield_of_faith_expanded = 'Мерехтливе поле зʼявляється, оточуючи обрану вами істоту в межах дистанції, даруючи йому на час тривалості бонус +2 до Броні.<br/><br/>' + 
'Фокус для закляття можна замінити на невеликий пергамент зі святими письменами.';

export const pass_without_trace = 'Прохід без сліду';

export const pass_without_trace_details = 'Тимчасово покращити Непомітність для себе і всіх навколо.';

export const pass_without_trace_expanded = 'Від вас починає виходити покрив тіней і тиші, що приховує вас та ваших супутників від виявлення. Поки закляття активне, всі істоти, вибрані вами в межах 30 футів (включно з вами) отримують бонус +10 до перевірок Непомітності, і їх не можна вистежити без допомоги магії. Істота, яка отримала цей бонус, не залишає слідів.';

export const spiritual_weapon = 'Духовна зброя';

export const spiritual_weapon_details = 'Створити примарну зброю будь-якої форми, здатну пересуватися зі швидкістю 20ф.';

export const spiritual_weapon_expanded = 'Ви створюєте в межах дистанції ширяючу примарну зброю, що існує, поки закляття активне, або поки ви не накладаєте це закляття ще раз.<br/><br/>' + 
'Коли ви накладаєте це закляття, ви можете зробити ближню атаку закляттям по цілі, що знаходиться в межах 5 футів від зброї. При влучанні ціль отримує пошкодження Силовим полем, що дорівнює 1d8 + модифікатор вашої базової Здібності.<br/><br/>' + 
'Ви можете Бонусною дією у свій хід перемістити зброю на відстань до 20 футів і повторити атаку по цілі, що знаходиться в межах 5 футів від неї.<br/><br/>' + 
'Зброя може бути будь-якої форми. Жреці божеств, повʼязаних із конкретною зброєю (святий Катберт відомий своєю булавою, а Тор — молотом), створюють ефект у вигляді саме такої зброю.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, пошкодження збільшується на 1d8 за кожні два слоту вище за другий.';

export const continual_flame = 'Сталий вогонь';

export const continual_flame_details = 'Створити нескінченне полумїя із предмета.';

export const continual_flame_expanded = 'З предмета, якого ви торкаєтеся, виходить полумʼя, еквівалентне яскравості полумʼя смолоскипу. Ефект виглядає як звичайне полумʼя, але не створює тепла та не використовує кисень. Вічний вогонь можна накрити чи сховати, але не згасити.';

export const see_invisibility = 'Бачення невидимого';

export const see_invisibility_details = 'Побачити невидимих істот та предмети, а також істот із Ефірного плану.';

export const see_invisibility_expanded = 'Поки закляття активне, ви бачите невидимих істот і предмети, наче вони видимі, і ваш зір тягнеться на Ефірний План. Ефірні істоти та предмети виглядають примарними та напівпрозорими.<br/><br/>' + 
'Фокус для створення закляття можна замінити на щіпку тальку та товченого срібла.';

export const suggestion = 'Навіювання';

export const suggestion_details = 'Коротка слушна вказівка істоті, яку вона повинна виконати при провалі Випробування.';

export const suggestion_expanded = 'Ви вселяєте певний курс дій (обмежений однією-двома фразами) істоті, видимій в межах дистанції, здатній чути і розуміти вас. Істоти, які не можуть бути Зачаровані, мають імунітет до цього ефекту. Навіювання має бути сформоване так, щоб дія звучала слушною. Прохання вдарити себе мечем, кинутися на спис, спалити себе, або якось інакше завдати собі пошкодження закінчують закляття.<br/><br/>' + 
'Ціль має пройти Випробування Мудрості. При провалі вона слідує заданому курсу дій. Навічені дії можуть тривати всю тривалість закляття. Якщо виконану діяльність можна виконати за менший термін, закляття закінчується, коли субʼєкт закінчує доручену задачу.<br/><br/>' + 
'Ви також можете вказати умову, яка запустить особливу дію під час дії закляття. Наприклад, ви можете навіяти лицарю, щоб він віддав свого бойового коня першому зустрінутому жебраку. Якщо до закінчення дії закляття умова не буде виконана, вказівка не виконується. Якщо ви або хтось із ваших супутників завдає пошкодження цілі, закляття закінчується.<br/><br/>' + 
'Фокус для створення закляття можна замінити на язик змії або шматочок медових сот, або краплю солодкого масла.';

export const magic_mouth = 'Чарівні вуста';

export const magic_mouth_details = 'Надиктувати повідомлення, встановити у предмет, та позначити умову відтворення межах області від предмета.';

export const magic_mouth_expanded = 'Ви впроваджуєте у предмет в межах дистанції послання, яке буде вимовлено, коли будуть виконані умови.<br/><br/>' + 
'Виберіть видимий предмет, який не тримає та не носить інша істота. Вимовте послання, яке може складатися не більше ніж із 25 слів, хоча його можна вимовляти протягом 10 хвилин. Потім визначте умову, виконання якої змусить закляття передати ваше послання.<br/><br/>' + 
'Коли умова буде виконана, на предметі зʼявляється магічний рот, який зачитує послання вашим голосом з тією самою гучністю. Якщо у вибраного вами предмета є рота або щось схоже на рот (наприклад, рот статуї), магічний рот зʼявляється на його місці, і здається, що слова виходять звідти.<br/><br/>' + 
'При накладанні закляття можна зробити так, що закляття закінчиться, передавши повідомлення, або залишиться активним і повторюватиме повідомлення при кожному спрацюванні умови. Викликаюча спрацьовування умова може бути як загальною, так і деталізованою, але повинна ґрунтуватися на зорових або чутних умовах, що відбуваються в межах 30 футів від предмета.<br/><br/>' + 
'Наприклад, ви можете змусити рота говорити, коли в межах 30 футів від нього пройде істота, або коли в межах 30 футів від нього задзвенить срібний дзвіночок.';

export const arcane_lock = 'Магічний замок';

export const arcane_lock_details = 'Замкнути щось та позначити умову в межах області від предмета, для відкриття на 1 хвилину.';

export const arcane_lock_expanded = 'Ви доторкаєтеся до закритих дверей, вікна, воріт, скрині або іншого входу, і він стає замкненим. Ви та вказані при накладанні цього закляття істоти можете відкривати предмет як завжди. Ви також можете встановити пароль, вимова якого в межах 5 футів від предмета відключає закляття на 1 хвилину. В іншому випадку предмет не відкривається, поки його не зламають, або поки закляття не розсіяють або не пригнічать. Накладання закдяття Відкривання на предмет придушує Магічний замок на 10 хвилин.<br/><br/>' + 
'Предмет, що знаходиться під дією цього закляття, набагато складніше зламати і відкрити силою — Складність виламування та злому замків збільшується на 10.';

export const phantasmal_force = 'Уявна сила';

export const phantasmal_force_details = 'Ілюзія істоти або явища, яка здається цілі, що провалила Випробування, настільки реалістичною, що навіть завдає пошкодження.';

export const phantasmal_force_expanded = 'Ви створюєте ілюзію у свідомості істоти, видимої в межах дистанції. Ціль має пройти Випробування Інтелекту. При провалі ви створюєте уявний предмет, істоту або інше видиме явище на свій вибір, що поміщається в куб з довжиною ребра 10 футів, і сприймається тільки ціллю, поки закляття діє.<br/><br/>' + 
'Ілюзія включає звуки, температуру та інші подразники, очевидні лише для цієї ж істоти. Ціль може дією дослідити ілюзію перевіркою Розслідування проти Складності ваших заклять. Якщо перевірка була успішною, ціль розуміє, що перед нею ілюзія, і закляття закінчується.<br/><br/>' + 
'Поки ціль перебуває під дією цього закляття, вона вважає ілюзію справжньою. Вона сама вигадує пояснення нелогічності взаємодії з ілюзією. Наприклад, спробувавши пройти вигаданим мостом над провалом, вона впаде вниз. Якщо ціль виживе, вона все одно вважатиме, що міст існує, а пройти їй завадило щось інше — її штовхнули, вона послизнулася, або її збив сильний порив вітру.<br/><br/>' + 
'Ціль настільки впевнена в існування ілюзії, що навіть отримуватиме від неї пошкодження. Ілюзія у формі істоти може атакувати ціль. Ілюзія, що виглядає як вогонь, басейн із кислотою чи лава, можуть спалити ціль. Кожен раунд у ваш хід ілюзія може завдати цілі пошкодження Психічною енергією 1d6, якщо та в межах 5 футів від ілюзії. Ціль сприймає пошкодження того виду, що відповідає цілі. Це закляття не має жодного ефекту на невмерлих і конструктів.<br/><br/>' + 
'Фокус для створення закляття можна замінити на шматок вовни.';

export const augury = 'Ворожіння';

export const augury_details = 'Перевірка наслідків свого плану дій на найближчі 30 хв.';

export const augury_expanded = 'Підкидаючи інкрустовані камінням палички, драконові кістки, розкладаючи карти або використовуючи інші інструменти для ворожіння, ви отримуєте знак від потойбічної сутності про результати особливої дії, яку ви плануєте зробити протягом наступних 30 хвилин. Майстер вибирає один із наступних можливих знамень:<br/><br/>' + 
'• Благо, для добрих результатів<br/>'+
'• Горе, для поганих результатів<br/>'+
'• Благо і горе, для результатів, водночас хороших та поганих<br/>'+
'• Ніщо, для результатів, які і не добрі і не погані<br/><br/>'+
'Закляття не бере до уваги обставини, здатні змінити результат, такі як накладання додаткових заклять або втрата або приєднання супутників.<br/><br/>' + 
'Якщо ви накладаєте це закляття кілька разів до завершення тривалого відпочинку, існує накопичувальний шанс 25 відсотків за кожне використання починаючи з другого, що ви отримаєте випадкову відповідь. Майстер робить цей кидок приховано.';

// — 60% ------

export const blindness_deafness = 'Глухота | сліпота';

export const blindness_deafness_details = 'Осліпити або оглушити ціль, що провалила Випробування.';

export const blindness_deafness_expanded = 'Ви можете засліпити ворога або зробити його глухим. Виберіть одну істоту, яку бачите в межах дистанції, яка одразу повинна пройти Випробування Статури. У разі провалу ціль стає на час дії закляття сліпою або глухою (на ваш вибір). У кінці кожного свого ходу ціль може здійснювати Випобування Статури. У разі успіху закляття закінчується.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за другий.';

export const flame_blade = 'Вогняний клинок';

export const flame_blade_details = 'Створити вогняний скімітар, що світиться. При втраті можна повернути в руку Бонусною дією.';

export const flame_blade_expanded = 'Ви створюєте палаючий клинок у вільній руці. Цей меч схожий розмірами і формою на скімітар, і він існує, поки закляття активне. Палаючий клинок випромінює яскраве світло в межах 10 футів і тьмяне світло в межах ще 10 футів. Ви можете Основною дією зробити ближню атаку закляттям. При попаданні ціль отримує пошкодження Вогнем 3d6. Якщо ви випустите меч з рук, він зникає, але ви можете повернути його в руку Бонусною дією.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 4 рівня або вище, пошкодження збільшується на 1d6 за кожні два рівні слоту вище 2.<br/><br/>' + 
'Фокус для створення закляття можна замінити на лист сумаха.';

export const shatter = 'Вщент';

export const shatter_details = 'Вдарити звуковою хвилею по області.';

export const shatter_expanded = 'Гучний дзвінкий звук виходить із вибраної вами точки в межах дистанції. Усі істоти у сфері з радіусом 10 футів із центром на цій точці мають пройти Випробування Статури. При провалі істота отримує пошкодження Звуком 3d8, або половину цього пошкодження при успіху. Істоти, виготовлені з неорганічної матерії, такої як камінь, кристал або метал, проходять це Випробування із перешкодою. Немагічні предмети, які не тримають і не носять, теж отримують пошкодження, якщо знаходяться в області закляття.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище за другий.<br/><br/>' + 
'Фокус для створення закляття можна замінити на шматочок слюди.';

export const barkskin = 'Дубова кора';

export const barkskin_details = 'Броня для згодної істоти.';

export const barkskin_expanded = 'Ви торкаєтеся згодної істоти. Поки закляття активне, шкіра цілі стає грубою і схожою зовні на кору дуба, а Броня не може бути нижчою за 16, незалежно від одягнених обладунків.<br/><br/>' + 
'Фокус для створення закляття можна замінити на жменю дубової кори.';

export const cordon_of_arrows = 'Кордон стріл';

export const cordon_of_arrows_details = 'Захистити вибрану зону зачарованими снарядами.';

export const cordon_of_arrows_expanded = 'Ви встромляєте чотири немагічні боєприпаси — стріли або арбалетні болти — в землю в межах дистанції і накладаєте на них закляття, що захищає область. Поки закляття активне, щоразу, коли інша істота крім вас вперше за хід виявляється в межах 30 футів від боєприпасів або закінчує там хід, один боєприпас вилітає та атакує його.<br/><br/>' + 
'Істота повинна досягти успіху у Випробуванні Спритності, інакше вона отримає Колоте пошкодження 1d6. Боєприпас при цьому знищується. Закляття закінчується, коли закінчуються боєприпаси. Накладаючи це закляття, ви можете вказати будь-яких істот, яких це закляття ігноруватиме.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, кількість боєприпасів збільшується на два за кожен рівень слоту вище за другий.';

export const protection_from_poison = 'Захист від отрути';

export const protection_from_poison_details = 'Нейтралізувати одну отруту і дати <span class="buff">перевагу</span> проти 🤢 Отруєного стану.';

export const protection_from_poison_expanded = 'Ви торкаєтесь істоти. Якщо воно Отруєне, ви нейтралізуєте отруту. Якщо на ціль діють кілька отрут, ви нейтралізуєте ту, про яку знаєте, або ж одну випадковим чином обрану отруту.<br/><br/>' + 
'Поки закляття активне, ціль робить з перевагою Виробування від Отруєного стану і отримує стійкість до пошкодження Отрутою.';

export const branding_smite = 'Тавруюча кара';

export const branding_smite_details = 'При наступному влученні атакою ближньою зброєю, завдати цілі додаткове пошкодження і зробити її видимою.';

export const branding_smite_expanded = 'Коли ви наступного разу влучите по цілі атакою ближньоб зброєю, поки закляття активне, ваша зброя випромінює астральне сяйво. Атака завдає цілі додаткове пошкодження Світлом 2d6. Ціль стає видимою, якщо була невидима, починає тьмяно світитися в межах 5 футів, і не може стати невидимою, поки це закляття активне.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, додаткове пошкодження збільшується на 1d6 за кожен рівень слоту вище за другий.';

export const crown_of_madness = 'Корона безумства';

export const crown_of_madness_details = 'Змусити ціль, що провалила Випробування, атакувати кого скажете. Потрібно витрачати Основну дію кожен хід на підтримку діі закляття.';

export const crown_of_madness_expanded = 'Один гуманоїд на ваш вибір, видимий вами в межах дистанції повинен досягти успіху у Випробуванні Мудрості, інакше він стане Зачарованим вами на час дії закляття. Поки ціль зачарована цим закляттям, на її голові зʼявляється вигнута залізна корона, а в очах світиться безумство. Зачарована ціль повинна в кожний свій хід перед переміщенням здійснювати Основною дією ближню атаку по будь-якій істоті крім себе, яку ви подумки вибираєте.<br/><br/>' + 
'Ціль може діяти у свій хід як завжди, якщо ви не вибрали істоту або в межах її досяжності немає інших істот. У наступні ходи ви повинні Основною дією підтримувати контроль над ціллю, інакше закляття закінчується. Крім того, ціль в кінці кожного свого ходу може здійснювати Випробування Мудрості. У разі успіху закляття закінчується.';

export const levitate = 'Левітація';

export const levitate_details = 'Змусити ціль вагою до 225 кг, що провалила Випробування, відірватися від землі і ширяти зі швидкістю 20ф, або себе із вашою швидкістю пішки.';

export const levitate_expanded = 'Одна вибрана істота або незакріплений предмет, видимий в межах дистанції, піднімається вертикально на відстань до 20 футів, і залишається висіти там на час дії закляття. закляття може змусити левітувати ціль, що важить до 500 фунтів (225 кг). Незгодна істота, яка досягла успіху у Випробуванні Статури, не потрапляє під дію цього закляття.<br/><br/>' + 
'Ціль може переміщатися тільки відштовхуючись від твердих предметів і поверхонь (таких як стіни і стеля), а також підтягуючись за них, що дозволяє переміщатися так, наче вона лазає. У свій хід ви можете змінити висоту цілі на 20 футів у будь-якому напрямку. Якщо ціллю ви є самі, ви можете частиною переміщення рухатися вгору або вниз. В іншому випадку, ви можете перемістити ціль, яка повинна залишатися при цьому в межах досяжності закляття.<br/><br/>' + 
'Коли закляття закінчується, якщо ціль все ще перебуває у повітрі, вона плавно опускається на землю.<br/><br/>' + 
'Фокус для створення закляття можна замінити на маленьку шкіряну петлю або шматочок золотого дроту, зігнутий у формі кубка з довгою ніжкою.';

export const moonbeam = 'Місячний промінь';

export const moonbeam_details = 'Викликати блідий промінь заввишки 40ф, що Основною дією пересувається зі швидкістю 60ф і завдає пошкодження.';

export const moonbeam_expanded = 'Блідий сріблястий промінь сяє в циліндрі з радіусом 5 футів і висотою 40 футів із центром на точці в межах дистанції. Поки закляття активне, циліндр освітлений тьмяним світлом. Коли істота вперше за хід входить в область закляття або починає там хід, вона огортається примарним полумʼям, що спричиняє справжній біль, і має пройти Випробування Статури. Воно отримує пошкодження Світлом 2d10 при провалі або половину цього пошкодження при успіху.<br/><br/>' + 
'У кожний наступний після накладання закляття хід можна Основною дією переміщати промінь на 60 футів у будь-якому напрямку.<br/><br/>' + 
'Перекидні проходять Випробування з перешкодою. При провалі вони миттєво набувають свого правдивого вигляду, і не можуть набувати інших форм, доки не вийдуть зі світла закляття.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, пошкодження збільшується на 1d10 за кожен рівень слоту вище за другий.<br/><br/>' + 
'Фокус для створення закляття можна замінити на кілька насінь місячнонасінника і шматочок опалесцентного польового шпату.';

export const ray_of_enfeeblement = 'Промінь знесилення';

export const ray_of_enfeeblement_details = 'Чорний промінь, що змушує ціль наносити лише половину пошкодження зброєю, що використовує Силу.';

export const ray_of_enfeeblement_expanded = 'Чорний промінь вилітає з вашого пальця до істоти, що знаходиться в межах дистанції. Здійсніть далекобійну атаку закляттям по цілі.<br/><br/>' + 
'При влучанні ціль до кінця дії закляття завдає лише половину пошкодження атаками зброєю, що використовуює Силу. У кінці кожного свого ходу ціль може пройти Випробування Статури від цього закляття. При успіху закляття закінчується.';

export const magic_weapon = 'Магічна зброя';

export const magic_weapon_details = 'Зробити зброю магічною і додати <span class="buff">бонус</span> до влучності та пошкодження.';

export const magic_weapon_expanded = 'Ви торкаєтеся немагічної зброї. Поки закляття активне, ця зброя стає магічною зброєю з бонусом +1 до кидків атаки та пошкодження.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 4 або вище рівня, бонус збільшується до +2. Якщо ви використовуєте слот мани 6 або вище рівня, бонус збільшується до +3.';

// — 65% --------

export const lesser_restoration = 'Мале відновлення';

export const lesser_restoration_details = 'Прибрати Глухоту, Отруєння, Параліч, Сліпоту або хворобу вибраній істоти.';

export const lesser_restoration_expanded = 'Ви доторкаєтеся до істоти і можете закінчити одну хворобу або стан, в якому воно знаходиться. Цим станом може бути Глухота, Отруєння, Параліч чи Сліпота.';

export const melfs_acid_arrow = 'Мельфова кислотна стріла';

export const melfs_acid_arrow_details = 'Постріл стрілою ⚗️ Кислоти, що наступного ходу завдає додаткове пошкодження на 2 куби менше від основної кількості.';

export const melfs_acid_arrow_expanded = 'До цілі, що знаходиться в межах дистанції, спрямовується мерехтлива зелена стріла, що розлітається бризками Кислоти. Здійсніть далекобійну атаку закляттям по цілі. При влучанні ціль отримує одноразово пошкодження Кислотою 4d4 і пошкодження кислотою 2d4 в кінці свого наступного ходу. При промаху стріла трохи зачіпає ціль, завдає половину одноразового пошкодження і наприкінці наступного ходу ціль пошкодження не отримує.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, пошкодження (і одноразова і наступна) збільшується на 1d4 за кожен рівень слоту вище за другий.<br/><br/>' + 
'Фокус для створення закляття можна замінити на порошок з листя ревеню та шлунок гадюки.';

export const prayer_of_healing = 'Молитва зцілення';

export const prayer_of_healing_details = 'Ритуал массового зцілення.';

export const prayer_of_healing_expanded = 'Кожна із шести істот на ваш вибір, видимих в межах дистанції, відновлює кількість здоровʼя, що дорівнює 2d8 + модифікатор вашої базової Здібності. Це закляття не має жодного ефекту на невмерлих і конструктів.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, лікування збільшується на 1d8 за кожен рівень слоту вище за другий.';

export const invisibility = 'Невидимість';

export const invisibility_details = 'Зробити істоту невидимою. Невидимість припиняється, якщо ціль атакує чи створює закляття.';

export const invisibility_expanded = 'Істота, яку ви торкаєтеся, стає невидимою до закінчення дії закляття. Все, що ціль тримає або носить, стає невидимим, поки залишається в неї. Закляття закінчується, якщо ціль здійснює атаку або накладає закляття.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за другий.<br/><br/>' + 
'Фокус для створення закляття можна замінити на вію у смолі.';

export const gentle_repose = 'Збереження останків';

export const gentle_repose_details = 'Зупинити розкладання трупа.';

export const gentle_repose_expanded = 'Ви доторкаєтеся до трупа чи інших останків. Поки закляття активне, ціль захищена від розкладання і не може стати невмерлим.<br/><br/>' + 
'Це закляття також збільшує обмеження часу, протягом якого труп можна воскресити, оскільки дні, проведені під впливом цього закляття, не враховуються при підрахунку часу від дня смерті для таких заклять як пожвавлення.<br/><br/>' + 
'Фокус для создания заклинания можно заменить на щепотку соли и по одной медной монетке на каждьій глаз трупа, которьіе должньі оставаться там все время, пока активне заклинание.';

export const nystuls_magic_aura = 'Ністулова аура';

export const nystuls_magic_aura_details = 'Підмінити інформацію про предмет для заклять Віщування.';

export const nystuls_magic_aura_expanded = 'Ви накладаєте ілюзію на істоту або предмет, якого торкаєтеся, щоб закляття школи Віщування отримували неправдиву інформацію про ціль. Ціллю може бути згодна істота або предмет, який не носить і не тримає інша істота. При накладанні цього закляття виберіть один із наведених нижче ефектів або відразу обидва:<br/><br/>' + 
'Хибна аура. Ви змінюєте те, як ціль представляється для заклять і магічних ефектів, таких як виявлення магії, що сканують магічну ауру. Ви можете немагічний предмет змусити виглядати магічним, магічний предмет змусити виглядати немагічним, або змінити магічну ауру предмета, щоб здавалося, що магія, що знаходиться в ньому, належить іншій школі магії, обраній вами. Якщо ви використовували цей ефект на предметі, можете зробити так, що нова магія буде відразу видно істоті, що тримає його в руках.<br/><br/>' + 
'Маскування. Ви змінюєте те, як ціль представляється для заклять і магічних ефектів, що визначають вид істот, таких як Божественне відчуття паладина і тригер закляття Знак. Ви вибираєте вигляд істоти, і всі закляття та магічні ефекти вважатимуть, що ціль належить цьому виду або має цей світогляд.<br/><br/>' + 
'Ефект триває, поки закляття активне. Якщо ви накладаєте це закляття на одну і ту ж істоту або предмет щодня протягом 30 днів, використовуючи той самий ефект, ілюзія починає тривати, доки не буде розсіяна.<br/><br/>' + 
'Фокус для створення закляття можна замінити на невеликий клапоть шовку.';

export const cloud_of_daggers = 'Хмара кинджалів';

export const cloud_of_daggers_details = 'Створити невелику область з кинджалами, що крутяться та завдають пошкодження всім хто в неї увійде.';

export const cloud_of_daggers_expanded = 'Ви заповнюєте повітря кинджалами, що крутяться, в кубі з довжиною ребра 5 футів, з центром на точці, обраній вами в межах дистанції. Істота отримує пошкодження 4d4, коли вперше за хід входить в область закляття або починає там хід.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, пошкодження збільшується на 2d4 за кожен рівень слоту вище за другий.<br/><br/>' + 
'Фокус для створення закляття можна замінити на уламок скла.';

export const zone_of_truth = 'Зона правди';

export const zone_of_truth_details = 'Створити невелику область, де всі, хто провалив Випробування, не можуть брехати.';

export const zone_of_truth_expanded = 'Ви створюєте магічну зону, що захищає від обману у сфері з радіусом 15 футів із центром на точці, обраній вами в межах дистанції. Поки закляття активне, істота, яка вперше за хід входить в область цього закляття або починає в ній хід, повинна зробити Випробування Харизми. При провалі істота не може навмисне брехати, перебуваючи у вказаному радіусі. Ви знаєте, які істоти пройшли Випробування, а які провалили.<br/><br/>' + 
'Істота, що потрапила під дію закляття, знає про закляття, і тому може уникати відповідати на питання, на які вона воліла б збрехати. Такі істоти можуть давати ухильну відповідь, поки змушені говорити правду.';

export const detect_thoughts = 'Читання думок';

export const detect_thoughts_details = 'Проникнути у думки розумної істоти. Поки закляття активне, можна пірнути глибше, якщо істота провалить Випробування, або змінити істоту.';

export const detect_thoughts_expanded = 'Поки закляття активне, ви можете читати думки деяких істот. Якщо ви створили це закляття, і в свій хід здійснюєте дію, ви можете зосередитися на одній істоті, яку бачите в межах 30 футів. Якщо у вибраної істоти Інтелект дорівнює 3 або нижче, або якщо вона не може говорити на жодній мові, вона не підпадає під дію закляття.<br/><br/>' +
'Спочатку ви знаєте поверхневі думки істоти — те, що знаходиться в його свідомості на поточний момент. Ви можете дією або перенести увагу на думки іншої істоти, або спробувати заглибитися в думки поточної істоти.<br/><br/>' +  
'Якщо ви занурюєтеся глибше, ціль повинна пройти Випробування Мудрості. У разі провалу ви отримуєте розуміння її думок (якщо є), її емоційного стану, і того, що її найбільше турбує (а також те, що вона любить і ненавидить). Якщо істота досягне успіху, закляття закінчується. У будь-якому випадку, ціль знає, що ви промацували її свідомість, і якщо ви не перевели увагу на думки іншої істоти, попередня істота може у свій хід Основною дією здійснити перевірку Інтелекту, протиставлену вашій перевірці Інтелекту; у разі успіху закляття закінчується.<br/><br/>' +  
'Питання, що задаються усно цілі, впливають на перебіг її думок, тому це закляття надзвичайно ефективно під час допитів.<br/><br/>' +  
'Ви можете також використовувати це закляття для виявлення присутності істот, яких ви не бачите. Коли ви накладаєте це закляття, або пізніше, поки воно активне, витративши дію, ви можете пошукати думки істот в межах 30 футів.<br/><br/>' + 
'Закляття перетинає більшу частину барʼєрів, але блокується 2 футами каменю, 2 дюймами звичайного металу або тонким листом свинцю. Ви не можете виявити істот з Інтелектом 3 і нижче, а також тих, хто не говорять на жодному мові. Виявивши таким методом присутність істоти, ви можете до закінчення дії закляття читати її думки, як описано вище, навіть якщо її не бачите, але вона повинна знаходитися в межах дистанції.<br/><br/>' +  
'Фокус для створення закляття можна замінити на мідну монету.';

export const knock = 'Тук-тук';

export const knock_details = 'Відімкнути один будь-який замок. Викликає гучний звук, який чути у великій області.';

export const knock_expanded = 'Виберіть предмет, який можна побачити в межах дистанції. Це можуть бути двері, ящик, скриня, кайдани, навісний замок, або інший предмет, що містить звичайний або магічний засіб, що запобігає доступу. Ціль, замкнена звичайним замком, заклинена або замкнена на засув, стає незачиненою, незаклиненою, а засуви самі відкриваються. Якщо на предметі було кілька замків, відкривається лише один із них.<br/><br/>' +
'Якщо ви вибрали ціль, замкнену закляттям Магічний замок, воно пригнічується на 10 хвилин, під час яких ціль може відкриватися і закриватися як зазвичай.<br/><br/>' +   
'Коли ви накладаєте це закляття, від цілі звучить гучний стукіт, чутний з відстані 300 футів.';

export const mirror_image = 'Двійники';

export const mirror_image_details = 'Створити три ваші ілюзорні копії.';

export const mirror_image_expanded = 'Біля вас зʼявляються три ваші ілюзорні копії. Поки закляття активне, копії переміщаються разом з вами і імітують ваші дії, рухаючись так, що неможливо зрозуміти, хто з вас справжній. Броня копії дорівнює 10+ ваш модифікатор Спритності.<br/><br/>' +   
'Щоразу, коли істота націлюється на вас атакою, поки закляття активне, кидайте d20, щоб визначити, чи атака не влучила замість вас по одній з ваших копій. Якщо у вас є три копії, ви повинні викинути «6» або більше, щоб зробити ціллю копію. Якщо дві копії, викинути потрібно «8» або більше. Якщо копія одна, ви повинні викинути 11 або більше.<br/><br/>' +
'Якщо атака влучила у копію, вона знищується. Копію може знищити лише атака, що влучила в неї. Вона ігнорує решту пошкоджень та ефектів. Закляття закінчується, якщо всі три копії будуть знищені. Також ви можете Основною дією розпустити свої копії.<br/><br/>' +   
'Істота не підпадає під дію цього закляття, якщо не може бачити, якщо покладається на інші відчуття крім зору, наприклад, сліпий зір, або якщо може бачити крізь ілюзію, наприклад, за допомогою істинного зору.';

export const warding_bond = 'Охоронний звʼязок';

export const warding_bond_details = 'Дати цілі <span class="buff">бонус</span> до Броні та Випробувань та <span class="buff">Стійкість</span> до усіх видів пошкодження. Отримувати таке ж пошкодження, як і ціль.';

export const warding_bond_expanded = 'Це закляття захищає згодну істоту, до якої ви доторкаєтеся, і створює містичний звʼязок між вами. Поки цілт знаходиться в межах 60 футів від вас, вона отримує бонус +1 до Броні та Випробувань, а також Стійеість до усіх видів пошкодження.<br/><br/>' +
'Крім цього, кожен раз, коли вона отримує пошкодження, ви отримуєте таку ж кількість пошкодження. Це закляття закінчується, якщо ваше здоровʼя опускаються до 0 або якщо відстань між вами та ціллю стає більше 60 футів.<br/><br/>' +   
'Воно також закінчується, якщо це закляття накласти повторно одне із звʼязаних істот. Ви також можете закінчити це закляття Основною дією.';

// — 70% ----------

export const scorching_ray = 'Палючі промені';

export const scorching_ray_details = 'Випустити вогняні промені по цілям.';

export const scorching_ray_expanded = 'Ви створюєте три вогняні промені і спрямовуєте їх у цілі, що знаходяться в межах дистанції. Це може бути одна чи декілька цілей. Для кожного променя здійсніть далекобійну атаку закляттям. При влучанні ціль отримує пошкодження Вогнем 2d6.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, ви створюєте один додатковий промінь за кожен рівень слоту вище за другий.';

export const spider_climb = 'Павуче лазіння';

export const spider_climb_details = 'Можливість для цілі ходити по стінах та стелі, залишаючи руки вільними.';

export const spider_climb_expanded = 'Поки закляття активне, одна згодна істота, до якої ви доторкаєтеся, отримує можливість переміщатися вгору, вниз і вздовж вертикальних поверхонь, а також по стелі, залишаючи вільними руки. Ціль також отримує швидкість лазіння, рівну своїй швидкості пішки.<br/><br/>' +  
'Фокус для створення закляття можна замінити на краплю бітуму та павука.';

export const web = 'Павутина';

export const web_details = 'Покрити область, що 🪢 Обплутує область і всіх істот в ній, що провалили Випробування. Павутина легко спалахує.';

export const web_expanded = 'Ви створюєте масу густої та клейкої павутини в точці на ваш вибір у межах дистанції. Павутина заповнює куб із довжиною ребра 20 футів на час тривалості закляття. Павутина є важкопрохідною місцевістю і слабо затуляє місцевість.<br/><br/>' +  
'Якщо павутина не прикута до двох міцних структур (таких як стіни або дерева) і не розкладена по підлозі, стіні або стелі, створена павутина опадає сама, і закляття закінчується на початку наступного ходу. Павутина, розкладена по плоскій поверхні, має товщину 5 футів.<br/><br/>' +  
'Усі істоти, що починають хід у павутинні, або входять до неї у свій хід, повинні пройти Випробування Спритні. При провалі істота стає Обплутаною, поки залишається в павутинні, або поки не вирветься.<br/><br/>' +  
'Істота, Обплутана павутинням, може Основною дією здійснити перевірку Сили проти Складності ваших заклять. У разі успіху воно перестає бути обплутаним.<br/><br/>' +  
'Павутина вогненебезпечна. Будь-який 5-футовий куб павутини, що зіткнувся з вогнем, згоряє за 1 раунд, завдаючи пошкодження Вогнем 2d4 всім істотам, що починають хід у вогні.<br/><br/>' +  
'Фокус для створення закляття можна замінити на шматочок павутини.';

export const aid = 'Допомога';

export const aid_details = 'Підлікувати та збільшити максимальне здоровʼя трьом істотам.';

export const aid_expanded = 'Ваше закляття наділяє союзників витривалістю та рішучістю. Виберіть три істоти в межах дистанції. Поки закляття активне, максимум здоровʼя і поточне здоровʼя всіх цілей збільшуються на 5.<br/><br/>' +  
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, здоровʼя цілей збільшується ще на 5 за кожен рівень слоту вище за другий.<br/><br/>' +  
'Фокус для закляття можна замінити на смужку білої тканини.';

export const locate_animals_or_plants = 'Пошук тварини чи рослини';

export const locate_animals_or_plants_details = 'Знайти певний вид рослини чи тварини в області.';

export const locate_animals_or_plants_expanded = 'Назвіть певний вид звіра чи рослини. Зосередившись на голосі природи в оточенні, ви дізнаєтеся напрям і відстань до найближчої істоти або рослини цього виду в межах 5 миль, якщо вони взагалі є.<br/><br/>' +  
'Фокус для створення закляття можна замінити на шматочок хутра шукайки.';

export const find_traps = 'Пошук пасток';

export const find_traps_details = 'Знайти пастки будь-якого характеру в області навколо себе.';

export const find_traps_expanded = 'Ви відчуваєте присутність будь-яких пасток у межах дистанції, що знаходяться в межах лінії огляду. Для цього закляття пасткою вважається все, що завдає несподіваного ефекту, який вважається для вас шкідливим або небажаним, і спеціально підготовлений таким своїм творцем.<br/><br/>' +  
'Таким чином, це закляття відчуває область, захищену закляттям Сигналізація, охоронною руною, або механічною пасткою, але не розпізнає стару підлогу, кволу стелю, або промоїну в землі. Це закляття просто дає знати, що пастка є. Ви не дізнаєтеся про місцезнаходження всіх пасток, зате знаєте загальний характер небезпеки, що походить від відчутої вами пастки.';

export const locate_object = 'Пошук предмета';

export const locate_object_details = 'Знайти відомий предмет, чи предмет певного виду.';

export const locate_object_expanded = 'Опишіть чи назвіть відомий вам предмет. Ви відчуваєте напрям до цього предмета, поки він знаходиться в межах 1000 футів від вас. Якщо предмет переміщається, ви дізнаєтесь його напрямок. Це закляття може шукати конкретний відомий вам предмет, якщо ви хоч раз бачили його поблизу (близько 30 футів).<br/><br/>' +  
'Як альтернатива, це закляття може шукати найближчий предмет певного виду, наприклад, особливий предмет одягу, ювелірна прикраса, меблі, інструмент чи зброя. Це закляття не може виявити предмет, якщо прямий шлях між вами перерізаний свинцем, навіть найтоншим листом.<br/><br/>' +  
'Фокус для створення закляття можна замінити на роздвоєну гілку.';

export const find_steed = 'Виклик скакуна';

export const find_steed_details = 'Фея/небожитель/погань помічник у вигляді обраної їздової тварини.<br/>'+
'• Неймовірно сильний, розумний та вірний<br/>' +
'• Довгостроковий міцний звʼязок між вами<br/>'+
'• Розуміє одну з ваших мов на вибір<br/>'+
'• Закляття "на себе" застосвуються і на скакуна<br/>'+
'• Можна сховати в кишеньковому вимірі<br/>'+
'• Телепатичне спілкування до 1 милі';

export const find_steed_expanded = 'Ви викликаєте дух, що набуває вигляду неймовірно розумного, сильного і вірного скакуна, створюючи довгостроковий звʼязок з ним. Зʼявляючись у вільному просторі в межах дистанції, скакун приймає обраний вами вигляд: бойовий кінь, поні, верблюд, лось або мастіфф. Майстер може дозволити інший вигляд. Скакун набуває характеристик обраної форми, хоча насправді він є небожителем, феєю або поганню (на ваш вибір). Крім того, якщо у вашого скакуна Інтелект 5 або менше, його Інтелект стає 6, і він отримує здатність розуміти одну мову на ваш вибір, якою ви розмовляєте.<br/><br/>' +  
'Ваш скакун служить вам як у бою, так і поза ним, і у вас з ним є інстинктивний звʼязок, що дозволяє битися як єдине ціле. Перебуваючи верхи на скакуні, ви можете робити так, що накладені вами закляття, що націлюються тільки на вас, будуть націлені ще й на скакуна. Коли здоровʼя скакуна спускаються до 0, він зникає, не залишаючи фізичного тіла. Ви можете також будь-коли відпустити скакуна дією, змушуючи його зникнути. У будь-якому випадку повторне використання цього закляття закликає цього ж скакуна, відновлюючи здоровʼя до максимуму. Поки скакун знаходиться в межах 1 милі від вас, ви можете спілкуватися один з одним телепатично. У вас не може бути більше одного скакуна, покликаного цим закляттям одночасно. Ви можете дією назавжди відпустити скакуна, звільнивши його від уз і змушуючи зникнути.';

export const gust_of_wind = 'Порив вітру';

export const gust_of_wind_details = 'Випустити порив сильного вітру, який відштовхує всіх в області та зменшує швидкість проти нього удвічі. Бонусную дією можна змінити напрям вітру.';

export const gust_of_wind_expanded = 'Порив сильного вітру довжиною 60 футів і шириною 10 футів виходить від вас у вибраному напрямку, поки закляття активне. Всі істоти, які починають хід у цій лінії, повинні досягти успіху у Випробуванні Сили, інакше їх відштовхне на 15 футів від вас у напрямку руху вітру.<br/><br/>' +  
'Усі істоти в лінії повинні витрачати 2 фути переміщення за кожний 1 фут, на який вони переміщуються у ваш бік.<br/><br/>' +  
'Вітер розсіює гази та випари, а також гасить свічки, смолоскипи та інші незахищені джерела вогню. Захищений вогонь, наприклад, у ліхтарях, вітер змушує тремтіти, і існує 50% шанс, що згаснуть і вони. Поки закляття активне, ви можете у кожному своєму ході Бонусною дією змінювати напрям, у якому дме вітер.<br/><br/>' +  
'Фокус для створення закляття можна замінити на шматочок їжі.';

export const animal_messenger = 'Поштова тварина';

export const animal_messenger_details = 'Надіслати невелике повідомлення конкретному одержувачу за допомогою будь-якої Крихітної тварини. Тварині потрібно вказати місце та описати ознаки одержувача. Швидкість тварини — 25 миль на день по землі або воді, 50 миль на день при польоті.';

export const animal_messenger_expanded = 'За допомогою цього закляття ви передаєте через повідомлення тварини. Виберіть Крихітного звіра, якого бачите в межах дистанції, такого як білка, сойка або кажан. Ви вказуєте місце, яке колись вже відвідували, та одержувача, описуючи його ознаки, такі як «чоловік чи жінка в уніформі міської варти» або «рудий дворф в гострокінцевому капелюсі». Ви також вимовляєте повідомлення довжиною не більше 25 слів.<br/><br/>' +  
'Звір протягом тривалості закляття рухається у вказане місце, покриваючи приблизно 50 миль за 24 години при польоті або 25 миль за інших видів переміщення. Прибувши на місце, звір передає ваше повідомлення описаній вами істоті, зображуючи звук вашого голосу. Звір передасть послання тільки істоті, що відповідає даному вами опису. Якщо звір не досягне місця до закінчення закляття, повідомлення буде втрачено, а звір почне повертатися до того місця, де ви наклали на нього закляття.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, його тривалість збільшується на 48 годин за кожен рівень слоту вище за другий.<br/><br/>' +  
'Фокус для створення закляття можна замінити на шматочок їжі.';

// — 75% --------

export const flaming_sphere = 'Вогняна сфера';

export const flaming_sphere_details = 'Створити велику вогненну кулю, що завдає пошкодження і здатна пересуватися.';

export const flaming_sphere_expanded = 'У вільному просторі на ваш вибір в межах дистанції зʼявляється куля з вогню діаметром 5 футів, існуюча, поки активне закляття. Всі істоти, що закінчують хід у межах 5 футів від кулі, повинні пройти Випробування Спритності. Істоти отримують пошкодження Вогнем 2d6 при провалі або половину цього пошкодження при успіху.<br/><br/>' +  
'Ви можете Бонусною дією перемістити кулю на 30 футів. Якщо ви тараните кулею істоту, ця істота повинна пройти Випробування від пошкодження кулі, і куля зупиняється до кінця цього ходу. Коли ви переміщаєте кулю, ви можете піднімати її над перешкодами до 5 футів заввишки і перестрибувати нею розломи до 10 футів завдовжки. Куля підпалює горючі предмети, які ніхто не тримає і не носить, і випромінює яскраве світло в межах 20 футів і тьмяне світло в межах ще 20 футів.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи ckjn 3 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище за другий.<br/><br/>' +  
'Фокус для створення закляття можна замінити на шматок сала, щіпку сірки та товченого заліза.';

export const blur = 'Розмиття';

export const blur_details = '<span class="buff">Перешкода</span> на влучання атак по вас.';

export const blur_expanded = 'Ваше тіло стає розмитим і хитається для всіх, хто бачить вас. Поки закляття активне, всі істоти роблять по вам кидки атаки з перешкодою. Атакуючий отримує імунітет до цього ефекту, якщо покладається не на зір, а, наприклад, сліпий зір, або може бачити крізь ілюзії, наприклад, за допомогою істинного зору.';

export const heat_metal = 'Нагрів металу';

export const heat_metal_details = 'Розжарити металевий обʼєкт на дистанції і завдати пошкодження контактуючим з ним. Можна підтримувати Бонусною дією.';

export const heat_metal_expanded = 'Виберіть рукотворний металевий предмет, такий як металева зброя або комплект важкого або середнього обладунку, видимий у межах дистанції. Ви робите його розпеченим до червоного кольору. Всі істоти, що знаходяться у фізичному контакті з цим предметом, отримують пошкодження Вогнем 2d8, коли ви накладаєте це закляття. Поки закляття активне, ви можете Бонусною дією в кожному наступному ході знову завдавати це пошкодження.<br/><br/>' + 
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище за другий.<br/><br/>' +  
'Фокус для створення закляття можна замінити на шматок заліза та полумʼя.';

export const enthrall = 'Відволічення';

export const enthrall_details = 'Відмовляюча промова, що викликає <span class="buff">перешкоду</span> на Уважність для виявлення інших істот.';

export const enthrall_expanded = 'Ви вимовляєте відволікаючу промову, примушуючи обраних вами істот, яких ви бачите в межах дистанції, і які при цьому можуть чути вас, пройти Випробування Мудрості. При провалі ціль отримує перешкоду до перевірок Уважності, виконаним для виявлення інших істот крім вас, поки закляття активне або поки ціль не перестане вас чути.<br/><br/>' + 
'Істоти, які не можуть бути Зачарованими, автоматично досягають успіху у Випробуванні, і якщо ви або ваші супутники бʼєтеся з істотою, вона проходить Випробування з перевагою. Закляття закінчується, якщо ви стаєте недієздатними або втрачаєте можливість говорити.';

export const alter_self = 'Самозміна';
export const alter_self_druid = 'Самозміна [0]';
export const alter_self_warlock = 'Самозміна [0]';

export const alter_self_details = 'Зміни у вашому тілі, які можна перемикати Основною дією.<br/>'+
'• Пристосуватися до води, виростивши зябра та перетинки<br/>' +
'• Виростити пазурі/кліки або ін. Пошкодження 1d6+1 і Влучність +1<br/>'+
'• Змінити зовнішність за будь-якими параметрами';

export const alter_self_expanded = 'Ви набуваєте іншого вигляду. При накладанні виберіть один із наведених нижче варіантів, ефект від якого триватиме всю тривалість закляття. Поки закляття активне, ви можете Основною дією закінчити один ефект, щоб отримати переваги іншого.<br/><br/>' + 
'Адаптація до води. Ви пристосовуєте своє тіло до існування у воді, відрощуючи зябра та перетинки між пальцями. Ви можете дихати під водою і отримуєте швидкість плавання, що дорівнює швидкості ходіння.<br/><br/>' + 
'Природна зброя. Ви відрощуєте пазурі, ікла, шипи, роги або іншу природну зброю на свій вибір. Ваш беззбройний удар завдає Колотого, Дробильного або Рубаного пошкодження 1d6, залежно від обраної вами зброї, і ви володієте беззбройними ударами. Ця зброя буде магічною, і ви отримуєте бонус +1 до кидків атаки та пошкодження нею.<br/><br/>' + 
'Зміна зовнішності. Ви змінюєте свою зовнішність. Ви самі вирішуєте, на кого бути схожим, включаючи зріст, вагу, обличчя, звук голосу, довжину волосся, кольори та особливі Здібності. Ви можете стати схожим на представника іншої раси, але ваші показники не змінюються. Ви також не можете виглядати як істота іншої категорії розміру, і ваше тіло залишається приблизно тим самим; наприклад, це закляття не зробить вас чотириногим. Поки закляття активне, ви можете Основною дією змінювати свою зовнішність.';

export const darkvision = 'Темний зір';

export const darkvision_details = 'Дати істоті можливість бачити у темряві на 60ф.';

export const darkvision_expanded = 'Ви торкаєтеся згодної істоти і даруєте їй можливість бачити у темряві. Поки закляття активне, ця істота має темний зір у межах 60 футів.<br/><br/>' +  
'Фокус для створення закляття можна замінити на щіпку сушеної моркви або агат.';

export const silence = 'Тиша';

export const silence_details = 'Створити повністю беззвучну область.';

export const silence_expanded = 'Поки закляття активне, ніякі звуки не можуть лунати в межах сфери з радіусом 20 футів із центром на точці, вибраній в межах дистанції, а також не можуть проходити крізь неї. Всі істоти та предмети, що повністю знаходяться у сфері, отримують імунітет до пошкодження звуком, і істоти вважаються Оглухлими, коли повністю знаходяться в ній. Там неможливо накладати закляття з вербальним компонентом.';

export const rope_trick = 'Трюк із мотузкою';

export const rope_trick_details = 'Створити невидимий підвішений вхід до ізольованого міжпростору на 8 Середніх істот, до якого можна потрапити по мотузці. Мотузку можна затягнути усередину.';

export const rope_trick_expanded = 'Ви доторкаєтеся до мотузки завдовжки до 60 футів. Один її кінець піднімається в повітря, а решта висить перпендикулярно до підлоги. На верхньому кінці мотузки зʼявляється невидимий вхід у міжпростір, що існує, доки закляття активне. У міжпростір можна потрапити по мотузці нагору. У цьому міжпросторі може поміститися вісім істот з розміром не більшим за Середній. Мотузку можна затягнути всередину, після чого зовні її не видно.<br/><br/>' +  
'Атаки та закляття не можуть проходити через вхід усередину міжпростору та назовні, але ті, хто знаходяться всередині, можуть все бачити як через вікно 3×5 футів із центром на мотузці. Все, що знаходиться у міжпросторі, вивалюється назовні, коли закляття закінчується.';

export const misty_step = 'Туманний крок';

export const misty_step_details = 'Телепортуватись на точку в межах дистанції.';

export const misty_step_expanded = 'Огорнувшись сріблястим туманом, ви телепортуєтесь на 30 футів у вільний простір, видимий вами.';

export const enlarge = 
"Збільшення | зменшення";

export const enlarge_details = 
'• Розмір цілі подвоюється, вага збільшується у вісім разів. Перевірки та Випробування Сили з <span class="buff">перевагою</span>. Додаткове пошкодження збоєю.<br/>' +
'• Розмір цілі зменшується, вага зменшується у вісім разів. Перевірки та Випробування Сили з <span class="debuff">перешкодою</span>. Зменшення пошкодження зброєю.';

export const enlarge_expanded = 
'Ви збільшуєте або зменшуєте істоту або предмет, який ви побачили в межах дистанції, на час дії закляття. Виберіть істоту або предмет, який ніхто не несе і не носить. Якщо ціль хоче, вона може пройти Випробування Статури. У разі успіху закляття не впливає на неї. Якщо ціль — істота, все, що вона носить і тримає, змінює розмір разом із нею. Все, що ця істота покине, відразу набуває свого природного розміру.<br/><br/>' +
'Збільшення. Розміри цілі подвоюються по всіх вимірах, а вага збільшується у вісім разів. Це збільшує розмір на одну категорію – від Середнього до Великого, наприклад. Якщо для цілі не вистачає простору, вона набуває максимально можливого розміру. Поки закляття активне, ціль здійснює з перевагою перевірки та Випробування Сили. Зброя цілі також збільшується. Атаки збільшеною зброєю завдають додаткове пошкодження 1d4.<br/><br/>' +
'Зменшення. Розміри цілі зменшуються вдвічі за всіма вимірами, а вага зменшується до однієї восьмої від звичайного. Це зменшує розмір однією категорію — від Середнього до Маленького, наприклад. Поки закляття активне, ціль здійснює з перешкодою перевірки та Випробування Сили. Зброя цілі також зменшується. Атаки зменшеною зброєю завдають на 1d4 менше пошкодження (не може бути меншим за 1).<br/><br/>' +  
'Фокус для створення закляття можна замінити на щіпку товченого заліза.';

export const hold_person = "Утримання особи";

export const hold_person_details = 'Паралізувати ціль, що провалила Випробування.';

export const hold_person_expanded = 
'Виберіть гуманоїда, якого бачите у межах дистанції. Ціль повинна досягти успіху у Випробуванні Мудрості, інакше стане паралізованою на період дії закляття. У кінці кожного свого ходу ціль може проходити нові Випробування Мудрості. У разі успіху закляття на цій цілі закінчується.<br/><br/>' +
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище 2. Ці гуманоїди повинні знаходитися в межах 30 футів один від одного, коли ви націлюєтеся на них.<br/><br/>' +  
'Фокус для створення закляття можна замінити на невеликий прямий шматочок заліза.';

export const enhance_ability = "Покращення Здібності";

export const enhance_ability_details = '<span class="buff">Перевага</span> на перевірки одного з атрибутів. А також:<br/>' +
'• При Силі — подвоєння вантажності.<br/>' +
'• При Спритності — імунітет на пошкодження від падіння з невеликої висоти.<br/>' +
'• При Статурі — 2d6 тимчасового здоровʼя на час дії закляття.';

export const enhance_ability_expanded = 
'Ви торкаєтеся істоти та покращуєте її за допомогою магії. Виберіть один із наведених нижче ефектів; ціль отримує цей ефект, поки закляття активне:<br/><br/>' +
'• Сила бика. Ціль здійснює з перевагою перевірки Сили, а вантажність подвоюється.<br/>' +  
'• Спритність кота. Ціль здійснює з перевагою перевірки Спритності. Крім того, вона не отримує пошкодження за падіння з висоти 20 футів або менше, якщо вона дієздатна.<br/>' +  
'• Витривалість ведмедя. Ціль робить з перевагою перевірки Статури. Вона також отримує 2d6 тимчасового здоровʼя, які зникають у кінці закляття.<br/>' +  
'• Хитрість лиси. Ціль здійснює з перевагою перевірки Інтелекту.<br/>' +  
'• Мудрість сови. Ціль здійснює з перевагою перевірки Мудрості.<br/><br/>' +
'• Харизма орла. Ціль здійснює з перевагою перевірки Харизми.<br/>' +  
'Якщо ви накладаєте це закляття, використовуючи слот 3 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище за другий.<br/><br/>' +  
'Фокус для закляття можна замінити на хутро або перо звіра.';

// — 80% -------

export const calm_emotions = "Заспокоєння";

export const calm_emotions_details = 'Заспокоїти всіх істот в області, або зробити байдужими.';

export const calm_emotions_expanded = 
'Ви намагаєтеся вгамувати сильні емоції у групи істот. Усі гуманоїди у сфері з радіусом 20 футів із центром у точці, обраній у межах дистанції, повинні пройти Випробування Харизми; істота може добровільно провалити це Виробування, якщо забажає. Якщо істота провалює Випробування, виберіть один із двох наведених нижче ефектів:<br/><br/>' +
'Ви можете приборкати всі ефекти, які роблять ціль Зачарованою чи Переляканою. Коли це закляття закінчується, всі пригнічені ефекти відновлюються за умови, що за цей час їхня дія не закінчилася.<br/><br/>' +  
'Як альтернатива, ви можете зробити ціль байдужою до обраних вами істот, до яких вона ставилася вороже. Ця байдужість закінчується, якщо ціль атакована або їй завдано пошкодження закляттям, або якщо вона стане свідком того, як завдають пошкодження її друзям.<br/><br/>' +  
'Коли закляття закінчується, істота стає знову ворожою, якщо Майстер не вирішить інакше.';

export const spike_growth = "Шипи";

export const spike_growth_details = 'Покрити область підлоги ледь помітними шипами, що зменшують швидкість і завдають пошкодження за кожні 5 футів, пройдених по них.';

export const spike_growth_expanded = 
'Підлога в межах 20-футового радіусу з центром на точці в межах дистанції покривається шипами та колючками. Ця місцевість стає важкопрохідною, поки закляття активне.<br/><br/>' +
'Коли істота переміщенням входить в цю область або йде по ній, вона отримує колоте пошкодження 2d4 за кожні 5 пройдених футів. Трансформація поверхні проходить практично непомітно, і шипи добре замасковані. Істоти, які не бачили цю місцевість у момент накладання закляття, повинні здійснити перевірку Уважності проти Складності ваших заклять, щоб розпізнати місцевість як небезпечну перед тим, як до неї увійти.';

export const side_pary = "Бокове парирування";

export const side_pary_details = 'Використовующи щит, створити <span class="buff">перешкоду</span> на атаку від видимого вами ворога по істоті поруч із вами.';

export const side_pary_expanded = 
'Якщо істота, яку ви бачите, атакує не вас, а іншу істоту, що знаходиться в межах 5 футів від вас, ви можете Реакцією створити перешкоду його кидку атаки. Для цього ви маєте використовувати щит.';

export const divine_channel_expanded = 'Це закляття використовує Божественний канал. Він відновлюється після короткого відпочинку.';

export const divine_channel_mana = 'Праведне відновлення'

export const divine_channel_mana_details = 'Відновити один слот мани.';

export const divine_channel_mana_expanded = 'Ви можете використовувати Божественний канал, щоб підживити свої закляття. Бонусною дією ви торкаєтеся свого священного символу, вимовляєте молитву і повертаєте один витрачений слот заклять, рівень якого не перевищує половини вашого бонусу майстерності (з округленням у більшу сторону). Ви можете використовувати це вміння кількість разів, що залежить від рівня в цьому класі: один раз на 3-му рівні, двічі на 7-му рівні, три рази на 15-му рівні. Ви відновлюєте всі витрачені використання після тривалого відпочинку.<br/><br/>' + divine_channel_expanded;

export const divine_channel_devotion_1 = "Священна зброя";

export const divine_channel_devotion_1_details = 'Додати влучність зброї, яку ви тримаєте. Також зброя випромінює яскраве світло в радіусі 20 футів і тьмяне світло в межах ще 20 футів.';

export const divine_channel_devotion_1_expanded = 
'Ви можете наповнити одну зброю, яку ви тримаєте, позитивною енергією, використовуючи Божественний канал. Протягом 1 хвилини ви додаєте свій модифікатор Харизми до кидків атаки цією зброєю (мінімальний бонус +1). Також зброя випромінює яскраве світло в радіусі 20 футів і тьмяне світло в межах ще 20 футів. Якщо зброя не є чарівною, то вона вважається чарівною на час дії закляття. Ви можете закінчити цей ефект у свій хід частиною будь-якої іншої дії. Якщо ви не тримаєте або не несете цю зброю, або втрачаєте свідомість, цей ефект закінчується.<br/><br/>' + divine_channel_expanded;

export const divine_channel_devotion_2 = "Вигнання нечистого";

export const divine_channel_devotion_2_details = 'Вигнати погань та невмерлих в межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_devotion_2_expanded = 
'Ви Основною дією показуєте свій священний символ і вимовляєте слова молитви, що засуджує погань і невмерлих, використовуючи Божественний канал. Вся погань та невмерлі, які можуть бачити або чути вас, що знаходяться в межах 30 футів від вас, повинні пройти Випробування Мудрості. Якщо вони провалюють Випробування, то виганяються на 1 хвилину або доти, доки не отримають пошкодження. Вигнані істоти повинні весь свій хід намагатися втекти від вас так далеко, як можуть, і не можуть добровільно переміститися в простір, що знаходиться в межах 30 футів від вас. Також вони не можуть здійснювати Реакції. З усіх дій вони можуть робити тільки Ривок і спроби уникнути ефектів, що не дає їм переміщатися. Якщо ж рухатися нікуди, істоти можуть використовувати дію Ухилення.<br/><br/>' + divine_channel_expanded;

export const divine_channel_antients_1 = "Гнів природи";

export const divine_channel_antients_1_details = '🪢 Обплутати лозами істоту, що провалила Випробування.';

export const divine_channel_antients_1_expanded = 
'Закликати первородні сили для Обплутування ворога. Ви можете Основною дією обплутати примарними лозами істоту, яку ви можете бачити, і яка знаходиться в межах 10 футів від вас. Ця істота повинна досягти успіху у Випробуванні Сили або Спритності (на свій вибір), інакше вона стане Обплутаною. Обплутана лозами істота повторює Випробування у кінці кожного свого ходу. У разі успіху воно звільняється, а лози зникають.<br/><br/>' + divine_channel_expanded;

export const divine_channel_antients_2 = "Вигнання невірного";

export const divine_channel_antients_2_details = 'Вигнати погань та фей у межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• Викривається справжня форма істоти<br/>' +  
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_antients_2_expanded = 
'Ви можете використовувати Божественний канал для виголошення давніх слів, які завдають біль феям та погані, коли вони чують їх. Ви Основною дією демонструєте свій священний символ і всі феї та погань в межах 30 футів, які вас чують, повинні зробити Випробування Мудрості. Проваливши його, істота виганяється на 1 хвилину або поки не отримає пошкодження. Вигнані істоти повинні весь свій хід намагатися втекти від вас так далеко, як можуть, і не можуть добровільно переміститися в простір, що знаходиться в межах 30 футів від вас. Також вони не можуть здійснювати Реакції. З усіх дій вони можуть робити тільки Ривок і спроби уникнути ефектів, що не дає їм переміщатися. Якщо ж рухатися нікуди, істоти можуть використовувати дію ухилення. Якщо справжня форма істоти прихована ілюзією, зміною вигляду, чи іншим ефектом, що приховує її, вона розкривається, коли істота виганяється.<br/><br/>' + divine_channel_expanded;

export const divine_channel_vengence_1 = "Засудження ворога";

export const divine_channel_vengence_1_details = '😱 Перелякати істоту, що провалила Випробування. Погань або невмерлий отримує <span class="buff">перешкоду</span> на Випробування. Пошкодження по істоті знімає ефект.';

export const divine_channel_vengence_1_expanded = 
'Ви Основною дією демонструєте свій священний символ і вимовляєте молитву-вирок використовуючи Божественний канал. Виберіть одну істоту в межах 60 футів, яку можете бачити. Ця істота повинна пройти Випробування Мудрості, якщо не має імунітету до Переляку. Погань і невмерлі роблять це Випробування з перешкодою. Якщо Випробування провалене, істота стає Переляканою на 1 хвилину, або поки не отримає пошкодження. Поки істота Перелякана, її швидкість дорівнює 0, і вона не отримує жодних бонусів до швидкості. Якщо спаскидок був успішний, швидкість істоти на 1 хвилину, або поки вона не отримує пошкодження, зменшується вдвічі.<br/><br/>' + divine_channel_expanded;

export const divine_channel_vengence_2 = "Обітниця ворожнечі";

export const divine_channel_vengence_2_details = 'Отримати <span class="buff">перевагу</span> на кидки атаки по вибраній істоті.';

export const divine_channel_vengence_2_expanded = 
'Ви можете Бонусною дією вимовити слова обітниці ворожнечі по відношенню до істоти, яку ви можете бачити і яка знаходиться в межах 10 футів від вас, використовуючи Божественний канал. Протягом 1 хвилини, поки його здоровʼя не опускається до 0, або воно не знепритомніє, ви робите кидки атаки по ньому з перевагою.<br/><br/>' + divine_channel_expanded;

// — 85% -----------

export const maneuver_ambush = "Засідка";

export const maneuver_ambush_details = 'Разовий <span class="buff">бонус</span> на перевірку Непомітності або Ініціативи.';

export const maneuver_ambush_expanded = 
'Коли ви здійснюєте перевірку Непомітності або кидок Ініціативи, ви можете додати Маневр до кидка, якщо ви дієздатні.';

export const maneuver_evasive_footwork = "Активне ухилення";

export const maneuver_evasive_footwork_details = 'Разовий <span class="buff">бонус</span> на Броню при переміщенні.';

export const maneuver_evasive_footwork_expanded = 
'При переміщенні ви можете витратити один Маневр, зробити його кидок і додати результат до Броні, доки не припините переміщення.';

export const maneuver_lunging_attack = "Атака з випадом";

export const maneuver_lunging_attack_details = 
'Разовий <span class="buff">бонус</span> 5ф на дальність ближньої атаки. Разовий <span class="buff">бонус</span> на пошкодження при влучанні цієї атаки.';

export const maneuver_lunging_attack_expanded = 
'Якщо Ви в свій хід здійснюєте атаку ближньою зброєю, Ви можете витратити один Маневр, щоб збільшити досяжність цієї атаки на 5 футів. При влучанні Ви додаєте Маневр до кидка пошкодження цієї атаки.';

export const maneuver_maneuvering_attack = "Атака з маневром";

export const maneuver_maneuvering_attack_details = 
'Разовий <span class="buff">бонус</span> союзникові на половину його швидкості, при влучанні вашої атаки по істоті. Істота не може здійснити по союзникові провоковану атаку.';

export const maneuver_maneuvering_attack_expanded = 
'Якщо Ви влучаєте по істоті атакою зброєю, Ви можете витратити один Маневр, щоб один із Ваших товаришів зміг переміститися у вигіднішу позицію. Ви додаєте Маневр до кидку пошкодження цієї атаки і вибираєте дружню істоту, яка може бачити або чути Вас. Ця істота може Реакцією переміститися на відстань до половини своєї швидкості, не провокуючи атаки від цілі Вашої атаки.';

export const maneuver_menacing_attack = "Атака із погрозою";

export const maneuver_menacing_attack_details = 
'😱 Перелякати істоту при влучанні атаки. Разовий <span class="buff">бонус</span> на пошкодження при влучанні атаки.';

export const maneuver_menacing_attack_expanded = 
'Якщо ви влучаєте по істоті атакою зброєю, ви можете витратити один Маневр, щоб спробувати Перелякати ціль. Ви додаєте Куб Маневра до кидку пошкодження цієї атаки, а ціль має пройти Випробування Мудрості. При провалі ціль стає Перелякана вами до кінця вашого наступного ходу.';

export const maneuver_feinting_attack = "Атака з фінтом";

export const maneuver_feinting_attack_details = 
'Разова <span class="buff">перевага</span> на вашу атаку по вибраній істоті. Разовий <span class="buff">бонус</span> на пошкодження при влучанні цієї атаки.';

export const maneuver_feinting_attack_expanded = 
'Ви можете в свій хід витратити один Маневр і Бонусною дією зробити фінт, вибравши як ціль одну істоту в межах 5 футів. Наступний кидок атаки по цій істоті у цьому ході Ви робите з перевагою. Якщо ця атака влучає, додайте Маневр до кидка пошкодження.';

export const maneuver_disarming_attack = "Обеззброююча атака";

export const maneuver_disarming_attack_details = 
'Вибити предмет із рук істоти при влучанні атаки. Разовий <span class="buff">бонус</span> на пошкодження при попаданні атаки.';

export const maneuver_disarming_attack_expanded = 
'Якщо ви влучаєте по істоті атакою зброєю, ви можете витратити один Маневр, щоб спробувати обеззброїти противника, змушуючи його випустити один предмет на ваш вибір, який він тримає. Ви додаєте Маневр до кидку пошкодження атаки, а ціль має пройти Випробування Сили. У разі провалу вона упускає обраний вами предмет. Предмет падає біля її ніг.';

export const maneuver_trip_attack = "Перекидальна атака";

export const maneuver_trip_attack_details = 
'Разовий <span class="buff">бонус</span> на влучання атаки. Збити з ніг істоту при влучанні атаки.';

export const maneuver_trip_attack_expanded = 
'Якщо ви влучаєте по істоті атакою зброєю, ви можете витратити один Маневр, щоб спробувати збити ціль з ніг. Ви додаєте Маневр до кидку пошкодження атаки, і, якщо розмір цілі Великий або менше, вона повинна пройти Випробування Сили. У разі провалу ви збиваєте ціль з ніг.';

export const maneuver_riposte = "Удар у відповідь";

export const maneuver_riposte_details = 
'Зустрічна атака по істоті, яка промахнулася ближньою атакою. Разовий <span class="buff">бонус</span> на пошкодження при влучанні атаки.';

export const maneuver_riposte_expanded = 
'Якщо істота промахується по вас ближньою атакою, ви можете Реакцією витратити один Маневр, щоб здійснити ближню атаку зброєю по цій істоті. Якщо ви влучаєте, ви додаєте Маневр до кидка пошкодження цієї атаки.';

export const maneuver_distracting_strike = "Відволікаючий удар";

export const maneuver_distracting_strike_details = 
'Разова <span class="buff">перевага</span> на чужу атаку по вибраній істоті, при влучанні вашої атаки. Разовий <span class="buff">бонус</span> на пошкодження при влучанні атаки.';

export const maneuver_distracting_strike_expanded = 
'Якщо ви влучаєте по істоті атакою зброєю, ви можете витратити один Маневр, щоб відволікти істоту, відкриваючи її для ваших союзників. Ви додаєте Маневр до кидка пошкодження цієї атаки. Наступний кидок атаки по цій цілі будь-якої істоти крім вас відбувається з перевагою, якщо атака відбувається до початку наступного ходу.';

export const maneuver_parry = "Парирування";

export const maneuver_parry_details = 
'Зменшити пошкодження від атаки, що влучила у вас.';

export const maneuver_parry_expanded = 
'Якщо інша істота завдає вам пошкодження ближньою атакою, ви можете Реакцією витратити один Маневр, щоб зменшити шкоду на величину, рівну кидку вашого Маневру + ваш модифікатор Спритності.';

export const maneuver_goading_attack = "Провокуюча атака";

export const maneuver_goading_attack_details = 
'<span class="buff">Перешкода</span> на атаки цілі по всіх істотах крім вас, якщо ваша атака влучає. Разовий <span class="buff">бонус</span> на пошкодження при влучанні атаки.';

export const maneuver_goading_attack_expanded = 
'Якщо ви влучаєте по істоті атакою зброєю, ви можете витратити один Маневр, щоб спробувати спровокувати противника атакувати вас. Ви додаєте Маневр до кидка пошкодження цієї атаки, а ціль повинна пройти Випробування Мудрості. При провалі ціль до кінця вашого наступного ходу здійснює з перешкодою кидки атаки по всіх цілях, крім вас.';

// — 90% -------

export const maneuver_rally = "Гуртування";

export const maneuver_rally_details = 
'Тимчасове здоровʼя для цілі.';

export const maneuver_rally_expanded = 
'Ви можете у свій хід Бонусною дією витратити один Маневр, щоб підтримати рішучість одного з ваших супутників. Якщо ви це зробите, виберіть дружню істоту, яка може бачити чи чути вас. Ця істота отримує тимчасове здоровʼя, що рівне кидку Маневру + ваш модифікатор Харизми.';

export const maneuver_pushing_attack = "Атака із поштовхом";

export const maneuver_pushing_attack_details = 
'Відштовхнути ціль на 15ф при влучанні вашої атаки. Разовий <span class="buff">бонус</span> на пошкодження при влучанні атаки.';

export const maneuver_pushing_attack_expanded = 
'Якщо ви влучаєте по істоті атакою зброєю, ви можете витратити один Маневр, щоб спробувати відштовхнути ціль. Ви додаєте Маневр до кидку пошкодження атаки, і, якщо розмір цілі Великий або менше, вона повинна пройти Випробування Сили. При провалі ви штовхаєте ціль на відстань до 15 футів від себе.';

export const maneuver_precision_attack = "Точна атака";

export const maneuver_precision_attack_details = 
'Разовий <span class="buff">бонус</span> Влучності для вашої атаки.';

export const maneuver_precision_attack_expanded = 
'Якщо ви робите кидок атаки зброєю по істоті, ви можете витратити один Маневр, щоб додати його до кидка. Ви можете використовувати цей прийом до або після кидка атаки, але до застосування ефектів атаки.';

export const maneuver_commanders_strike = "Удар командувача";

export const maneuver_commanders_strike_details = 
'Спровокувати союзника здійснити атаку Реакцією, за рахунок однієї зі своїх атак. Разовий <span class="buff">бонус</span> на пошкодження при влучанні атаки.';

export const maneuver_commanders_strike_expanded = 
'Якщо ви здійснюєте дію Атака, ви можете відмовитися від однієї з ваших атак і Бонусною дією направити удар одного з ваших соратників. Якщо ви це зробите, виберіть дружню істоту, яка може бачити або чути вас і витратіть один Маневр. Ця істота може негайно здійснити Реакцією одну атаку зброєю, додавши Маневр до кидка пошкодження цієї атаки.';

export const maneuver_sweeping_attack = "Широка атака";

export const maneuver_sweeping_attack_details = 
'Завдати пошкодження додатковій істоті поряд від цілі вашої поточної успішної атаки.';

export const maneuver_sweeping_attack_expanded = 
'Якщо Ви влучаєте по істоті атакою ближньою зброєю, Ви можете витратити один Маневр, щоб спробувати завдати пошкодження іншій істоті цією ж атакою. Виберіть іншу істоту в межах 5 футів від початкової цілі та в межах вашої досяжності. Якщо початковий кидок атаки влучив би по другій істоті, вона отримує пошкодження, що дорівнює Маневру. Покшодження є того ж виду, що і для початкової атаки.';

export const inspiration = "Наснага";

export const inspiration_details = 
'Тимчасове здоровʼя для істот в області, які бачать/чують та розуміють вас.';

export const inspiration_expanded = 
'Ви можете витратити 10 хвилин на наснагу супутників, зміцнюючи їхню рішучість. Якщо Ви робите це, оберіть до шести дружніх істот (можете включити себе) в межах 30 футів від себе, які можуть бачити або чути вас і які можуть вас розуміти. Всі ці істоти отримують тимчасове здоровʼя у кількості, що дорівнює вашому рівню + вашому модифікатору Харизми. Істоти більше не можуть отримувати тимчасовк здоровʼя від цього вміння, доки не закінчать короткий або довгий відпочинок.';

export const grapple = "Схоплення";

export const grapple_details = 
'Схопити істоту, по якій ви влучили беззбройною атакою чи імпровізованою зброєю.';

export const grapple_expanded = 
'Якщо Ви у свій хід влучаєте по істоті беззбройним ударом або імпровізованою зброєю, ви можете Бонусною дією спробувати схопити ціль.';

export const healer_tooling = "Комплексне лікування";

export const healer_tooling_details = 
'Відновити здоровʼя істоті за рахунок комплекту цілителя + його власного Регену.';

export const healer_tooling_expanded = 
'Ви можете Основною дією витратити одне використання комплекту цілителя, щоб подбати про істоту і відновити йому 1d6 + 4 здоровʼя плюс додаткове здоровʼя, рівне максимуму Регена, що є у нього. Ця істота не зможе повторно відновлювати здоровʼя від цього вміння, доки не закінчить короткий або довгий відпочинок.';

export const extra_strike = "Додаткова атака";

export const extra_strike_details = 
'Ще одна ближня атака, якщо початкова призвела до крита або до зниження здоровʼя істоти до 0.';

export const extra_strike_expanded = 
'У свій хід, коли Ви здійснюєте критичне влучання ближньою зброєю або опускаєте ним здоровʼя істоти до 0, ви можете Бонусною дією зробити одну атаку ближньою зброєю.';

export const heavy_strike = "Важка атака";

export const heavy_strike_details = 
'<span class="debuff">• Разовий штраф</span> -5 на Влучність ближньої атаки.<br/>' +
'<span class="buff">• Разовий бонус</span> +10 на пошкодження при влучанні цієї атаки.';

export const heavy_strike_expanded = 
'Перед здійсненням атаки ближньою зброєю з властивістю «важка», якою ви володієте, ви можете прийняти штраф -5 до кидка атаки. Якщо така атака влучає, Ви додаєте +10 до пошкодження від цієї атаки.';

export const polearm_strike = "Атака держаком";

export const polearm_strike_details = 
'Вдарити зворотним боком вашої держакової зброї.';

export const polearm_strike_expanded = 
'Якщо ви здійснюєте дію Атака і атакуєте тільки алебардою, бойовим посохом або гліфою, Ви можете Бонусною дією здійснити ближню атаку протилежним кінцем зброї. Пошкодження для цієї атаки дорівнює 1d4, і атака завдає Дробильного пошкодження. Бонусна атака використовує модифікатор тієї ж Здібності, що й Основна.';

export const shield_push = "Поштовх щитом";

export const shield_push_details = 
'Відштовхнути істоту щитом.';

export const shield_push_expanded = 
'Якщо ви в свій хід здійснюєте дію Атака, ви можете Бонусною дією спробувати відштовхнути щитом істоту, що знаходиться в межах 5 футів від вас.';

// — 95% ------------

export const heavy_shot = "Важкий постріл";

export const heavy_shot_details = 
'<span class="debuff">• Разовий штраф</span> -5 на Влучність далекобійної атаки.<br/>' +
'<span class="buff">• Разовий бонус</span> +10 на пошкодження при влучанні цієї атаки.';

export const heavy_shot_expanded = 
'Перед здійсненням атаки далекобійною зброєю, якою ви володієте, ви можете прийняти штраф -5 до кидка атаки. Якщо така атака влучає, Ви додаєте +10 до пошкодження від цієї атаки.';

export const charge_attack = "Атака з ривка";

export const charge_attack_details = 
'Ближня атака з додатковим пошкодженням при Ривку.';

export const charge_attack_expanded = 
'Коли ви здійснюєте дію Ривок, ви можете Бонусною дією зробити одну ближню атаку зброєю. Якщо ви перед здійсненням цієї Бонусної дії перемістилися щонайменше на 10 футів по прямій лінії, ви або отримуєте бонус +5 до кидка пошкодження цієї атаки (якщо ви вирішили зробити ближню атаку і влучили).';

export const charge_push = "Поштовх з ривка";

export const charge_push_details = 
'Відштовхнути ціль при Ривку.';

export const charge_push_expanded = 
'Коли ви здійснюєте дію Ривок, ви можете Бонусною дією відштовхнути іншу істоту. Якщо ви перед здійсненням цієї Бонусної дії перемістилися щонайменше на 10 футів по прямій лінії, ви штовхаєте ціль на 10 футів від себе (якщо вирішили штовхати і досягли успіху).';

export const learn_ritual = "Вивчити знайдене ритуальне закляття";

export const learn_ritual_details = 
'Записати знайдене, зі знайденого сувою чи книги, ритуальне закляття класу вашої ритуальної спеціалізації.';

export const learn_ritual_expanded = 
'Знайшовши закляття в письмовій формі, такий як магічний сувій або книга заклять, ви можете скопіювати його в свою ритуальну книгу. Це закляття має бути у списку заклять обраного вами класу Ритуального заклинача, рівень його не повинен перевищувати половину вашого рівня (округлену у велику сторону), і у нього має бути ключове слово «ритуал». Процес переписування займає по 2 години за кожний рівень закляття і коштує 50 зм за кожний рівень. Це вартість матеріальних компонентів, що витрачаються в процесі експериментів із закляттям, а також особливих чорнил, якими воно записується.';

export const crossbow_shot = "Швидкий постріл";

export const crossbow_shot_details = 
'Швидка атака ручним арбалетом у руці, що супроводжує основну атаку цього ходу.';

export const crossbow_shot_expanded = 
'Коли ви здійснюєте дію Атака та атакуєте одноручною зброєю, ви можете Бонусною дією атакувати ручним арбалетом, що знаходиться в руці.';

export const create_crypt = "Створити шифрування";

export const create_crypt_details = 
'Написати шифрований лист.';

export const create_crypt_expanded = 
'Ви пишете шифровані листи. Інші не можуть розшифрувати код, поки або ви не навчите їх, або вони не досягнуть успіху в перевірці Інтелекту (Складність дорівнює вашому значенню Інтелекту + ваш бонус Майстерності), або вони не використовують для розшифрування магію.';

export const bardic_inspiration = "Бардівське натхнення";

export const bardic_inspiration_details = 
'Допомогти істоті пройти перевірку характеристки або навички / зробити атаку / пройти Випробування (будь якого куба d20). З 2го рівня так само можна допомогти завдати пошкодження / лікування закляттям.';

export const bardic_inspiration_expanded = 
'Своїми словами чи музикою ви надихаєте інших. Для цього ви повинні Бонусною дією вибрати одну істоту, відмінну від вас, в межах 60 футів, яка може вас чути. Ця істота отримує Куб бардівського натхнення — d6.<br/><br/>'+
'Протягом наступних 10 хвилин ця істота може один раз кинути цей Куб і додати результат до перевірки Здібності, кидку атаки або Випробування, яке вона здійснює. Істота може вирішити кидати Куб натхнення вже після кидка d20, але має зробити це перш, ніж Майстер оголосить результат кидка. Як тільки Куб бардівського натхнення кинутий, він зникає. Істота може мати тільки один такий Куб одночасно.<br/><br/>'+
'Ви можете використовувати це вміння кількість разів, що дорівнює модифікатору Вашої Харизми, але як мінімум один раз. Витрачені використання цього вміння відновлюються після довгого відпочинку. Ваш Куб бардівського натхнення змінюється зі зростанням вашого рівня у цьому класі. Вона стає d8 на 5 рівні, d10 на 10 рівні та d12 на 15 рівні.<br/><br/>'+
'Починаючи з 2 рівня: Якщо істота має ваш Куб бардівського натхнення і вона створює закляття, яке відновлює здоровʼя або завдає пошкодження, істота може кинути цей Куб і вибрати ціль, на яку діє закляття. Додайте результат кидка Кубу бардівського натхнення до відновленого здоровʼя або завданого пошкодження.';

export const song_of_rest = "Пісня відпочинку";

export const song_of_rest_details = 
'Збільшити Реген союзникам під час короткого відпочинку.';

export const song_of_rest_expanded = 
'Ви за допомогою заспокійливої музики чи промов допомагаєте своїм пораненим союзникам відновити їхні сили під час короткого відпочинку.<br/><br/>'+
'Якщо Ви або будь-які союзні істоти, здатні чути ваше виконання, відновлюєте здоровʼя у кінці короткого відпочинку, кожен із вас відновлює додатково 1d6 здоровʼя. Для того, щоб відновити додаткове здоровʼя, істота повинна витратити наприкінці короткого відпочинку щонайменше один куб Регену.<br/><br/>'+
'Кількість здоровʼя, що додатково відновлюється, зростає з вашим рівнем у цьому класі: 1d8 на 9 рівні, 1d10 на 13 рівні та 1d12 на 17 рівні';

export const cutting_word = "Гостре слівце";

export const cutting_word_details = 
'Завадити істоті в атаці / пошкодженні / перевірці здібності.';

export const cutting_word_expanded = 
'Ви використовуєте власну дотепність, щоб відволікти, збентежити або по-іншому підірвати Здібності та впевненість супротивників.<br/><br/>'+
'Якщо істота, яку ви можете бачити, в межах 60 футів від вас здійснює кидок атаки, пошкодження або перевірку Здібності, ви можете Реакцією витратити один з ваших Кубів бардівського натхнення і відняти результат цього кидка від кидка цілі.<br/><br/>'+
'Ви можете вирішити використати це вміння після кидка істоти, але до того моменту, коли Майстер оголосить результат кидка або перевірки. Істота не піддається цьому вмінню, якщо не може чути вас або має імунітет до Зачарування.';

export const second_wind = "Друге дихання";

export const second_wind_details = 
'Відновити своє здоровʼя';

export const second_wind_expanded = 
'Ви маєте обмежене джерело витривалості, яким можете скористатися, щоб уберегти себе. На свій хід ви можете Бонусною дією відновити здоровʼя в розмірі 1d10 + ваш рівень воїна. Використавши це вміння, ви повинні завершити короткий або довгий відпочинок, щоб отримати можливість використовувати його знову.';

export const action_surge = "Сплеск дії";

export const action_surge_details = 
'Додаткова Основна дія у поточному ході.';

export const action_surge_expanded = 
'У свій хід здійснити одну додаткову Основную дію окрім звичайної та Бонусної дій. Використавши це вміння, Ви повинні завершити короткий або довгий відпочинок, щоб отримати можливість використовувати його знову. Починаючи з 17 рівня, ви можете використовувати це вміння двічі, перш ніж Вам знадобиться відпочинок, але протягом одного ходу його все одно можна використовувати лише один раз.';

export const weapon_bond = "Зв'язок зі зброєю";

export const weapon_bond_details = 
'Створити міцний звʼязок зі своєю зброєю, яка унеможливлює обеззброєння власника в бою.';

export const weapon_bond_expanded = 
'Створити магічний звʼязок між вами та однією зброєю. Ви виконуєте ритуал протягом 1 години, він може бути здійснений протягом короткого відпочинку. Зброя під час проведення ритуалу повинна знаходитись на доступній відстані від вас, і наприкінці ви повинні доторкнутися до неї, щоб створити звʼязок. Як тільки ви привʼязали до себе зброю, вас не можна обеззброїти, доки ви дієздатні.<br/><br/>'+
'У вас може бути не більше ніж дві привʼязані зброї одночасно, і Бонусною дією ви закликаєте їх по одній. Якщо ви спробуєте створити звʼязок із третьою зброєю, вам доведеться розірвати звʼязок з одним із перших двох.';

export const call_bonbed_weapon = "Покликати зв'язану зброю";

export const call_bonbed_weapon_details = 
'Телепортувати Звʼязану зброю в руку.';

export const call_bonbed_weapon_expanded = 
'Якщо Звʼязана зброя знаходиться на одному плані існування з вами, ви можете в свій хід Бонусною дією покликати її, телепортуючи собі в руку.';

export const learn_spell = "Вивчити знайдене закляття";

export const learn_spell_details = 
'Записати знайдене закляття у свою книгу заклять.';

export const learn_spell_expanded = 
'Якщо ви знаходите закляття чарівника 1 або вищого рівня, ви можете додати його до своєї книги заклять, якщо воно має такий рівень, закляття якого ви можете готувати, і у вас є вільний час для його розшифрування та копіювання.<br/><br/>'+
'Копіювання закляття у вашу книгу включає відтворення основної форми закляття, а також розшифровку унікальної системи позначень, яку використовує чарівник, який записав його. Ви повинні підібрати потрібні жести та звуки, після чого записуєте його у свою книгу заклять, використовуючи власні позначення.<br/><br/>'+
'За кожний рівень закляття процес займає 2 години і коштує 50 золотих монет. Вартість являє собою матеріальні компоненти, які ви витрачаєте на експерименти із закляттям, щоб його опанувати, а також високоякісне чорнило для запису тексту. Після того як ви витратили час і гроші, ви можете готувати це закляття, як і свої інші закляття.';

export const small_conjuration = "Малий виклик";

export const small_conjuration_details = 
'Створити неживий предмет немагічної форми, який ви раніше бачили, до 1 фута в довжину, до 10 фунтів за вагою. Випромінює тьмяне світло.';

export const small_conjuration_expanded = 
'Ви можете Основною дією створити неживий предмет у своїй руці або на землі у вільному просторі, яке Ви можете бачити, і яке розташоване в межах 10 футів від вас. Цей предмет не повинен перевищувати в довжину 1 фут (30 сантиметрів) і важити більше 10 фунтів, і його форма повинна бути як у немагічного предмета, який ви вже бачили. Видно, що предмет магічний, і він випромінює тьмяне світло в межах 5 футів.<br/><br/>'+
'Предмет зникає через 1 годину, коли ви використовуєте це вміння знову, або коли він отримує або завдає будь-яке пошкодження.';

export const magical_protection = "Магічний захист";

export const magical_protection_details = 
'Під час створення закляття Огородження створити додатковий захист навколо себе.';

export const magical_protection_details_6 = 
'Під час створення закляття Огородження створити додатковий захист навколо себе. Здатно поглинати пошкодження по союзниках у межах дальності.';

export const magical_protection_expanded = 
'Коли ви накладаєте закляття школи Огородження 1 рівня або вище, ви можете одночасно використовувати частину магії для створення магічного захисту, що існує до закінчення тривалого відпочинку.<br/><br/>'+
'Захист має здоровʼя, що рівне вашому подвоєному рівню чарівника + модифікатор Інтелекту.<br/><br/>'+
'Коли ви отримуєте пошкодження, його замість вас отримує Захист. Якщо пошкодження опускає здоровʼя Захисту до 0, то пошкодження, що залишилося після цього, отримуєте ви. Коли у Захисту залишається 0 здоровʼя, він не може поглинати пошкодження, але його магія залишається. Якщо ви накладаєте закляття школи Огородження 1 рівня або вище, захист відновлює кількість хітів, що дорівнює подвоєному рівню закляття.<br/><br/>'+
'Починаючи з 6 рівня, коли видима вами істота, що знаходиться в межах 30 футів від вас, отримує шкоду, ви можете реакцією поглинути цю шкоду вашим Магічним Захистом. Якщо пошкодження опускає здоровʼя захисту до 0, то істота, що захищається, отримує всю шкоду, що залишилася.<br/><br/>'+
'Один раз створивши Захист, ви не можете створити його знову, доки не завершите тривалий відпочинок.';

export const hypnotic_look = "Гіпнотичний погляд";

export const hypnotic_look_details = 
'😍 Зачарувати вибрану істоту. Швидкість стає рівною 0, розгублений вигляд.';

export const hypnotic_look_expanded = 
'Основною дією виберіть одну видиму вами істоту в межах 5 футів від себе. Якщо ціль може бачити або чути вас, вона повинна досягти успіху у Випробуванні Мудрості проти Складності вашого закляття чарівника, інакше вона буде Зачарована вами до кінця вашого наступного ходу.<br/><br/>'+
'Швидкість зачарованої істоти падає до 0, вона виводиться з ладу і виглядає розгубленою. У наступні ходи ви можете Основною дією підтримувати цей ефект, продовжуючи його до кінця свого наступного ходу. Однак ефект припиняється, якщо ви віддалитеся від істоти більш ніж на 5 футів, якщо істота не може більше ні бачити, ні чути вас, або якщо істота отримує пошкодження.<br/><br/>'+
'Після закінчення ефекту, або якщо істота успішно пройшла Випробування від нього, ви не можете використовувати дане вміння проти цієї істоти, доки не завершите тривалий відпочинок.';

export const small_alchemy = "Мала алхімія";

export const small_alchemy_details = 
'Тимчасово перетворити кубічний фут одного звичайного матеріалу на інший звичайний.';

export const small_alchemy_expanded = 
'Ви здійснюєте спеціальну алхімічну процедуру на одному предметі, що складається повністю з дерева, каменю (але не дорогоцінного каменю), заліза, міді або срібла, перетворюючи його на один із цих матеріалів. За кожні 10 хвилин можна перетворити до 1 кубічного фута матеріалу. Через 1 годину, або коли ви втрачаєте концентрацію (якби ви концентрувалися на заклятті), матеріал повернеться у свій початковий стан.';

export const portent = "Знаміння";

export const portent_details = 
'Підготувати два кидки d20 для використання замість своїх або союзних кидків протягом дня.';

export const portent_details_14 = 
'Підготувати три кидки d20 для використання замість своїх або союзних кидків протягом дня.';

export const portent_expanded = 
'Коли ви закінчуєте тривалий відпочинок, зробіть два кидки до 20 і запишіть їх результати. Ви можете замінити будь-який кидок атаки, Випробування, або перевірку Здібності, зроблену вами або істотою, яку ви можете бачити, одним з цих кидків передбачення. Ви повинні зробити вибір про те, що так робите, до кидка, і ви можете замінити значення кидка подібним шляхом лише один раз за хід. Кожен кидок передбачення може бути використаний лише один раз.<br/><br/>'+
'Коли ви закінчуєте тривалий відпочинок, ви втрачаєте всі невикористані кидки передбачення.<br/><br/>'+
'Починаючи з 14 рівня бачення у ваших мріях, посилюються і малюють більш точну картину того, що прийде. Ви робите три кидки d20 для вашого вміння Знамення замість двох.';

export const overload = "Перегрузка";

export const overload_details = 
'Максимальне пошкодження від закляття Втілення [1—5].<br/>' +
'• Перший раз — без шкоди собі;<br/>' + 
'• Другий рвз раз — ви отримуєте 2d12 💀 Некротичного пошкодження за кожний рівень закляття;<br/>' + 
'• +1d12/коло за кожний наступний раз.<br/>' + 
'Відновлюється після тривалого відпочинку.';

export const overload_expanded = 
'Ви можете збільшити силу своїх найпростіших заклять. Створюючи закляття чарівника, що завдає пошкодження, з 1 по 5 коло, ви можете завдати максимальне пошкодження.<br/><br/>'+
'Без шкоди для себе Ви можете використати це вміння один раз. Якщо Ви повторно використовуєте це вміння до закінчення довгого відпочинку, то отримуєте шкоду некротичною енергією 2к12 за кожний рівень закляття відразу після створення. Кожен наступний раз, при використанні даного вміння до закінчення довгого відпочинку, пошкодження некротичною енергією збільшується на 1к12. Ця пошкодження ігнорує опір та імунітет.';

export const druid_message = "Друїдичне послання";

export const druid_message_details = 
'Нанести на обʼєкт таємне послання секретною Друїдською мовою.';

export const druid_message_expanded = 
'Ви можете говорити і залишати таємні послання Друїдською мовою. Ви та всі, хто знають цю мову, автоматично помічаєте ці послання. Інші помічають присутність послання при успішній перевірці Уважності зі Складністю 15, але без магії не можуть розшифрувати його.';

export const prepare_shield = "Підняти щит";

export const prepare_shield_details = 
'Взяти в руку щит та підготувати до бою.';

export const prepare_shield_expanded = 
'Якщо щит не у вас в руках, то його екіпірування займає одну Основну дію. Щит використовується однією рукою. Використання щита збільшує Броню на 2. Ви отримуєте перевагу тільки від одного щита одночасно.';

export const wild_shape = "Дика форма";

export const wild_shape_details = 
'• Ви отримуєте повне здоровʼя та фізіологічні Здібності вибраного звіра<br/>'+
'• Особистість, Навички, Вміння та Випробування зберігаються<br/>'+
'• Концентрація не переривається від перетворення<br/>'+
'• Не можна створювати закляття<br/>'+
'• Спорядження вибірково може залишитись надітим | впасти | злитися з Формою<br/><br/>'+
'Якщо здоровʼя звіра опустилося до 0, ви повертаєтеся у звичайний вигляд з колишнім здоровʼям мінус пошкодження, що залишилися від останньої атаки по звірові.<br/>';

export const wild_shape_details_2 = 
'Прийняти вигляд будь-якого побаченого колись звіра небезпекою до 1/4, що не вміє плавати і літати, наприклад вовка.<br/><br/>'+wild_shape_details;

export const wild_shape_details_4 = 
'Прийняти вигляд будь-якого побаченого колись звіра небезпекою до 1/2, який не вміє літати, наприклад крокодила.<br/><br/>'+wild_shape_details;

export const wild_shape_details_8 = 
'Прийняти за допомогою магії вигляд будь-якого побаченого колись звіра небезпекою до 1, наприклад гігантського орла.<br/><br/>'+wild_shape_details;

export const wild_shape_expanded = 
'Ви можете Основною дією прийняти за допомогою магії вигляд будь-якого звіра, якого ви бачили. Ви можете використовувати це вміння двічі, відновлюючи його після короткого чи тривалого відпочинку. Рівень друїда визначає, на яких звірів можна перетворюватися:<br/><br/>'+
'2й рівень: <br/>Звір небезпекою до 1/4 без швидкості плавання та польоту. <br/>Наприклад, вовк.<br/><br/>'+
'4й рівень: <br/>Звір небезпекою до 1/2 без швидкості польоту. <br/>Наприклад, крокодил.<br/><br/>'+
'8й рівень: <br/>Звір небезпекою до 1. <br/>Наприклад, гігантський орел.<br/><br/>'+
'Коли ви перетворені, діють такі правила:<br/><br/>'+
'• Усі ігрові параметри беруться з параметрів звіра, але зберігаються світогляд, особистість та значення Інтелекту, Мудрості та Харизми. Також у вас залишаються володіння навичками та Випробування, на додаток до таких від нового вигляду. Якщо кваліфікація є і у вас, і у звіра, але у нього бонус вищий, використовується бонус звіра. Легендарні дії та дії у лігві недоступні.<br/><br/>'+
'• Коли ви перетворені, ви приймаєте Здоровʼя та Реген звіра. Повернувшись у нормальний вигляд, ваше здоровʼя буде таким, як до перетворення. Однак якщо ви повернулися у свій вигляд через опускання здоровʼя звіра до 0, «зайве» пошкодження переноситься на нормальний вигляд. Наприклад, якщо ви у вигляді звіра отримали пошкодження 10, маючи при цьому 1 здоровʼя у звіра, то ви повертаєтеся в нормальний вигляд і отримуєте 9 пунктів пошкодження. Якщо це пошкодження не довела здоровʼя персонажа до 0, він не втрачає свідомість.<br/><br/>'+
'• Ви не можете накладати закляття, а промови та дії, що вимагають рук, можуть бути обмежені видом звіра. Перетворення не перериває вашу концентрацію на вже створених закляттях і не заважає робити дії, які є частиною закляття, такі як у разі закляття Виклик Блискавки.<br/><br/>'+
'• Ви зберігаєте переваги від усіх умінь класу, раси та інших джерел і можете користуватися ними, якщо цьому не перешкоджає новий фізичний вигляд. Однак недоступні особливі почуття, такі як темний зір, якщо їх немає у звіра.<br/><br/>'+
'• Ви самі визначаєте, яке спорядження залишиться лежати на землі, яке зіллється з новою формою, а яке буде надіте. Майстер вирішує, яке спорядження може носити тварина, щоб вона діяла як завжди. Спорядження не змінює форму і розмір під нову форму, і якщо не підходить новій формі, воно має залишитися на землі або злитися з новою формою. Спорядження, що злилося з формою, не працює, поки ви знову не прийме свій вигляд.<br/><br/>'+
'У формі тварини можна залишатися число годин, що дорівнює половині рівня друїда (округлюючи в меншу сторону). Потім друїд повертається в нормальний вигляд, якщо не витратить ще одне використання Дикого вигляду. Несвідомий, доведений до 0 хітів або мертвий друїд відразу повертається до нормального вигляду. Можна повернутися до нормального вигляду достроково, Бонусною дією.<br/><br/>';

export const find_familiar_druid = 'Виклик фамільяра друїда';

export const find_familiar_druid_details = 'Фея-помічник у вигляді невеликої тварини.<br/>'+
'• Діє незалежно, але завжди кориться командам<br/>' +
'• Може спілкуватися з вами телепатично (до 100ф)<br/>'+
'• Може ділитися даними своїх органів чуття<br/>'+
'• Можна сховати у кишеньковому вимірі<br/>'+
'• У бою має окрему ініціативу та хід<br/>'+
'• Не може атакувати, але може бути джерелом заклять';

export const find_familiar_druid_expanded = 'Ви отримуєте на службу фамільяра — духа, що приймає вигляд обраної вами невеликої тварини. Зʼявившись у вільному просторі в межах дистанції, фамільяр набуває характеристик обраної форми, хоча він не звір, а фея.<br/><br/>'+
'Ваш фамільяр діє незалежно від вас, але завжди кориться вашим командам. У битві він визначає свою ініціативу та діє у свій хід. Фамільяр не може атакувати, але може здійснювати інші дії як завжди. Коли здоровʼя фамільяра опускаються до 0 він зникає, не залишаючи фізичного тіла. Він повертається, коли ви використовуєте це закляття ще раз.<br/><br/>'+
'Поки фамільяр знаходиться в межах 100 футів, ви можете спілкуватися з ним телепатично. Крім того, ви можете почати дивитися очима фамільяра і слухати його вухами до початку свого наступного ходу, отримуючи перевагу від особливих відчуттів, якими може мати фамільяр. В цей час для своїх власних почуттів ви сліпі та глухі.<br/><br/>'+
'Якщо ви накладаєте закляття з умовою «Дотик цілі», його може передати фамільяр, ніби це він його накладав. Ваш фамільяр повинен при цьому знаходитись в межах 100 футів від вас, і він використовує свою Реакцію, коли ви накладаєте його. Якщо закляття вимагає кидка атаки, ви використовуєте свій модифікатор атаки.<br/><br/>'+
'Ви можете тимчасово відпустити свого фамільяра. Той зникає в кишеньковому вимірі, де чекатиме, доки ви його не покликаєте. Якщо фамільяр несе якісь речі, то вони залишаються на місці зникнення після його відходу в кишеньковий вимір. Поки фамільяр тимчасово відпущений, ви можете повернути його у вільний простір в межах 30 футів від себе. Як альтернатива, ви можете відпустити його назовсім.<br/><br/>'+
'У вас не може бути більше одного фамільяра одночасно. Якщо ви накладаєте це закляття, вже маючи фамільяра, ви змушуєте прийняти новий вигляд. Виберіть один із описаних вище видів. Ваш фамільяр стає цією істотою.<br/><br/>'+
'Фамільяр зникає через кількість годин, що дорівнює половині вашого рівня.';

export const wild_shape_combat = "Бойова дика форма";

export const wild_shape_combat_details_2 = 
'Прийняти вигляд будь-якого побаченого колись звіра небезпекою до 1, що не вміє плавати і літати, наприклад лева.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_4 = 
'Прийняти вигляд будь-якого побаченого колись звіра небезпекою до 1, який не вміє літати, наприклад ведмідя.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_6 = 
'Прийняти вигляд будь-якого побаченого колись звіра небезпекою до 2, який не вміє літати, наприклад носорога.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_8 = 
'Прийняти за допомогою магії вигляд будь-якого побаченого колись звіра небезпекою до 2, наприклад аллозавра.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_9 = 
'Прийняти за допомогою магії вигляд будь-якого побаченого колись звіра небезпекою до 3, наприклад гігантського скорпіона.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_10 = 
'Прийняти за допомогою магії вигляд будь-якого побаченого колись звіра або елементаля небезпекою до 3, наприклад гігантського скорпіона.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_12 = 
'Прийняти за допомогою магії вигляд будь-якого побаченого колись звіра або елементаля небезпекою до 4, наприклад слона.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_15 = 
'Прийняти за допомогою магії вигляд будь-якого побаченого колись звіра або елементаля небезпекою до 5, наприклад трицератопса.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_details_18 = 
'Прийняти за допомогою магії вигляд будь-якого побаченого колись звіра або елементаля небезпекою до 6, наприклад мамонта.<br/><br/>'+wild_shape_details;

export const wild_shape_combat_expanded = 
'Ви можете Бонусною дією прийняти за допомогою магії вигляд будь-якого звіра, якого ви бачили. Ви можете використовувати це вміння двічі, відновлюючи його після короткого чи тривалого відпочинку. Рівень друїда визначає, на яких звірів можна перетворюватися:<br/><br/>'+
'2й рівень: <br/>Звір небезпекою до 1 без швидкості плавання та польоту. <br/>Наприклад, лев.<br/><br/>'+
'4й рівень: <br/>Звір небезпекою до 1 без швидкості польоту. <br/>Наприклад, ведмідь.<br/><br/>'+
'6й рівень: <br/>Звір небезпекою до 2 без швидкості польоту. <br/>Наприклад, носорог.<br/><br/>'+
'8й рівень: <br/>Звір небезпекою до 2. <br/>Наприклад, аллозавр.<br/><br/>'+
'9й рівень: <br/>Звір небезпекою до 3. <br/>Наприклад, гігантський скорпіон.<br/><br/>'+
'10й рівень: <br/>Звір або елементаль небезпекою до 3. <br/>Наприклад, гігантський скорпіон.<br/><br/>'+
'12й рівень: <br/>Звір або елементаль небезпекою до 4. <br/>Наприклад, слон.<br/><br/>'+
'15й рівень: <br/>Звір або елементаль небезпекою до 5. <br/>Наприклад, трицератопс.<br/><br/>'+
'18й рівень: <br/>Звір або елементаль небезпекою до 6. <br/>Наприклад, мамонт.<br/><br/>'+
'Коли ви перетворені, діють такі правила:<br/><br/>'+
'• Усі ігрові параметри беруться з параметрів звіра, але зберігаються світогляд, особистість та значення Інтелекту, Мудрості та Харизми. Також у вас залишаються володіння навичками та Випробування, на додаток до таких від нового вигляду. Якщо кваліфікація є і у вас, і у звіра, але у нього бонус вищий, використовується бонус звіра. Легендарні дії та дії у лігві недоступні.<br/><br/>'+
'• Коли ви перетворені, ви приймаєте Здоровʼя та Реген звіра. Повернувшись у нормальний вигляд, ваше здоровʼя буде таким, як до перетворення. Однак якщо ви повернулися у свій вигляд через опускання здоровʼя звіра до 0, «зайве» пошкодження переноситься на нормальний вигляд. Наприклад, якщо ви у вигляді звіра отримали пошкодження 10, маючи при цьому 1 здоровʼя у звіра, то ви повертаєтеся в нормальний вигляд і отримуєте 9 пунктів пошкодження. Якщо це пошкодження не довела здоровʼя персонажа до 0, він не втрачає свідомість.<br/><br/>'+
'• Ви не можете накладати закляття, а промови та дії, що вимагають рук, можуть бути обмежені видом звіра. Перетворення не перериває вашу концентрацію на вже створених закляттях і не заважає робити дії, які є частиною закляття, такі як у разі закляття Виклик Блискавки.<br/><br/>'+
'• Ви зберігаєте переваги від усіх умінь класу, раси та інших джерел і можете користуватися ними, якщо цьому не перешкоджає новий фізичний вигляд. Однак недоступні особливі почуття, такі як темний зір, якщо їх немає у звіра.<br/><br/>'+
'• Ви самі визначаєте, яке спорядження залишиться лежати на землі, яке зіллється з новою формою, а яке буде надіте. Майстер вирішує, яке спорядження може носити тварина, щоб вона діяла як завжди. Спорядження не змінює форму і розмір під нову форму, і якщо не підходить новій формі, воно має залишитися на землі або злитися з новою формою. Спорядження, що злилося з формою, не працює, поки ви знову не прийме свій вигляд.<br/><br/>'+
'У формі тварини можна залишатися число годин, що дорівнює половині рівня друїда (округлюючи в меншу сторону). Потім друїд повертається в нормальний вигляд, якщо не витратить ще одне використання Дикого вигляду. Несвідомий, доведений до 0 хітів або мертвий друїд відразу повертається до нормального вигляду. Можна повернутися до нормального вигляду достроково, Бонусною дією.';

export const wild_shape_heal = "Лікування дикої форми";

export const wild_shape_heal_details = 
'Відновити здоровʼя своїй Бойовій дикій формі.';

export const wild_shape_heal_expanded = 
'У Бойовій дикій формі ви можете Бонусною дією витратити слот мани, щоб відновити 1d8 здоровʼя за кожен рівень витраченого слоту.';

export const divine_channel_undead = "Вигнання невмерлих";

export const divine_channel_undead_details = 'Вигнати невмерлих в межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_undead_details_5 = 'Вигнати невмерлих в межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• невмерлі небезпекою 1/2 і нижче — миттєво знищується<br/>' +
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_undead_details_8 = 'Вигнати невмерлих в межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• невмерлі небезпекою 1 і нижче — миттєво знищується<br/>' +
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_undead_details_11 = 'Вигнати невмерлих в межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• невмерлі небезпекою 2 і нижче — миттєво знищується<br/>' +
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_undead_details_14 = 'Вигнати невмерлих в межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• невмерлі небезпекою 3 і нижче — миттєво знищується<br/>' +
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_undead_details_17 = 'Вигнати невмерлих в межах дистанції, які бачать чи чують вас і провалили Випробування:<br/>' +
'• невмерлі небезпекою 4 і нижче — миттєво знищується<br/>' +
'• Повинні тікати від вас так далеко, як можуть<br/>' +  
'• Не можуть здійснювати Реакції<br/>' +  
'• Можуть використовувати спробу увороту від сковуючих ефектів<br/>' +  
'• Можуть використовувати Ухилення, якщо нікуди рухатися<br/>' +  
'• Пошкодження по істоті знімає ефект закляття.';

export const divine_channel_undead_expanded = 
'Ви Основною дією показуєте свій священний символ і вимовляєте слова молитви, що засуджує невмерлих, використовуючи Божественний канал. Всі невмерлі, які можуть бачити або чути вас, що знаходяться в межах 30 футів від вас, повинні пройти Випробування Мудрості. Якщо вони провалюють Випробування, то виганяються на 1 хвилину або доти, доки не отримають пошкодження. Вигнані істоти повинні весь свій хід намагатися втекти від вас так далеко, як можуть, і не можуть добровільно переміститися в простір, що знаходиться в межах 30 футів від вас. Також вони не можуть здійснювати Реакції. З усіх дій вони можуть робити тільки Ривок і спроби уникнути ефектів, що не дає їм переміщатися. Якщо ж рухатися нікуди, істоти можуть використовувати дію Ухилення.<br/><br/>' + 
'Починаючи з 5 рівня, коли невмерлий провалює Випробування, істота миттєво знищується, якщо його показник небезпеки не перевищує значення, вказаного нижче:<br/><br/>' +
'• 5й рівень: 1/2<br/>'+
'• 8й рівень: 1<br/>'+
'• 11й рівень: 2<br/>'+
'• 14й рівень: 3<br/>'+
'• 17й рівень: 4<br/>'+
divine_channel_expanded;

export const divine_intervention = "Божественне втручання";

export const divine_intervention_details = 
'Звернутися за кокретною допомогою до свого божества з невеликим шансом на успіх. При успіху – перерва 7 днів.';

export const divine_intervention_details_20 = 
'Звернутися за кокретною допомогою до свого божества з гарантованим успіхом. Між використаннями має бути не менше ніж 7 днів.';

export const divine_intervention_expanded = 
'Починаючи з 10 рівня ви можете порохати своє божество про допомогу, коли сильно цього потребуєте. Благання про допомогу здійснюється Основною дією. Опишіть допомогу, на яку ви чекаєте, і киньте відсотковий куб. Якщо випаде число, яке не перевищує ваш рівень, ваше божество втрутиться. Майстер сам обирає природу втручання; підійде ефект будь-якого закляття клірика або закляття домену. Якщо божество втручається, ви не можете використовувати це вміння протягом 7 днів. В іншому випадку ви можете використати це вміння після тривалого відпочинку.<br/><br/>' +
'На 20 рівні ваше звернення автоматично успішне і не вимагає перевірки.';

export const thunder_wrath = "Гнів Бурі";

export const thunder_wrath_details = 
'Викликати негайний удар ⚡ Струмом або 🔊 Звуком у відповідь за нанесене вам пошкодження.';

export const thunder_wrath_expanded = 
'Ви можете гучно покарати атакуючих. Якщо істота в межах 5 футів від вас, яку ви можете бачити, успішно влучає атакою по вас, ви можете Реакцією змусити істоту пройти Випробування Спритності. Істота отримує пошкодження Струмом або Звуком (на ваш вибір) 2d8, якщо провалить Випробування, і половину цього пошкодження якщо досягне успіху.<br/><br/>' + 
'Ви можете використовувати це вміння кількість разів, що дорівнює вашому модифікатору Мудрості (мінімум 1 раз). Ви відновлюєте всі витрачені застосування, коли завершуєте тривалий відпочинок.';

export const divine_channel_wrath = "Руйнівний Гнів";

export const divine_channel_wrath_details = 
'Завдати максимальне пошкодження ⚡ Струмом або 🔊 Звуком при успішному влучанні.';

export const divine_channel_wrath_expanded = 
'Ви можете використати Божественний канал, щоб опанувати могутність бурі з неприборканою лютістю. Коли ви робите кидок пошкодження Звуком або Струмом, ви можете використати Божественний канал, щоб заподіяти максимальне пошкодження замість кидка.';

export const divine_channel_directed_strike = "Спрямований удар";

export const divine_channel_directed_strike_details = 
'Бонус влучності до здійсненого удару.';

export const divine_channel_directed_strike_expanded = 
'Ви можете використовувати Божественний канал, щоб завдати удару з надприродною точністю. Коли ви здійснюєте кидок атаки, ви можете використовувати Божественний канал, щоб отримати бонус +10 до кидка. Ви можете вирішити, чи застосовувати це вміння, після того, як побачите результат кидка, але до того як Майстер скаже, влучила атака або промахнулася.';

export const divine_channel_war_god_bless = "Благословіння бога війни";

export const divine_channel_war_god_bless_details = 
'Бонус влучності до здійсненого удару союзника.';

export const divine_channel_war_god_bless_expanded = 
'Якщо істота в межах 30 футів від вас робить кидок атаки, ви можете Реакцією надати цій істоті бонус +10 до кидка, використовуючи Божественний канал. Ви можете вирішити, чи застосовувати це вміння, після того, як побачите результат кидка, але до того як Майстер скаже, влучила атака або промахнулася.';

export const thunder_strike = "Громовий удар";

export const thunder_strike_details = 
'Відштовхнути ціль при успішному влучанні ⚡ Струмом.';

export const thunder_strike_expanded = 
'Коли ви завдаєте пошкодженя Струмом істоті з розміром Великий або менше, ви також можете відштовхнути його на 10 футів від себе.';

export const inspired_attack = "Атака з наснагою";

export const inspired_attack_details = 
'Додаткова атака зброєю у ходу.';

export const inspired_attack_expanded = 
'Ваш бог наділяє вас наснагою, коли ви вступаєте в битву. Коли ви використовуєте дію Атака, ви можете здійснити одну атаку зброєю Бонусною дією. Ви можете використовувати це вміння кількість разів, що дорівнює вашому модифікатору Мудрості (мінімум 1 раз). Ви відновлюєте всі витрачені використання, коли завершуєте тривалий відпочинок.';

export const divine_strike_sound = "Божественний удар Звуком";
export const divine_strike_weapon = "Божественний удар Зброєю";
export const divine_strike_radiant = "Божественний удар Світлом";
export const divine_strike_poison = "Божественний удар Отрутою";
export const divine_strike_nature = "Божественний удар";

export const divine_strike_details = 
'Один раз в хід завдати додаткове пошкодження при влучанні зброєю.';

export const divine_strike_nature_details = 
'Один раз в хід завдати додаткове пошкодження 🔥 Вогнем | ❄️ Холодом | ⚡️ Струмом при влучанні зброєю.';

export const divine_strike_expanded = 
'Ви отримуєте здатність наповнювати удари своєї зброї божественною енергією. Один раз у кожен свій хід, коли ви влучаєте по цілі атакою зброєю, ви можете завдати цілі додатков 1d8.<br/><br/>' +
'Коли ви досягаєте 14 рівня, додаткове пошкодження зростає до 2d8.';

export const divine_channel_save_life = "Збереження життя";

export const divine_channel_save_life_details = 
'Зцілення істот в області до половини здоровʼя.';

export const divine_channel_save_life_expanded = 
'Ви можете використовувати Божественний канал, щоб лікувати тяжкі поранення. Ви Основною дією демонструєте свій священний символ і закликаєте цілющу енергію, яка може відновити кількість здоровʼя, яка дорівнює вашому рівню, помноженому на пʼять. Виберіть будь-яких істот в межах 30 футів від себе і розподіліть ці пункти здоровʼя між ними. Це вміння не може відновити здоровʼя вище половини від максимуму істоти. Ви не можете використовувати це вміння на менертвих та конструктах.';

export const divine_channel_learn_tool = "Знання століть";

export const divine_channel_learn_tool_details = 
'Опанувати навичкою чи інструментом на деякий час.';

export const divine_channel_learn_tool_expanded = 
'Ви можете використовувати Божественний канал, щоб отримати доступ до джерела знань. Ви Основною дією вибираєте навичку або інструмент. На 10 хвилин ви освоюєте володіння обранною навичкою чи інструментом.';

export const divine_channel_read_thoughts = "Читання думок";

export const divine_channel_read_thoughts_details = 
'Дізнатися поверхневі думки істоти.';

export const divine_channel_read_thoughts_expanded = 
'Ви можете використовувати Божественний канал, щоб читати думки істот. Потім можна використовувати доступ до розуму істоти, щоб командувати ним. Виберіть Основною дією одну істоту, яку ви можете бачити, що знаходиться в межах 60 футів від вас. Ця істота має пройти Випробування Мудрості. Якщо істота досягла успіху, ви не можете використовувати це вміння на ньому, поки не завершите тривалий відпочинок. Якщо істота провалює Випробування, ви можете прочитати його поверхневі думки (те, що у нього в голові, його поточні емоції і те, про що воно активно думає), коли воно знаходиться в межах 60 футів від вас. Цей ефект триває протягом 1 хвилини.<br/><br/>' + 
'Протягом цього часу ви можете Основною дією закінчити цей ефект, накладаючи на істоту закляття Навіювання, не витрачаючи слот мани. Ціль автоматично провалює Випробування від цього закляття.';

export const cleric_extra_damage = "Збільшене пошкодження заклять";

export const cleric_extra_damage_details = 
'Додаткове пошкодження до всіх [0] заклять.';

export const cleric_extra_damage_expanded = 
'Ви додаєте модифікатор Мудрості до пошкодження, яке завдаєте [0] закляттями.';

export const cleric_see_past = "Видіння минулого";

export const cleric_see_past_details = 
'Побачити минуле предмета, який ви тримаєте, або оточення, у якому знаходитесь.';

export const cleric_see_past_expanded = 
'Ви можете викликати видіння минулого, повʼязаного з предметом, який ви тримаєте, або навколишнім оточенням. Ви проводите не менше 1 хвилини медитуючи та молячись, а потім отримуєте примарні туманні видіння останніх подій. Ви можете медитувати таким чином кількість хвилин, що дорівнює вашому базовому значенню Мудрості, і повинні підтримувати концентрацію протягом цього часу, начебто ви робили закляття. Після того як ви використовували це вміння, ви не можете використовувати його повторно, поки не закінчите короткий або тривалий відпочинок.<br/><br/>' + 
'Читання предмета.<br/>Тримаючи предмет під час медитації, ви отримуєте видіння попереднього власника цього предмета. Після медитації протягом 1 хвилини ви дізнаєтеся, як власник придбав і втратив предмет, а також останню значущу подію за участю предмета та його власника. Якщо предмет належав іншій істоті в недавньому минулому (у межах кількості днів, що дорівнює базовому значенню вашої Мудрості), ви можете витратити 1 додаткову хвилину на кожного власника, щоб дізнатися ту саму інформацію про нього.<br/><br/>' + 
'Читання оточення.<br/>Поки ви медитуєте, ви спостерігаєте видіння останніх подій, що відбулися в безпосередній близькості (кімната, вулиця, тунель, галявина тощо в межах куба з довжиною ребра 50 футів) протягом кількості минулих днів, рівної базовому значенню вашої Мудрості. За кожну хвилину медитації ви дізнаєтеся про одну значну подію, починаючи з останньої. Значні події зазвичай повʼязані з сильними емоціями, наприклад, битвами та зрадами, весіллями та вбивствами, пологами та похоронами. Однак вони можуть також включати більш звичайні події, які, проте, важливі в поточній ситуації.';

export const tricker_bless = "Благословення лукавого";

export const tricker_bless_details = 
'Надати союзнику <span class="buff">перевагу</span> на Непомітність';

export const tricker_bless_expanded = 
'Ви можете діяти торкнутися згодної істоти, відмінної від вас, щоб дозволити їй здійснювати з перевагою перевірки Непомітності.';

export const divine_channel_mirror_image = "Подвоєння";

export const divine_channel_mirror_image_details = 
'Створити ідеальну ілюзорну копію себе:<br/>'+
'• Можна Бонусною дією пересувати на 30ф<br/>'+
'• Можна пересувати до 120ф від вас<br/>'+
'• Можна робити джерелом ваших заклять<br/>'+
'• Дає <span class="buff">перевагу</span> на ближнє влучання, як від союзника<br/>';

export const divine_channel_mirror_image_details_17 = 
'Створити до 4х ідеальних ілюзорних копії себе:<br/>'+
'• Можна Бонусною дією пересувати на 30ф<br/>'+
'• Можна пересувати до 120ф від вас<br/>'+
'• Можна робити джерелом ваших заклять<br/>'+
'• Дає <span class="buff">перевагу</span> на ближнє влучання, як від союзника<br/>';

export const divine_channel_mirror_image_expanded = 
'Ви можете використовувати Божественний канал, щоб створити ілюзорну копію себе. Ви Основною дією створюєте ідеальну ілюзію себе, яка існує протягом 1 хвилини, або поки ви не втратите концентрацію (якби ви концентрувалися на заклятті). Ілюзія зʼявляється у вільному просторі, який ви можете бачити в межах 30 футів від себе.<br/><br/>' + 
'Бонусною дією у свій хід ви можете перемістити ілюзію на відстань до 30 футів у простір, яку ви можете бачити, але ілюзія має залишатися в межах 120 футів від вас.<br/><br/>' + 
'Протягом дії вміння ви можете творити закляття, ніби ви знаходилися в просторі ілюзії, але ви повинні використовувати власні відчуття. Крім того, коли і ви і ваша ілюзія перебуваєте в межах 5 футів від істоти, яка може бачити ілюзію, ви робите кидки атаки по цій істоті з перевагою, оскільки ілюзія відволікає її.<br/><br/>' + 
'На 17 рівні ви можете створити до чотирьох копій себе, замість однієї, коли ви використовуєте Подвоєння. Бонусною дією ви можете перемістити будь-яку кількість копій на 30 футів, максимальна дистанція від вас не повинна перевищувати 120 футів.';

export const divine_channel_cloak_of_shadows = "Плащ тіней";

export const divine_channel_cloak_of_shadows_details = 
'Стати невидимим.';

export const divine_channel_cloak_of_shadows_expanded = 
'Ви можете використовувати Божественний канал, щоб зникнути. Ви Основною дією стаєте невидимим до кінця свого наступного ходу. Ви стаєте видимим, якщо атакуєте або накладаєте закляття.';

export const divine_channel_charm_alimal_plant = "Зачарування тварин та рослин";

export const divine_channel_charm_alimal_plant_details = 
'😍 Зачарувати всі рослини та тварин у межах дальності.';

export const divine_channel_charm_alimal_plant_details_17 = 
'😍 Зачарувати всі рослини та тварин у межах дальності. Бонусною дією можна дати їм вказівки до дій.';

export const divine_channel_charm_alimal_plant_expanded = 
'Ви можете використовувати Божественний канал, щоб зачарувати тварин і рослини. Ви Основною дією демонструєте свій священний символ і називаєте імʼя свого божества.<br/><br/>' + 
'Всі звірі та рослинні істоти, які можуть бачити вас, і знаходяться в межах 30 футів від вас, повинні пройти Випробування Мудрості. Якщо істота провалить Випробування, вона стає Зачарованою вами на 1 хвилину, або поки не отримає пошкодження. Поки істота зачарована, вона дружелюбна до вас та інших істот, яких ви вкажете.<br/><br/>' + 
'На 17 рівні ви отримуєте здатність командувати тваринами та рослинними істотами. Поки істоти Зачаровані вашим умінням Зачарування тварин та рослин, ви можете Бонусною дією у свій хід усно вказати, що кожне з них робитиме у свій наступний хід.';

export const dampen_elements = "Стримування стихій";

export const dampen_elements_details = 
'Надати собі або союзнику <span class="buff">стійкість</span> до пошкодження 🔊 Звуком | ⚗️ Кислотою | 🔥 Вогнем | ❄️ Холодом | ⚡ Струмом.';

export const dampen_elements_expanded = 
'Коли ви або істота в межах 30 футів від вас отримує пошкодження Звуком, Кислотою, Вогнем, Холодом або Струмом, ви можете Реакцією надати істоті стійкість до цього виду пошкодження.';

export const warding_flare = "Захисний спалах";

export const warding_flare_details = 
'Дати істоті <span class="debuff">перешкоду</span> на влучність по вас.';

export const warding_flare_details_6 = 
'Дати істоті <span class="debuff">перешкоду</span> на влучність по вас або союзнику.';

export const warding_flare_expanded = 
'Ви можете створити божественне світло між собою та атакуючим противником. Якщо ви атаковані видимою вами істотою, що знаходиться в межах 30 футів, ви можете Реакцією створити перешкоду його кидку атаки, викликаючи перед атакуючим спалах світла, перш ніж він влучить або промаже. Істоти, які не можуть бути засліплені, мають імунітет до цього вміння.<br/><br/>' + 
'Ви можете використовувати це вміння кількість разів, що дорівнює вашому модифікатору Мудрості (мінімум один раз). Ви відновлюєте всі витрачені використання, коли завершуєте тривалий відпочинок.<br/><br/>' + 
'Починаючи з 6 рівня, ви можете використовувати вміння Захисний спалах, коли істота, яку ви можете бачити в межах 30 футів від себе, атакує не вас, а іншу істоту.';

export const divine_channel_radiance_dawn = "Сяйво світанку";

export const divine_channel_radiance_dawn_details = 
'Розсіяти магічну темряву і завдати пошкодження всім ворожим істотам у межах дальності.';

export const divine_channel_radiance_dawn_expanded = 
'Ви можете використовувати Божественний канал, щоб закликати сонячне світло, що проганяє темряву і завдає пошкодження випромінюванням ворогам. Ви дією демонструєте свій священний символ, і вся магічна темрява в межах 30 футів від вас розсіюється.<br/><br/>' + 
'Крім того, всі ворожі істоти в межах 30 футів від вас повинні пройти Випробування Статури. Істоти отримують пошкодження Світлом, рівний 2d10 + ваш рівень у разі провалу і половину цього пошкодження у разі успіху.<br/><br/>' + 
'Істоти з повним укриттям від вас не схильні до впливу цього вміння.';

export const crown_of_light = "Корона світла";

export const crown_of_light_details = 
'Випустити яскраве світло в межах дальності, що дає <span class="debuff">перешкоду</span> на Випробування проти заклять з пошкодженням 🔥 Вогнем або ✨ Світлом.';

export const crown_of_light_expanded = 
'Ви можете Основною дією створити ауру сонячного світла, яка існує 1 хвилину або поки ви не розсієте її ще однією Основною дією.<br/><br/>' + 
'Ви випромінюєте яскраве світло в радіусі 60 футів і тьмяне світло в радіусі ще 30 футів. Ваші супротивники, що знаходяться на яскравому світлі, роблять з перешкодою спаски від будь-яких заклять, що завдають пошкодження Вогнем або Світлом.';

export const fey_presence = "Фейська зовнішність";

export const fey_presence_details = 
'😱 Перелякати | 😍 Зачарувати всіх істот в області, що виходить від вас.';

export const fey_presence_expanded = 
'Ваш покровитель дарує вам можливість проектувати одночасно привабливе і в той же час страшне присутністю фей. Осноною дією ви можете змусити кожну істоту в 10-футовому кубі, що виходить від вас, пройти Випробування Мудрості зі Складністю ваших заклять. Усі істоти, що провалили Випробування, стають до кінця вашого наступного ходу Зачарованими або Наляканими вами (на ваш вибір).<br/><br/>' + 
'Використавши це вміння, ви не можете використовувати його знову, не завершивши короткого чи тривалого відпочинку.';

export const create_weapon = "Викликати зброю";

export const create_weapon_details = 
'Створити у руці ближню зброю вибраної форми. Бонус Майстерності до влучності.';

export const create_weapon_expanded = 
'Ви можете створити зброю договору у своїй порожній руці. Ви самі вибираєте форму цієї ближньої зброї щоразу, коли її створюєте.<br/><br/>' + 
'Ви отримуєте володіння цією зброєю, поки використовуєте її. Зброя вважається магічною при визначенні подолання стійкості та імунітету від немагічних атак та пошкодження.<br/><br/>' + 
'Зброя договору зникає, якщо вона протягом 1 хвилини знаходиться далі 5 футів від вас. Воно також зникає, якщо ви використовуєте це вміння ще раз, відкликаєте зброю (Основна дія не потрібна), або вмираєте.';

export const convert_weapon = "Прив'язати зброю до договору";

export const convert_weapon_details = 
'Перетворити ближню магічну зброю на зброю договору.';

export const convert_weapon_expanded = 
'Ви можете трансформувати одну магічну зброю у свою зброю договору, провівши спеціальний ритуал, тримаючи цю зброю. Ритуал відбувається 1 годину, його можна провести під час короткого відпочинку. Згодом ви можете відкликати зброю, поміщаючи її між вимірами. Воно буде зʼявлятися в руці, коли ви надалі створюватимете зброю договору.<br/><br/>' + 
'Ви не можете зробити це з артефактом або розумною зброєю.<br/><br/>' + 
'Зброя перестає бути зброєю договору, коли ви вмираєте, виконуєте годинний ритуал з іншою зброєю або коли ви виконаєте ритуал завдовжки на годину, щоб розірвати звʼязок. Зброя матеріалізується біля ваших ніг, якщо в момент розриву звʼязку вона перебувала між вимірами.';

export const find_familiar_warlock = 'Виклик фамільяра чаклуна';

export const find_familiar_warlock_details = 'Фея/небожитель/погань помічник у вигляді невеликої тварини.<br/>'+
'• Діє незалежно, але завжди кориться командам<br/>' +
'• Може спілкуватися з вами телепатично (до 100ф)<br/>'+
'• Може ділитися даними своїх органів чуття<br/>'+
'• Можна сховати у кишеньковому вимірі<br/>'+
'• У бою має окрему ініціативу та хід<br/>'+
'• Може бути джерелом заклять<br/>'+
'• Може атакувати ціною однієї вашої атаки та своєї Реакції';

export const find_familiar_warlock_expanded = 'Ви отримуєте на службу фамільяра — духа, що приймає вигляд обраної вами невеликої тварини. Зʼявившись у вільному просторі в межах дистанції, фамільяр набуває характеристик обраної форми, хоча він не звір, а небожитель, фея чи погань (на ваш вибір).<br/><br/>'+
'Ваш фамільяр діє незалежно від вас, але завжди кориться вашим командам. У битві він визначає свою ініціативу та діє у свій хід. Коли ви здійснюєте атака, ви можете замість однієї своєї атаки дозволити атакувати один раз фамільяру. При цьому він робить свою атаку Реакцією. Фамільяр може здійснювати інші дії як завжди. Коли здоровʼя фамільяра опускаються до 0 він зникає, не залишаючи фізичного тіла. Він повертається, коли ви використовуєте це закляття ще раз.<br/><br/>'+
'Поки фамільяр знаходиться в межах 100 футів, ви можете спілкуватися з ним телепатично. Крім того, ви можете почати дивитися очима фамільяра і слухати його вухами до початку свого наступного ходу, отримуючи перевагу від особливих відчуттів, якими може мати фамільяр. В цей час для своїх власних почуттів ви сліпі та глухі.<br/><br/>'+
'Якщо ви накладаєте закляття з умовою «Дотик цілі», його може передати фамільяр, ніби це він його накладав. Ваш фамільяр повинен при цьому знаходитись в межах 100 футів від вас, і він використовує свою Реакцію, коли ви накладаєте його. Якщо закляття вимагає кидка атаки, ви використовуєте свій модифікатор атаки.<br/><br/>'+
'Ви можете тимчасово відпустити свого фамільяра. Той зникає в кишеньковому вимірі, де чекатиме, доки ви його не покликаєте. Якщо фамільяр несе якісь речі, то вони залишаються на місці зникнення після його відходу в кишеньковий вимір. Поки фамільяр тимчасово відпущений, ви можете повернути його у вільний простір в межах 30 футів від себе. Як альтернатива, ви можете відпустити його назовсім.<br/><br/>'+
'У вас не може бути більше одного фамільяра одночасно. Якщо ви накладаєте це закляття, вже маючи фамільяра, ви змушуєте прийняти новий вигляд. Виберіть один із описаних вище видів. Ваш фамільяр стає цією істотою.';

export const gaze_of_two_minds = 'Погляд двох умів';

export const gaze_of_two_minds_details = 'Перейти на органи відчуттів союзника.';

export const gaze_of_two_minds_expanded = 'Ви можете Основною дією торкнутися згодного гуманоїда і до кінця свого наступного ходу сприймати всі його відчуття. Поки істота знаходиться на тому ж плані існування, що й ви, ви можете в наступні ходи Основною Дією продовжувати цей звʼязок до кінця наступного ходу. При сприйнятті відчуттями іншої істоти, ви отримуєте всі переваги від особливих почуттів, які має ця істота, а самі при цьому сліпі та глухі.';

export const investment_of_the_chain_master = 'Дресирування фамільяра';

export const investment_of_the_chain_master_details = 'Ваш фамільяр:'+
'• Може літати | плавати, зі швидкістю 40ф.<br/>' +
'• Може атакувати ціною вашої Бонусної дії<br/>'+
'• Використовує вашу складність заклять<br/>'+
'• Може отримати від вас <span class="buff">стійкість</span> до пошкодження ціною вашої Реакції';

export const investment_of_the_chain_master_expanded = 'Коли ви використовуєте закляття Виклик фамільяра, ви наділяєте покликану істоту частинкою своєї потойбічної сили, даруючи йому такі переваги:<br/><br/>' +
'• Фамільяр отримує або швидкість польоту, або швидкість плавання (на ваш вибір), що дорівнює 40 футам.<br/><br/>'+
'• Бонусною дією ви можете наказати фамільяру здійснити дію атаки. Атаки зброєю фамільяра вважаються магічними у випадках, коли потрібно подолати імунітет чи опір для немагічних атак.<br/><br/>'+
'• Якщо фамільяр змушує ціль проходити Випробування, то ціль проходить Випробування проти вашої Складності.<br/><br/>'+
'• Коли фамільяр отримує пошкодження, ви можете використати свою Реакцію, щоб дарувати йому стійкість проти цього типу пошкодження.';

export const mage_armor_shadow = "Обладунок мага [0]"

export const speak_with_animals_warlock = "Розмова з тваринами [0]"

export const eldritch_blast_slow = 'Уповільнюючий Містичний заряд';

export const eldritch_blast_slow_details = 'Ваш Містичний заряд сповільнює ціль на 10ф. Можна використати один раз в хід.';

export const eldritch_blast_slow_expanded = 'Один раз у кожному з ваших ходів, коли ви влучаєте по істоті Містичним зарядом, ви можете до кінця наступного ходу зменшити його швидкість на 10 футів.';

export const disguise_self_warlock = 'Маскування [0]';

export const eldritch_blast_distant = 'Далекий Містичний заряд';

export const eldritch_blast_distant_details = 'Ваш Містичний заряд долітає значно далі.';

export const eldritch_blast_distant_expanded = 'Коли ви накладаєте Містичний заряд, його дистанція дорівнює 300 футів.';

export const false_life_warlock = 'Хибне життя [0]';

export const false_life_warlock_expanded = 'Зміцнивши себе некромантською подобою життя, ви отримуєте 1d4 + 4 тимчасового здоровʼя на час тривалості закляття.';

export const eldritch_blast_damage = 'Болісний Містичний заряд';

export const eldritch_blast_damage_details = 'Ваш Містичний заряд завдає більше пошкодження.';

export const eldritch_blast_damage_expanded = 'Коли ви накладаєте Містичний заряд, додайте модифікатор Харизми до пошкодження при влучанні.';

export const eldritch_blast_push = 'Відштовхуючий Містичний заряд';

export const eldritch_blast_push_details = 'Ваш Містичний заряд відштовхує ціль.';

export const eldritch_blast_push_expanded = 'Якщо ви влучаєте по істоті Містичним зарядом, ви можете штовхнути його на 10 футів від себе прямою лінією.';

export const rebuke_of_the_talisman = 'Помста талісмана';

export const rebuke_of_the_talisman_details = 'Завдати пошкодження і відштовхнути на 10ф того, хто завдасть пошкодження носію талісмана.';

export const rebuke_of_the_talisman_expanded = 'Коли по носію вашого талісмана влучає атакуючий, який знаходиться в межах 30 футів і ви його бачите, ви можете використати свою Реакцію, щоб завдати Психічне пошкодження атакуючому, що дорівнює вашому Бонусу майстерності і відкинути його на 10 футів від носія талісмана.';

export const detect_magic_warlock = 'Виявлення магії [0]';

export const silent_image_warlock = 'Безмовний образ [0]';

export const restore_mana_warlock = 'Внутрішній резерв';

export const restore_mana_warlock_details = 'Відновити всі слоти мани.';

export const restore_mana_warlock_expanded = 'Ви можете звернутися до внутрішнього резерву містичної сили, благаючи при цьому покровителя відновити витрачені слоти мани. Вам треба витратити 1 хвилину, благаючи покровителя, щоб відновити всі використані слоти мани. Ви повинні закінчити тривалий відпочинок, щоб застосувати це вміння знову.';

export const misty_escape = 'Туманне зникнення';

export const misty_escape_details = 'При отриманні пошкодження стати невидимим і телепортуватися в будь-якому видимому напрямку.';

export const misty_escape_expanded = 'Якщо ви отримали пошкодження, ви можете Реакцією розчинитися хмарою туману і стати невидимим і телепортуватися на відстань до 60 футів у видимий вами вільний простір. Ви залишаєтеся невидимим до початку наступного ходу, або поки не здійснюєте атаку або закляття.<br/><br/>'+
'Використавши це вміння, ви не можете використовувати його знову, не завершивши короткого чи тривалого відпочинку.';

export const beguiling_defenses = 'Чаруючий захист';

export const beguiling_defenses_details = '😍 Контр-зачарувати ціль.';

export const beguiling_defenses_expanded = 'Ваш покровитель навчає вас, як обертати магію ваших ворогів, що впливає на розум, проти них самих. Якщо інша істота намагається Зачарувати вас, ви можете спробувати Реакцією повернути Зачарування проти його творця. Ця істота має досягти успіху у Випробуванні Мудрості зі Складністю ваших заклять чаклуна, інакше вона стане Зачарована вами на 1 хвилину, або поки не отримає пошкодження.';

export const dark_delirium = 'Темне марення';

export const dark_delirium_details = 'Занурити ціль в ілюзорний ізольований туман.';

export const dark_delirium_expanded = 'Ви можете занурювати істот у ілюзорний простір. Виберіть видиму істоту в межах 60 футів від себе. Воно має здійснити спаски Мудрості зі Складністю ваших заклять чаклуна. У разі провалу воно Зачароване або Налякане вами (на ваш вибір) протягом 1 хвилини, або поки ви не втратите концентрацію (якби концентрувалися на заклинанні). Поки ілюзія триває, істота вважає, що вона загубилася у туманному просторі, образ якого ви вибираєте. Істота може бачити і чути лише себе, вас та ілюзію.<br/><br/>'+
'Ефект завершується передчасно, якщо істота отримує будь-яке пошкодження. Використавши це вміння, ви не можете використовувати його знову, не завершивши короткого чи тривалого відпочинку.';

export const dark_ones_blessing = 'Благословення Темного';

export const dark_ones_blessing_details = 'Відновити здоровʼя від вбивства.';

export const dark_ones_blessing_expanded = 'Коли ви опускаєте хіти ворожої істоти до 0, ви отримуєте тимчасове здоровʼя, що рівні вашому модифікатору Харизми + ваш рівень чаклуна (мінімум 1).';

export const dark_ones_own_luck = 'Талан Темного';

export const dark_ones_own_luck_details = 'Бонус до перевірки Характеристики, Навички або Випробування.';

export const dark_ones_own_luck_expanded = 'Ви можете звернутися до свого покровителя, щоб змінити долю на свою користь. Коли ви робите перевірку характеристики або Випробування, ви можете використовувати це вміння, щоб додати d10 до кидка. Ви можете так зробити після основного кидка, але до того, як його ефект набуде чинності.<br/><br/>'+
'Використавши це вміння, ви не можете використовувати його знову до закінчення короткого чи тривалого відпочинку.';

export const hurl_through_hell = 'Кидок крізь пекло';

export const hurl_through_hell_details = 'При влучанні вашої атаки, викинути ціль на невеликий час у нижні плани.';

export const hurl_through_hell_expanded = 'Коли ви влучаєте по істоті атакою, ви можете використати це вміння, щоб миттєво перемістити ціль крізь нижні плани. Істота зникає і мчить через кошмарний ландшафт.<br/><br/>'+
'У кінці вашого наступного ходу ціль повертається в простір, який займала, або найближчий вільний простір. Якщо ціль — не погань, вона отримує пошкодження Психічною енергією 10d10 від пережитих моторошних відчуттів.<br/><br/>'+
'Використавши це вміння, ви не можете використовувати його ще до закінчення тривалого відпочинку.';

export const entropic_ward = 'Ентропічний догляд';

export const entropic_ward_details = 'Створити цілі <span class="buff">перешкоду</span> на атаку по вас. При промаху цілі по вас, отримати <span class="buff">перевагу</span> на атаку по цілі.';

export const entropic_ward_expanded = 'Ви можете магічно берегти себе від атак і використовувати невдалу ворожу атаку на користь собі. Якщо істота робить кидок атаки по вас, ви можете Реакцією створити перешкоду для цього кидка. Якщо атака промахнеться по вас, ваш наступний кидок атаки по цій істоті відбувається з перевагою, якщо ви здійсните його до кінця наступного ходу.<br/><br/>'+
'Використавши це вміння, ви не можете використовувати його знову до закінчення короткого чи тривалого відпочинку.';

export const create_thrall = 'Створити раба';

export const create_thrall_details = 'Міцно 😍 Зачарувати недієздатну ціль з можливістю телепатичного спілкування.';

export const create_thrall_expanded = 'Ви отримуєте здатність інфікувати розум гуманоїдів чужорідною магією свого покровителя. Ви можете Основною торкнутися недієздатного гуманоїду. Ця істота стає Зачарованою вами до тих пір, поки на неї не буде створено закляття Зняття прокляття, або поки вона не перестане бути Зачарованою, або поки ви не використовуєте це вміння ще раз. Ви можете спілкуватися телепатично із зачарованою істотою, поки ви обидва перебуваєте на одному плані існування.';

export const unarmed_strike_bonus = 'Бонусний удар';

export const unarmed_strike_bonus_details = 'Вдарити кулаком або іншою частиною тіла на додаток до основної атаки.';

export const unarmed_strike_bonus_expanded = 'Якщо у свій хід ви використовуєте дію Атака для збройної атаки, або атаки монаською зброєю, ви можете зробити ще одну беззбройну атаку Бонусною дією. Наприклад, якщо ви здійснили Основну дію Атака і атакували бойовим посохом, ви можете зробити Бонусною дією беззбройну атаку, за умови, що в цьому ходу ви ще не здійснювали бонусну дію.';

export const step_of_the_wind = 'Крок вітру';

export const step_of_the_wind_details = 'Здійснити Відхід | Ривок. Подвоїти дальність стрибків.';

export const step_of_the_wind_expanded = 'Ви можете витратити 1 слот Чі у свій хід, щоб зробити Бонусною дією Відхід або Ривок. У цей перебіг дальність ваших стрибків подвоюється.';

export const patient_defense = 'Пильна оборона';

export const patient_defense_details = 'Отримати <span class="buff">перевагу</span> на Випробування Спритності, і <span class="buff">перешкоду</span> на атаки по вас.';

export const patient_defense_expanded = 'Ви витрачаєте 1 слот Чі у свій хід, щоб зробити Бонусною дією Ухилення:<br/><br/>'+
'До початку вашого наступного ходу, якщо ви бачите атакуючого, всі кидки атаки по вам відбуваються з перешкодою і Випробування Спритності ви робите з перевагою.<br/><br/>'+
'Ви втрачаєте цю перевагу, якщо стаєте недієздатним або якщо ваша швидкість падає до 0 або перестаєте бачити атакуючого.';

export const flurry_of_blows = 'Шквал ударів';

export const flurry_of_blows_details = 'Двічі вдарити кулаком або іншою частиною тіла на додаток до основної атаки.';

export const flurry_of_blows_expanded = 'Відразу ж після того, як ви в свій хід здійснили дію Атака, ви можете витратити 1 слот Чі, щоб зробити Бонусною дією дві беззбройні атаки.';

export const dedicated_weapon = 'Вибрана зброя';

export const dedicated_weapon_details = 'Зробити додаткову зброю монаською.';

export const dedicated_weapon_expanded = 'Ви тренуєтеся використовувати не тільки просту зброю та короткі мечі як монаську зброю, але й інші види зброї. Щоразу, коли ви закінчуєте короткий або тривалий відпочинок, ви можете торкнутися однієї зброї і направити в неї свою Чі, після чого ця зброя вважається для вас монаською, поки ви не скористаєтеся цим вмінням знову. Вибрана зброя повинна відповідати таким критеріям:<br/><br/>'+
'• Зброя має бути простою або військовою.<br/>'+
'• Ви повинні мати володіння цією зброєю.<br/>'+
'• У зброї не повинно бути таких властивостей: Тяжке або Особливе.';

export const deflect_missiles = 'Відбиття снарядів';

export const deflect_missiles_details = 'Відбити снаряд. Якщо пошкодження знизилося до 0, можна витратити 1× Чі, щоб спіймати і далекобійно атакувати ним.';

export const deflect_missiles_expanded = 'Ви можете Реакцією відбити або спіймати снаряд, якщо по вас влучили далекобійною атакою зброєю. Якщо ви робите це, пошкодження знижується на 1к10 + модифікатор Спритності + рівень монаха. Якщо ви знизили покшодження до 0, ви можете спіймати снаряд у разі, якщо він досить малий, щоб тримати його однією рукою, і одна з ваших рук вільна. Якщо ви зловили снаряд за допомогою цього вміння, ви можете витратити одне слот Чі, щоб частиною цієї ж Реакції зробити далекобійну атаку спійманою зброєю або боєприпасом з дистанцією 20/60 футів. Ви робите цю атаку з володінням, незалежно від володіння даною зброєю, і цей снаряд вважається для даної атаки монаською зброєю.';

export const weapon_strike_bonus = 'Бонусний удар зброєю';

export const weapon_strike_bonus_details = 'Атакувати зброєю на додаток до використання Чі.';

export const weapon_strike_bonus_expanded = 'Якщо ви частиною дії у свій хід витрачаєте хоча б 1 слот Чі, то можете до закінчення свого ходу Бонусною дією зробити одну беззбройну атаку або атаку монаською зброєю.';

export const slow_fall = 'Повільне падіння';

export const slow_fall_details = 'Помʼякшити своє падіння.';

export const slow_fall_expanded = 'Якщо ви впали, ви можете Реакцією зменшити пошкодження від падіння на значення, що дорівнює вашому рівню, помноженому на пʼять.';

export const quickened_healing = 'Прискорене зцілення';

export const quickened_healing_details = 'Відновлення здоровʼя.';

export const quickened_healing_expanded = 'Основною дією ви можете витратити 2 слоти Чі і зробити кидок куба бойових мистецтв. Ви відновлюєте кількість здоровʼя, що дорівнює результату кидка куба бойових мистецтв + ваш бонус Майстерності.';

export const extra_strike_monk = 'Додаткова атака';

export const extra_strike_monk_details = 'Ще одна атака в основний хід';

export const extra_strike_monk_expanded = 'Якщо ви в свій хід здійснюєте атаку, ви можете здійснити дві атаки замість однієї.';

export const stunning_strike = 'Оглушальний удар';

export const stunning_strike_details = 'Оглушити ціль ближньою атакою, що влучила.';

export const stunning_strike_expanded = 'Ви можете взаємодіяти з енергією Чі, що тече у тілі вашого супротивника. Якщо ви влучили по іншій істоті атакою ближньою зброєю, ви можете витратити 1 слот Чі, щоб завдати оглушального удару. Ціль повинна досягти успіху у Впиробуванні Статури, інакше вона стане оглушенною до кінця вашого наступного ходу.';

export const diamond_soul = 'Діамантова душа';

export const diamond_soul_details = 'Перепройти провалене Випробування.';

export const diamond_soul_expanded = 'Якщо ви провалили Випробування, ви можете повторити його, витративши 1 слот Чі, і повинні використати новий результат.';

export const focused_aim = 'Фокус на цілі';

export const focused_aim_details = 'Додати влучність до проваленої атаки (до 3х використань на одну атаку).';

export const focused_aim_expanded = 'Коли ви промахуєтесь кидком атаки, ви можете витратити від 1 до 3 слотів Чі, щоб збільшити результат кидка атаки на 2 за кожний витрачений слот Чі, що потенційно може звернути промах у влучення.';

export const stillness_of_mind = 'Спокій розуму';

export const stillness_of_mind_details = 'Позбутися 😍 Зачарування або 😱 Переляку.';

export const stillness_of_mind_expanded = 'Ви можете Основною дією закінчити один з ефектів, що діють на вас, що роблять вас Зачарованим або Переляканим.';

export const empty_body = 'Пусте тіло';

export const empty_body_details = 'Стати невидимим на 1 хвилину і отримати <span class="buff">стійкість</span> до будь-якого пошкодження, крім 🌈 Силового поля.';

export const empty_body_expanded = 'Ви можете витратити 4 слоти Чі, щоб стати невидимим на 1 хвилину. Протягом цього часу ви отримаєте стійкість до будь-якого пошкодження, крім пошкодження Силовим полем.';

export const open_hand_tech_details = "До одного Шквалу ударів можна використати лише одну Відкриту долоню."

export const open_hand_tech_knock = 'Відкрита долоня: Збиття';

export const open_hand_tech_knock_details = 'При успішному Шквалі ударів — збити ціль з ніг. '+open_hand_tech_details;

export const open_hand_tech_push = 'Відкрита долоня: Поштовх';

export const open_hand_tech_push_details = 'При успішному Шквалі ударів — відштовхнути ціль. '+open_hand_tech_details;

export const open_hand_tech_confuse = 'Відкрита долоня: Оглушення';

export const open_hand_tech_confuse_details = 'При успішному Шквалі ударів — заборонити Реакції. '+open_hand_tech_details;

export const open_hand_tech_expanded = 'Ви отримуєте можливість маніпулювати ворожою енергією Чі, спрямовуючи свою власну. Якщо ви влучаєте по цілі однією з атак Шквалу ударів, ви можете накласти на ціль один із наступних ефектів:<br/><br/>'+
'• Ціль повинна досягти успіху у Випробуванні Спритності, інакше вона збивається з ніг.<br/><br/>'+
'• Ціль має пройти Випробування Сили. У разі провалу ви можете штовхнути її від себе на відстань до 15 футів.<br/><br/>'+
'• Ціль не може здійснювати Реакції до кінця вашого наступного ходу.';

export const wholeness_of_body = 'Зцілення тіла';

export const wholeness_of_body_details = 'Суттєво відновити здоровʼя';

export const wholeness_of_body_expanded = 'Ви отримуєте здатність лікувати себе. Ви можете відновити кількість здоровʼя, що дорівнює вашому потрійному рівню. Ви повинні завершити тривалий відпочинок, перш ніж зможете використати це вміння знову.';

export const sanctuary_monk = 'Умиротворення монаха';

export const sanctuary_monk_details = 'Захистити себе від атак, накладаючи на атакуючих Випробування.';

export const sanctuary_monk_expanded = 'Ви захищаєте себе від атак. Поки закляття активне, всі істоти, що націлюються на вас атаками або шкідливими закляттями, повинні спочатку пройти Випробування Мудрості. При провалі істота має вибрати нову ціль або втратить атаку чи закляття.<br/><br/>' + 
'Це закляття не захищає від ефектів, що діють на область, таких як Вибух вогняної кулі. Якщо ви здійснюєте атаку, накладаєте закляття, що впливає на ворожу істоту або завдаєте пошкодження іншій істоті, це закляття закінчується.';

export const quivering_palm = 'Тремтяча долоня';

export const quivering_palm_details = 'При беззбройному влучанні — відкладено опустити здоровʼя цілі до 0.';

export const quivering_palm_expanded = 'Ви маєте можливість створювати смертельні коливання в чужому тілі. Якщо ви влучите по цілі беззбройним ударом, ви можете витратити 3 слоти Чі, щоб створити невловимі коливання в його тілі, які тривають протягом кількості діб, що дорівнює вашому рівню. Коливання нешкідливі, поки ви не зупините їх Основною дією. Для того, щоб зупинити їх, ціль повинна бути на одному плані існування з вами. При цьому істота має пройти Випробування Статури. У разі успіху ціль отримує пошкодження Некротичною енергією 10d10, а у разі провалу її хіти опускаються до 0.<br/><br/>'+
'Ви можете тримати одночасно тільки одну істоту під дією Тремтячої долоні. Ви можете вирішити закінчити коливання без пошкодження, не витрачаючи Основну дію.';

export const pass_without_trace_monk = 'Безслідне пересування [Чі]';

export const pass_without_trace_monk_expanded = 'Від вас починає виходити покрив тіней і тиші, що приховує вас та ваших супутників від виявлення. Поки закляття активне, всі істоти, вибрані вами в межах 30 футів (включно з вами) отримують бонус +10 до перевірок Непомітності, і їх не можна вистежити без допомоги магії. Істота, яка отримала цей бонус, не залишає слідів.';

export const darkvision_monk = 'Темний зір [Чі]';

export const darkvision_monk_expanded = 'Ви торкаєтеся згодної істоти і даруєте їй можливість бачити у темряві. Поки закляття активне, ця істота має темний зір у межах 60 футів.';

export const silence_monk = 'Тиша [Чі]';

export const darkness_monk = "Темрява [Чі]";

export const shadow_step = 'Крок тіні';

export const shadow_step_details = 'Телепортуватися з однієї тіні до іншої в межах дальності. Отримати <span class="buff">перевагу</span> на наступну атаку цього ходу';

export const shadow_step_expanded = 'Ви отримуєте можливість зробити крок з однієї тіні в іншу. Якщо ви перебуваєте в темряві або просторі з тьмяним освітленням, ви можете Бонусною дією телепортуватися на 60 футів в інший тьмяний або темний простір, який бачите. Свою першу ближню атаку до кінця поточного ходу ви зробите з перевагою.';

export const cloak_of_shadows_monk = 'Мантія тіні';

export const cloak_of_shadows_monk_details = 'Перебуваючи у тьмяному освітленні, стати невидимим. Невидимість спадає при атаці | заклятті | яскравому світлі.';

export const cloak_of_shadows_monk_expanded = 'Ви дізнаєтеся, як ставати єдиним із тінню. Коли ви знаходитесь в області з тьмяним освітленням або в темряві, ви можете стати невидимим. Ви залишаєтеся невидимим до тих пір, поки не нападете, не створете закляття, або не опинитеся в області яскравого світла.';

export const opportunist = 'Опортуніст';

export const opportunist_details = 'Атакувати істоту, в яку влучила атака від іншої істоти.';

export const opportunist_expanded = 'Ви дізнаєтесь, як використовувати миттєве відволікання цілі, по якій влучила атака. Якщо по істоті в радіусі 5 футів від вас влучила атака, здійснена іншою істотою, але не вами, ви можете Реакцією здійснити ближню атаку.';

export const monk_element_adept = 'Ви можете витрачати слоти Чі для посилення заклять, отриманих завдяки стихійним практикам, якщо ці закляття збільшують свою ефективність з рівнем. Рівень закляття збільшується на 1 за кожний витрачений слот Чі.'

export const water_whip = 'Водяний батіг';

export const water_whip_details = 'Збити ціль з ніг | підтягнути до себе на 25ф батогом з води і завдати пошкодження.';

export const water_whip_details_5 = water_whip_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 3)';

export const water_whip_details_9 = water_whip_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 4)';

export const water_whip_details_13 = water_whip_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 5)';

export const water_whip_details_17 = water_whip_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 6)';

export const water_whip_expanded = 'Ви можете Основною дією витратити 2 слоти Чі і створити водяний батіг, що збиває істот з ніг. Істота, яку ви бачите, і яка знаходиться в межах 30 футів від вас, повинна пройти Випробування Спритності. У випадку провалу істота отримує Дробильне пошкодження 3d10, плюс додаткове пошкодження 1d10 за кожний додатковий витрачений слот Чі, і ви можете або збити його з ніг, або підтягнути його на 25 футів ближче до себе. У разі успішного Випробування істота отримує половину пошкодження, не підтягується і не збивається з ніг.<br/><br/>'+monk_element_adept;

export const shatter_monk = 'Гонг';

export const shatter_monk_details = 'Вдарити звуковою хвилею по області.';

export const shatter_monk_details_5 = shatter_monk_details;

export const shatter_monk_details_9 = shatter_monk_details+' +1d8 пошкодження за кожний додатковий слот Чі. (максимум 4)';

export const shatter_monk_details_13 = shatter_monk_details+' +1d8 пошкодження за кожний додатковий слот Чі. (максимум 5)';

export const shatter_monk_details_17 = shatter_monk_details+' +1d8 пошкодження за кожний додатковий слот Чі. (максимум 6)';

export const shatter_monk_expanded = 'Гучний дзвінкий звук виходить із вибраної вами точки в межах дистанції. Усі істоти у сфері з радіусом 10 футів із центром на цій точці мають пройти Випробування Статури. При провалі істота отримує пошкодження Звуком 3d8, або половину цього пошкодження при успіху. Істоти, виготовлені з неорганічної матерії, такої як камінь, кристал або метал, проходять це Випробування із перешкодою. Немагічні предмети, які не тримають і не носять, теж отримують пошкодження, якщо знаходяться в області закляття.<br/><br/>'+monk_element_adept;

export const fire_snake_teeth = 'Зуби вогняної змії';

export const fire_snake_teeth_det = 'Вогняні кулаки з дальністю та пошкодженням 🔥 Вогнем.';

export const fire_snake_teeth_details = fire_snake_teeth_det+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 2)';

export const fire_snake_teeth_details_5 = fire_snake_teeth_det+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 3)';

export const fire_snake_teeth_details_9 = fire_snake_teeth_det+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 4)';

export const fire_snake_teeth_details_13 = fire_snake_teeth_det+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 5)';

export const fire_snake_teeth_details_17 = fire_snake_teeth_det+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 6)';

export const fire_snake_teeth_expanded = 'Коли ви використовуєте дію Атака у свій хід, ви можете витратити 1 слот Чі, і під час атаки з ваших рук і ніг будуть вириватися язики полумʼя. Досяжність для цієї та наступних беззбройних атак поточного ходу збільшується на 10 футів. влучляння таких атак завдає пошкодження Вогнем замість замість Дробильного пошкодження.<br/><br/>'+monk_element_adept;

export const ashing_strike = 'Палаючі долоні';

export const ashing_strike_details = 'Стовп полумʼя з долонь, що спалює все перед собою.';

export const ashing_strike_details_5 = ashing_strike_details+' +1d6 пошкодження за кожний додатковий слот Чі. (максимум 3)';

export const ashing_strike_details_9 = ashing_strike_details+' +1d6 пошкодження за кожний додатковий слот Чі. (максимум 4)';

export const ashing_strike_details_13 = ashing_strike_details+' +1d6 пошкодження за кожний додатковий слот Чі. (максимум 5)';

export const ashing_strike_details_17 = ashing_strike_details+' +1d6 пошкодження за кожний додатковий слот Чі. (максимум 6)';

export const ashing_strike_expanded = 'Зʼєднавши долоні та розкривши пальці, ви випускаєте з них тонкі язики полумʼя. Усі істоти в межах 15-футового конуса повинні пройти Випробування Спритності. Істота отримує пошкодження вогнем 3d6 у разі провалу і половину цього пошкодження у разі успіху. Полумʼя підпалює всі горючі предмети, які ніхто не тримає і не носить.<br/><br/>'+monk_element_adept;

export const four_thunder_fist = 'Хвиля грому';

export const four_thunder_fist_details = 'Гучним звуком гуркоту грому, завдавши пошкодження, відкинути від вас на 10ф всіх найближчих істот та предмети.';

export const four_thunder_fist_details_5 = four_thunder_fist_details+' +1d8 пошкодження за кожний додатковий слот Чі. (максимум 3)';

export const four_thunder_fist_details_9 = four_thunder_fist_details+' +1d8 пошкодження за кожний додатковий слот Чі. (максимум 4)';

export const four_thunder_fist_details_13 = four_thunder_fist_details+' +1d8 пошкодження за кожний додатковий слот Чі. (максимум 5)';

export const four_thunder_fist_details_17 = four_thunder_fist_details+' +1d8 пошкодження за кожний додатковий слот Чі. (максимум 6)';

export const four_thunder_fist_expanded = 'Від вас походить хвиля громової сили. Всі істоти в кубі з довжиною ребра 15 футів, що виходить від вас, повинні зробити спасинок Статури. При провалі істота отримує шкоду звуком 2d8 і штовхається на 10 футів від вас. При успіху істота отримує половину пошкодження і не штовхається.<br/><br/>' +
'Крім того, незакріплені предмети, що опинилися повністю в області ефекту, автоматично штовхаються на 10 футів від вас ефектом закляття, і закляття видає громовий гуркіт, який чути на відстані 300 футів.<br/><br/>'+monk_element_adept;

export const gust_of_wind_monk = 'Порив вітру [Чі]';

export const gust_of_wind_monk_details = 'Випустити порив сильного вітру, який відштовхує всіх в області та зменшує швидкість проти нього удвічі. Бонусную дією можна змінити напрям вітру.';

export const gust_of_wind_monk_expanded = 'Порив сильного вітру довжиною 60 футів і шириною 10 футів виходить від вас у вибраному напрямку, поки закляття активне. Всі істоти, які починають хід у цій лінії, повинні досягти успіху у Випробуванні Сили, інакше їх відштовхне на 15 футів від вас у напрямку руху вітру.<br/><br/>' +  
'Усі істоти в лінії повинні витрачати 2 фути переміщення за кожний 1 фут, на який вони переміщуються у ваш бік.<br/><br/>' +  
'Вітер розсіює гази та випари, а також гасить свічки, смолоскипи та інші незахищені джерела вогню. Захищений вогонь, наприклад, у ліхтарях, вітер змушує тремтіти, і існує 50% шанс, що згаснуть і вони. Поки закляття активне, ви можете у кожному своєму ході Бонусною дією змінювати напрям, у якому дме вітер.<br/><br/>' +  
'Фокус для створення закляття можна замінити на шматочок їжі.';

export const air_fist = 'Повітряний кулак';

export const air_fist_details = 'Стиснутим повітрям відкинути ціль на 20ф, збити з ніг і завдати пошкодження.';

export const air_fist_details_5 = air_fist_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 3)';

export const air_fist_details_9 = air_fist_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 4)';

export const air_fist_details_13 = air_fist_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 5)';

export const air_fist_details_17 = air_fist_details+' +1d10 пошкодження за кожний додатковий слот Чі. (максимум 6)';

export const air_fist_expanded = 'Ви створюєте область стисненого повітря, яка бʼє як могутній кулак. Ви Основною дією витрачаєте 2 слоти Чі і вибираєте істоту в межах 30 футів від себе. Ця істота має пройти Випробування Сили.<br/><br/>' +  
'У випадку провалу істота отримує Дробильне пошкодження 3d10, і ви можете відкинути істоту на 20 футів від себе і збити його з ніг. У разі успішного Випробування істота отримує половину пошкодження, не відкидається і не збивається з ніг.<br/><br/>'+monk_element_adept;

export const hold_person_monk = 'Утримання особи [Чі]';

export const hold_person_monk_details = 'Паралізувати ціль, що провалила Випробування.';

export const hold_person_monk_details_5 = hold_person_monk_details

export const hold_person_monk_details_9 = hold_person_monk_details+' +1 ціль за кожний додатковий слот Чі. (максимум 4)';

export const hold_person_monk_details_13 = hold_person_monk_details+' +1 ціль за кожний додатковий слот Чі. (максимум 5)';

export const hold_person_monk_details_17 = hold_person_monk_details+' +1 ціль за кожний додатковий слот Чі. (максимум 6)';

export const hold_person_monk_expanded = 'Виберіть гуманоїда, якого бачите у межах дистанції. Ціль повинна досягти успіху у Випробуванні Мудрості, інакше стане паралізованою на період дії закляття. У кінці кожного свого ходу ціль може проходити нові Випробування Мудрості. У разі успіху закляття на цій цілі закінчується.<br/><br/>'+monk_element_adept;

export const element_bond = 'Спорідненість зі стихією';

export const element_bond_details = 'Створити один із ефектів:<br/><br/>'+
'• Сніп іскор | вітерець | легкий туман | гудіння каменю та тп.<br/>'+
'• Запалити або згасити свічку, факел або багаття.<br/>'+
'• Остудити чи нагріти до 1 фунта неживої матерії до 1 години.<br/>'+
'• Надати твердої форми воді | повітрю | землі | вогню в кубі рубом 1ф.';

export const element_bond_expanded = 'Ви можете на деякий час взяти під контроль розташовані поблизу в межах 30 футів від вас стихійні сили. Це дозволить вибрати один з таких ефектів:<br/><br/>'
+
'• Створюється нешкідливий миттєвий ефект, повʼязаний з водою, повітрям, землею або вогнем, такий як сніп іскор, подих вітру, потік легкого туману або тихе гудіння каменю.<br/><br/>'+
'• Миттєво загоряється або гаситься свічка, смолоскип або невелике багаття.<br/><br/>'+
'• Остуджується або нагрівається до 1 фунта неживої матерії на час до 1 години.<br/><br/>'+
'• Вода, повітря, земля або вогонь, здатні втиснутись у куб із довжиною ребра 1 фут, приймають на 1 хвилину грубу форму, вибрану вами.';

export const form_river = 'Форма води';

export const form_river_details = 'Перетворити воду на лід будь-якої форми в межах області, або навпаки.';

export const form_river_expanded = 'Ви можете Основною дією витратити 1 слот Чі, щоб вибрати область льоду або води в просторі з довжиною сторони не більше 30 футів, в межах 120 футів від себе. Ви можете перетворити в цій області воду на лід і навпаки, і ви можете надавати льоду в цій області будь-яку форму. Ви можете підвищити або знизити рівень льоду, створити або заповнити поглиблення, звести або зрівняти стіну або створити крижаний стовп. Розмір будь-якої зміни не може перевищувати половини найбільшої сторони області. Наприклад, якщо ви змінюєте простір з довжиною сторони 30 футів, можна створити стовп до 15 футів у висоту, підняти або опустити висоту області до 15 футів, вирити поглиблення до 15 футів, і так далі. Ви не можете замурувати істоту в лід або заподіяти цим впливом іншій істоті пошкодження.';

export const mage_hand_rogue = 'Чарівна рука пройдисвіта';

export const mage_hand_rogue_details = 'Примарна | невидима рука, яку ви можете контролювати Хитрою або Основною дією:<br/><br/>'+
'• Може переміщатися, не може атакувати<br/>'+
'• Може покласти | вийняти предмет із контейнера<br/>'+
'• Може використовувати крадійські інструменти';

export const mage_hand_rogue_details_13 = 'Примарна | невидима рука, яку ви можете контролювати Хитрою або Основною дією:<br/><br/>'+
'• Може переміщатися, не може атакувати<br/>'+
'• Може покласти | вийняти предмет із контейнера<br/>'+
'• Може використовувати крадійські інструменти<br/>'+
'• Може дати <span class="buff">перевагу</span> на влучність по істоті';

export const mage_hand_rogue_expanded = 'У точці, обраній вами в межах дистанції, зʼявляється примарна ширяюча рука. Рука існує, поки закляття активне, або поки ви не відпустите її Основною дією. Рука зникає, якщо опинитсья більш ніж за 30 футів від вас, або якщо ви повторно використовуєте це закляття.<br/><br/>'+
'Ви можете Основною дією контролювати руку. Крім того, ви можете використовувати Бонусну дію, надане вмінням Хитра дія, для керування рукою. Ви можете з її допомогою маніпулювати предметами, відкривати незачинені двері та контейнери, прибирати предмети у відкриті контейнери та діставати їх звідти, або виливати вміст флаконів. При кожному використанні руки можна перемістити її на 30 футів.<br/><br/>'+

'Ви можете покласти один предмет, що утримується рукою, у контейнер, що тримає або носить інша істота. Ви можете дістати предмет із контейнера, що тримає або носить інша істота. Ви можете використовувати крадійскі інструменти для розкриття замків та знешкодження пасток у дистанції цього закляття.<br/><br/>'+

'Рука не може здійснювати атаки, активувати магічні предмети та переносити понад 10 фунтів.<br/><br/>'+

'Ви можете виконати одну з цих дій, не будучи поміченим, якщо успішно здійсните перевірку Спритності рук проти перевірки Уважності істоти.<br/><br/>'+
'На 13 рівні ви отримуєте можливість відволікати ціль своєю Чарівною рукою. У свій хід ви Бонусною дією можете позначити істоту в межах 5 футів від руки, створеної цим закляттям. Якщо ви так робите, то до кінця цього ходу кидаєте атаки по цій істоті з перевагою.';

export const steal_spell = 'Вкрасти закляття';

export const steal_spell_details = 'Нівелювати ефект закляття, спрямованого на вас, і вкрасти можливість створювати це закляття.';

export const steal_spell_expanded = 'Ви отримуєте здатність магічно красти в іншого чаклуна знання про те, як творити закляття. Відразу після створення закляття істотою, ціллю якого є ви, або якщо ви потрапили в область дії цього закляття, ви можете Реакцією змусити істоту пройти Випробування його базової характеристики. Сл дорівнює вашій Складності закляття. Якщо Випробування провалене, ви заперечуєте ефект закляття на собі, і крадете знання цього закляття, якщо воно як мінімум 1 рівня, і ви можете накладати закляття цього рівня (воно не має бути закляттям чарівника).<br/><br/>'+
'Ви знаєте це закляття протягом наступних 8 годин і можете його накладати, використовуючи свої слоти заклять. Істота, у якої ви вкрали закляття, не може її творити протягом 8 годин. Використовуючи це вміння, ви не можете використати його повторно до закінчення тривалого відпочинку.';

export const favored_foe = 'Відмітити противника';

export const favored_foe_details = 'Завдавати додаткове пошкодження конкретної цілі невеликий час.';

export const favored_foe_expanded = 'Коли ви влучаєте атакою по істоті, ви можете закликати сили природи, щоб відмітити істоту і зробити її вашим заклятим противником на 1 хвилину або до того часу, не втратите концентрацію (якби ви концентрувалися на заклятті). Вперше в кожен свій хід, коли ви влучаєте атакою по бажаному противнику і завдаєте йому пошкодження, у тому числі і коли ви відмічаєте його, ви можете додатково завдати 1d4 пошкодження того ж типу.<br/><br/>'+
'Ви можете використовувати це вміння для позначки бажаного ворога кількість разів, що дорівнює вашому бонусу майстерності. Ви відновлюєте всі витрачені використання після тривалого відпочинку. Додаткові пошкодження цього вміння збільшується, коли ви досягаєте певних рівнів у цьому класі: на 6-му рівні до 1d6, на 14-му рівні до 1d8.';

export const tireless = 'Невтомність';

export const tireless_details = 'Додати здоровʼя.';

export const tireless_expanded = 'Основною дією ви можете дати собі кількість тимчасового здоровʼя, що дорівнює 1d8 + модифікатор Мудрості (мінімум 1 здоровʼя). Ви можете використовувати це вміння кількість разів, що дорівнює вашому бонусу майстерності. Ви відновлюєте всі витрачені використання після тривалого відпочинку.';

export const primeval_awareness = 'Пошук істот у місцевості';
export const primeval_awareness_favored = 'Пошук істот в обраній місцевості';

export const primeval_awareness_details = 'Визначити без деталей, чи є в межах дальності аберації, дракони, погань, небожителі, невмерлі, феї та елементали.';

export const primeval_awareness_expanded = 'Ви можете Основною дією витратити один слот мани, щоб зосередитися на пізнанні простору навколо себе. Протягом 1 хвилини за кожний рівень використаного слоту ви можете відчути присутність наступних видів істот у межах 1 милі (або в межах 6 миль, якщо ви перебуваєте в обраній місцевості): аберації, дракони, погань, небожителі, невмерлі, феї та елементали. Це вміння не розкриває розташування та кількість істот.';

export const primal_awareness_details = ' Можна застосувати без мани і додаткового часу на каст 1 раз на день.'

export const primal_awareness_expanded = '<br/><br/><span class="rare-text">* Ви можете один раз застосувати це закляття без використання мани і додаткового часу на каст. Як тільки ви використовуєте закляття таким чином, ви не зможете зробити це повторно до закінчення тривалого відпочинку.</span>'

export const speak_with_animals_ranger = 'Розмова з тваринами [0*]';

export const speak_with_animals_ranger_details = speak_with_animals_details + primal_awareness_details;

export const speak_with_animals_ranger_expanded = speak_with_animals_expanded + primal_awareness_expanded;

export const beast_sense_ranger = 'Чуття звіра [0*]';

export const beast_sense_ranger_details = beast_sense_details + primal_awareness_details;

export const beast_sense_ranger_expanded = beast_sense_expanded + primal_awareness_expanded;

export const commune_with_nature_ranger = 'Спілкування з природою [0*]';

export const commune_with_nature_ranger_details = commune_with_nature_details + primal_awareness_details;

export const commune_with_nature_ranger_expanded = commune_with_nature_expanded + primal_awareness_expanded;

export const extra_attack = 'Додатковий удар';

export const extra_attack_details = '+1 атака в додаток до основної.';

export const extra_attack_expanded = 'Якщо ви в свій хід здійснюєте дію Атака, ви можете здійснити дві атаки замість однієї.';

export const disguise_on_sight = 'Маскування на виду';

export const disguise_on_sight_details = 'Злитися з природним оточенням і отримати бонус Непомітності. Спадає при активності.';

export const disguise_on_sight_expanded = 'Ви можете витратити одну хвилину для створення персонального камуфляжу. У вас повинен бути доступ до свіжих мулу, бруду, рослин, сажі та інших природних матеріалів, за допомогою яких буде створюватися камуфляж. Після того як ви замаскувалися подібним чином, ви можете спробувати сховатися, притиснувшись до твердої поверхні, такої як дерево або стіна, що не поступається вам по висоті та ширині.<br/><br/>'+
'Ви отримуєте бонус +10 до перевірок Непомітності, поки перебуваєте без руху і не робите дій. Після того, як ви перемістилися, здійснили Дію або Реакцію, ви повинні знову маскуватися із самого початку, щоб отримати цю перевагу.';

export const disguise_nature = 'Природна завіса';

export const disguise_nature_details = 'Стати невидимим на декілька секунд.';

export const disguise_nature_expanded = 'Ви звертаєтесь до сил природи, щоб вони ненадовго приховали вас від сторонніх очей. Бонусною дією, ви можете магічно стати невидимим разом зі своїм спорядженням, яке ви носите на собі і тримаєте в руках до початку вашого наступного ходу.<br/><br/>'+
'Ви можете використовувати це вміння кількість разів, що дорівнює вашому бонусу майстерності. Ви відновлюєте всі витрачені використання після тривалого відпочинку.';

export const disappearance = 'Зникнення';

export const disappearance_details = 'Швидка Засідка.';

export const disappearance_expanded = 'Ви можете використовувати в свій хід Засідку Бонусною дією. Крім того, ви не можете бути вистежені немагічним способом, якщо ви не залишите слід навмисно.';

export const enemy_killer = 'Вбивця ворогів';

export const enemy_killer_details = 'Один раз в хід бонусне пошкодження по обраним ворогам | противникам.';

export const enemy_killer_expanded = 'Ви стаєте безпрецедентним мисливцем на своїх ворогів. Один раз у кожному своєму ходу ви можете додати модифікатор Мудрості до кидка атаки або пошкодження по істоті зі списку ваших обраних ворогів або противників. Ви можете використовувати це вміння до або після кидка, але до того, як ефекти кидка набудуть чинності.';

export const horde_breaker = 'Скруха орд';

export const horde_breaker_details = 'Один раз у хід, при атаці, атакувати ще одну істоту поряд з ціллю.';

export const horde_breaker_expanded = 'Один раз у кожний свій хід, коли ви здійснюєте атаку зброєю, ви можете здійснити ще одну атаку тією ж зброєю по іншій істоті, що знаходиться в межах досяжності вашої зброї і в межах 5 футів від початкової цілі.';

export const giant_killer = 'Вбивця велетнів';

export const giant_killer_details = 'Атакувати видиму вами Велику істоту у відповідь.';

export const giant_killer_expanded = 'Якщо Велика або ще більша істота в межах 5 футів влучає або промахується по вас атакою, Ви можете Реакцією атакувати цю істоту відразу після її атаки, за умови, що Ви можете бачити її.';

export const colossus_slayer = 'Вбивця колосів';

export const colossus_slayer_details = 'Один раз у хід завдати додаткове пошкодження при атаці вже пораненої до цього істоти.';

export const colossus_slayer_expanded = 'Ваша завзятість може повалити самих потужних ворогів. Якщо ви влучаєте по істоті атакою зброєю, ця істота отримує додаткове пошкодження 1d8, якщо її здоровʼя вже нижче максимуму. Ви можете заподіяти цю додаткове пошкодження тільки один раз у хід.';

export const whirlwind_attack = 'Вихрова атака';

export const whirlwind_attack_details = 'Ближня атака по всіх ворогах навколо.';

export const whirlwind_attack_expanded = 'Ви можете Основною дією здійснити ближні атаки за будь-якою кількістю істот в межах 5 футів від себе, здійснюючи окремий кидок атаки по кожній цілі.';

export const volley = 'Залп';

export const volley_details = 'Далекобійна атака по всіх ворогах в області.';

export const volley_expanded = 'Ви можете Основною дією вчинити далекобійні атаки по будь-якій кількості видимих вами істот, що знаходяться в межах 10 футів від однієї точки, і що знаходяться в межах дистанції вашої зброї. У вас повинні бути боєприпаси для кожної атаки, як звичайно, і ви повинні зробити окремий кидок атаки для кожної цілі.';

export const stand_against_the_tide = 'Стійка проти течії';

export const stand_against_the_tide_details = 'Перевести ближню атаку, що не влучила по вас, на іншу істоту.';

export const stand_against_the_tide_expanded = 'Якщо ворожа істота промахується по вам ближньою атакою, ви можете Реакцією змусити його повторити цю атаку по іншій істоті (крім неї самої) на ваш вибір.';

export const companion_15 = '<br/>• Закляття на себе поширюються на супутника';

export const companion_7_expanded = '<br/><br/>Починаючи з 7 рівня, якщо у ваш хід звір-супутник не нападає, ви можете Бонусною дією наказати звірові зробити Відхід, Допомога або Ривок у його хід. Крім того, атаки звіра тепер вважаються магічними при визначенні подолання опору та імунітету до немагічних атак та пошкодження.'

export const companion_11_expanded = '<br/><br/>Починаючи з 11 рівня, коли ви наказуєте своєму звірові використовувати дію Атака, він може зробити або дві атаки, або дію Мультіатака, якщо вона її має.'

export const companion_15_expanded = '<br/><br/>Починаючи з 15 рівня, коли ви накладаєте закляття, спрямоване на себе, ви також можете поширити його ефект на звіра-супутника, якщо він знаходиться в межах 30 футів від вас.'

export const rangers_companion = 'Супутник слідопита';

export const rangers_companion_details = 'Приручити звіра до Середнього та небезпекою до 1/4:<br/>'+
'• Бонус до Броні, Влучності, Пошкодження, Випробувань, Навичок<br/>'+
'• Здоровʼя дорівнює початковому або 4× ваш рівень<br/>'+
'• Непомітний рух без штрафу швидкості<br/>'+
'• Ініціатива у бою дорівнює вашій<br/>'+
'• Команди переміщення в бою не потребує дій<br/>'+
'• Команди Основною дією: Атака, Відхід, Допомога, Ривок<br/>'+
'• Без команди звір здійснює Ухилення та Реакції<br/>'+
'• При вашій недоступності діє самостійно';

export const rangers_companion_details_7 = 'Приручити звіра до Середнього та небезпекою до 1/4:<br/>'+
'• Бонус до Броні, Влучності, Пошкодження, Випробувань, Навичок<br/>'+
'• Здоровʼя дорівнює початковому або 4× ваш рівень<br/>'+
'• Непомітний рух без штрафу швидкості<br/>'+
'• Ініціатива у бою дорівнює вашій<br/>'+
'• Команди переміщення в бою не потребує дій<br/>'+
'• Команди Бонусною дією: Відхід, Допомога, Ривок<br/>'+
'• Команди Основною дією: Магічна атака<br/>'+
'• Без команди звір здійснює Ухилення та Реакції<br/>'+
'• При вашій недоступності діє самостійно';

export const rangers_companion_details_11 = 'Приручити звіра до Середнього та небезпекою до 1/4:<br/>'+
'• Бонус до Броні, Влучності, Пошкодження, Випробувань, Навичок<br/>'+
'• Здоровʼя дорівнює початковому або 4× ваш рівень<br/>'+
'• Непомітний рух без штрафу швидкості<br/>'+
'• Ініціатива у бою дорівнює вашій<br/>'+
'• Команди переміщення в бою не потребує дій<br/>'+
'• Команди Бонусною дією: Відхід, Допомога, Ривок<br/>'+
'• Команди Основною дією: Магічна мультиатака<br/>'+
'• Без команди звір здійснює Ухилення та Реакції<br/>'+
'• При вашій недоступності діє самостійно';

export const rangers_companion_details_15 = rangers_companion_details_11 + companion_15;

export const rangers_companion_expanded = 'Ви отримуєте звіра-супутника, який супроводжує вас під час пригод і навчений боротися разом з вами. Виберіть звіра, з розміром не більшим за Середній, небезпека якого дорівнює 1/4 або нижче. Додайте свій бонус майстерності до Броні, кидків атаки та пошкодження звіра, а також до Випробувань і Навичок, якими він володіє. Максимум його здоровʼя дорівнює його звичайному максимуму або вашому чотирикратному рівню слідопиту, залежно від того, що вище. Подорожуючи обраною місцевістю тільки вдвох зі звіром, ви можете пересуватися непомітно в нормальному темпі. Як будь-які інші істоти, звір може витрачати Реген під час короткого відпочинку.<br/><br/>'+
'Звір підкоряється вашим командам так добре, як тільки може. Він здійснює хід із вашою ініціативою. Під час свого ходу ви можете усно командувати звіром, куди йому рухатися (для цього не потрібно ніяких дій від вас). Ви можете Основною дією дати усну команду звірові здійснити Атаку, Відхід, Допомогу, Рьівок. Якщо ви не даєте команду, звір здійснює дію Ухилення.<br/><br/>'+
'Якщо у вас є вміння Додаткова атака, ви можете самі здійснити одну атаку зброєю, коли наказуєте звірові зробити дію Атака. Якщо ви недієздатні або відсутні, звір самостійно робить дії, зосереджуючись на захисті вас і себе. Для того, щоб він здійснював Реакції, ваша команда не потрібна, у тому числі і при здійсненні провокованої атаки.<br/><br/>'+
'Якщо звір помирає, ви можете отримати іншого, витративши 8 годин на звʼязування себе зі звіром, не ворожим до вас, того ж виду, або іншого.'+companion_7_expanded+companion_11_expanded+companion_15_expanded

export const primal_companion = 'Первісний cупутник';

export const primal_companion_details = 'Викликати звіра, повʼязаного зі стихією:<br/>'+
'• Ініціатива у бою дорівнює вашій<br/>'+
'• Команди переміщення в бою не потребує дій<br/>'+
'• Команди Основною дією: Атака, Відхід, Допомога, Ривок<br/>'+
'• Без команди звір здійснює Ухилення та Реакції<br/>'+
'• При вашій недоступності діє самостійно';

export const primal_companion_details_7 = 'Викликати звіра, повʼязаного зі стихією:<br/>'+
'• Ініціатива у бою дорівнює вашій<br/>'+
'• Команди переміщення в бою не потребує дій<br/>'+
'• Команди Бонусною дією: Відхід, Допомога, Ривок<br/>'+
'• Команди Основною дією: Магічна атака<br/>'+
'• Без команди звір здійснює Ухилення та Реакції<br/>'+
'• При вашій недоступності діє самостійно';

export const primal_companion_details_11 = 'Викликати звіра, повʼязаного зі стихією:<br/>'+
'• Ініціатива у бою дорівнює вашій<br/>'+
'• Команди переміщення в бою не потребує дій<br/>'+
'• Команди Бонусною дією: Відхід, Допомога, Ривок<br/>'+
'• Команди Основною дією: Магічна мультиатака<br/>'+
'• Без команди звір здійснює Ухилення та Реакції<br/>'+
'• При вашій недоступності діє самостійно';

export const primal_companion_details_15 = primal_companion_details_11 + companion_15;

export const primal_companion_expanded = 'Ви магічно закликаєте первісного звіра, який бере силу з Вашого звʼязку з природою. Звір дружній до Вас та Ваших супутників та підкоряється Вашим командам. Виберіть його статблок: земний звір, морський звір чи небесний звір. Ви також вибираєте вид тварини, вибираючи із відповідних для обраного статблока. Який би статблок Ви не обрали, звір носить первісну мітку, що вказує на його містичне походження.<br/><br/>'+

'У бою звір діє на Вашому ході. Він може рухатися і використовувати реакції самостійно, але єдина доступна йому дія — Ухилення, якщо Ви не витратите Бонусну дію на своєму ходу, наказуючи йому вчинити одну з дій з його стаблока або будь-яку іншу дію. Коли здійснюєте дія Атака, Ви також можете витратити одну з Ваших атак, наказуючи звірові зробити дія Атака. Якщо Ви недієздатні, звір може вчиняти одну будь-яку дію, а не тільки Ухилення.<br/><br/>'+

'Протягом години після смерті звіра Ви можете діяти торкнутися його і витратити слот мани 1 рівня або вище. За хвилину звір повертається до життя з повними здоровʼям. Коли Ви завершуєте довгий відпочинок, Ви можете покликати іншого первісного звіра. Новий звір зʼявляється у вільному просторі в межах 5 футів від Вас та Ви вибираєте його статблок та зовнішність. Якщо у Вас є інший первісний звір, покликаний цією здатністю, він зникає, коли зʼявляється новий. Звір також зникає, якщо ви вмираєте.'+companion_7_expanded+companion_11_expanded+companion_15_expanded

export const sorcery_to_mana = 'Чародійство у Ману';
export const sorcery_to_mana_details = 'Перевести Чародійства у слот мани.<br/>'+
'2× Чародійство → Мана [1]<br/>'+
'3× Чародійство → Мана [2]<br/>'+
'5× Чародійство → Мана [3]<br/>'+
'6× Чародійство → Мана [4]<br/>'+
'7× Чародійство → Мана [5]';

export const sorcery_to_mana_expanded = 'У свій хід ви можете Бонусною дією перетворити одиниці Чародійства, що залишилися, на додатковий слот заклять. Вартість створення слоту:<br/><br/>'+
'2× Чародійство → Мана [1]<br/>'+
'3× Чародійство → Мана [2]<br/>'+
'5× Чародійство → Мана [3]<br/>'+
'6× Чародійство → Мана [4]<br/>'+
'7× Чародійство → Мана [5]<br/><br/>'+
'Ви не можете створити слот з рівнем вище [5]. Створений слот заклять зникає у кінці тривалого відпочинку.';

export const mana_to_sorcery = 'Мана у Чародійство'

export const mana_to_sorcery_details = 'Перевести слоти мани у Чародійство.';

export const mana_to_sorcery_expanded = 'Ви можете у свій хід Бонусною дією перетворити один слот заклять на одиниці Чародійства, кількість яких дорівнює рівню слоту. Вартість створення Чародійств:<br/><br/>'+
'Мана [1] → 1× Чародійство<br/>'+
'Мана [2] → 2× Чародійство<br/>'+
'Мана [3] → 3× Чародійство<br/>'+
'Мана [4] → 4× Чародійство<br/>'+
'Мана [5] → 5× Чародійство<br/><br/>'+
'Ви не можете використовувати слот з рівнем вище 5. Створені одиниці Чародійства зникають у кінці тривалого відпочинку.';

export const metamagic_careful = 'Зробити закляття акуратнішим'

export const metamagic_careful_details = 'Допомогти союзникам пройти Випробування проти вашого закляття.';

export const metamagic_careful_expanded = 'Коли ви накладаєте закляття, яке змушує інших істот пройти Випробування, ви можете захистити деяких із них від магічного впливу. Для цього ви витрачаєте 1 одиницю Чародійства та вибираєте істот у кількості, що дорівнює вашому модифікатору Харизми (мінімум одна істота). Зазначені істоти автоматично досягають успіху у Випробуванні від цього закляття.';

export const metamagic_distant = 'Зробити закляття далекобійнішим'

export const metamagic_distant_details = 'Подвоїти дальність вашого закляття.';

export const metamagic_distant_expanded = 'При накладанні закляття, дистанція якого 5 футів і більше, можна витратити 1 одиницю Чародійства, щоб подвоїти цю відстань. При накладанні закляття з дистанцією «Дотик цілі» ви можете витратити 1 одиницю Чародійства, щоб збільшити цю відстань до 30 футів.';

export const metamagic_empowered = 'Посилити закляття'

export const metamagic_empowered_details = 'Перекинути куби пошкодження вашого закляття. Працює разом з іншою Метамагією.';

export const metamagic_empowered_expanded = 'При здійсненні кидка пошкодження від закляття ви можете витратити 1 одиницю Чародійства, щоб перекинути кілька кубів пошкодження (кількість дорівнює вашому модифікатору Харизми, мінімум один). Ви повинні використати нове значення. Ви можете скористатися варіантом «Посилене закляття», навіть якщо ви вже використовували інший варіант метамагії для цього закляття.';

export const metamagic_extended = 'Продовжити закляття'

export const metamagic_extended_details = 'Подвоїти тривалість вашого закляття.';

export const metamagic_extended_expanded = 'При накладанні закляття з тривалістю 1 хвилина або більше ви можете витратити 1 одиницю Чародійства, щоб подвоїти цей час, аж до максимального в 24 годині.';

export const metamagic_heightened = 'Зробити закляття непереборним'

export const metamagic_heightened_details = 'Перешкода однієї цілі на Випробування від вашого закляття.';

export const metamagic_heightened_expanded = 'Коли ви накладаєте закляття, яке змушує істоту пройти Випробування для захисту від його ефектів, ви можете витратити 3 одиниці Чародійства, щоб одна з цілей закляття пройшла перше Випробування від цього закляття з перешкодою.';

export const metamagic_quickened = 'Прискорити закляття'

export const metamagic_quickened_details = 'Зменшити каст вашого закляття.';

export const metamagic_quickened_expanded = 'Якщо ви використовуєте закляття з часом накладання «Основна дія», ви можете витратити 2 одиниці Чародійства, щоб створити це закляття Бонусною дією.';

export const metamagic_subtle = 'Зробити закляття невловимим'

export const metamagic_subtle_details = 'Зробити ваше закляття без жестів та слів.';

export const metamagic_subtle_expanded = 'Під час використання закляття ви можете витратити 1 одиницю Чародійства, щоб створити його без слів та жестів.';

export const metamagic_transmuted = 'Перетворити закляття'

export const metamagic_transmuted_details = 'Змінити тип пошкодження вашого закляття.';

export const metamagic_transmuted_expanded = 'Коли ви накладаєте закляття, яке завдає пошкодження із зазначеним нижче типом, ви можете витратити 1 одиницю Чародійства, щоб змінити цей тип пошкодження на: Кислота, Холод, Вогонь, Струм, Отрута або Звук.';

export const metamagic_twinned = 'Дублювати закляття'

export const metamagic_twinned_details = 'Додати ще одну ціль вашому закляттю. Вартує одиниць Чародійства, що дорівнює рівню закляття.';

export const metamagic_twinned_expanded = 'Якщо ви використовуєте закляття, що націлюється тільки на одну істоту і не має дальність «на себе», ви можете витратити кількість одиниць Чародійства, рівну рівню закляття (1 для [0]), щоб націлитись на другу істоту-ціль в межах дистанції цього закляття.';

export const metamagic_seeking = 'Прицілити закляття'

export const metamagic_seeking_details = 'Перекинути кидок атаки вашого закляття. Працює разом із іншою Метамагією.';

export const metamagic_seeking_expanded = 'Якщо ви зробили кидок атаки для закляття і промахнулися, ви можете витратити 2 одиниці Чародійства, щоб перекинути d20, і ви повинні використовувати новий результат. Ви можете використовувати цю Метамагію, навіть якщо ви вже використовували варіант Метамагії під час вимовлення закляття.';

export const magical_direction = 'Магічне спрямування'

export const magical_direction_details = 'Перекинути перевірку здібностей.';

export const magical_direction_expanded = 'Ви можете підключитися до свого внутрішнього джерела магії, щоб спробувати створити успіх із провалу. Коли ви робите невдалу перевірку здібності, ви можете витратити 1 одиницю Чародійства, щоб перекинути d20, і ви повинні використовувати результат цього кидка, потенційно перетворюючи провал на успіх.';

export const elemental_affinity_fire = 'Спорідненість з 🔥 Вогнем'

export const elemental_affinity_fire_details = 'Посилити закляття з пошкодженням 🔥 Вогнем.';

export const elemental_affinity_fire_expanded = 'Коли ви накладаєте закляття, що завдає пошкодження Вогнем, повʼязаного з вашим драконовим предком, ви додаєте модифікатор Харизми до одного кидка пошкодження цього закляття.';

export const elemental_affinity_cold = 'Спорідненість з ❄️ Холода'

export const elemental_affinity_cold_details = 'Посилити закляття з пошкодженням ❄️ Холодом.';

export const elemental_affinity_cold_expanded = 'Коли ви накладаєте закляття, що завдає пошкодження Холодом, повʼязаного з вашим драконовим предком, ви додаєте модифікатор Харизми до одного кидка пошкодження цього закляття.';

export const elemental_affinity_electricity = 'Спорідненість зі ⚡ Струмом'

export const elemental_affinity_electricity_details = 'Посилити закляття з пошкодженням ⚡ Струмом.';

export const elemental_affinity_electricity_expanded = 'Коли ви накладаєте закляття, що завдає пошкодження Струмом, повʼязаного з вашим драконовим предком, ви додаєте модифікатор Харизми до одного кидка пошкодження цього закляття.';

export const elemental_affinity_acid = 'Спорідненість з ⚗️ Кислотою.'

export const elemental_affinity_acid_details = 'Посилити закляття з пошкодженням ⚗️ Кислотою.';

export const elemental_affinity_acid_expanded = 'Коли ви накладаєте закляття, що завдає пошкодження Кислотою, повʼязаного з вашим драконовим предком, ви додаєте модифікатор Харизми до одного кидка пошкодження цього закляття.';

export const elemental_affinity_poison = 'Спорідненість з 🧪 Отрутою'

export const elemental_affinity_poison_details = 'Посилити закляття з пошкодженням 🧪 Отрутою.';

export const elemental_affinity_poison_expanded = 'Коли ви накладаєте закляття, що завдає пошкодження Отрутою, повʼязаного з вашим драконовим предком, ви додаєте модифікатор Харизми до одного кидка пошкодження цього закляття.';

export const dragon_resistanse_fire = 'Стійкість до 🔥 Вогню'

export const dragon_resistanse_fire_details = 'Отримати <span class="buff">стійкіть</span> до пошкодження 🔥 Вогнем.';

export const dragon_resistanse_fire_expanded = 'Ви можете витратити 1 одиницю Чародійства, щоб отримати стійкість до пошкодження Вогнем на 1 годину.';

export const dragon_resistanse_cold = 'Стійкість до ❄️ Холоду'

export const dragon_resistanse_cold_details = 'Отримати <span class="buff">стійкіть</span> до пошкодження ❄️ Холодом.';

export const dragon_resistanse_cold_expanded = 'Ви можете витратити 1 одиницю Чародійства, щоб отримати стійкість до пошкодження Холодом на 1 годину.';

export const dragon_resistanse_electricity = 'Стійкість до ⚡ Струму'

export const dragon_resistanse_electricity_details = 'Отримати <span class="buff">стійкіть</span> до пошкодження ⚡ Струмом.';

export const dragon_resistanse_electricity_expanded = 'Ви можете витратити 1 одиницю Чародійства, щоб отримати стійкість до пошкодження Струмом на 1 годину.';

export const dragon_resistanse_acid = 'Стійкість до ⚗️ Кислоти'

export const dragon_resistanse_acid_details = 'Отримати <span class="buff">стійкіть</span> до пошкодження ⚗️ Кислотою.';

export const dragon_resistanse_acid_expanded = 'Ви можете витратити 1 одиницю Чародійства, щоб отримати стійкість до пошкодження Кислотою на 1 годину.';

export const dragon_resistanse_poison = 'Стійкість до 🧪 Отрути'

export const dragon_resistanse_poison_details = 'Отримати <span class="buff">стійкіть</span> до пошкодження 🧪 Отрутою.';

export const dragon_resistanse_poison_expanded = 'Ви можете витратити 1 одиницю Чародійства, щоб отримати стійкість до пошкодження Отрутою на 1 годину.';

export const dragon_wings = 'Крила дракона'

export const dragon_wings_details = 'Створити крила і отримати швидкість польоту, що дорівнює швидкості пішки.';

export const dragon_wings_expanded = 'Ви отримуєте здатність розправити драконові крила у себе за спиною, отримуючи при цьому швидкість польоту, що дорівнює вашій поточній швидкості. Ви можете створити ці крила Бонусною дією у свій хід. Крила існують, поки ви не розвієте їх Бонусною дією у свій хід. Ви не можете розправити свої крила, носячи обладунки, якщо, звичайно, броня не виготовлена спеціально для цього. Одяг, також не пристосований під крила, може бути знищений, коли ви розправляєте крила.';

export const dragon_presence = 'Вигляд дракона'

export const dragon_presence_details = '😍 Зачарувати | 😱 Перелякати усіх в межах дальності.';

export const dragon_presence_expanded = 'Ви можете викликати жахливий образ свого предка дракона, жахаючи ворогів. Ви можете діяти витратити 5 одиниць Чародійства, щоб оточити себе аурою страху або трепету (на ваш вибір), радіусом 60 футів. Протягом 1 хвилини, або поки ви не втратите концентрацію (якби ви концентрувалися на заклятті), всі ворожі істоти, що починають хід у цій аурі, повинні досягти успіху у Випробуванні Мудрості, інакше вони стануть Зачарованими (якщо ви обрали трепет) або Переляканими ( якщо ви обрали страх) до закінчення дії аури. Істота, що досягла успіху у Випробуванні, отримує імунітет до вашої аури на 24 години.';

export const wild_magic_surge = 'Потік Хаоса'

export const wild_magic_surge_details = 'Отримати <span class="buff">перевагу</span> на кидок d20. Наступні використання заклять можуть викликати випадковий магічний ефект.';

export const wild_magic_surge_expanded = 'Ви можете маніпулювати силами випадку та хаосу, щоб отримати перевагу для одного кидка атаки, перевірки характеристики або Випробування. Зробивши це, ви повинні зробити тривалий відпочинок, перш ніж ви зможете використати це вміння ще раз. У будь-який момент до того, як ви відновите це вміння, відразу після використання вами закляття чарівника як мінімум 1 рівня, Майстер може попросити вас кинути куб на ефект від Хвилі дикої магії. Після цього ви відновлюєте використання цього вміння.';

export const bend_luck = 'Підкорити вдачу'

export const bend_luck_details = 'Вплинути на кидок d20 іншої видимої істоти.';

export const bend_luck_expanded = 'Ви маєте можливість міняти долю за допомогою дикої магії. Коли інша істота, видима вам, здійснює кидок атаки, перевірку характеристики чи Випробування, ви можете Реакцією витратити 2 одиниці Чародійства та кинути 1d4. Результат кидка стане бонусом або штрафом (на ваш вибір) кидка істоти. Ви можете зробити це після кидка істоти, але перш ніж почнуть діяти ефекти від кидка.';

export const countercharm = 'Контрзачарування'

export const countercharm_details = 'Отримати <span class="buff">перевагу</span> на Випробування проти 😍 Зачарування та 😱 Переляку для себе і всіх союзників в межах дальності.';

export const countercharm_expanded = 'Ви отримуєте можливість використовувати звуки або слова сильні для руйнування ефектів, що впливають на розум. Ви можете Основною дією почати виконання, яке триватиме до кінця вашого наступного ходу. Протягом цього часу ви та всі дружні істоти в межах 30 футів від вас здійснюють Випробування від Переляку та Зачарування з перевагою. Щоб отримати цю перевагу, істоти повинні чути вас. Виконання закінчується передчасно, якщо ви опиняєтеся недієздатними, втрачаєте здатність говорити або припиняєте виконання добровільно (не вимагає дії).';

export const bonus_attack = 'Бойова магія';

export const bonus_attack_details = 'Бонусна атака при використанні закляття Основною дією.';

export const bonus_attack_expanded = 'Ви навчилися сплітати використання заклять і зброї в одну гармонійну дію. Якщо ви Основною дією накладаєте закляття, ви можете Бонусною дією зробити одну атаку зброєю.';

export const peerless_skill = 'Незрівнянні навички';

export const peerless_skill_details = 'Використати своє Натхнення для власної перевірки характеристики.';

export const peerless_skill_expanded = 'Якщо ви здійснюєте перевірку характеристики, ви можете кинути куб Натхнення і додати результат до перевірки. Ви можете прийняти рішення про використання цієї здатності після кидка перевірки, але до того, як Майстер оголосить результат цієї перевірки.';

export const extra_attack_fighter = 'Додатковий удар';

export const extra_attack_fighter_details = '+1 атака на додаток до основної.';

export const extra_attack_fighter_details_11 = '+2 атаки на додаток до основної.';

export const extra_attack_fighter_details_20 = '+3 атаки на додаток до основної.';

export const extra_attack_fighter_expanded = 'Якщо ви в свій хід здійснюєте атаку, ви можете зробити дві атаки замість однієї. Кількість атак збільшується до трьох на 11 рівні цього класу, і до чотирьох на 20 рівні.';

export const indomity = 'Витримка';

export const indomity_details = 'Перекинути Випробування';

export const indomity_expanded = 'Ви можете перекинути провалене Випробування, і повинні використовувати новий результат. Після цього Ви можете повторно використовувати це вміння тільки після завершення тривалого відпочинку. Ви можете використовувати це вміння двічі між періодами тривалого відпочинку після досягнення 13 рівня, і тричі після досягнення 17 рівня.';

export const know_your_enemy = 'Пізнати ворога';

export const know_your_enemy_details = 'Зʼясувати 2 деталі про ворога у порівнянні з вами.';

export const know_your_enemy_expanded = 'Якщо ви витратите як мінімум 1 хвилину, розглядаючи або по-іншому взаємодіючи з істотою поза бою, ви можете дізнатися деяку інформацію про його здібності в порівнянні з вашими. Майстер повідомить вам, чи дорівнює, перевершує або поступається істота вам у двох характеристиках на ваш вибір з перерахованих:<br/><br/>'+

'• Сила<br/>'+
'• Спритність<br/>'+
'• Статура<br/>'+
'• Броня<br/>'+
'• Поточне здоровʼя<br/>'+
'• Рівень (якщо є)';

export const bonus_attack_fighter = 'Бойова магія';

export const bonus_attack_fighter_details = 'Бонусна атака при використанні закляття [0] Основною дією.';

export const bonus_attack_fighter_details_18 = 'Бонусна атака при використанні закляття Основною дією.';

export const bonus_attack_fighter_expanded = 'Якщо ви Основною дією накладаєте закляття [0], ви можете Бонусною дією зробити одну атаку зброєю. Починаючи з 18 рівня, якщо ви Основною дією накладаєте закляття, ви можете Бонусною дією зробити одну атаку зброєю.';

export const arcane_charge = 'Чарівний ривок';

export const arcane_charge_details = 'Телепоруватися в межах дистанції під час використання Сплеску дій.';

export const arcane_charge_expanded = 'Ви отримуєте можливість телепортуватися на 30 футів у вільний простір, який ви бачите, коли використовуєте Сплеск дій. Ви можете телепортуватися до або після додаткової дії.';

export const survivor = 'Живучість';

export const survivor_details = 'Зцілення на початку кожного ходу.';

export const survivor_expanded = 'Ви досягаєте вершин стійкості в бою. На початку кожного свого ходу ви відновлюєте кількість здоровʼя, що дорівнює 5 + ваш модифікатор Статури, якщо кількість вашого здоровʼя менше половини від максимуму. Ця здатність не працює, якщо у вас 0 здоровʼя.';

export const aura_of_protection = 'Аура захисту';

export const aura_of_protection_details = 'Бонус до Випробувань собі та союзникам у межах дистанції.';

export const aura_of_protection_expanded = 'Якщо ви або дружня істота в межах 10 футів від вас повинні пройти Випробування, ця істота отримує бонус до Випробування, що дорівнює модифікатору вашої Харизмі (мінімальний бонус +1). Ви повинні перебувати у свідомості, щоб надавати цей бонус.<br/><br/>'+

'На 18 рівні дистанція цієї аурії збільшується до 30 футів.';

export const aura_of_courage = 'Аура відваги';

export const aura_of_courage_details = 'Імунітет до 😱 Переляку для вас та союзників в межах дистанції.';

export const aura_of_courage_expanded = 'Ви і дружні істоти в межах 10 футів від вас не можете бути Перелякані, поки ви перебуваєте в свідомості.<br/><br/>'+

'На 18 рівні дистанція цієї аурії збільшується до 30 футів.';

export const paladin_divine_smite_free = 'Додаткова божественна кара';

export const paladin_divine_smite_free_details = 'Додаткове пошкодження при влучанні кожної ближньої атаки.';

export const paladin_divine_smite_free_expanded = 'Ви сповнюєтесь праведною потужністю, що дає всім вашим атакам ближньою зброєю божественну силу. Щоразу, коли ви влучаєте по істоті ближньою зброєю, ця істота отримує додаткове пошкодження Світлом 1d8.';

export const cleansing_touch = 'Чистий дотик';

export const cleansing_touch_details = 'Зняти ефект закляття собі чи союзнику.';

export const cleansing_touch_expanded = 'Ви можете Основною дією закінчити дію закляття на собі або на одній згодній істоті, до якої ви доторкаєтеся. Ви можете використати це вміння кількість разів, що дорівнює вашому модифікатору Харизмі (мінімум 1). Ви відновлюєте можливість використання після тривалого відпочинку.';

export const aura_of_devotion = 'Аура відданості';

export const aura_of_devotion_details = 'Імунітет до 😍 Зачарування для вас та союзників в межах дистанції.';

export const aura_of_devotion_expanded = 'Ви і дружні вам істоти в межах 10 футів від вас не можуть бути Зачаровані, поки ви знаходитесь в свідомості.<br/><br/>'+
'На 18 рівні дистанція цієї аурії збільшується до 30 футів.';

export const protection_from_evil_and_good_paladin = 'Постійний захист від добра та зла';

export const protection_from_evil_and_good_paladin_details = 'Аберації, погань, небожителі, невмерлі, феї чи елементали атакують вас з перешкодою, і не можуть Перелякати, Зачарувати чи зробити Одержимим.';

export const protection_from_evil_and_good_paladin_expanded = 'Ви завжди знаходитеся під ефектом закляття Захист від зла та добра.<br/><br/>' + 
'Поки закляття активне, ви захищені від певного виду істот: аберації, погань, небожителі, невмерлі, феї чи елементали.<br/><br/>' + 
'Захист надає кілька переваг. Істоти цих видів здійснюють із перешкодою кидки атаки по вас. Ви також не можете бути Зачаровані, Перелякані і Одержимі ними. Якщо ви вже Зачаровані, Перелякані або Одержимі істотою такого виду, ви робите наступні Випробування від таких ефектів з перевагою.';

export const holy_nimbus = 'Святий німб';

export const holy_nimbus_details = 'Випромінювати сонячне світло і завдавати ним пошкодження. Отримати <span class="buff">перевагу</span> на Випробування від заклять породжень та невмерлих.';

export const holy_nimbus_expanded = 'Ви можете Основною дією створити ауру сонячного світла. Протягом 1 хвилини від вас виходить яскраве світло в радіусі 30 футів, і тьмяне світло в межах ще 30 футів. Щоразу, коли ворожа істота починає хід у цьому яскравому світлі, вона отримує пошкодження Світлом 10.<br/><br/>' + 
'Крім того, протягом цього часу ви робите з перевагою Випробування від заклять, створених поганню і невмерлими.<br/><br/>' + 
'Використавши це вміння, ви не можете використовувати його повторно, поки не закінчите тривалий відпочинок.';

export const aura_of_warding = 'Аура догляду';

export const aura_of_warding_details = '<span class="buff">Стійкість</span> до пошкодження від заклять для вас і союзників у межах дистанції.';

export const aura_of_warding_expanded = 'Давня магія так сильно вас пропитує, що утворює містичний захист. Ви і дружні істоти в межах 10 футів від вас отримуєте стійкість до пошкодження від заклять.<br/><br/>'+
'На 18 рівні дистанція цієї аурії збільшується до 30 футів.';

export const undying_sentinel = 'Невмирущий вартовий';

export const undying_sentinel_details = 'Коли здоровʼя опускається до 0 і ви при цьому не вбити, воно стає = 1.';

export const undying_sentinel_expanded = 'Якщо ваше здоровʼя опускається до 0, але ви не були вбиті, ви можете вирішити, щоб ви замість цього залишилися з 1 здоровʼя.<br/><br/>'+
'Використавши це вміння, ви не можете використовувати його повторно, поки не закінчите тривалий відпочинок.';

export const elder_champion = 'Древній чемпіон';

export const elder_champion_details = 'Перевтілитися у Древнього та отримати:<br/>'+
'• Зцілення на початку кожного ходу<br/>'+
'• Прискорене накладання заклять<br/>'+
'• <span class="buff">Перешкоди</span> на Випробування проти ваших заклять и Божественого каналу, в межах дальності';

export const elder_champion_expanded = 'Ви можете набувати форми древньої сили природи, вибравши її зовнішній вигляд. Наприклад, ваша шкіра може позеленіти або перетворитися на кору, а ваші волосся можуть стати листям або мохом, або у вас можуть вирости роги або левова грива.<br/><br/>'+
'Ви можете Основною дією піддатися цій трансформації. На 1 хвилину ви отримуєте наступні переваги:<br/><br/>'+
'• На початку кожного свого ходу ви відновлюєте 10 здоровʼя.<br/><br/>'+
'• Щоразу, коли ви накладаєте закляття паладина, яке накладається за Основну дію, ви можете замість цього створити його Бонусною дією.<br/><br/>'+
'• Ворожі істоти в межах 10 футів від вас роблять з перешкодою Випробування від ваших заклять паладина та варіантів Божественного каналу.<br/><br/>'+
'Використавши це вміння, ви зможете використати його повторно тільки після закінчення тривалого відпочинку.';

export const relentless_avenger = 'Нещадний месник';

export const relentless_avenger_details = 'При влучанні провокованою атакою переміститися на половину швидкості.';

export const relentless_avenger_expanded = 'Ваша надприродна увага допомагає вам запобігти втечі ворога. Якщо ви влучаєте по істоті провокованою атакою, ви можете частиною цієї Реакції переміститися на відстань, що дорівнює половині вашої швидкості. Цей рух не провокує атаки.';

export const soul_of_vengeance = 'Душа помсти';

export const soul_of_vengeance_details = 'Швидка ближня атака по істоті під Обітницею ворожнечі.';

export const soul_of_vengeance_expanded = 'Суть вашої Обітниці ворожнечі дає вам велику владу над ворогом. Коли істота, яка перебуває під дією вашої Обітниці ворожнечі, здійснює атаку, ви можете Реакцією здійснити атаку ближньою зброєю по цій істоті, якщо вона знаходиться в межах досяжності.';

export const avenging_angel = 'Ангел помсти';

export const avenging_angel_details = 'Перевтілитися у ангела помсти, що випромінює ауру 😱 Переляку.';

export const avenging_angel_expanded = 'На 20 рівні ви можете прийняти вигляд ангельського месника. Ви Основною дією трансформуєтеся і на 1 годину ви отримуєте наступні переваги:<br/><br/>'+
'• З вашої спини виростають крила, що дають вам швидкість польоту 60 футів.<br/><br/>'+
'• Ви випромінюєте ауру загрози в радіусі 30 футів. Коли ворог вперше входить в ауру або починає в ній свій хід під час битви, він повинен досягти успіху у Випробуванні Мудрості, інакше він стане Переляканим протягом 1 хвилини, або поки не отримає пошкодження. Кидки атаки по цій переляканій істоті відбуваються з перевагою.<br/><br/>'+
'Використавши це вміння, ви можете використати його повторно тільки після закінчення тривалого відпочинку.';

export const athletic_jump = 'Атлетичий стрибок';

export const athletic_jump_details = 'Бонус до стрибка в довжину з розбігу.';

export const athletic_jump_expanded = 'Якщо ви робите стрибок у довжину з розбігу, дальність стрибка збільшується на кількість футів, що дорівнює модифікатору Сили.';

export const quick_dash = 'Швидкий ривок';

export const quick_dash_details = 'При Люті і без важких обладунків, можливість зробити ривок Ривок Бонусною дією.';

export const quick_dash_expanded = 'Коли ви перебуваєте в стані Люті і не носите важких обладунків, у свій хід ви можете здійснювати Ривок Бонусною дією. Дух орла перетворює вас на хижака, що з легкістю носиться по полю бою.';

export const safe_movement = 'Безпечне переміщення';

export const safe_movement_details = 'Телепортуватися у межах дальності. Відновлюється також від створення закляття Виклику [1+].';

export const safe_movement_expanded = 'Ви можете телепортуватися на 30 футів у вільний простір, який можете бачити. Використовуючи це вміння один раз, ви не можете використовувати його знову до закінчення тривалого відпочинку або поки не створите закляття школи Виклику 1 рівня або вище.';

export const swap = 'Своп';

export const swap_details = 'Помінятися місцями з союзником в межах дальності. Відновлюється також від створення закляття Виклику [1+].';

export const swap_expanded = 'Ви можете вибрати простір у межах діапазону, зайнятий істотою Маленького або Середнього розміру. Якщо ця істота згодна, ви обидва телепортуєтесь, міняючись місцями. Використовуючи це вміння один раз, ви не можете використовувати його знову до закінчення тривалого відпочинку або доки не створите закляття школи виклику 1 рівня або вище.';

export const transform_illusion = 'Змінити ілюзію';

export const transform_illusion_details = 'Змінити характер створеної ілюзії.';

export const transform_illusion_expanded = 'Якщо ви створили закляття школи Ілюзії тривалістю щонайменше 1 хвилина, ви можете Основною дією змінити характер цієї ілюзії (використовуючи звичайні обмеження для цієї ілюзії), за умови, що можете бачити цю ілюзію.';

export const self_illusion = 'Власна ілюзія';

export const self_illusion_details = 'Підставити копію себе під атаку по вас.';

export const self_illusion_expanded = 'Ви можете інстинктивно створити ілюзорну копію себе у відповідь на раптову небезпеку. Якщо істота робить по вас кидок атаки, ви можете Реакцією створити ілюзорну копію між атакуючим та собою. Атака автоматично промахується на вас і знищує ілюзію. Після використання цього вміння ви повинні завершити короткий або тривалий відпочинок, перш ніж зможете скористатися ним знову.';

export const illusory_reality = 'Ілюзорна реальність';

export const illusory_reality_details = 'Зробити ілюзію чи її частину реальною. Ця частина не може завдавати пошкодження.';

export const illusory_reality_expanded = 'Ви осягли секрети плетіння тіньових заклять, що робить ваші ілюзії наполовину реальними. Коли ви накладаєте закляття Ілюзії 1 рівня або вище, ви можете вибрати будь-який неживий і немагічний обʼєкт, який є частиною цієї ілюзії, щоб зробити його реальним. Ви можете зробити це у свій хід Бонусною дією, поки діє закляття. Обʼєкт стає реальним на 1 хвилину. Наприклад, ви можете створити ілюзію мосту через розлом і тримати його реальним досить довго, щоб ваші союзники могли по ньому перебратися. Обʼєкт не може завдавати шкоди або безпосередньо поранити будь-кого.';

export const control_undead = 'Керування невмерлим';

export const control_undead_details = 'Підкорити одного невмерлого в межах дальності.';

export const control_undead_expanded = 'Ви можете магією підкорити собі невмерлого, навіть якщо він створений іншим чарівником. Ви можете вибрати одного невмерлого в межах 60 футів від себе, якого можете бачити. Ця істота має пройти Випробування Харизми проти складності ваших заклять. Якщо воно успішне, ви не можете більше використовувати це вміння на цю ціль. Якщо воно провалене, ціль стає дружньою до вас і підкоряється вашим командам, доки ви не використовуєте це вміння знову. Розумні невмерлі керуються подібним способом набагато важче.<br/><br/>'+
'Якщо в цілі Інтелект 8 або вище, вона здійснює спаскидок з перевагою. Якщо вона провалює спаскидок і має Інтелект 12 або вище, вона може повторювати спаскидок наприкінці кожної години, поки не досягне успіху і не звільниться.';

export const instinct_charm = 'Інстинктивне зачарування';

export const instinct_charm_details = 'Перенаправити атаку по собі на іншу істоту.';

export const instinct_charm_expanded = 'Коли істота, яку ви бачите і яка знаходиться в межах 30 футів від вас, здійснює кидок атаки по вам, ви можете Реакцією перенаправити атаку, за умови, що є інша істота, яка знаходиться в межах цієї атаки. Атакуючий повинен пройти Випробування Мудрості проти складності ваших заклять. При провалі атакуючий повинен вибрати як ціль найближчу істоту (крім вас і себе). Якщо є кілька потенційних цілей, атакуючий сам вибирає ціль серед них. У разі успіху ви не можете використати це вміння проти атакуючого, доки не завершите тривалий відпочинок. Ви повинні вирішити, чи використовуватимете це вміння, перш ніж буде відомо, влучила атака або промахнулася. Істоти, яких не можна зачарувати, мають імунітет до цього ефекту.';

export const powerful_charm = 'Посилене зачарування';

export const powerful_charm_details = 'Додати ще одну істоту як ціль у створюваному заклятті Чарів [1+].';

export const powerful_charm_expanded = 'При накладанні вами закляття школи Чарів 1 рівня або вище, яке націлюється лише на одну істоту, ви можете зробити ціллю другу істоту.';

export const false_memories = 'Хибні спогади';

export const false_memories_details = 'Після завершення закляття Чарів, стерти спогад одної з істот про те, що воно було зачароване вами.';

export const false_memories_expanded = 'Ви отримуєте здатність змусити істоту забути про магічний вплив, який ви на нього наклали. При створенні закляття школи Чарів, яке зачаровує одне чи кілька істот, ви можете змінити сприйняття і спогади однієї з них і вона забуде, що була зачарована.';

export const erase_memories = 'Стерти памʼять';

export const erase_memories_details = 'Після завершення закляття Чарів, стерти відрізки перебування цілі у Зачарованому стані';

export const erase_memories_expanded = 'Перед тим, як закляття розвіється, ви можете Основною дією спробувати змусити вибрану істоту забути деякі моменти її перебування в зачарованому стані. Істота повинна досягти успіху в Випробуванні Інтелекту проти складності вашого закляття чарівника, або вона втратить спогади за проміжок часу, що вимірюється в годинах і дорівнює 1 + ваш модифікатор Харизми (мінімум 1). Ви можете змусити істоту забути менше часу. Кількість забутого часу неспроможна перевищувати тривалість самого закляття.';

export const transmutation_stone = 'Камінь перетворення';

export const transmutation_stone_details = 'Створити камінь, що дає носію один із ефектів нижче. Якщо камінь у вас, ефект можна перемикати під час створення заклять Перетворення [1+].<br/>'+
'• Темний зір 60ф<br/>'+
'• +10 Швидкість<br/>'+
'• Бонус до Випробувань Статури<br/>'+
'• Стійкість до пошкодження одної зі стихій<br/>'+
'• Дає 1× Геній';

export const transmutation_stone_expanded = 'Ви можете витратити 8 годин для створення каменю, що зберігає магію Перетворення. Ви можете отримати ефект з каменю самостійно, або передати його іншій істоті. Істота отримує ефект на ваш вибір до тих пір, поки камінь знаходиться у неї. Коли ви створюєте камінь, оберіть створювану ним перевагу з наступного списку:<br/><br/>'+
'• Темний зір в межах 60 футів<br/>'+
'• Збільшення швидкості на 10 футів, поки істота не перевантажена<br/>'+
'• Володіння Випробуваннями Статури<br/>'+
'• Стійкість до пошкодження Звуком, Кислотою, Вогнем, Холодом або Струмом (зробіть вибір при створенні каменю)<br/><br/>'+
'Кожного разу, коли ви накладаєте закляття Перетворення 1 і вище рівнів, ви можете змінити ефект вашого каменю, якщо він знаходиться у вас. Якщо ви створюєте новий камінь перетворення, попередній перестає працювати.';

export const big_transformation = 'Велика трансформація';

export const big_transformation_details = 'Перетворити один немагічний предмет на інший схожий за розміром, масою та обсягом.';

export const big_transformation_expanded = 'Ви можете спожити запас магії, що зберігається у Камені перетворення. Ви можете перетворити один немагічний предмет обʼємом не більше 5 кубічних футів на інший немагічний предмет схожого розміру і маси, і рівного або меншого обʼєму. Ви повинні 10 хвилин тримати предмет у руках для його трансформації. Ваш Камінь перетворення знищується, і не може бути використаний знову, доки ви не завершите тривалий відпочинок.';

export const return_youth = 'Повернути молодість';

export const return_youth_details = 'Зовнішньо омолодити істоту, максимум до 13 років.';

export const return_youth_expanded = 'Ви можете спожити запас магії, що зберігається у Камені перетворення. Ви доторкаєтеся Каменем перетворення до згодної істоти, і видимий вік цієї істоти зменшується на 3d10 років до мінімуму в 13 років. Цей ефект не підвищує його термін життя. Ваш Камінь перетворення знищується, і не може бути використаний знову, доки ви не завершите тривалий відпочинок.';

export const panacea = 'Панацея';

export const panacea_details = 'Повністю вилікувати та зцілити істоту.';

export const panacea_expanded = 'Ви можете спожити запас магії, що зберігається у Камені перетворення. Ви знімаєте всі ефекти прокльонів, хвороб та отрут з істоти, якої торкаєтеся Каменем перетворення. Крім того, ця істота відновлює усе здоровʼя. Ваш Камінь перетворення знищується, і не може бути використаний знову, доки ви не завершите тривалий відпочинок.';

export const divination_expert = 'Експерт віщування';

export const divination_expert_details = 'При створенні закляття Віщування [2+] відновити один слот мани рівнем нижче, [5-].';

export const divination_expert_expanded = 'Ви так легко творите закляття школи Віщування, що витрачаєте лише малу частину своїх сил. Коли ви накладаєте закляття школи Віщування 2 рівня або вище, використовуючи слот мани, ви відновлюєте один вже витрачений слот мани. Відновлений слот повинен бути нижчим за рівнем, ніж закляття, яке ви накладаєте, і не може бути вище 5 рівня.';

export const third_eye = 'Третє око';

export const third_eye_details = 'Отримати один із ефектів до наступного відпочинку:<br/>'+
'• Вміння читати усіма мовами<br/>'+
'• Бачити невидимих істот на лініі огляду, та невидимі предмети до 10ф від себе<br/>'+
'• Темний зір 60ф<br/>'+
'• Зір Ефірного плану 60ф.';

export const third_eye_expanded = 'Ви можете дією збільшити силу сприйняття. Коли ви так робите, виберіть одну перевагу, яка триватиме, поки ви не станете недієздатними або поки ви не здійсните короткий або тривалий відпочинок. Ви не можете використовувати це вміння повторно, доки не завершите відпочинок.<br/><br/>'+
'• Велике розуміння. Ви можете читати будь-якими мовами.<br/><br/>'+
'• Бачити невидиме. Ви можете бачити невидимих істот, що знаходяться на лінії огляду, і предмети в межах 10 футів.<br/><br/>'+
'• Темний зір. Ви отримуєте темний зір у межах 60 футів.<br/><br/>'+
'• Ефірний погляд. Ваш зір тягнеться на Ефірний План в межах 60 футів.';

export const rogue_luck = 'Вдача';

export const rogue_luck_details = 'Зробити атаку влучною, або перевірку харатеристики рівною 20.';

export const rogue_luck_expanded = 'Ви отримуєте надприродний дар досягати успіху, коли це найпотрібніше. Якщо ваша атака промахується по цілі, яка знаходиться в межах досяжності, ви можете змінити промах на влучання. Як альтернатива, якщо ви провалили перевірку характеристики, ви можете замінити результат, що випав на к20, на «20».<br/><br/>'+
'Викорисмавши це вміння, ви не можете використати його повторно, доки не завершите короткий або тривалий відпочинок.';

export const shield_block = 'Відбити закляття';

export const shield_block_details = 'При успішному Випробуванні Спритності, повністю відбити пошкодження від закляття.';

export const shield_block_expanded = 'Якщо ви піддаєтеся дії ефекту, що дозволяє пройти Випробування Спритності для отримання половини пошкодження, ви можете Реакцією взагалі не отримати пошкодження у разі успішного Випробування, виставивши щит між собою та джерелом ефекту.';

export const aura_of_vitality = 'Аура витривалості';

export const aura_of_vitality_details = 'Дозволяє Бонусною дією зціляти одну істоту в області.';

export const aura_of_vitality_expanded = 'Від вас виходить аура цілющої енергії з радіусом 30 футів. Поки закляття активне, аура переміщується разом з вами, залишаючись із центром на вас. Ви можете Бонусною дією відновлювати будь-якій істоті в аурі (включаючи себе) 2d6 здоровʼя.';

export const aura_of_life = 'Аура життя';

export const aura_of_life_details = 'Аура, що дає <span class="buff">стійкість</span> до 💀 Некротичного пошкодження і <span class="buff">імунітет</span> проти зменшення максимального здоровʼя. Зцілює 1 здоровʼя тим союзникам в області, у кого 0 здоровʼя.';

export const aura_of_life_expanded = 'Від вас виходить аура, що захищає життя, з радіусом 30 футів. Поки закляття активне, аура переміщається разом з вами, залишаючись з центром на вас. Усі не ворожі істоти в аурі (включаючи вас) мають протидію до Некротичного пошкодження, і максимум їх здоровʼя не може зменшитися. Крім того, не ворожі живі істоти відновлюють 1 здоровʼя, коли починають свій хід в цій аурі з 0 здоровʼя.';

export const true_seeing = 'Істинний зір';

export const true_seeing_details = 'Дати союзникові істинний зір та бачення Ефірного плану';

export const true_seeing_expanded = 

'Закляття дає згодній істоті, до якої ви доторкнулися, здатність бачити речі такими, якими вони є насправді. Поки закляття активне, ця істота має істинний зір, бачить потаємні двері, приховані магією, його зір простягається на Ефірний План, і все це в межах 120 футів.<br/><br/>'+

'<span class="rare-text">* Істоти з істинним зором можуть у певному радіусі бачити у звичайній і магічній темряві, бачити невидимі істоти та предмети, автоматично виявляти зорові ілюзії і процвітати від них, і бачити справжній вигляд перевертків і істот, перетворених магією. Крім того, зір таких істот тягнеться на Ефірний План.</span>';

export const mordenkainens_sword = 'Меч Морденкайнена';

export const mordenkainens_sword_details = 'Створити в повітрі потужний меч з силового поля.';

export const mordenkainens_sword_expanded = 'Ви створюєте потік силового поля у формі меча, що ширяє в межах дистанції. Він існує, доки активне закляття.<br/><br/>'+
'Коли меч зʼявляється, ви робите ближню атаку закляття по цілі на ваш вибір, що знаходиться в межах 5 футів від меча. При влучанні ціль отримує пошкодження Силовим полем 3d10.<br/><br/>'+
'Пока заклинание активне, вьі можете в каждом своем ходу бонусньім действием перемещать меч на 20 футов в видимое место, и повторять єту атаку по той же самой или другой цели.';

export const glibness = 'Кмітливість';

export const glibness_details = 'Усі перевірки Харизми дають мінімум 15 на кубі. Імунітет до перевірок щирості.';

export const glibness_expanded = 'Поки закляття не закінчиться, щоразу, коли ви здійснюєте перевірку Харизми, ви можете замінити результат, що випав, числом «15». Крім того, що б ви не говорили, магія, яка перевіряє, чи кажете ви правду, показує, що ви щирі.';

export const power_word_kill = 'Слово сили: Смерть';

export const power_word_kill_details = 'Миттєво вбити істоту з <100 здоровʼя.';

export const power_word_kill_expanded = 'Ви вимовляєте слово сили, що змушує істоту, яку ви бачите в межах дистанції, миттєво померти. Якщо у вибраної вами істоти не більше 100 хітів, вона вмирає. В іншому випадку це закляття не має жодного ефекту.';

export const luck_feat = 'Везіння';

export const luck_feat_details = '<span class="buff">Перевага</span> на будь-який кидок d20, навіть на ворожий кидок атаки на вас.';

export const luck_feat_expanded = 'У вас є 3 одиниці Везіння. Щоразу, коли ви здійснюєте кидок атаки, перевірку характеристики або Випробування, ви можете витратити одну одиницю Везіння, щоб кинути додатковий d20. Ви можете вирішити витратити одиницю везіння після звичайного кидка куба, але до визначення наслідків. Після цього ви самі вибираєте, яку d20 використовуватиме для кидка атаки, перевірки характеристики або Випробування.<br/><br/>'+
'Ви також можете витратити одну одиницю Везіння, коли по вас відбувається кидок атаки. Киньте d20, а потім вирішіть, яку з кісток використовуватиме атакуючий, вашу чи свою. Якщо одразу кілька істот витрачають одиниці Везіння для впливу на один кидок, одиниці скасовують один одного і додаткові куби ніхто не кидає.<br/><br/>'+
'Ви відновлюєте витрачені одиниці Везіння наприкінці тривалого відпочинку.';

export const extra_attack_warlock = 'Додатковий удар чаклуна';

export const extra_attack_warlock_details = '+1 атака зброєю договору в додаток до основної.';

export const extra_attack_warlock_expanded = 'Якщо ви в свій хід здійснюєте дію Атака зброєю договору, ви можете здійснити дві атаки замість однієї.';

export const dissapear = 'Зникнення';

export const dissapear_details = 'Стати невидимим в області тьмяного світла або в темряві, доки ви не переміститеся або не використаєте Дію або Реакцію.';

export const dissapear_expanded = 'Коли ви знаходитесь в області тьмяного світла або в темряві, ви можете Основною дією стати невидимою, поки ви не переміститеся або не використаєте Основну дію або Реакцію.';

export const lifedrinker = 'Випити життя';

export const lifedrinker_details = 'Завдати додаткове пошкодження при влучанні атакою зброєю договору.';

export const lifedrinker_expanded = 'Коли ви влучаєте по істоті зброєю договору, ця істота отримує додаткове пошкодження Некротичною енергією, що дорівнює вашому модифікатору Харизми (мінімум 1).';

export const astral_projection = 'Проекція в Астрал';

export const astral_projection_details = 'Перенести себе та союзників в Астральний План, зберігаючи доступ до спорядження. Матеріальні тіла залишаються непритомні в гібернації. Якщо здоровʼя астральної проекції падає до 0, істота повертається до свого фізичного тіла. Під час переходу на інший план, тіло матеріалізується там. Творець закляття може його припинити Основною дією.';

export const astral_projection_expanded = 'Ви і до восьми згодних істот у межах дистанції проектуєте свої астральні тіла до Астрального Плану (закляття провалюється, якщо ви вже знаходитесь на цьому плані). Залишені вами матеріальні тіла лежать непритомні у стані призупиненої життєдіяльності; їм не потрібна їжа, повітря, і вони не старіють.<br/><br/>'+
'Ваше астральне тіло у всьому схоже на смертне тіло, воно має ті ж параметри та спорядження. Єдиною відмінністю є срібна нитка, що виходить у вас між лопаток і стирчить назовні, стаючи невидимою через 1 фут. Ця нитка повʼязує вас із матеріальним тілом.<br/><br/>'+
'Поки ця нитка ціла, ви можете знайти шлях назад до тіла. Якщо нитку перерізати (це можуть зробити тільки ефекти, які явно вказують, що вони можуть це) ваші душа і тіло розділяються, і ви миттєво вмираєте.<br/><br/>'+
'Ваше астральне тіло може вільно ходити Астральним Планом і проходити крізь портали, що ведуть в інші плани. Якщо ви входите в новий план або повертаєтеся до свого, ваше тіло та спорядження переносяться по срібній нитці, дозволяючи повернутися у своє тіло під час входу на інший план.<br/><br/>'+
'Ваше астральне тіло – окреме втілення. Пошкодження та інші ефекти, що застосовуються на нього, не впливають на фізичне тіло, і не залишаються при вашому поверненні в тіло.<br/><br/>'+
'Закляття закінчується для вас та ваших супутників, якщо ви закінчуєте його Основною дією. При цьому істоти повертаються у свої тіла та прокидаються.<br/><br/>'+
'Закляття може закінчитися для окремих істот передчасно. Успішне закляття Розсіювання магії, використане на астральне, або фізичне тіло, закінчує це закляття для цієї істоти.<br/><br/>'+
'Якщо здоровʼя фізичного чи астрального тіла істоти опускається до 0, закляття закінчується для цієї істоти.<br/><br/>'+
'Якщо закляття закінчується, і срібна нитка незаймана, вона повертає астральне тіло у фізичне, закінчуючи стан зупиненої життєдіяльності.<br/><br/>'+
'Якщо ви повернулися у своє тіло, а супутники залишилися в астральних тілах, але хочуть повернутись, вони самі повинні знайти спосіб повернутися, що зазвичай відбувається в результаті опускання хітів до 0.';

export const astral_projection_monk = 'Проекція монаха в Астрал';

export const astral_projection_monk_details = 'Перенести себе в Астральний План, зберігаючи доступ до спорядження. Матеріальне тіло залишається непритомним в гібернації. Якщо здоровʼя астральної проекції падає до 0, ви повертаєтесь до свого фізичного тіла. Під час переходу на інший план, тіло матеріалізується там. Ви можете припинити закляття Основною дією.';

export const astral_projection_monk_expanded = 'Ви проектуєте своє астральне тіло до Астрального Плану (закляття провалюється, якщо ви вже знаходитесь на цьому плані). Залишене вами матеріальне тіло лежить непритомне у стані призупиненої життєдіяльності; йому не потрібна їжа, повітря, і воно не старіє.<br/><br/>'+
'Ваше астральне тіло у всьому схоже на смертне тіло, воно має ті ж параметри та спорядження. Єдиною відмінністю є срібна нитка, що виходить у вас між лопаток і стирчить назовні, стаючи невидимою через 1 фут. Ця нитка повʼязує вас із матеріальним тілом.<br/><br/>'+
'Поки ця нитка ціла, ви можете знайти шлях назад до тіла. Якщо нитку перерізати (це можуть зробити тільки ефекти, які явно вказують, що вони можуть це) ваші душа і тіло розділяються, і ви миттєво вмираєте.<br/><br/>'+
'Ваше астральне тіло може вільно ходити Астральним Планом і проходити крізь портали, що ведуть в інші плани. Якщо ви входите в новий план або повертаєтеся до свого, ваше тіло та спорядження переносяться по срібній нитці, дозволяючи повернутися у своє тіло під час входу на інший план.<br/><br/>'+
'Ваше астральне тіло – окреме втілення. Пошкодження та інші ефекти, що застосовуються на нього, не впливають на фізичне тіло, і не залишаються при вашому поверненні в тіло.<br/><br/>'+
'Закляття закінчується, якщо ви закінчуєте його Основною дією. Успішне закляття Розсіювання магії, використане на астральне, або фізичне тіло, закінчує це закляття. Якщо здоровʼя фізичного чи астрального тіла істоти опускаються до 0, закляття закінчується.<br/><br/>'+
'Якщо закляття закінчується, і срібна нитка незаймана, вона повертає астральне тіло у фізичне, закінчуючи стан зупиненої життєдіяльності.';

export const fly = 'Політ';

export const fly_details = 'Дати цілі швидкість польоту 60ф.';

export const fly_expanded = 'Ви доторкаєтеся до згодної істоти. Ціль отримує на час тривалості закляття швидкість польоту 60 футів. Коли закляття закінчується, ціль падає, якщо все ще знаходиться в польоті і нічим не може зупинити падіння.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 4 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище 3.<br/><br/>' +
'Фокус для створення закляття можна замінити на перо з крила будь-якого птаха.';

export const fly_monk = 'Політ монаха';

export const fly_monk_details = 'Отримати швидкість польоту 60ф.';

export const fly_monk_expanded = 'Ви отримуєте на час тривалості закляття швидкість польоту 60 футів. Коли закляття закінчується, ви падаєте, якщо все ще перебуваєте в польоті і нічим не можете зупинити падіння.';

export const fireball = 'Вогняна куля';
export const fireball_monk = 'Вогняна куля [Чі]';

export const fireball_details = 'Гучний вибух полумʼя, що завдає пошкодження всім в області.';

export const fireball_details_5 = fireball_details

export const fireball_details_9 = fireball_details+' +1d6 за кожний додатковий слот Чі. (максимум 4)';

export const fireball_details_13 = fireball_details+' +1d6 за кожний додатковий слот Чі. (максимум 5)';

export const fireball_details_17 = fireball_details+' +1d6 за кожний додатковий слот Чі. (максимум 6)';

export const fireball_expanded = 'Яскравий промінь вилітає з вашого вказівного пальця в точку, вибрану вами в межах дистанції, де відбувається вибух полумʼя з гулким ревом. Усі істоти в межах сфери з радіусом 20 футів із центром у цій точці повинні зробити пройти Випробування Спритності. Ціль отримує втрату Вогнем 8d6 при провалі або половину цього пошкодження при успіху. Цей вогонь огинає кути. Він підпалює горючі предмети, які ніхто не тримає і не носить.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 4 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище за третій.<br/><br/>' +
'Фокус для створення закляття можна замінити на крихітну кульку з гуано кажана і сірки.';

export const fireball_monk_expanded = 'Яскравий промінь вилітає з вашого вказівного пальця в точку, вибрану вами в межах дистанції, де відбувається вибух полумʼя з гулким ревом. Усі істоти в межах сфери з радіусом 20 футів із центром у цій точці повинні пройти Випробування Спритності. Ціль отримує втрату Вогнем 8d6 при провалі або половину цього пошкодження при успіху. Цей вогонь огинає кути. Він підпалює горючі предмети, які ніхто не тримає і не носить.<br/><br/>'+monk_element_adept;

export const gaseous_form = 'Газоподібна форма';

export const gaseous_form_details = 'Перетворити союзника на туманну хмару.<br/>'+
'• Швидкість 10ф, може літати<br/>'+
'• Може входити у простір інших істот<br/>'+
'• Не може атакувати та накладати закляття<br/>'+
'• Не може говорити та взаємодіяти з предметами<br/>'+
'• <span class="buff">Стійкість</span> до немагічного пошкодження<br/>'+
'• <span class="buff">Перевага</span> на Випробування Сили, Спритності та Статури';

export const gaseous_form_expanded = 'Ви перетворюєте на час дії закляття згодну істоту, до якої доторкаєтеся, а також все, що вона носить і несе, на туманну хмару.<br/><br/>'+
'Закляття закінчується, якщо здоровʼя істоти опускається до 0. Безтілесні істоти не підпадають під дію цього закляття. Поки ціль перебуває у цій формі, єдиною її швидкістю переміщення стає швидкість польоту 10 футів. Ціль може входити в простір інших істот і залишатися там. Ціль не може атакувати та накладати закляття.<br/><br/>'+
'Істота отримує стійкість до немагічного пошкодження, і робить з перевагою Випробування Сили, Спритності та Статури.<br/><br/>'+
'Ціль може проходити через невеликі отвори, вузькі щілини, але рідини для неї вважаються твердими поверхнями. Ціль не може впасти і залишається парити, навіть якщо стає оглушеною або недієздатною. Перебуваючи у формі туманної хмари, ціль не може говорити і маніпулювати предметами, і всі предмети, які вона тримає або носить, не можна кидати, використовувати і взагалі якось із ними взаємодіяти.<br/><br/>'+
'Фокус для створення закляття можна замінити на шматок марлі та клуб диму.';

export const gaseous_form_monk = 'Газоподібна форма монаха';

export const gaseous_form_monk_details = 'Перетворити себе на туманну хмару.<br/>'+
'• Швидкість 10ф, може літати<br/>'+
'• Може входити у простір інших істот<br/>'+
'• Не може атакувати та накладати закляття<br/>'+
'• Не може говорити та взаємодіяти з предметами<br/>'+
'• <span class="buff">Стійкість</span> до немагічного пошкодження<br/>'+
'• <span class="buff">Перевага</span> на Випробування Сили, Спритності та Статури';

export const gaseous_form_monk_expanded = 'Ви перетворюєте на час дії закляття себе, а також все, що ви тримаєте і носите, на туманну хмару.<br/><br/>'+
'Закляття закінчується, якщо ваше здоровʼя опускається до 0. Поки ви знаходитесь в цій формі, єдиною вашою швидкістю переміщення стає швидкість польоту 10 футів. Ви можете входити в простір інших істот і залишатися там. Ви не можете атакувати і накладати закляття.<br/><br/>'+
'Ви отримуєте стійкість до немагічного пошкодження, і робите з перевагою Випробування Сили, Спритності та Статури.<br/><br/>'+
'Ви можете проходити через невеликі отвори, вузькі щілини, але рідини для неї вважаються твердими поверхнями. Ви не можете впасти і залишаєтеся парити, навіть якщо стаєте оглушеними або недієздатними. Перебуваючи у формі туманної хмари, ви не можете говорити і маніпулювати предметами, і всі предмети, які ви тримаєте або носите, не можна упускати, використовувати і взагалі як-небудь з ними взаємодіяти.';

export const cone_of_cold = 'Конус холоду';

export const cone_of_cold_details = 'Хвиля дуже холодного повітря з рук.';

export const cone_of_cold_expanded = 'З ваших рук виривається хвиля холодного повітря. Всі істоти в 60-футовому конусі повинні пройти Випробування Статури. Істоти отримують пошкодження холодом 8d8 при провалі, або половину цього пошкодження при успіху. Істоти, вбиті цим закляттям, стають замороженими статуями, доки не розтануть.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 6 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище пʼятого.<br/><br/>'+
'Фокус для закляття можна замінити на невеликий кристалічний або скляний конус.';

export const cone_of_cold_monk = 'Конус холоду [Чі]';

export const cone_of_cold_monk_details = 'Хвиля дуже холодного повітря з рук.';

export const cone_of_cold_monk_details_5 = cone_of_cold_monk_details

export const cone_of_cold_monk_details_9 = cone_of_cold_monk_details+' +1d8 за кожний додатковий слот Чі. (максимум 4)';

export const cone_of_cold_monk_details_13 = cone_of_cold_monk_details+' +1d8 за кожний додатковий слот Чі. (максимум 5)';

export const cone_of_cold_monk_details_17 = cone_of_cold_monk_details+' +1d8 за кожний додатковий слот Чі. (максимум 6)';

export const cone_of_cold_monk_expanded = 'З ваших рук виривається хвиля холодного повітря. Всі істоти в 60-футовому конусі повинні пройти Випробування Статури. Істоти отримують пошкодження холодом 8d8 при провалі, або половину цього пошкодження при успіху. Істоти, вбиті цим закляттям, стають замороженими статуями, доки не розтануть.<br/><br/>'+monk_element_adept;

export const wall_of_stone = 'Камʼяна стіна';
export const wall_of_stone_monk = 'Камʼяна стіна [Чі]';

export const wall_of_stone_details = 'Створити камʼяну лінію з 10 дотичних плит з бронею 15 і здоровʼям 30 за кожні 2.5см товщини. При висоті 10ф товщина буде 15см. Можна створювати бійниці, зубці та ін. При утриманні 10 хвилин стіна стає постійною.';

export const wall_of_stone_expanded = 'У точці, яку ви вибрали в межах дистанції, зʼявляється немагічна стіна з каменю. Стіна товщиною 6 дюймів (15 сантиметрів) та складена з десяти плит 10×10 футів. Кожна плита повинна сусідити як мінімум з однією плитою.<br/><br/>'+

'В якості альтернативи, ви можете створити плити 10 × 20 футів, але завтовшки лише 3 дюйми (7,5 сантиметрів). Якщо стіна при появі проходить по простору істоти, ця істота виштовхується по одну зі сторін стіни (на ваш вибір). Якщо ви створюєте проліт довжиною більше 20 футів, ви повинні зменшити вдвічі розмір кожної плити, щоб створити підтримку. <br/><br/>'+

'Якщо істота з усіх боків оточується стіною (або стіною та іншою суцільною поверхнею), ця істота може пройти Випробування Спритності. При успіху воно може Реакцією переміститися на відстань, що дорівнює своїй швидкості, щоб не бути оточеним стіною.<br/><br/>'+

'Стіна може мати будь-яку форму, хоч і не може займати один простір з істотами та предметами. Стіна має бути вертикальною, і не має повністю лежати на твердому підставі. Однак вона повинна зливатися з існуючим каменем, спираючись на нього. Таким чином, ви можете створити цим закляттям міст через прірву або похилий спуск. Ви можете надавати стіні загальні форми, створюючи бійниці, зубці, і так далі.<br/><br/>'+

'Стіна є предметом, виготовленим з каменю, якому можна завдати пошкодження і тим самим пробити. У кожної плити Броня 15 та по 30 Здоровʼя за кожен 1 дюйм (2,5 сантиметри) товщини. Зменшення здоровʼя плити до 0 знищує її, і за рішенням Майстра, може спричинити обвал суміжних плит.<br/><br/>'+

'Якщо ви підтримуєте концентрацію на цьому заклятті протягом усієї тривалості, стіна стає постійною, і з цієї миті її вже не можна буде розсіяти. В іншому випадку стіна зникає, коли закляття буде закінчено.<br/><br/>'+

'Фокус для закляття можна замінити на невеликий граніт.';

export const stoneskin = 'Камʼяна шкіра';

export const stoneskin_details = 'Дати союзнику <span class="buff">стійкість</span> до немаігчного механічного пошкодження.';

export const stoneskin_expanded = 'Це закляття робить плоть згодної істоти, до якої ви доторкнулися, твердою як камінь. Поки закляття активне, ціль отримує стійкість до немагічного Дробильного, Колотого і Рубаного пошкодження.';

export const stoneskin_monk = 'Камʼяна шкіра монаха';

export const stoneskin_monk_details = 'Отримати <span class="buff">стійкість</span> до немаігчного механічного пошкодження.';

export const stoneskin_monk_expanded = 'Це закляття робить вашу плоть плоть твердою як камінь. Поки закляття активне, ви отримуєье стійкість до немагічного Дробильного, Колотого і Рубаного пошкодження.';

export const wall_of_fire = 'Вогняна стіна';
export const wall_of_fire_monk = 'Вогняна стіна [Чі]';

export const wall_of_fire_details = 'Створити лінію або кільце з вогню висотою 20ф та товщиною 1ф. Всі хто опиняються в <10ф з одного боку, отримують пошкодження.';

export const wall_of_fire_details_5 = wall_of_fire_details

export const wall_of_fire_details_9 = wall_of_fire_details+' +1d8 за кожний додатковий слот Чі. (максимум 4)';

export const wall_of_fire_details_13 = wall_of_fire_details+' +1d8 за кожний додатковий слот Чі. (максимум 5)';

export const wall_of_fire_details_17 = wall_of_fire_details+' +1d8 за кожний додатковий слот Чі. (максимум 6)';

export const wall_of_fire_expanded = 'Ви створюєте стіну із вогню на твердій поверхні в межах дистанції. Ви можете створити стіну до 60 футів у довжину, 20 футів у висоту та 1 фут товщиною, або замкнутою кільцем стіною до 20 футів діаметром, 20 футів висотою та товщиною 1 фут.<br/><br/>'+
'Стіна непрозора і існує, доки активне закляття. Коли стіна зʼявляється, всі істоти в її області повинні пройти Випробування Спритливості. При провалі вони отримують пошкодження Вогнем 5d8 або половину цього пошкодження при успіху.<br/><br/>'+
'Одна сторона стіни, вибрана при накладанні закляття, завдає пошкодження вогнем 5d8 всім істотам, що закінчують хід в межах 10 футів від цієї сторони або всередині стіни. Істоти отримують таке ж пошкодження, коли вперше за хід входять у стіну або закінчують там хід. Інша сторона стіни не завдає пошкодження.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 5 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слот мани вище за четвертий.<br/><br/>'+
'Фокус для закляття можна замінити на невеликий шматочок фосфору.';

export const wall_of_fire_monk_expanded = 'Ви створюєте стіну із вогню на твердій поверхні в межах дистанції. Ви можете створити стіну до 60 футів у довжину, 20 футів у висоту та 1 фут товщиною, або замкнутою кільцем стіною до 20 футів діаметром, 20 футів висотою та товщиною 1 фут.<br/><br/>'+
'Стіна непрозора і існує, доки активне закляття. Коли стіна зʼявляється, всі істоти в її області повинні пройти Випробування Спритливості. При провалі вони отримують пошкодження Вогнем 5d8 або половину цього пошкодження при успіху.<br/><br/>'+
'Одна сторона стіни, вибрана при накладанні закляття, завдає пошкодження вогнем 5d8 всім істотам, що закінчують хід в межах 10 футів від цієї сторони або всередині стіни. Істоти отримують таке ж пошкодження, коли вперше за хід входять у стіну або закінчують там хід. Інша сторона стіни не завдає пошкодження.<br/><br/>'+monk_element_adept;

export const beacon_of_hope = 'Маяк надії 🤍';

export const beacon_of_hope_details = 'Дати союзникам в області навколо себе <span class="buff">перевагу</span> на Випробування Мудрості та кидки від смерті, а також максимально можливе відновлення здоровʼя від будь-якого лікування.';

export const beacon_of_hope_expanded = 'Це закляття дарує надію та живучість. Оберіть будь-яку кількість істот у межах дистанції. Поки закляття активне, усі цілі здійснюють Випробування Мудрості та кидки від смерті з перевагою, а також відновлює від будь-якого лікування максимально можливу кількість здововʼя.';

export const dispel_magic = 'Розсіювання магії';

export const dispel_magic_details = 'Зняти з цілі дію всіх заклять вказаного рівня. Рівні вище знімаються при успішній додатковій перевірці.';

export const dispel_magic_expanded = 'Виберіть одну істоту, предмет або магічний ефект у межах дистанції. Всі закляття 3 рівня та нижче на цілі закінчуються. Для кожного закляття з рівнем 4 або вище здійсніть перевірку базової характеристики. Сл дорівнює 10 + рівень закляття. У разі успіху це закляття закінчується.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 4 або вище рівня, ви автоматично закінчуєте ефекти заклять на цілі, чий рівень не перевищує рівень використаного слоту мани.';

export const freedom_of_movement = 'Свобода пересування';

export const freedom_of_movement_details = 'Дати союзнику:<br/>'+
'• Рух важкопрохідною місцевістю <span class="buff">без штрафу</span>.<br/>'+
'• Рух та атаки під водою <span class="buff">без штрафу</span>.<br/>'+
'• <span class="buff">Імунітет</span> проти Паралізованості та 🪢 Обплутаності.<br/>'+
'• 5ф переміщення для звільнення від немагічних оков або схоплення.';

export const freedom_of_movement_expanded = 'Ви доторкаєтеся до згодної істоти. Поки закляття активне, на переміщення цілі не впливає важкопрохідна місцевість, а закляття та інші магічні ефекти неспроможні ні зменшити її швидкість, ні зробити її паралізованою чи оплутанною. Більше того, перебування під водою не накладає штрафів на переміщення та атаки цілі.<br/><br/>'+

'Ціль може також витратити 5 футів переміщення, щоб автоматично вивільнитися з немагічних оков, таких як кайдани або схоплення іншою істотою.<br/><br/>'+
'Фокус для створення закляття можна замінити на шкіряний ремінь, обмотаний навколо передпліччя або аналогічної кінцівки.';

export const guardian_of_faith = 'Вартовий віри';

export const guardian_of_faith_details = 'Створити примарного вартового, що завдає всім в області навколо нього сумарно 60 пошкодження.';

export const guardian_of_faith_expanded = 'У вибраній та видимій вами вільній клітині в межах дистанції зʼявляється і починає парити примарний вартовий. Він займає свій простір, і його контури погано видно за винятком блискучого меча та щита, прикрашеного символом вашого божества.<br/><br/>'+
'Всі істоти, ворожі вам, які вперше за хід переміщуються у простір, що знаходиться в межах 10 футів від вартового, повинні досягти успіху у Випробуванні Спритності. При провалі ця істота отримує пошкодженя Світлом 20, або половину цього пошкодження при успіху.<br/><br/>'+
'Вартовий зникає, коли завдасть сумарно 60 пошкодження.';

export const flame_strike = 'Удар полум’ям';

export const flame_strike_details = 'Створити колону вогню висотою 40ф що завдає пошкодження.';

export const flame_strike_expanded = 'У вказаному місці виникає вертикальна колона божественного полумʼя. Всі істоти в циліндрі з радіусом 10 футів і висотою 40 футів з центром на точці в межах дистанції повинні пройти Випробування Спритності, при провалі істота отримує пошкодження 4d6 Світлом і 4d6 Вогнем, або половину цього пошкодження при успіху.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот 6 рівня або вище, покшодження Світлом або Вогнем (на ваш вибір) збільшується на 1d6 за кожен рівень слоту вище пʼятого.<br/><br/>'+

'Фокус для створення закляття можна замінити на щіпку сірки.';

export const commune = 'Спілкування';

export const commune_details = 'Задати 3 питання вашому божеству, на які можна отримати правдиві відповіді "так", "ні", рідко "неясно" і ще рідше, при конфлікті інтересів — коротку фразу. Кожне нове використання до тривалого відпочинку зменшує ймовірність відповіді.';

export const commune_expanded = 'Ви звʼязуєтеся зі своїм божеством чи божественним посередником і ставите три запитання, на які можна відповісти «так» чи «ні». Ви повинні поставити запитання поки закляття активне. На кожне запитання ви отримуєте правильну відповідь. Божественні творіння не завжди всезнаючі, тому ви можете отримати відповідь «неясно», якщо питання знаходиться поза компетенцією божества. Якщо односкладова відповідь може ввести в оману або суперечить інтересам божества, божество може видати коротку фразу.<br/><br/>'+

'Якщо ви накладаєте це закляття кілька разів до завершення тривалого відпочинку, існує накопичувальний шанс 25 відсотків за кожне використання, починаючи з другого, що ви не отримаєте відповіді. Майстер робить цей кидок потайки.<br/><br/>'+

'Фокус для створення закляття можна замінити на пахощі та флакон святої чи нечистої води.';

export const protection_from_energy = 'Захист від енергії';

export const protection_from_energy_details = 'Дати союзникові <span class="buff">стійкість</span> до пошкодження одного з видів: 🔊 Звук, ⚗️ Кислота, 🔥 Вогонь, ❄️ Холод, ⚡ Струм.';

export const protection_from_energy_expanded = 'Пока заклинание активне, согласное существо, которого вьі коснулись, получает сопротивление к одному виду урона на ваш вьібор: Звук, Кислота, Огонь, Холод или єлектричество.';

export const plant_growth = 'Зростання рослин';

export const plant_growth_details = 'Зробити всі рослини в області (з опціональним виключеням деяких ділянок) густими та рослими, уповільнюючи істот у 4 рази. Альтернативно, при касті 8 годин, всі рослини в радіусі 0.5 милі стають удобреними на 1 рік, і приносять подвійний врожай.';

export const plant_growth_expanded = 'Це закляття вливає життєві сили у рослини, що є у певній області. Є два варіанти використання цього закляття; одне дає короткостроковий, інше – довготривалий ефект.<br/><br/>'+

'Якщо ви активуєте це закляття Основною дією, виберіть точку в межах дистанції. Всі звичайні рослини в межах 100-футового радіусу з центром на цій точці стають густими та високими. Істота, що пересуваєтсья цією місцевістю, повинна витратити 4 фути переміщення за кожен 1 пройдений фут. Ви можете виключити одну або кілька областей будь-якого розміру в межах зони дії закляття.<br/><br/>'+

'Якщо ви активуєте це закляття 8 годин, ви робите ґрунт удобреним. Всі рослини в радіусі півмилі з центром на точці в межах дистанції стають удобеними на 1 рік. Ці рослини приносять подвійний врожай.';

export const ice_storm = 'Крижана буря';

export const ice_storm_details = 'Обрушити крижані глиби, що завдають пошкодження в області висотою 40 футів і зробити її важкопрохідною.';

export const ice_storm_expanded = 'На землю в циліндрі з радіусом 20 футів та висотою 40 футів, з центром на точці в межах дистанції, падають глиби льоду. Всі істоти в циліндрі повинні здійснити Випробування Спритності. Істота отримує Дробильне пошкодження 2d8 і пошкодження Холодом 4d6 при провалі, або половину цього пошкодження при успіху.<br/><br/>'+

'Крижана буря перетворює область впливу на важкопрохідну місцевість до кінця вашого наступного ходу.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот 5 рівня або вище, Дробильне пошкодження збільшується на 1d8 за кожен рівень слоту вище четвертого.<br/><br/>'+

'Фокус для створення закляття можна замінити на щіпку пилу та кілька крапель води.';

export const tree_stride = 'Деревохід';

export const tree_stride_details = 'Отримати здатність переміщатися між живими деревами одного виду в області, і знання про їхнє розташування в області. Для переходу треба використати 5ф переміщення.';

export const tree_stride_expanded = 'Ви отримуєте здатність входити в дерево і переміщатися через нього до іншого дерева того ж виду, що знаходиться в межах 500 футів. Обидва дерева повинні бути живими, і розмір обох повинен бути не меншим за ваш. Ви повинні використовувати 5 футів переміщення, щоб увійти до дерева.<br/><br/>'+

'Ви миттєво дізнаєтеся про розташування всіх інших дерев цього виду в межах 500 футів, і частиною цього ж переміщення можете або перейти в одне з цих дерев, або вийти з дерева, в якому ви знаходитесь. Ви зʼявляєтеся у просторі на свій вибір в межах 5 футів від поточного дерева, використовуючи ще 5 футів переміщення.<br/><br/>'+

'Якщо у вас не залишилося переміщення, ви зʼявляєтеся в межах 5 футів від дерева, до якого входили. Ви можете використовувати цю переміщувальну здатність один раз в раунд, поки активне закляття. Кожен хід ви повинні закінчувати поза деревом.';

export const haste = 'Пришивидшення';

export const haste_details = 'Дати союзнику:<br/>'+
'• 2× Швидкість<br/>'+
'• +2 Броня<br/>'+
'• <span class="buff">Перевага</span> на Випробування Спритності<br/>'+
'• Додаткова Основна дія в хід ( Атака зброєю | Засідка | Використання предмета | Відхід | Ривок )<br/>'+
'Після завершення закляття ціль стає загальмованою на хід.';

export const haste_expanded = 'Виберіть згодну істоту, видиму в межах дистанції. Поки закляття активне, швидкість цілі подвоюється, вона отримує бонус +2 до Броні, вона здійснює з перевагою Випробування Спритності, і в кожному ході може здійснювати додаткову дію. Цією дією може бути лише Атака (одна атака зброєю), Засідка, Використання предмета, Відхід чи Ривок.<br/><br/>'+

'Коли закляття закінчується, ціль не може переміщатися і робити дії, доки не пропустить свій наступний хід, оскільки стає загальмованою.<br/><br/>'+

'Фокус для створення закляття можна замінити на стружку кореня лакриці.';

export const banishment = 'Вигнання';

export const banishment_details = 'Перемістити істоту, що провалила Випробування, на інший план. Якщо ціль родом з іншого плану, при повній тривалості — ціль не повертається.';

export const banishment_expanded = 'Ви намагаєтесь відправити одну істоту, яку ви бачите в межах дистанції, на інший план існування. Ціль повинна досягти успіху в Випробуванні Харизми, інакше буде вигнана.<br/><br/>'+

'Якщо ціль родом із того плану існування, на якому перебуваєте ви, ви виганяєте її в безпечний деміплан. Перебуваючи там, ціль недієздатна. Вона залишається там, поки активне закляття, після чого повертається в простір, з якого зникла, або найближчий вільний простір, якщо місце зайняте.<br/><br/>'+

'Якщо ціль не належить до плану існування, на якому ви знаходитесь, вона буде вигнана з тихим звуком і повернеться на свій рідний план. Якщо закляття закінчиться менше, ніж за 1 хвилину, ціль повернеться в простір, з якого зникла, або найближчий вільний простір, якщо це місце зайняте. В іншому випадку ціль не повернеться.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот 5 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожний рівень слоту вище четвертого.<br/><br/>'+

'Фокус для створення закляття можна замінити на неприємний для цілі предмет.';

export const dimension_door = 'Двері крізь простір';

export const dimension_door_details = 'Телепортуватися до будь-якої точки в межах дистанції. Можна взяти із собою предмети чи союзника.<br/><span class="debuff">При неможливості переміститися у вибрану точку, ті що переміщаються отримують пошкодження.</span>';

export const dimension_door_expanded = 'Ви телепортуєтесь в інший простір у межах дистанції. Ви опиняєтеся точно у вибраному місці. Це може бути місце, яке ви бачили, яке ви уявляєте, або яке ви можете описати вказівками напрямку та відстані, наприклад, «200 футів піді мною» або «на північний захід, вгору під кутом 45 градусів, на 300 футів». Ви можете взяти з собою предмети, якщо їхня вага дозволяє вам їх нести. Ви також можете взяти з собою одну згодну істоту, розміром не більше за вашу, яка теж може нести дозволену вагу предметів. При накладанні закляття істота повинна бути в межах 5 футів від вас.<br/><br/>'+

'Якщо ви повинні опинитися в місці, вже зайнятому істотою або предметом, ви і всі істоти, що переміщаються з вами, отримуєте пошкодження Силовим полем 4d6, і закляття вас не телепортує.';

export const scrying = 'Спостереження';

export const scrying_details = 'Створити невидимий сенсор спостереження поряд з ціллю, яка може переміщатися за нею. Складність Випробування проти закляття залежить від вашого знання цілі та фізичного звʼязку з її частиною:<br/>'+
'• Ви тільки чули про ціль: <span class="debuff">-5 до складності</span><br/>'+
'• Ви зустрічалися з ціллю: +0 до складності<br/>'+
'• Ви добре знаєте ціль: <span class="buff">+5 до складності</span><br/>'+
'• У вас є образ цілі: <span class="buff">+2 до складності</span><br/>'+
'• У вас є річ цілі: <span class="buff">+4 до складності</span><br/>'+
'• У вас є частина тіла цілі: <span class="buff">+10 до складності</span>';

export const scrying_expanded = 'Ви можете бачити і чути конкретну обрану вами істоту, яка знаходиться на тому ж плані існування, що й ви. Закляття створює невидимий сенсор близько 10 футів від цілі. Ви можете бачити і чути через нього, ніби ви там знаходилися. Сенсор переміщається разом з ціллю, залишаючись в межах 10 футів від неї, доки активне закляття. Істоти, здатні бачити невидимі предмети, бачать сенсор як сяючу кульку розміром з ваш кулак.<br/><br/>'+

'Мета має пройти Випробування Мудрості. Складність кидка модифікується тим, наскільки добре ви знаєте ціль, і тим, який фізичний звʼязок з цією істотою у вас є. При успіху сенсор не зʼявляєтсья, і ви не можете використовувати це закляття на ній протягом 24 годин.<br/><br/>'+

'Ви знаєте ціль:<br/>'+
'• Через посередників (ви тільки чули про ціль): -5 до складності<br/>'+
'• З перших рук (ви зустрічалися з ціллю): +0 до складності<br/>'+
'• Знайомі (ви добре знаєте ціль): +5 до складності<br/><br/>'+

'У вас є:<br/>'+
'• Образ чи малюнок цілі: +2 до складності<br/>'+
'• Річ або одяг цілі: +4 до складності<br/>'+
'• Частина тіла цілі - пасмо волосся, частина нігтя ... : +10 до складності<br/><br/>'+

'Якщо ціль знає, що ви накладаєте це закляття, вона може добровільно провалити Випробування, якщо хоче, щоб за нею стежили. Також, замість того, щоб націлюватися на істоту, ви можете вибрати як ціль місце, вже бачене вами. У цьому випадку сенсор зʼявляється там і не переміщається.';

export const hold_monster = 'Приборкання чудовиська';

export const hold_monster_details = 'Паралізувати істоту. Не діє на невмерлих.';

export const hold_monster_expanded = 'Виберіть істоту, яку бачите в межах дистанції. Ціль повинна досягти успіху у Випробування Мудрості, інакше стане паралізованою на період дії закляття. Наприкінці кожного свого ходу ціль може здійснювати нове Випробування Мудрості. У разі успіху закляття цієї цілі закінчується.<br/><br/>'+

'Це закляття не має ефекту на невмерлих.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 6 рівня або вище, ви можете зробити ціллю одну додаткову істоту за кожен рівень слоту вище пʼятого. Ці істоти повинні знаходитися в межах 30 футів один від одного, коли ви націлюєтеся на них.<br/><br/>'+

'Фокус для створення закляття можна замінити на невеликий прямий шматочок заліза.';

export const hold_monster_warlock = 'Приборкання чудовиська: ланцюги Карцері';

export const hold_monster_warlock_details = 'Паралізувати погань, небожителя або елементаля. Ту саму істоту можливо паралізувати лише 1 раз на день.';

export const hold_monster_warlock_expanded = 'Виберіть погань, небожителя або елементаля, якого бачите в межах дистанції. Ціль повинна досягти успіху у Випробування Мудрості, інакше стане паралізованою на період дії закляття. Наприкінці кожного свого ходу ціль може здійснювати нове Випробування Мудрості. У разі успіху закляття цієї цілі закінчується.Ви повинні закінчити тривалий відпочинок, перш ніж знову зможете використовувати це закляття проти цієї істоти.';

export const sleet_storm = 'Хуртовина';

export const sleet_storm_details = 'Створити область мокрого снігу висотою 20ф, який гасить джерела вогню, робить місцевість сильно загородженою і важкопрохідною, і покриває поверхню слизьким льодом.';

export const sleet_storm_expanded = 'Поки закляття активне, в циліндрі заввишки 20 футів і радіусом 40 футів із центром на точці, вибраній вами в межах дистанції, йде мокрий сніг. Це сильно загороджена місцевість, і відкриті джерела вогню в ній тухнуть. Поверхня в цій місцевості покривається слизьким льодом, що робить його важкопрохідною місцевістю.<br/><br/>'+

'Коли істота вперше за хід входить в область закляття або починає там хід, вона має пройти Випробування Спритності. При провалі воно падає ниць. Якщо істота концентрується, перебуваючи в області закляття, вона повинна пройти Випробування Статури проти Складності ваших заклять, інакше вона втратить концентрацію.<br/><br/>'+

'Фокус для створення закляття можна замінити на щіпку пилу та кілька крапель води.';

export const call_lightning = 'Виклик блискавки';

export const call_lightning_details = 'Створити грозову хмару, що бʼє блискавкою кожен хід, за Основну дію. Не можна створити в приміщенні нижче 100ф. <span class="buff">+1d10 пошкодження</span> за грозової погоди.';

export const call_lightning_expanded = 'Хмара у формі циліндра 10 футів у висоту та з радіусом 60 футів, зʼявляється з центром у точці, яку ви бачите у 100 футах у межах дистанції над головою. Закляття провалюється, якщо ви не бачите точку в повітрі, де має зʼявитися хмара (наприклад, якщо ви знаходитесь в кімнаті, в якій хмара не поміститься).<br/><br/>'+

'Активувавши закляття, виберіть видиму точку в межах дистанції під хмарою, з хмари до цієї точки бʼє розряд блискавки. Всі істоти в межах 5 футів від цієї точки повинні пройти Випробування Спритності. Істота отримує пошкодження Струмом 3d10 при провалі або половину цього пошкодження у разі успішного використання.<br/><br/>'+

'Поки закляття активне, ви можете у кожний свій хід Основною дією викликати нову блискавку, у ту чи іншу точку.<br/><br/>'+

'Якщо в момент накладання закляття ви знаходитеся на відкритому повітрі під час грози, закляття не створює нову хмару, а дає контроль над існуючою. У такій ситуації пошкодження від закляття збільшується на 1d10.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 4 рівня або вище, пошкодження збільшується на 1d10 за кожен рівень слоту вище за третій.';

export const control_water = 'Підкорення води';

export const control_water_details = 'Надатчи воді в області один з ефектів:<br/>'+
'• Вир, що притягує і завдає пошкодження кожний хід<br/>'+
'• Повінь | Хвиля, що перекидає кораблі<br/>'+
'• Спрямований у будь-який бік потік<br/>'+
'• Поділ води та формування проходу по дну.';

export const control_water_expanded = 'Поки це закляття активне, ви контролюєте потік або стоячу воду в кубі з довжиною ребра 100 футів. Ви можете вибрати один ефект з наведених нижче. Ви можете у свій хід Основною дією повторити той же ефект або вибрати інший.<br/><br/>'+

'Вир. Для цього ефекту потрібно обʼєм води в квадраті з довжиною 50 футів і глибиною 25 футів. У центрі області ви формуєте вир. Вир створює воронку шириною 5 футів в основі, 50 футів у верхній частині, і висотою 25 футів. Всі істоти та предмети, що знаходяться у воді в межах 25 футів від виру, підтягуються до нього на 10 футів. Істоти можуть пливти геть від виру, перевіряючи Атлетику проти Складності ваших заклять. Якщо істота вперше за раунд входить у простір воронки чи починає у ній хід, вона має пройти Випробування Сили. При провалі істота отримує Дробильне пошкодження 2d8 і стає спійманою воронкою, поки закляття активне. При успішному порятунку істота отримує половину пошкодження, і не стає спійманою воронкою. Спіймана воронкою істота може Основною дією намагатися спливти від виру, як описано вище, але перевірка Атлетики відбувається з перешкодою. У кожному ході вперше, коли предмет опиняється в ділянці вирви, він отримує Дробильне пошкодження 2d8; це ж пошкодження завдається за кожен раунд, проведений предметом у воронці.<br/><br/>'+

'Повінь. Ви підвищуєте рівень стоячої води в області на висоту до 20 футів. Якщо в область потрапляє берег, вода виливається на землю. Якщо ви вибрали область серед іншого водного простору, то ви натомість створюєте хвилю висотою 20 футів, що проходить з одного краю області до іншого, де вона й опадає. Всі транспортні засоби Величезного розміру і менше по ходу хвилі несуться разом із хвилею на протилежний бік. Всі транспортні засоби Величезного розміру і менше, ударені хвилею, отримують 25% шанс перекинутися. Рівень води залишається підвищеним, поки закляття не скінчиться або не виберете інший ефект. Якщо цей ефект створив хвилю, хвиля буде повторюватися на початку наступного ходу, поки триває ефект «повінь».<br/><br/>'+

'Спрямування потоку. Ви змушуєте потік води в області переміщатися у вибраному вами напрямку, навіть якщо воді доведеться текти через перешкоди, вгору по стінах чи іншому непритаманному їй напрямку. В області закляття вода тече так, як ви вкажете, але опинившись за межами зони закляття, вона повертається до природної течії. Вода продовжує текти у вказаному вами напрямі, доки закляття активне або поки ви не оберете інший ефект.<br/><br/>'+

'Поділ води. Ви змушуєте воду в області розійтись убік, формуючи прохід. Прохід простягається в області закляття, а розділені води формують стіни з обох боків. Прохід існує, поки закляття активне, або поки ви не оберете інший ефект. Після цього вода впродовж раунду повільно заповнює прохід, відновлюючись до нормального рівня.<br/><br/>'+

'Фокус для створення закляття можна замінити на щіпку пилу та кілька крапель води.';

export const insect_plague = 'Нашестя комах';

export const insect_plague_details = 'Закликати рій сарани, що завдає пошкодження і робить місцевість слабко загородженою і важкопрохідною.';

export const insect_plague_expanded = 'Рой кусачої сарани заповнює сферу з радіусом 20 футів із центром на точці, обраній вами в межах дистанції. Сфера огинає кути. Сфера існує, поки активне закляття, та її місцевість слабко загороджена. Простір сфери є важкопрохідною місцевістю. Коли сфера зʼявляється, всі істоти, що знаходяться в ній, повинні пройти Випробування Статури. Істота отримує Колотий збиток 4d10 при провалі, або половину пошкодження при успіху. Істоти повинні також проходити Випробування Статури, коли вперше за хід входять в область закляття або закінчують там хід.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 6 рівня або вище, пошкодження збільшується на 1d10 за кожен рівень слоту вище пʼятого.<br/><br/>'+

'Фокус для створення закляття можна замінити на кілька крупинок цукру, трохи зернят та краплю жиру.';

export const destructive_wave = 'Руйнівна хвиля';

export const destructive_wave_details = 'Створити хвилю божественної енергії, що завдає пошкодження і збиває з ніг. Пошкодження Світлом можна замінити на 💀 Некротичний.';

export const destructive_wave_expanded = 'Ви топаєте по підлозі, створюючи хвилю божественної енергії, що виходить від вас. Всі істоти, вибрані вами в межах 30 футів, повинні досягти успіху в Випробування Статури, інакше вони отримають пошкодження Звуком 5к6, а також пошкодження 5к6 Світлом або Некротичною енергією (на ваш вибір), і будуть збиті з ніг. Істота, яка досягла успіху в Випробуванні, отримує половину пошкшодження і не збивається з ніг.';

export const spirit_guardians = 'Духовні вартові';

export const spirit_guardians_details = 'Оточити себе духами, що уповільнюють ворогів удвічі і завдають їм пошкодження. Прошкодження Світлом можна замінити на 💀 Некротичний.';

export const spirit_guardians_expanded = 'Ви викликаєте духів, які вас захищають. Поки закляття активне, вони заповнюють простір в межах 15 футів від вас. Якщо ви добрий чи нейтральний, вони можуть виглядати як ангели чи феї (на ваш вибір). Якщо ви злий, вони можуть виглядати поганню. Коли ви активуєте це закляття, ви можете вказати будь-яку кількість істот, яких ви бачите, на яких не буде діяти це закляття.<br/><br/>'+

'Швидкість інших істот у цій області зменшена вдвічі і коли істоти вперше за хід потрапляють в область або починають там хід, вони повинні пройти Випробування Мудрості. При провалі істота отримує пошкодження Світлом 3d8 (якщо ви добрий або нейтральний) або пошкодження Некротичною енергією 3d8 (якщо ви злий). За успіху істота отримує половину пошкодження.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 4 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище за третій.<br/><br/>'+

'Фокус для створення закляття можна замінити на символ віри.';

export const crusaders_mantle = 'Мантія хрестоносця';

export const crusaders_mantle_details = 'Додаткове пошкодження Світлом для себе та союзників в області.';

export const crusaders_mantle_expanded = 'Від вас починає виходити свята сила аурою з радіусом 30 футів, що пробуджує у дружніх істотах відвагу. Поки закляття активне, аура переміщається разом з вами, залишаючись із центром на вас. Перебуваючи в аурі, всі неворожі істоти (включаючи вас) при попаданні атакою зброєю завдають додаткової шкоди Світлом 1d4.';

export const revivify = 'Відродження';

export const revivify_details = 'Оживити істоту, яка померла не більше хвилини тому, не від старості. Не відновлює втрачені частини тіла.';

export const revivify_expanded = 'Ви торкаєтеся істоти, мертвої не більше хвилини. Ця істота повертається до життя з одним хітом. Це закляття неспроможне оживити істот, померлих від старості, і також не відновлює відсутні частини тіл.';

export const death_ward = 'Захист від смерті';

export const death_ward_details = 'Один раз запобігти втраті свідомості союзника при опусканні здоровʼя до 0.';

export const death_ward_expanded = 'Ви торкаєтесь істоти і даруєте їй певний захист від смерті. Коли здоровʼя цілі вперше опускаються до 0 через отримання пошкодження, здоровʼя цілі опускаються лише до 1, і закляття закінчується. Якщо закляття ще діє, а ціль потрапляє під дію ефекту, що вбиває її миттєво, не завдаючи пошкодження, цей ефект ігнорується, і закляття закінчується.';

export const mass_cure_wounds = 'Массове лікування ран';

export const mass_cure_wounds_details = 'Створити область, що лікує кількох союзників.';

export const mass_cure_wounds_expanded = 'Хвиля цілющої енергії виходить із точки, обраної вами в межах дистанції. Виберіть до шести істот у сфері з радіусом 30 футів із центром на цій точці. Всі цілі відновлюють здоровʼя в кількості, що дорівнює 3d8 + модифікатор вашої базової характеристики заклять.<br/><br/>'+

'Це закляття не має жодного ефекту на невмерлих і конструктів.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 6 або вище рівня, відновлюється додатково 1d8 здоровʼя за кожен рівень слоту вище пʼятого.';

export const raise_dead = 'Оживлення';

export const raise_dead_details = 'Оживити істоту, яка померла не більше 10 днів тому. Зцілює всі рани. Нейтралізує отрути та хвороби. Не знімає магічні хвороби та прокляття. Не відновлює втрачені частини тіла. Відроджений отримує штраф на всі кидки d20, що зменшується на 1 з кожним тривалим відпочинком.';

export const raise_dead_expanded = 'Ви повертаєте до життя мертву істоту, яку торкаєтеся, за умови, що вона мертва не більше 10 днів. Якщо душа цієї істоти може воззʼєднатися з тілом і хоче цього, істота воскресає з 1 пунтком здоровʼя.<br/><br/>'+

'Це закляття також нейтралізує всі отрути і зцілює немагічні хвороби, що були в момент смерті. Однак це закляття не знімає магічні хвороби, прокляття та подібні ефекти; якщо їх не зняти до накладання цього закляття, вони знову почнуть діяти, коли істота оживе.<br/><br/>'+

'Це закляття не може оживити невмерлих. Це закляття зцілює всі рани, але не відновлює відсутні частини тіла. Якщо істота не має життєво важливих частин тіла — наприклад, голови — закляття автоматично провалюється.<br/><br/>'+

'Повернення до життя – важке випробування. Ціль отримує штраф −4 до всіх кидків атаки, Випробувань та перевірок характеристик. Щоразу, коли ціль закінчує тривалий відпочинок, штраф зменшується на 1, допоки не зникне повністю.';

export const raise_dead_wizard = 'Оживлення каменем перетворення';

export const raise_dead_wizard_details = 'Оживити істоту, яка померла не більше 10 днів тому. Зцілює всі рани. Нейтралізує отрути та хвороби. Не знімає магічні хвороби та прокляття. Не відновлює втрачені частини тіла. Відроджений отримує штраф на всі кидки d20, що зменшується на 1 з кожним тривалим відпочинком.';

export const raise_dead_wizard_expanded = 'Ви можете спожити запас магії, що зберігається у Камені перетворення. Ви повертаєте до життя мертву істоту, яку торкаєтеся, за умови, що вона мертва не більше 10 днів. Якщо душа цієї істоти може воззʼєднатися з тілом і хоче цього, істота воскресає з 1 пунтком здоровʼя.<br/><br/>'+

'Це закляття також нейтралізує всі отрути і зцілює немагічні хвороби, що були в момент смерті. Однак це закляття не знімає магічні хвороби, прокляття та подібні ефекти; якщо їх не зняти до накладання цього закляття, вони знову почнуть діяти, коли істота оживе.<br/><br/>'+

'Це закляття не може оживити невмерлих. Це закляття зцілює всі рани, але не відновлює відсутні частини тіла. Якщо істота не має життєво важливих частин тіла — наприклад, голови — закляття автоматично провалюється.<br/><br/>'+

'Повернення до життя – важке випробування. Ціль отримує штраф −4 до всіх кидків атаки, Випробувань та перевірок характеристик. Щоразу, коли ціль закінчує тривалий відпочинок, штраф зменшується на 1, допоки не зникне повністю.<br/><br/>'+

'Ви можете Основною дією споживати запас магії, що зберігається у камені перетворення. Коли ви так робите, ваш камінь перетворення знищується, і не може бути використаний знову, поки ви не завершите тривалий відпочинок.';

export const nondetection = 'Невиявність';

export const nondetection_details = 'Сховати ціль від магії Віщування.';

export const nondetection_expanded = 'Поки закляття активне, ціль, до якої ви доторкнулися, прихована від заклять школи Віщування. Ціллю може бути згодна істота, місце або предмет, що не перевищує 10 футів по жодному з лінійних розмірів. Ціль не може стати ціллю магії школи Віщування, і її не сприймають магічні спостерігаючі сенсори.';

export const speak_with_dead = 'Розмова з мерцем';

export const speak_with_dead_details = 'Поставити 5 питань трупу.';

export const speak_with_dead_expanded = 'Ви даєте подобу життя і свідомості трупу, обраному в межах дистанції, дозволяючи йому відповідати на запитання, що задаються. У трупа має бути рота, і він не може бути невмерлим. Закляття провалюється, якщо цей труп був ціллю цього закляття протягом останніх 10 днів.<br/><br/>'+

'Поки закляття активне, ви можете поставити трупу пʼять запитань. Труп знає лише те, що знав за життя, включаючи відомі мови. Відповіді зазвичай короткі, загадкові чи невиразні, і труп не зобовʼязаний давати правдиву відповідь, якщо ви ворожі до нього або він розпізнає у вас ворога.<br/><br/>'+

'Це закляття не повертає душу в тіло істоти, а лише дає дух. Таким чином, труп не може дізнаватися про нову інформацію, не усвідомлює того, що відбувалося після його смерті, і не може розмірковувати про майбутні події.<br/><br/>'+

'Фокус для створення закляття можна замінити на підпалені пахощі.';

export const speak_with_dead_warlock = 'Розмова з мерцем [0]';

export const arcane_eye = 'Магічне око';
export const arcane_eye_warlock = 'Магічне око [0]';

export const arcane_eye_details = 'Створити маленьке невидиме око, що дає огляд в області. Можна переміщати Основною дією.';

export const arcane_eye_expanded = 'Ви створюєте невидиме магічне око в межах дистанції, яке ширяє в повітрі на час тривалості. Ви подумки сприймаєте зорову інформацію з ока, у якого звичайний зір і темний зір у межах 30 футів. Око дивиться у всіх напрямках. Основною дією ви можете перемістити очей на 30 футів в будь-якому напрямку. Око може віддалятися від вас на будь-яку відстань, але не може йти на інші плани існування. Суцільні перешкоди блокують рух ока, але воно може проходити через отвори діаметром 1 дюйм (2,5 сантиметра).<br/><br/>'+

'Фокус для создания заклинания можно заменить на хутро кажана.';

export const confusion = 'Збентеження';

export const confusion_details = 'Змусити ціль вести себе випадковим чином.';

export const confusion_expanded = 'Це закляття атакує і скручує свідомість істот, породжуючи галюцинації та провокуючи на необдумані дії. Усі істоти у сфері з радіусом 10 футів із центром на точці, обраній у межах дистанції, мають досягти успіху в Випробуванні Мудрості, інакше потраплять під дію ефекту. Ціль, що потрапила під ефект, не може здійснювати Реакції і повинна кидати d10 на початку кожного свого ходу, щоб визначити свою поведінку в цьому ході.<br/><br/>'+

'1: Істота використовує все своє переміщення рух у випадковому напрямі. Для визначення напрямку киньте d8, призначивши кожній грані свій напрямок. Дія в цьому ході істота не робить.<br/><br/>'+

'2-6: Істота у цьому ходу не переміщається і не робить дій.<br/><br/>'+

'7-8: Істота Основною дією здійснює ближню атаку випадковій істоті не більше досяжності. Якщо в межах досяжності немає інших істот, істота у цьому ході нічого не робить.<br/><br/>'+

'9-10: Істота може діяти та переміщатися як завжди.<br/><br/>'+

'У кінці кожного свого ходу ціль може здійснювати Випробування Мудрості. У разі успіху цієї цілі ефект закінчується.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 5 рівня або вище, радіус сфери збільшується на 5 футів за кожен рівень слоту вище за четвертий.<br/><br/>'+

'Фокус для створення закляття можна замінити на три горіхові шкаралупи.';

export const legend_lore = 'Знання легенд';

export const legend_lore_details = 'Дізнатися важливі знання про легендарний предмет, місце або особистість.';

export const legend_lore_expanded = 'Назвіть або опишіть особу, місце чи річ. закляття створює у вашій свідомості короткий опис важливих знань про цей предмет. Це можуть бути поточні розмови, забуті історії, або навіть таємні знання, які ніколи не виходять за межі певного кола посвячених. Чим більше інформації про обраний предмет у вас вже є, тим точніше і детальніше буде отримана інформація. Отримана інформація є правдивою, але може бути завуальованою.<br/><br/>'+

'Якщо названий предмет не має легендарної значущості, ви не отримуєте інформації.';

export const blink = 'Мерехтіння';

export const blink_details = 'Отримати шанс зникати з поля бою в Ефірній план, доки не настав ваш хід. На початку свого ходу ви зʼявляєтеся в межах дистанції від точки, в якій зникли.';

export const blink_expanded = 'Поки закляття активне, кидайте d20 у кінці кожного свого ходу. Якщо випаде «11» або вище, ви зникаєте на поточному плані існування та зʼявляєтеся на Ефірному Плані (закляття провалюється, якщо ви вже на цьому плані). Перебуваючи на Ефірному Плані, ви можете чути план, з якого прийшли, і бачичи все у відтінках сірого у межах 60 футів. Ви можете впливати лише на істот, що знаходяться на Ефірному Плані, і тільки такі істоти можуть впливати на вас. Істоти, що не знаходяться там, не можуть вас сприймати та взаємодіяти з вами, якщо вони не мають таких можливостей.<br/><br/>'+

'На початку наступного ходу, а також якщо закляття закінчиться, коли ви будете на Ефірному Плані, ви повертаєтеся у вільний простір на свій вибір, який ви бачите в межах 10 футів від місця, з якого ви зникли. Якщо в межах дистанції немає вільного простору, ви зʼявляєтеся у найближчому вільному просторі (вибраному випадковим чином, якщо є кілька відповідних місць).<br/><br/>'+

'Ви можете зупинити закляття Основною дією.';

export const polymorph = 'Поліморф';

export const polymorph_details = 'Змінити вигляд істоти.<br/>'+
'• Новий вигляд не небезпечніший або не вищий рівень цілі<br/>'+
'• Ціль отримує повне здоровʼя, фізіологічні та ментальні характеристики обраного вигляду<br/>'+
'• Особистість цілі зберігається<br/>'+
'• Не можна говорити, створювати закляття та користуватися спорядженням<br/>'+
'• Після завершення здоровʼя стане попереднім.<br/><br/>'+
'Якщо здоровʼя вигляду опустилося до 0, ви повертаєтеся у попередній вигляд з колишнім здоровʼям мінус пошкодження, що залишилося від останньої атаки по вигляду.';

export const polymorph_expanded = 'Це закляття надає новий вигляд істоті, яку ви бачите в межах дистанції. Незгодна істота повинна пройти Випробування Мудрості, щоб уникнути ефекту. Перекидні автоматично проходять Випробування. Це закляття не може вплинути на ціль, у якої 0 хітів. Перетворення триває, доки активне закляття, або поки що здоровʼя цілі не опуститься до 0, або вона не помре.<br/><br/>'+

'Новий вигляд може належати звірові, чия Небезпечність не перевищує Небезпечність цілі (або рівень цілі, якщо вона не має показника небезпеки). Ігрові характеристики цілі, включаючи значення ментальних характеристик, замінюються на характеристики обраного звіра. У цілі залишається світогляд та її особистість. Ціль приймає здоровʼя нового вигляду. При поверненні до природного вигляду кількість її здоровʼя буде як до перетворення. Якщо вона повертається у свій вигляд від того, що здоровʼя опустилися до 0, все зайве пошкодження перенесеться на природну подобу. Якщо зайве пошкодження не опускає здоровʼя природного вигляду істоти до 0, вона не втрачає свідомість. Дії істоти в новому вигляді обмежені тими, що доступні їй, і вона не може говорити, накладати закляття і робити інші дії, що вимагають рук чи мови.<br/><br/>'+
'Спорядження цілі зливається з її новим виглядом. Істота не може активувати, використовувати, надягати чи отримувати якісь інші переваги від свого спорядження.';

export const polymorph_warlock = 'Поліморф *';

export const polymorph_warlock_details = 'Змінити вигляд істоти.<br/>'+
'• Новий вигляд не небезпечніший або не вищий рівень цілі<br/>'+
'• Ціль отримує повне здоровʼя, фізіологічні та ментальні характеристики обраного вигляду<br/>'+
'• Особистість цілі зберігається<br/>'+
'• Не можна говорити, створювати закляття та користуватися спорядженням<br/>'+
'• Після завершення здоровʼя стане попереднім.<br/><br/>'+
'Якщо здоровʼя вигляду опустилося до 0, ви повертаєтеся у попередній вигляд з колишнім здоровʼям мінус пошкодження, що залишилося від останньої атаки по вигляду.<br/><br/>'+
'Можна використати тільки один раз на день.';

export const polymorph_warlock_expanded = polymorph_expanded+'<br/><br/><span class="rare-text">* Ви можете створити це закляття один раз. Ви не можете зробити це повторно, доки не закінчите тривалий відпочинок.</span>';

export const confusion_warlock = 'Збентеження *';

export const confusion_warlock_details = 'Змусити ціль вести себе випадковим чином. Можна використати тільки один раз на день.';

export const confusion_warlock_expanded = confusion_expanded+'<br/><br/><span class="rare-text">* Ви можете створити це закляття один раз. Ви не можете зробити це повторно, доки не закінчите тривалий відпочинок.</span>';

export const polymorph_wizard = 'Поліморф чарівника';

export const polymorph_wizard_details = 'Змінити свій вигляд.<br/>'+
'• Новий вигляд не небезпечніше 1<br/>'+
'• Ви отримуєте повне здоровʼя, фізіологічні та ментальні характеристики обраного вигляду<br/>'+
'• Особистість зберігається<br/>'+
'• Не можна говорити, створювати закляття та користуватися спорядженням<br/>'+
'• Після завершення здоровʼя стане попереднім.<br/><br/>'+
'Якщо здоровʼя вигляду опустилося до 0, ви повертаєтеся у попередній вигляд з колишнім здоровʼям мінус збитки, що залишилися, від останньої атаки по вигляду.';

export const polymorph_wizard_expanded = 'Це закляття надає вам нового вигляду. Новий вигляд може належати звірові, чия Нбезпечність не перевищує 1. Ваші ігрові характеристики, включаючи значення ментальних характеристик, замінюються характеристиками обраного звіра. У вас залишається світогляд і особистість. Ви приймаєте здоровʼя нового вигляду. При поверненні до природного вигляду кількість здоровʼя буде як до перетворення. Якщо ви повертаєтеся у свій вигляд від того, що здоровʼя опустилося до 0, все зайве пошкодження перенесеться на природний вигляд. Якщо зайве пошкодження не опускає здоровʼя природного вигляду до 0, ви не втрачаєте свідомість. Дії в новому вигляді обмежені тими, що доступні вигляду, і не можна говорити, накладати закляття та здійснювати інші дії, що вимагають рук або мови.<br/><br/>'+
'Спорядження зливається з новим виглядом. Не можна активувати, використовувати, надягати чи отримувати якісь інші переваги від свого спорядження.<br/><br/>'+
'Фокус для створення закляття можна замінити на кокон гусениці.';

export const modify_memory = 'Підміна спогадів';

export const modify_memory_details = 'Підмінити цілі спогади про події тривалістю до 10 хвилин, в межах давнини.';

export const modify_memory_expanded = 'Ви намагаєтесь змінити спогади іншої істоти. Одна істота, яку ви бачите, має пройти Випробування Мудрості. Якщо ви з ним бʼєтеся, воно робить Випробування з перевагою. При провалі ціль стає зачарованою вами на час дії закляття. Зачарована ціль недієздатна і не усвідомлює оточення, але все ще може чути вас.<br/><br/>'+
'Поки триває ця зачарування, ви можете вплинути на спогад цілі, що відбувся за останні 24 години і триває не більше 10 хвилин. Ви можете повністю стерти памʼять про цю подію, дозволити цілі згадати все з приголомшливою точністю, змінити деталі цієї події, або замінити цей спогад на щось зовсім інше. Ви повинні вимовити цілі її нові спогади, і вона повинна розуміти вашу мову, щоб змінені спогади посіли місце. Її свідомість сама заповнить прогалини в деталях вашого опису.<br/><br/>'+
'Змінена памʼять не обовʼязково впливатиме на поведінку істоти, особливо якщо вона суперечить її схильностям, світогляду чи вірі. Нелогічна змінена памʼять, така як використання спогадів про те, як цілі подобається купатися в кислоті, відкидаються, як поганий сон. Майстер може вважати, що змінена памʼять надто абсурдна, щоб впливати на істоту значною мірою. Якщо ціль отримає пошкодження або стане ціллю іншого закляття, це закляття закінчується, і спогади цілі не змінюються. Якщо закляття закінчується до того, як ви закінчите описувати нові спогади, памʼять істоти не торкнеться. В іншому випадку змінені спогади набувають чинності наприкінці дії закляття.<br/><br/>'+
'Закляття Зняття прокляття і Вище відновлення, накладені на ціль, відновлюють її справжню памʼять.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 6 рівня або вище, ви можете змінити спогади про події, що відбулися до 7 днів тому [6], 30 днів тому [7], 1 рік тому [8], або будь-якої миті в житті істоти [9].';

export const dominate_person = 'Підкорення особи';

export const dominate_person_details = 'Отримати контроль над діями цілі.';

export const dominate_person_expanded = 'Ви намагаєтеся взяти під контроль гуманоїда, якого бачите у межах дистанції. Він має досягти успіху у Випробуванні Мудрості, інакше стане зачарованим вами на час дії закляття. Якщо ви або дружні вам істоти борються з ним, він здійснює Випробування з перевагою.<br/><br/>'+
'Поки істота зачарована, ви маєте телепатичний звʼязок з ним, поки ви обидва перебуваєте на одному плані існування. За допомогою цього звʼязку ви можете віддавати йому команди, поки перебуваєте у свідомості (Основна дія не потрібна), і вона повинна їх виконувати. Ви можете вказати загальну дію, таку як «атакуй геть ту істоту», «біжи звідси» або «принеси ось це». Якщо істота виконує наказ і не отримує подальших вказівок від вас, вона в міру своїх сил обороняється та захищає себе.<br/><br/>'+
'Ви можете Основною дією захопити повний контроль над ціллю. До кінця вашого наступного ходу істота здійснює лише вибрані вами дії, і не робить нічого, що ви їй не дозволяєте. В цей час ви можете змушувати істоту здійснювати Реакції, але при цьому ви витрачаєте і свою Реакцію теж.<br/><br/>'+
'Щоразу, коли ціль отримує пошкодження, вона проходить нове Випробування Мудрості від цього закляття. У разі успіху закляття закінчується.<br/><br/>'+
'Якщо ви накладаєте це закляття з рівнем слоту 6, концентрацію можна підтримувати до 10 хвилин. Якщо ви накладаєте це закляття з рівнем слоту 7, концентрацію можна підтримувати до 1 години. Якщо ви накладаєте це закляття з рівнем 8 або вище, концентрацію можна підтримувати до 8 годин.';

export const wind_wall = 'Стіна вітрів';

export const wind_wall_details = 'Створити лінію вітру довільної форми, висотою 15ф і товщиною до 1ф, що завдає пошкодження, затримує Маленьких та газоподібних істот, туман, дим, і відбиває дрібні снаряди.';

export const wind_wall_expanded = 'У точці землі, обраної вами у межах дистанції, починає дути сильний вітер. Ви можете зробити стіну довжиною до 50 футів, висотою до 15 футів і завтовшки до 1 фута. Ви можете як завгодно згинати стіну, аби це був безперервний шлях по землі.<br/><br/>'+
'Стіна існує, поки активне закляття. Коли стіна зʼявляється, всі істоти в її області повинні пройти Випробування Сили. Істота отримує Дробильне пошкодження 3d8 при провалі, або половину цього пошкодження при успіху. <br/><br/>'+
'Сильний вітер стримує туман, дим та інші гази. Маленькі та ще менші літаючі істоти та предмети не можуть пройти через цю стіну. Легкі, сипкі матеріали, що опинилися в стіні, злітають нагору. Стріли, болти та інші звичайні боєприпаси, випущені в цілі, що знаходяться за стіною, відхиляються вгору та автоматично промахуються (валуни, кинуті велетнями та облоговими машинами, а також подібні боєприпаси, не відхиляються). Істоти в газоподібній формі не можуть пройти через неї.<br/><br/>'+
'Фокус для створення закляття можна замінити на крихітне віяло та перо екзотичного походження.';

export const dominate_beast = 'Підкорення звіра';

export const dominate_beast_details = 'Отримати контроль над діями звіра.';

export const dominate_beast_expanded = 'Ви намагаєтеся взяти під контроль звіра, якого бачите у межах дистанції. Він має досягти успіху у Випробуванні Мудрості, інакше стане зачарованим вами на час дії закляття. Якщо ви або дружні вам істоти борються з ним, він здійснює Випробування з перевагою.<br/><br/>'+
'Поки істота зачарована, ви маєте телепатичний звʼязок з ним, поки ви обидва перебуваєте на одному плані існування. За допомогою цього звʼязку ви можете віддавати йому команди, поки перебуваєте у свідомості (Основна дія не потрібна), і вона повинна їх виконувати. Ви можете вказати загальну дію, таку як «атакуй геть ту істоту», «біжи звідси» або «принеси ось це». Якщо істота виконує наказ і не отримує подальших вказівок від вас, вона в міру своїх сил обороняється та захищає себе.<br/><br/>'+
'Ви можете Основною дією захопити повний контроль над ціллю. До кінця вашого наступного ходу істота здійснює лише вибрані вами дії, і не робить нічого, що ви їй не дозволяєте. В цей час ви можете змушувати істоту здійснювати Реакції, але при цьому ви витрачаєте і свою Реакцію теж.<br/><br/>'+
'Щоразу, коли ціль отримує пошкодження, вона проходить нове Випробування Мудрості від цього закляття. У разі успіху закляття закінчується.<br/><br/>'+
'Якщо ви накладаєте це закляття з рівнем слоту 6, концентрацію можна підтримувати до 10 хвилин. Якщо ви накладаєте це закляття з рівнем слоту 7, концентрацію можна підтримувати до 1 години. Якщо ви накладаєте це закляття з рівнем 8 або вище, концентрацію можна підтримувати до 8 годин.';

export const grasping_vine = 'Ціпка лоза';

export const grasping_vine_details = 'Створити лозу в межах дальності та Бонусньою дією наказувати їй хапати і притягувати до себе ворогів.';

export const grasping_vine_expanded = 'Ви створюєте лозу, що виростає із землі у вільному просторі на ваш вибір, видимому вами в межах дистанції. Коли ви накладаєте це закляття, ви можете наказати лозі вхопити видиму вами істоту, що знаходиться в межах 30 футів від неї. Ця істота має досягти успіху у Випробуванні Спритності, інакше її підтягне на 20 футів до лози. Поки закляття активне, ви можете в кожному своєму ході бонусною дією наказувати лозі схопити ту саму чи іншу істоту.';

export const daylight = 'Денне світло';

export const daylight_details = 'Створити яскраве світло з точки чи обʼєкта. Розсіює магічну пітьму.';

export const daylight_expanded = 'З точки, вибраної вами в межах дистанції, поширюється сфера світла з радіусом 60 футів. Сфера випромінює яскраве світло та тьмяне світло в межах ще 60 футів. Якщо обрана вами точка знаходиться на обʼєкті, який ви тримаєте або на обʼєкті, який не носять або не тримають інші істоти, світло виходить з предмета і переміщається разом з ним. Якщо повністю накрити джерело світла непрозорим предметом, наприклад чашею або шоломом, світло буде заблоковане.<br/><br/>'+
'Якщо частина зони цього закляття накриває зону темряви, створеної закляттям з рівнем не вище 3, закляття, що створило темряву, розсіюється.';

export const slow = 'Уповільнення';

export const slow_details = 'Дати цілям в області:<br/>'+
'• ÷2 Швидкість<br/>'+
'• -2 Броня<br/>'+
'• -2 на Випробування Спритності<br/>'+
'• Не можна здійснювати Реакції<br/>'+
'• Не можна робити більше однієї атаки в хід<br/>'+
'• Не можна здійснювати Основну та Бонусну дію разом<br/>'+
'• Складніше створювати закляття з кастом Основна дія';

export const slow_expanded = 'Ви змінюєте час для шести істот на вибір, що знаходяться в кубі з довжиною ребра 40 футів в межах дистанції. Всі цілі мають досягти успіху у Випробуванні Мудрості, інакше вони потраплять під дію закляття на час його тривалості. Швидкість істоти, що потрапила під дію, зменшаться вдвічі, вона отримує штраф −2 до Броні і Випробувань Спритності, і вона не може здійснювати Реакції. У свій хід воно може використовувати або Основну дію, або Бонусну дію, і не може використовувати обидва. Незалежно від умінь та магічних предметів, вона не може у свій хід здійснювати більше однієї ближньої чи далекобійної атаки. Якщо істота спробує активувати закляття з часом накладання Основна дія, киньте d20. Якщо випаде «11» або більше, закляття не набуде чинності до його наступного ходу, і в цьому ході воно має використовувати Основну дію для завершення закляття. Якщо вона цього не зробить, закляття буде витрачено. Істота, що під дією цього закляття, здійснює нові Випробування Мудрості наприкінці кожного свого ходу. При успіху ефект для нього закінчується.<br/><br/>'+
'Фокус для створення закляття можна замінити на краплю патоки.';

export const jump_warlock = 'Стрибок чаклуна';

export const jump_warlock_details = 'Подовжити свій стрибок втричі.';

export const jump_warlock_expanded = 'Поки закляття активне, ваша відстань стрибка збільшується втричі.';

export const levitate_warlock = 'Левітація чаклуна';

export const levitate_warlock_details = 'Відірватися від землі і ширяти із вашою швидкістю пішки.';

export const levitate_warlock_expanded = 'Ви піднімаєтесь вертикально на відстань до 20 футів, і залишаєтесь висіти там на час дії закляття. Ви можете переміщатися тільки відштовхуючись від твердих предметів і поверхонь (таких як стіни і стеля), а також підтягуючись за них, що дозволяє переміщатися так, наче ви лазаєте. Ви можете частиною переміщення рухатися вгору або вниз. Коли закляття закінчується, якщо ви все ще перебуває у повітрі, ви плавно опускаєтеся на землю.';

export const slow_warlock = 'Уповільнення *';

export const slow_warlock_details = 'Дати цілям в області:<br/>'+
'• ÷2 Швидкість<br/>'+
'• -2 Броня<br/>'+
'• -2 на Випробування Спритності<br/>'+
'• Не можна здійснювати Реакції<br/>'+
'• Не можна робити більше однієї атаки в хід<br/>'+
'• Не можна здійснювати Основну та Бонусну дію разом<br/>'+
'• Складніше створювати закляття з кастом Основна дія';

export const slow_warlock_expanded = 'Ви змінюєте час для шести істот на вибір, що знаходяться в кубі з довжиною ребра 40 футів в межах дистанції. Всі цілі мають досягти успіху у Випробуванні Мудрості, інакше вони потраплять під дію закляття на час його тривалості. Швидкість істоти, що потрапила під дію, зменшаться вдвічі, вона отримує штраф −2 до Броні і Випробувань Спритності, і вона не може здійснювати Реакції. У свій хід воно може використовувати або Основну дію, або Бонусну дію, і не може використовувати обидва. Незалежно від умінь та магічних предметів, вона не може у свій хід здійснювати більше однієї ближньої чи далекобійної атаки. Якщо істота спробує активувати закляття з часом накладання Основна дія, киньте d20. Якщо випаде «11» або більше, закляття не набуде чинності до його наступного ходу, і в цьому ході воно має використовувати Основну дію для завершення закляття. Якщо вона цього не зробить, закляття буде витрачено. Істота, що під дією цього закляття, здійснює нові Випробування Мудрості наприкінці кожного свого ходу. При успіху ефект для нього закінчується.<br/><br/>'+
'Фокус для створення закляття можна замінити на краплю патоки.<br/><br/>' + 
'<span class="rare-text">* Ви можете один раз створити закляття, використовуючи слот мани. Ви не можете зробити це повторно, поки не закінчите тривалий відпочинок.</span>';

export const stinking_cloud = 'Смердюча хмара';

export const stinking_cloud_details = 'Створити область нудотного газу, що робить місцевість сильно загородженою, і забирає у істот Основну дію.';

export const stinking_cloud_expanded = 'Ви створюєте сферу з радіусом 20 футів жовтого нудотворного газу із центром на точці в межах дистанції. Ця хмара обходить кути, і її простір — сильно затулена місцевість. Хмара тримається у повітрі, доки активне закляття.<br/><br/>'+
'Всі істоти, що знаходяться повністю в хмарі на початку свого ходу, повинні пройти Випробування Статури від отрути. При провалі істота втрачає свою Основну дію, тому що її нудить і її голова паморочиться. Істоти, які не зобовʼязані дихати, а також мають імунітет до отрути, автоматично досягають успіху у цьому Випробуванні.<br/><br/>'+
'Помірний вітер (щонайменше 10 миль на годину) розсіює хмару через 4 раунди. Сильний вітер (щонайменше 20 миль на годину) розсіює його через 1 раунд.<br/><br/>'+
'Фокус для створення закляття можна замінити на тухле яйце або кілька гнилих листків капусти.';

export const animate_dead = 'Підняти мерця';
export const animate_dead_wizard = 'Підняти мерців';

export const animate_dead_details = 'Оживити труп із можливістю віддавати йому накази. Одночасно можно мати до 4 таких істот.';

export const animate_dead_expanded = 'Це закляття створює прислужливого невмерлого. Виберіть купу кісток або труп гуманоїду Середнього або Маленького розміру в межах дистанції. Ваше закляття наділяє ціль мерзенною подобою життя, роблячи з нього істоту-невмерлого. Ціль стає Скелетом, якщо ви вибирали кістки або Зомбі, якщо ви вибрали труп.<br/><br/>'+
'У кожен свій хід ви можете Бонусною дією подумки дати наказ істоті, створеній цим закляттям, якщо вона знаходиться в межах 60 футів від вас. Якщо ви контролюєте кілька істот, ви можете віддавати один і той самий наказ будь-якій кількості з них одночасно. Ви вирішуєте, яку дію здійснить ця істота, і куди вона переміститься в наступному ході, або ви можете віддати загальний наказ, наприклад, охороняти кімнату чи коридор. Якщо ви не дасте команду, істота буде лише захищатися від ворогів. Отримавши наказ, істота продовжує його виконувати, доки завдання не буде виконано.<br/><br/>'+
'Істота знаходиться під вашим контролем 24 години, після чого перестає слухатися команд. Для підтримки контролю ще на 24 години ви повинні накласти це закляття на нього ще раз до закінчення 24-годинного періоду. Таке використання закляття лише підтримує контроль над уже створеними істотами, кількість яких не може бути більшою за чотири, і не оживляє нових.<br/><br/>'+
'Якщо ви накладаєте це закляття, використовуючи слот 4 рівня або вище, ви піднімаєте або продовжуєте контроль над двома додатковими істотами за кожен рівень слоту вище третього. Для кожної істоти, що створюється, потрібна окрема купа кісток або труп.<br/><br/>'+
'Фокус для створення закляття можна замінити на краплю крові, шматочок плоті та дрібку кісткового пилу.';

export const speak_with_plants = 'Розмова з рослинами';

export const speak_with_plants_details = 'Запитати рослин навколо про недавні події або попросити допомогти (дозволити пройти, перегородити шлях переслідувачам, і т.д.)';

export const speak_with_plants_expanded = 'Ви наділяєте рослини в межах 30 футів від себе обмеженою свідомістю та подобою життя, дозволяючи їм спілкуватися з вами та виконувати ваші прості інструкції.<br/><br/>'+

'Ви можете запитувати їх про події, що відбувалися в області закляття за останній день, отримуючи інформацію про істот, погоду та інші обставини.<br/><br/>'+

'Ви можете також перетворити на час дії закляття важкопрохідну місцевість, викликану рослинами (кущі та чагарники), на звичайну місцевість. Ви можете перетворити на час дії закляття звичайну місцевість у важкопрохідну, якщо там є рослини, наприклад, змушуючи гілки та ліани хапати переслідувачів. На розсуд Майстра, рослини можуть виконувати й інші завдання.<br/><br/>'+

'Це закляття не дозволяє рослинам викопуватися і переміщатися, але вони можуть вільно рухати гілками, паростками та стеблами. Якщо в області закляття є рослинна істота, ви можете спілкуватися з нею, начебто у вас була спільна мова, але ви не отримуєте магічної здатності впливати на неї. Це закляття може змусити рослини, створені закляттям Обплутування, відпустити істоту.';

export const speak_with_plants_ranger = 'Розмова з рослинами [0*]';

export const speak_with_plants_ranger_details = speak_with_plants_details+primal_awareness_details;

export const speak_with_plants_ranger_expanded = speak_with_plants_expanded+primal_awareness_expanded;

export const locate_creature = 'Пошук істоти';

export const locate_creature_details = 'Знайти конкретну істоту або найближчого представника знайомого вам виду в межах дистанції та не за річкою.';

export const locate_creature_expanded = 'Опишіть чи назвіть відому вам істоту. Ви відчуваєте напрям до цієї істоти, поки вона знаходиться в межах 1000 футів від вас. Якщо істота переміщається, ви дізнаєтеся, в який бік вона прямує. Це закляття може шукати конкретну відому вам істоту, або найближчу істоту конкретного виду (наприклад, людина або єдиноріг), якщо ви хоча б раз бачили таку істоту поблизу (близько 30 футів).<br/><br/>'+

'Якщо описана або названа вами істота знаходиться в іншому вигляді, наприклад під ефектом закляття Поліморф, це закляття не знайде цю істоту. Це закляття не може виявити істот, якщо прямий шлях між вами перерізаний поточною водою шириною щонайменше 10 футів.<br/><br/>'+

'Фокус для створення закляття можна замінити на шматочок хутра шукача.';

export const locate_creature_ranger = locate_creature + ' [0*]';

export const locate_creature_ranger_details = locate_creature_details+primal_awareness_details;

export const locate_creature_ranger_expanded = locate_creature_expanded+primal_awareness_expanded;

export const water_walk = 'Ходіння по воді';

export const water_walk_details = 'Дати союзникам здатні ходити по рідкій поверхні. Дозволяє підняти істоту, що потопає.';

export const water_walk_expanded = 'Це закляття дарує здатність переміщатися рідкою поверхнею — такою як вода, кислота, бруд, сніг, сипучий пісок або лава — якби це була нешкідлива тверда поверхня (істоти, що йдуть по рідкій лаві, все одно отримують пошкодження від жару). До десяти згодних істот, видимих вами в межах дистанції, отримують цю здатність на час дії закляття. Якщо ви робите ціллю істоту, занурену в рідину, закляття піднімає її на поверхню зі швидкістю 60 футів у раунд.<br/><br/>'+

'Фокус для створення закляття можна замінити на пробку.';

export const lightning_bolt = 'Блискавка';

export const lightning_bolt_details = 'Випустити промінь блискавки, що завдає пошкодження і підпалює горючі предмети.';

export const lightning_bolt_expanded = 'Розряд блискавки формує лінію довжиною 100 футів та шириною 5 футів, що вилітає у вибраному вами напрямку. Усі істоти в лінії мають пройти Випробування Спритності. При провалі істота отримує пошкодження Струмом 8d6, або половину пошкодження при успіху. Блискавка підпалює горючі предмети в області, що вражається, які ніхто не тримає і не носить.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 4 рівня або вище, пошкодження збільшується на 1d6 за кожен рівень слоту вище третього.<br/><br/>'+

'Фокус для створення закляття можна замінити на шматочок хутра та жезла з бурштину, кристала або скла.';

export const meld_into_stone = 'Злиття з каменем';

export const meld_into_stone_details = 'Увійти до каміння достатнього розміру. Там вас неможливо виявити без допомоги магії. Ви не можете бачити, але можете злишати з <span class="debuff">перешкодою</span>.';

export const meld_into_stone_expanded = 'Ви входите у камʼяний предмет або поверхню, досить велику, щоб там помістилося ваше тіло, і зливаєтеся на час дії закляття разом із усім спорядженням із каменем. Використовуючи переміщення, ви входите у камінь у точці, якою можете торкнутися. Ваша присутність абсолютно непомітна і без допомоги магії виявити вас неможливо. Злившись з каменем, ви не можете бачити, що відбувається навколо, і всі перевірки Уважності, які здійснені, щоб слухати, відбуваються з перешкодою. Ви розумієте хід часу і можете в камені накладати закляття на себе. Невеликі фізичні пошкодження каменю не завдають вам пошкодження, але часткове руйнування або зміна його форми (так, що ви більше всередині не поміститеся) виганяють вас і завдають Дробильне пошкодження 6d6. Повне руйнування каменю (або перетворення його в іншу субстанцію) виганяє вас і заподіює Дробильне пошкодження 50. Якщо вас виганяють, ви падаєте вниз у вільному просторі, найближчому до місця, в якому ви входили в камінь.<br/><br/>' +

'Ви можете переміщенням вийти з каменю в тому місці, де ви входили, що закінчує це закляття. Інакше ви не можете переміщатися.';

export const stone_shape = 'Форма каменя';

export const stone_shape_details = 'Надати каменю розміром не більше області, бажану форму.';

export const stone_shape_expanded = 'Ви доторкаєтеся до камʼяного предмета з розміром не більшим за Середній, або каменю, що не перевищує жодного з вимірів більше 5 футів, і надаєте йому будь-яку форму. Так, наприклад, ви можете перетворити великий камінь на зброю, статую або труну, або створити невеликий прохід у стіні, якщо ця стіна завтовшки менше 5 футів. Ви можете також скоригувати камʼяні двері або обрамлення, щоб замкнути їх наглухо. У створюваного вами предмета можуть бути дві дверні петлі і клямка, але дрібніші механічні деталі неможливі.<br/><br/>'+

'Фокус для створення закляття можна замінити на мʼяку глину, з якої робиться груба копія бажаного камʼяного предмета.';

export const passwall = 'Прохід';

export const passwall_details = 'Створити крізь твердий матеріал прохід до 5ф завширшки і до 8ф заввишки, нешкідливий для споруди і істот, що знаходяться в ньому при закритті.';

export const passwall_expanded = 'У точці, яку ви бачите в межах дистанції на деревʼяній, оштукатуреній або камʼяній поверхні (такі як стіна, стеля або підлога) зʼявляється прохід, що існує, поки активне закляття. Ви самі визначаєте габарити проходу: до 5 футів завширшки, до 8 футів заввишки і до 20 футів завглибшки. Цей прохід не дестабілізує саму споруду. Коли прохід зникає, всі істоти та предмети, що все ще знаходяться в ньому, безпечно виштовхуються у вільний простір, найближчий до поверхні, на якій ви активували це закляття.<br/><br/>'+

'Фокус для створення закляття можна замінити на щіпку кунжутного насіння.';

export const divination = 'Ворожіння';

export const divination_details = 'Отримати від бога чесну відповідь про ціль, подію, або діяльність, яка відбудеться протягом наступних 7 днів. Не враховує обставини, здатні змінити результат подій.';

export const divination_expanded = 'Ваша магія і підношення повʼязують вас із богом чи його слугою. Ви ставите одне питання про конкретну ціль, подію або діяльність, яка відбудеться протягом наступних 7 днів. Майстер дає на це чесну відповідь. Це може бути коротка фраза, загадковий вірш чи знамення. Це закляття не враховує всі можливі обставини, здатні змінити результат подій, такі як накладання додаткових заклять та втрата та придбання супутників.<br/><br/>'+
'Якщо ви накладаєте це закляття кілька разів до завершення тривалого відпочинку, існує накопичувальний шанс 25 відсотків за кожне використання, починаючи з другого, що ви отримаєте випадкову відповідь. Майстер робить цей кидок потай.';

export const dream = 'Сновидіння';

export const dream_details = 'Сформувати сон цілі, здатний навіть завдати пошкодження і завадити отримати відпочинок. Якщо у вас є частина тіла цілі, вона здійснює Випробування з перешкодою.';

export const dream_expanded = 'Це закляття формує сон іншої істоти. Виберіть ціллю цього закляття відому вам істоту. Ціль має бути на одному плані існування з вами. Цим закляттям не можна звʼязатися з істотами, які не сплять, наприклад, з ельфами.<br/><br/>'+

'Ви або згодна істота, якої ви торкаєтеся, входите в транс, стаючи посильним. Перебуваючи у трансі, посильний усвідомлює своє оточення, але не може здійснювати дії і переміщатися. Якщо ціль спить, посильний зʼявляється у її сні і може розмовляти з нею, поки та спить, і поки що закляття активне. Посильний може формувати оточення сну, створюючи місцевість, предмети та інші образи. Посильний може будь-коли вийти з трансу, закінчивши ефект цього закляття. Після пробудження ціль чудово памʼятає сон. Ви можете зробити посильного кошмарним для цілі. У цьому випадку посильний може передати послання не довше десяти слів, і ціль зобовʼязана здійснити Випробування Мудрості.<br/><br/>'+

'При провалі відлуння кошмару тривають весь сон, і ціль не отримує переваг від цього відпочинку. Крім того, коли ціль прокидається, вона отримує пошкодження Психічною енергією 3d6. Якщо у вас є частина тіла, шматок волосся, обрізки нігтів або інша подібна частина тіла цілі, вона здійснює Випробування з перешкодою.<br/><br/>'+

'Якщо в момент накладання закляття ціль не спить, посильний це дізнається, і може або закінчити транс (і закляття), або почекати, поки ціль засне, і тоді він зʼявиться уві сні цілі.<br/><br/>'+

'Фокус для створення закляття можна замінити на жменю піску, крапля чорнила та перо, висмикнуте у сплячого птаха.';

export const greater_invisibility = 'Стійка невидимість';

export const greater_invisibility_details = 'Зробити себе чи союзника невидимим.';

export const greater_invisibility_expanded = 'Ви або істота, яку ви торкаєтеся, стаєте невидимою, поки активне закляття. Все, що ціль носить і тримає, стає невидимим, поки перебуває у цієї особи.';

export const create_food_and_water = 'Створення їжі та води';

export const create_food_and_water_details = 'Створити швидкопсувний добовий раціон без смаку на 15 гуманоїдів та 5 скакунів. Вода з раціону не псується.';

export const create_food_and_water_expanded = 'Ви створюєте 25 кг їжі та 100 літрів води на землі або в контейнері в межах дистанції, яких достатньо для харчування пʼятнадцяти гуманоїдів або пʼятьох скакунів на 24 години. Їжа несмачна, але ситна, і псується, якщо її не зʼїсти за 24 години. Вода чиста, і вона не псується.';

export const hallucinatory_terrain = 'Міраж';

export const hallucinatory_terrain_details = 'Змусити місцевість виглядати як інша місцевість. Рукотворні обʼєкти та істоти не змінюють вигляд. Тактильні відчуття місцевості не змінюються.';

export const hallucinatory_terrain_expanded = 'Ви змушуєте природну місцевість у межах куба з довжиною ребра 150 футів, що знаходиться в межах дистанції, виглядати, звучати та пахнути як інша природна місцевість. Таким чином, поле або дорогу можна зробити схожою на болото, пагорб, ущелину або іншу важкопрохідну або непрохідну місцевість. Ставок можна зробити схожим на травʼяний лужок, прірву зробити пологим спуском, а камʼянистий яр — широкою і рівною дорогою. Рукотворні будови, спорядження та істоти в області не змінюють вигляду. Тактильні відчуття місцевості не змінюються, тому істоти, що входять до неї, швидше за все, зрозуміють обман.<br/><br/>'+

'Якщо на дотик різниця не очевидна, істота, яка ретельно досліджує ілюзію, може здійснити перевірку Розслідування проти Сладності ваших заклять, щоб не повірити. Істота, здатна розпізнавати ілюзії, бачить напівпрозорий образ, накладений місцевість.<br/><br/>'+

'Фокус для створення закляття можна замінити на камінь, гілку, і шматочок зеленої рослини.';

export const blight = 'Всихання';

export const blight_details = 'Забрати з істоти рідину та життєві сили. Рослинні створіння проходять Випробування з перешкодою і отримують максимальне пошкодження.';

export const blight_expanded = 'Некротична енергія омиває обрану вами істоту, яку ви бачите в межах дистанції, забираючи з неї рідину та життєві сили. Ціль повинна пройти Випробування Статури. Ціль отримує пошкодження Некротичною енергією 8d8 у разі провалу або половину цього пошкодження у разі успіху. Якщо ціль - рослинна істота або магічна рослина, вона здійснює Впиробування з перешкодою, і закляття завдає їй максимальне пошкодження. Якщо ціль - немагічна рослина, що не є істотою, наприклад, дерево або кущ, вона не робить Випробування, а просто засихає і вмирає. Це закляття не має жодного ефекту на нежить і конструктів.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 5 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище четвертого.';

export const water_breathing = 'Підводне дихання';

export const water_breathing_details = 'Дати істотам в області здатність дихати під водою.';

export const water_breathing_expanded = 'Це закляття дарує на час своєї дії до десяти істотам, які ви видимі в межах дистанції, здатність дихати під водою. Ці істоти зберігають і звичайне дихання.<br/><br/>'+

'Фокус для закляття можна замінити на очерет або соломинку.';

export const conjure_elemental = 'Виклик елементаля';

export const conjure_elemental_details = 'З відповідної сировини створити союзного елементаля не вище заданого рівня небезпеки, що виконує накази для яких не потрібно витрачати дії. <span class="debuff">При втраті концентрації елементаль стає ворожим.</span>';

export const conjure_elemental_expanded = 'Ви викликаєте елементаля, що слухаєьться вас. Виберіть область води, повітря, землі або вогню, що уміститься в куб з довжиною ребра 10 футів. У вільному просторі в межах 50 футів від обраної області зʼявляється відповідний місцевості елементаль з показником небезпеки 5 або менше. Наприклад, з багаття виходить Вогненний елементаль, а Земляний елементаль вибирається з-під землі. Елементаль зникає, коли його здоровʼя опускається до 0 або коли закляття закінчується. Елементаль дружній до вас і ваших супутників, поки закляття активне.<br/><br/>'+

'Здійсніть перевірку ініціативи за елементаля, і він здійснюватиме свої власні ходи. Він підкоряється відданим вами усно командам (дія не потрібна). Якщо ви не віддаєте команду, він захищається від ворожих істот, але дій не робить. Якщо ваша концентрація порушена, елементаль не зникає. Натомість ви втрачаєте над ним контроль, і він стає ворожим до вас і ваших супутників, і може напасти. Некерований елементаль не може бути відпущений вами, але він зникає через 1 годину після того, як був викликаний.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 6 рівня або вище, показник небезпеки збільшується на 1 за кожен рівень слоту вище пʼятого.<br/><br/>'+

'Фокус для створення закляття можна замінити на воду і пісок для водяного, пахощі для повітряного, що горить, мʼяку глину для земляного або сірку і фосфор для вогняного.';

export const conjure_elemental_warlock = conjure_elemental + ' *';

export const conjure_elemental_warlock_details = conjure_elemental_details;

export const conjure_elemental_warlock_expanded = conjure_elemental_expanded+'<br/><br/><span class="rare-text">* Ви можете один раз створити закляття, використовуючи слот мани. Ви не можете зробити це повторно, поки не закінчите тривалий відпочинок.</span>';

export const cloudkill = 'Вбивча хмара';

export const cloudkill_details = 'Створити хмару отруйного туману, що повільно рухається від вас, роблячи місцевість в області сильно загородженою і завдаючи пошкодження.';

export const cloudkill_expanded = 'Ви створюєте сферу з радіусом 20 футів із отруйного жовто-зеленого туману з центром на точці, обраній вами в межах дистанції. Цей туман огинає кути. Він існує, поки активне закляття, або поки сильний вітер його не розвіє. Його простір — сильно загороджена місцевість. Коли істота вперше за хід входить в область закляття або починає там хід, вона повинна пройти Випробування Статури. Істота отримує пошкодження Отрутою 5d8 при провалі або половину цього пошкодження у разі успіху. Істота підпадає під дію закляття навіть якщо затримує дихання або взагалі не має дихати. Хмара на початку кожного вашого ходу переміщається на 10 футів від вас, котячись по поверхні. Випари, будучи важчими за повітря, стікаються вниз, і навіть можуть проникати в отвори.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 6 рівня або вище, пошкодження збільшується на 1d8 за кожен рівень слоту вище пʼятого.';

export const seeming = 'Вдавання';

export const seeming_details = 'Змінити вигляд істот навколо себе в області.';

export const seeming_expanded = 'Це закляття дозволяє вам змінювати вигляд будь-якої кількості істот, які ви побачите в межах дистанції. Ви даєте кожній вибраній цілі новий, ілюзорний вигляд. Незгодна ціль може пройти Випробування Харизми, і в разі успіху закляття на неї не діє.<br/><br/>'+

'Це закляття маскує зовнішній вигляд, а також одяг, обладунки, зброю та спорядження. Ви можете зробити кожну істоту на 1 фут (30 сантиметрів) нижче або вище, і зробити її худої, товстої або звичайної статури. Ви не можете змінювати вигляд тіла цілі, так що у неї має бути такий же набір кінцівок. В усьому іншому ілюзію визначаєте ви. Ви можете закінчити дію закляття достроково, витративши Основну дію. Зміни, внесені цим закляттям, не перевіряються фізичним дослідженням. Наприклад, якщо закляття додасть капелюха до спорядження персонажа, предмети проходитимуть крізь нього, і всі, хто торкнеться його, або не відчують нічого, або відчують голову і волосся.<br/><br/>'+

'Якщо ви зробите себе худішим, то рука того, хто захоче до вас доторкнутися, вдариться про вас, не дійшовши до видимих контурів. Істота може Основною дією оглянути ціль і здійснити перевірку Розслідування проти Складності ваших заклять. У разі успіху воно зрозуміє, що вигляд ненатуральний.';

export const fire_shield = 'Вогняний | Прохолодний щит';

export const fire_shield_details = 'Оточити себе активним захистом (🔥 Вогнем або ❄️ Холодом), що випромінює яскраве світло в області і дає на вибір <span class="buff">стійкість</span> до пошкодження ❄️ Холодом або 🔥 Вогнем.';

export const fire_shield_expanded = 'На час тривалості закляття ваше тіло покривається тонким шаром полумʼя, що випромінює яскраве світло в межах 10-футового радіуса і тьмяне світло в межах ще 10 футів.<br/><br/>'+
'Це полумʼя надає вам або теплий щит, або прохолодний щит на ваш вибір. Теплий щит дає вам стійкість до пошкодження Холодом, а прохолодний щит дає стійкість до пошкодження Вогнем. Крім того, щоразу, коли істота, що знаходиться в межах 5 футів від вас, влучає по вас ближньою атакою, щит випромінює полумʼя. Атакуючий отримує пошкодження Вогнем 2d8 у разі теплого щита або пошкодження Холодом 2d8 у разі прохолодного щита.<br/><br/>'+
'Ви можете достроково закінчити це закляття Основною дією.<br/><br/>'+

'Фокус для створення закляття можна замінити на фосфор або світляка.';

export const hallow = 'Освячення';

export const hallow_details = 'Створити область, неможливу для входу та взаємодії породжень, небожителів, невмерлих, фей та елементалей, що має один з ефектів:<br/>'+

'• Поховані в області не можуть стати невмерлими<br/>'+
'• Істоти не можуть переміщатися між планами<br/>'+

'• Область освітлюється яскравим світлом<br/>'+
'• Істоти мають стійкість до пошкодження на вибір, крім механічного<br/>'+
'• Істоти не можуть бути Перелякані, поки в області<br/>'+
'• Істоти можуть розуміти всі язики<br/>'+

'• Область заповнюється темнотою<br/>'+
'• Істоти мають вразливість до пошкодження на вибір, крім механічного<br/>'+
'• Істоти Перелякані, поки в області<br/>'+
'• Звукоізоляція області<br/>'+
'• Інше';

export const hallow_expanded = 'Ви торкаєтеся крапки і наповнюєте область навколо неї святою (або нечистою) силою. Радіус області може бути до 60 футів, і закляття провалюється, якщо в цей радіус потрапляє область, що вже під дією закляття Освячення. Наповнена область піддається наступним ефектам:<br/><br/>'+

'По-перше, погань, небожителі, невмерлі, феї та елементали не можуть увійти в цю область, і не можуть робити істот, що знаходяться всередині, Зачарованими, Переляканими та Одержимими. Усі істоти, Зачаровані, Перелякані чи Одержимі такими істотами, перестають бути Зачарованими, Переляканими та Одержимими, входячи в цю область. Ви можете виключити з ефекту один або кілька видів істот.<br/><br/>'+

'По-друге, ви можете привʼязати до області додатковий ефект. Виберіть один ефект зі списку (або виберіть ефект, запропонований Майстром):<br/><br/>'+

'• Вічний спокій. Мертві тіла, поховані в області, не можуть стати невмерлими.<br/><br/>'+

'• Втручання у подорожі між вимірами. Зачеплені істоти не можуть переміщатися та подорожувати, використовуючи телепортацію або переміщення між вимірами та планами.<br/><br/>'+

'• Денне світло. Область висвітлюється яскравим світлом. Магічна темрява, створена закляттями з рівнем менше рівня слоту, використаної вами для накладання цього закляття, не може затьмарити світло.<br/><br/>'+

'• Захист від енергії. Зачеплені істоти в області отримують опір до одного виду пошкодження на ваш вибір окрім Дробильного, Колотого та Рубаного.<br/><br/>'+

'• Хоробрість. Зачеплені істоти не можуть бути Перелякані, поки знаходяться в області.<br/><br/>'+

'• Мови. Зачеплені істоти можуть спілкуватися з будь-якими іншими істотами, що знаходяться в області, навіть якщо вони не мають спільної мови.<br/><br/>'+

'• Темрява. Область заповнюється темнотою. Звичайне світло, а також магічне світло, створене закляттями, з рівнем менше рівня комірки, використаної вами для накладання цього закляття, не можуть висвітлити область.<br/><br/>'+

'• Вразливість енергії. Зачеплені істоти в області отримують вразливість до одного виду пошкодження на ваш вибір окрім Дробильного, Колотого та Рубаного.<br/><br/>'+

'• Страх. Зачеплені істоти Перелякані, поки знаходяться в області.<br/><br/>'+

'• Тиша. Звуки не залишають межі області, і не проникають зовні всередину.<br/><br/>'+

'Деякі з цих ефектів застосовуються до істот, що знаходяться в області; ви можете вказати, чи буде він застосовуватися до всіх істот, істот, що служать певному божеству чи лідеру, або істот певного виду, наприклад, оркам і тролям.<br/><br/>'+

'Коли істота, що потрапляє під дію ефекту, входить в область закляття вперше за хід або починає там хід, вона може пройти Випробування Харизми. У разі успіху істота ігнорує додатковий ефект, доки не залишить цю область.';

export const clairvoyance = 'Підглядання';

export const clairvoyance_details = 'Створити сенсор зору чи слуху у знайомому або очевидному місці.';

export const clairvoyance_expanded = 'Ви створюєте невидимий сенсор у межах дистанції у відомому вам місці (місці, яке ви вже бачили або відвідували) або на очевидному місці, незнайомому вам (наприклад, за дверима, за рогом, або в гаю дерев). Сенсор залишається на місці, і його не можна атакувати або якось з ним взаємодіяти. Накладаючи закляття, ви вибираєте зір чи слух. Ви можете використовувати вибране почуття через сенсор, якби ви знаходилися на його місці. Ви можете перемикатися між зором і слухом. Істота, здатна побачити сенсор (наприклад, що знаходиться під закляттям Бачення невидимого або з істинним зором) бачить нематеріальну кульку, що світиться, розміром з ваш кулак.';

export const sending = 'Послання';

export const sending_details = 'Надіслати 25 слів будь-якій відомій вам істоті з Інтелектом 1+, і отримати відповідь такої ж довжини.';

export const sending_expanded = 'Ви надсилаєте коротке повідомлення довжиною не більше 25 слів істоті, відомій вам. Ця істота чує послання у свідомості, розпізнає вас як відправника, якщо воно вас знає, і відразу може відповісти (не більше 25 слів). Це закляття дозволяє істотам з Інтелектом як мінімум 1 розуміти зміст вашого послання. Ви можете передати послання на будь-яку відстань, і навіть на інші плани існування, але якщо ціль знаходиться на іншому плані, існує 5-відсотковий шанс, що послання не буде доставлене.<br/><br/>'+

'Фокус для створення закляття можна замінити на тонкий мідний дріт.';

export const evards_black_tentacles = 'Чорні щупальця Еварда';

export const evards_black_tentacles_details = 'Створити важкопрохідну область атакуючих і хапаючих щупалець.';

export const evards_black_tentacles_expanded = 'Чорні щупальця, що звиваються, заповнюють квадрат на підлозі з довжиною сторони 20 футів, який ви бачите в межах дистанції. Поки закляття активне, ці щупальця роблять територію важкопрохідною. Коли істота вперше за хід входить в область закляття або починає там хід, вона повинна досягти успіху у Випробуванні Спритності, інакше отримає Дробильне пошкодження 3d6 і стане обплутаною щупальцями до закінчення закляття. Істота, що починає хід у цій області, та вже обплутане щупальцями, отримує Дробильне пошкодження 3d6.<br/><br/>'+

'Облутана щупальцями істота може Основною дією здійснити перевірку Сили або Спритності (на свій вибір) проти Складності ваших заклять. У разі успіху воно вивільняється.';

export const telekinesis = 'Телекінез';

export const telekinesis_details = 'Переміщати силою думки істоту або предмет і маніпулювати ним.';

export const telekinesis_expanded = 'Ви отримуєте здатність переміщати силою думки істот та предмети, а також маніпулювати ними. Коли ви накладаєте це закляття, а також у кожному наступному раунді, новою Основною дією, ви можете впливати на одну істоту або предмет, видимий в межах дистанції, викликаючи один з наведених нижче ефектів. Ви можете діяти на ту саму ціль, а можете щоразу вибирати нову. Якщо ви змінюєте ціль, попередня перестає бути під дією цього закляття.<br/><br/>'+

'Предмет. Ви можете спробувати перемістити предмет, що важить до 1000 фунтів. Якщо предмет не несуть і не носять, ви автоматично переміщаєте його на 30 футів у будь-якому напрямку, але не за межі дистанції закляття. Якщо предмет несе або носить істота, ви повинні перевірити базову характеристику, протиставивши її перевірці Сили істоти. Якщо ви виграєте перевірку, ви вириваєте предмет у цієї істоти, і можете перемістити його на 30 футів у будь-якому напрямку, але не за межі дистанції закляття. Ви дуже добре контролюєте предмет телекінетичної хваткою, так що можете маніпулювати простими інструментами, відчиняти двері та контейнери, діставати та прибирати предмети з відкритих контейнерів, а також виливати вміст посуду.<br/><br/>'+

'Істота. Ви можете спробувати перемістити істоту з розміром не більше Величезного. Здійсніть перевірку своєї базової характеристики, протиставивши її перевірці Сили істоти. Якщо ви виграєте перевірку, ви переміщаєте істоту на 30 футів у будь-якому напрямку, включаючи вгору, але не за межі дистанції закляття. До кінця вашого наступного ходу істота стає обплутаною телекінетичною хваткою. Істота, піднята нагору, висить у повітрі. У наступних раундах ви можете намагатися підтримувати телекінетичне захоплення істоти, повторюючи зустрічну перевірку.';

export const bestow_curse = 'Прокляття';

export const bestow_curse_details = 'Накласти на ціль один із ефектів:<br/>'+
'• Перешкода на перевірки та Випробування однієї з характеристик<br/>'+
'• Перешкода на кидки атаки по вас<br/>'+
'• Шанс відібрати Основну дію кожний хід<br/>'+
'• Додаткове 💀 Некротичне пошкодження 1d8 від вас<br/>'+
'• Інше';

export const bestow_curse_expanded = 'Ви торкаєтеся істоти, і воно має досягти успіху в спаски Мудрості, інакше стане проклятим на період дії закляття. Накладаючи це закляття, виберіть характер прокляття з наступного списку:<br/><br/>'+

'• Виберіть одну характеристику. Будучи проклятою, ціль здійснює перевірки та скидання цієї характеристики з перешкодою.<br/><br/>'+

'• Будучи проклятою, ціль робить з перешкодою кидки атаки по вам.<br/><br/>'+

'• Будучи проклятою, ціль повинна на початку кожного свого ходу здійснювати спаски Мудрості. У разі провалу вона марно витрачає свою дію.<br/><br/>'+

'• Поки ціль проклята, ваші атаки та закляття завдають їй додаткової шкоди некротичною енергією 1d8. закляття Зняття прокляття закінчує цей ефект.<br/><br/>'+

'• З дозволу Майстра, ви можете створити альтернативний ефект прокляття, але він не повинен бути сильнішим за ті, що представлені вище. Фінальне рішення про ефект прокляття залишається за Майстром.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи комірку 4 або вище рівня, тривалість буде «Концентрація, аж до 10 хвилин». Якщо ви використовували комірку 5 або вище рівня, тривалість буде «8 годин». Якщо ви використовували комірку 7 або вище рівня, тривалість буде «24 години». Якщо ви використовували комірку 9 рівня, закляття діє, поки не розсіється. При використанні комірки щонайменше 5 рівня концентрація не потрібна.';

export const bestow_curse_warlock = bestow_curse + ' *';

export const bestow_curse_warlock_details = bestow_curse_details;

export const bestow_curse_warlock_expanded = bestow_curse_expanded+'<br/><br/><span class="rare-text">* Ви можете один раз створити закляття, використовуючи слот мани. Ви не можете зробити це повторно, поки не закінчите тривалий відпочинок.</span>';

export const compulsion = 'Примушування';

export const compulsion_details = 'Відправити істот в області навколо себе у бажаному напрямку.';

export const compulsion_expanded = 'Вибрані вами істоти, яких ви бачите в межах дистанції, і які чують вас, повинні здійснити Випробування Мудрості. Ціль автоматично досягає успіху, якщо не може бути зачарована. При провалі Випробування ціль потрапляє під дію цього закляття. Поки закляття активне, ви можете в кожному своєму ході Бонусною дією вказати напрямок, що горизонтальний для вас. Всі цілі, що потрапили під дію, повинні в свій наступний хід максимально можливо переміщатися в тому напрямку. Перед переміщенням вони можуть вчинити свої дії. Після переміщення через закляття вони можуть пройти ще одне Випробування Мудрості, щоб закінчити цей ефект. Ціль не має переміщатися у явно небезпечне місце, таке як вогонь чи багаття, але вона провокує атаки, коли йде у зазначеному напрямі.';

export const compulsion_warlock = compulsion + ' *';

export const compulsion_warlock_details = compulsion_details;

export const compulsion_warlock_expanded = compulsion_expanded+'<br/><br/>* Ви можете один раз створити закляття, використовуючи слот мани. Ви не можете зробити це повторно, поки не закінчите тривалий відпочинок.';

export const help_of_talisman_warlock = 'Допомога талісмана';

export const help_of_talisman_warlock_details = 'Додати бонус до перевірки здібності.';

export const help_of_talisman_warlock_expanded = 'Ваш покровитель дає вам амулет - талісман, який може допомогти власнику, коли в цьому буде потреба. Коли власник провалює перевірку здібності, може додати до кидку d4, потенційно перетворивши кидок на успіх. Цю перевагу можна використовувати кількість разів, що дорівнює вашому бонусу майстерності, і всі використані використання відновлюються, коли ви закінчуєте тривалий відпочинок.';

export const create_talisman_warlock = 'Створити талісман';

export const create_talisman_warlock_details = 'Отримати заміну втраченого талісмана від свого покровителя.';

export const create_talisman_warlock_expanded = 'Якщо ви втратите талісман, ви можете провести церемонію тривалістю в годину, щоб отримати заміну від свого покровителя. Цей обряд можна проводити під час короткого чи тривалого відпочинку, і він руйнує попередній амулет. Коли ви вмираєте, талісман перетворюється на попіл.';

export const conjure_animals = 'Виклик тварин';

export const conjure_animals_details = 'Створити звірів небезпечністю до 2 та дозволеною сумарною небезпечністю.';

export const conjure_animals_expanded = 'Ви викликаєте фейських духів, що приймають вигляд звірів і зʼявляються у вільному просторі в межах дистанції. Виберіть один з варіантів того, хто зʼявляється:<br/><br/>'+

'• Один звір із показником небезпеки 2 або нижче<br/>'+
'• Два звіра з показником небезпеки 1 або нижче<br/>'+
'• Чотири звірі з показником небезпеки 1/2 або нижче<br/>'+
'• Вісім звірів із показником небезпеки 1/4 або нижче<br/><br/>'+

'Всі звірі вважаються також феями, і вони зникають, коли їхні здоровʼя опускаються до 0 або коли закляття закінчується. Покликані істоти дружні до вас та ваших супутників. Здійсніть кидок ініціативи за покликаних істот як одну групу, і вони здійснюватимуть свої власні ходи. Вони підкоряються відданим вами усно командам (дія не потрібна). Якщо ви не віддаєте команду, вони захищаються від ворожих істот, але дій не роблять. Параметри істот є у Майстра.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слоти більшого рівня, ви вибираєте один з описаних вище варіантів, але зʼявляється вдвічі більше істот при слоті 5 рівня, втричі більше при слоті 7 рівня, і в чотири рази більше при слоті 9 рівня .';

export const conjure_barrage = 'Виклик шквалу';

export const conjure_barrage_details = 'Створити хвилю однакової зброї | снаряда, що завдає відповідного виду пошкодження.';

export const conjure_barrage_expanded = 'Ви метаєте немагічну зброю або вистрілюєте немагічний боєприпас у повітря, створюючи конус однакової зброї, що летить уперед і потім зникає. Всі істоти в 60-футовому конусі повинні досягти успіху в Виспитанні Спритності. При провалі істота отримує пошкодження 3d8, а при успіху половину цього пошкодження. Вид пошкодження такий самий, як у зброї або боєприпасу, використаного як компонент.<br/><br/>'+

'Фокус для створення закляття можна замінити на метальну зброю або боєприпас.';

export const hipnotic_pattern = 'Гипнотичний візерунок';

export const hipnotic_pattern_details = 'Створити візерунок, що 😍 Зачаровує істот в області.';

export const hipnotic_pattern_expanded = 'Ви створюєте хитрий різнокольоровий візерунок, що переплітається в повітрі всередині куба з довжиною ребра 30 футів, що знаходиться в межах дистанції. Візерунок зʼявляється на коротку мить і зникає. Усі істоти в цій галузі, що бачать візерунок, повинні пройти Випробування Мудрості. При провалі істота стає Зачарованою на час дії закляття. Будучи зачарованим цим закляттям, істота недієздатна, і її швидкість дорівнює 0. Закляття закінчується для окремої істоти, якщо вона отримує пошкодження або якщо хтось інший Основною потрясе її, щоб вивезти зі ступору.<br/><br/>'+

'Фокус для створення закляття можна замінити на запалену паличку пахощів або кришталеву посудину, заповнену фосфоресційним матеріалом.';

export const hunger_of_hadar = 'Голод Хадара';

export const hunger_of_hadar_details = 'Створити область темряви і кошмару, що робить місцевість важкопрохрдимою, що засліплює і завдає пошкодження Холодом істотам, що починають хід в області. Також завдає пошкодження Кислотою, тим хто закінчує хід в області і провалили Випробування.';

export const hunger_of_hadar_expanded = 'Ви відкриваєте ворота у міжзоряну темряву, область, населену невідомими кошмарами. Зʼявляється сфера імли та холоду з радіусом 20 футів, з центром на точці в межах дистанції, що існує, доки активне закляття. Ця порожнеча заповнена какофонією тихих шепотів і шумів, що хлюпають, які чути в межах 30 футів. Ніяке світло, ні магічне, ні звичайне, не може висвітлити цю місцевість, і істоти, що повністю перебувають у цій області, засліплені.<br/><br/>'+

'Пустота створює спотворення в тканині простору, і її місцевість є важкопрохідною. Всі істоти, що починають хід у цій місцевості, отримують пошкодження Холодом 2d6. Всі істоти, що закінчують хід у цій місцевості, повинні досягти успіху в Випробуванні Спритності, інакше отримують пошкодження Кислотою 2d6, оскільки до них торкаються бліді потойбічні щупальця.<br/><br/>'+

'Фокус для створення закляття можна замінити на мариноване щупальце восьминога.';

export const counterspell = 'Контрзакляття';

export const counterspell_details = 'Запобігти створенню чужого закляття. Можна спробувати запобігти створення закляття рівня вище зазначеного.';

export const counterspell_expanded = 'Ви намагаєтеся перервати процес накладання закляття. Якщо істота накладає закляття 3 рівня чи нижче, його закляття провалюється, і ніякого ефекту. Якщо вона накладала закляття 4 або вище рівня, здійсніть перевірку базової характеристики. Складність дорівнює 10 + рівень закляття. У разі успіху закляття провалюється і не має жодного ефекту.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот 4 рівня або вище, перерване закляття автоматично не робить ефекту, якщо його рівень не перевищує рівень використаного вами слоту.';

export const leomunds_tiny_hut = 'Хатина Леомунда';

export const leomunds_tiny_hut_details = 'Створити нерухомий купол, непроникний для всіх крім себе та союзників, комфортний та освітлений бажаним чином усередині. Також він непроникний для заклять та магічних ефектів.';

export const leomunds_tiny_hut_expanded = 'Над вами та навколо вас зʼявляється купол із силового поля радіусом 10 футів, що залишається нерухомим весь час існування. Закляття закінчується, якщо ви залишаєте його область. Під куполом разом з вами можуть поміститися девʼять істот з розміром не більше за Середній. Закляття зривається, якщо в його область потрапляє істота більшого розміру або більше девʼяти істот.<br/><br/>'+

'Купол зовні матовий, будь-якого кольору, але зсередини він прозорий. Істоти та предмети, що знаходилися під куполом, коли ви наклали це закляття, можуть вільно проходити крізь нього. Всі інші істоти та предмети не можуть пройти крізь нього. Закляття та інші магічні ефекти не можуть простягатися під купол і не можуть бути накладені крізь нього.<br/><br/>'+

'Атмосфера під ним суха і комфортна, хоч би якою була погода зовні. Поки закляття активне, ви можете зробити простір під ним тьмяно освітленим або зануреним у темряву.<br/><br/>'+

'Фокус для створення закляття можна замінити на маленьку кристалічну намистину.';

export const magic_circle = 'Магічне коло';

export const magic_circle_details = 'Створити область, умовно непроникну для породжень|небожителів|невмерлих|фей|єлементалей. Ці істоти атакують із перешкодою по істотах в області. Також у істот в області імунітет до Зачарування, Переляку, Одержимості. Можна перетворити навпаки на пастку для обраного виду істот.';

export const magic_circle_expanded = 'Ви створюєте з магічної енергії циліндр з радіусом 10 футів і заввишки 20 футів із центром на точці на підлозі, видимої в межах дистанції. Там, де циліндр перетинає підлогу або іншу поверхню, зʼявляються руни, що світяться. Виберіть один або кілька видів істот з наступного списку: погань, небожителі, невмерлі, феї або елементали. Коло діє на обраних істот так:<br/><br/>'+

'• Істоти не можуть добровільно увійти в циліндр немагічними засобами. Якщо істота намагається використати телепортацію або міжпланарну подорож, вона має спочатку досягти успіху у Випробуванні Харизми.<br/>'+
'• Істота робить з перешкодою кидки атаки за цілями, що знаходяться в межах циліндра.<br/>'+
'• Цілей у циліндрі не можна зробити зачарованими, переляканими або одержимими.<br/><br/>'+

'При накладанні цього закляття ви можете змінити його магію, щоб істоти обраного виду не могли покинути циліндр, і захищені були цілі, що знаходяться зовні.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 4 рівня або вище, тривалість збільшується на 1 годину за кожен рівень слоту вище за третій.';

export const mass_healing_word = 'Массове цілюще слово';

export const mass_healing_word_details = 'Підлікувати істот у області.';

export const mass_healing_word_expanded = 'Ви вимовляєте слова відновлення, і до шести істот на ваш вибір, видимих в межах дистанції, відновлюють здоровʼя в кількості, що дорівнює 1d4 + ваш модифікатор базової характеристики. Це закляття не має жодного ефекту на невмерлих і конструктів.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани рівня 4 або вище, відновлюється додатково 1d4 хітів за кожен рівень слоту вище 3.';

export const lightning_arrow = 'Блискавична стріла';

export const lightning_arrow_details = 'Перетворити наступний свій снаряд далекобійної зброї на розряд блискавки. При промаху наносить половину пошкодження. Також, незалежно від влучення, завдає пошкодження на 2d8 менше всім в області, хто провалив Випробування, і половину тим хто досяг успіху.';

export const lightning_arrow_expanded = 'Наступного разу, коли ви здійсните далекобійну атаку зброєю, поки активне це закляття, ваш боєприпас або сама зброя, якщо це метальна зброя, перетворюється на розряд блискавки. Здійсніть кидок атаки як завжди. Замість звичайного пошкодження від зброї ціль при попаданні отримує пошкодження Струмом 4d8, або половину пошкодження при промаху.<br/><br/>'+

'Незалежно від попадання або промаху, всі істоти в межах 10 футів від цілі повинні пройти Випробування Спритності. При провалі ці істоти отримують пошкодження Струмом 2d8, або половину пошкодження при успіху. Після цього боєприпас чи зброя повертається у звичайну форму.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 4 рівня або вище, пошкодження від обох ефектів збільшується на 1d8 за кожен рівень слоту вище третього.';

export const major_image = 'Повноцінний образ';

export const major_image_details = 'Створити ілюзію з розміром у межах області, що має всі ознаки реального обʼєкта, але не завдає пошкодження або шкоди. Здатно миттєво переміщатися та змінюватись у межах дистанції.';

export const major_image_expanded = 'Ви створюєте образ предмета, істоти або іншого видимого явища, що міститься в обʼємі в куб із довжиною ребра 20 футів. Образ зʼявляється в точці, яку ви бачите в межах дистанції, і існує, поки активне закляття.<br/><br/>'+

'Він виглядає абсолютно реальним, включаючи звук, запах та температуру, що відповідає цьому предмету. Ви не можете створити жар або холод, достатній для отримання шкоди, звук, гучність якого може завдати шкоди звуком або оглушити істоту, або запах, здатний викликати нудоту. Поки ви знаходитесь в межах дистанції від ілюзії, ви можете Основною дією змусити образ переміститися в будь-яке місце в межах дистанції. Поки образ змінює місце розташування, ви можете змінювати його зовнішність, щоб рух виглядав природним. Наприклад, якщо ви створюєте образ істоти та переміщаєте його, ви можете змінити образ, щоб здавалося, що воно йде. Так само ви можете змусити ілюзію видавати в різний час різні звуки, і навіть змушувати її брати участь у розмові.<br/><br/>'+

'Фізична взаємодія з образом дає зрозуміти, що це ілюзія, тому що крізь неї все проходить. Істоти, що досліджують образ Основною дією, можуть визначити, що це ілюзія, зробивши успішну перевірку Розслідування проти складності ваших заклять. Якщо істота розпізнає ілюзію, вона може бачити крізь неї, і всі інші сенсорні ефекти ілюзії теж притуплюються.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот 6 рівня або вище, воно триває, поки не розсіється, не вимагаючи від вас концентрації.';

export const blinding_smite = 'Осліплююча кара';

export const blinding_smite_details = 'Нанести додаткове пошкодження наступною успішною ближньою атакою зброєю. Осліпити ціль атаки, що провалила Випробування.';

export const blinding_smite_expanded = 'Коли ви наступного разу потрапите по істоті ближньою атакою зброєю, поки закляття активне, ваша зброя спалахує яскравим світлом, і атака завдає цілі додаткове покшодження 3d8 Світлом.<br/><br/>'+

'Крім того, ціль повинна досягти успіху в Випробування Статури, інакше вона стане засліпленою до закінчення закляття. Осліплена цим закляттям істота робить Випробування Статури наприкінці кожного свого ходу. У разі успіху воно перестає бути засліпленим.';

export const glyph_of_warding = 'Охоронна руна';

export const glyph_of_warding_details = 'Нанести на поверхню або нерухомий предмет руну розміром з область, що при настанні призначеної події-активатора, повʼязаної з ціллю, завдає пошкодження вибраного стихійного типу в області сфери 40ф, або створює вибране закляття рівня руни.';

export const glyph_of_warding_expanded = 'Коли ви накладаєте це закляття, ви малюєте руну, що завдає пошкодження іншим істотам, або на поверхні (такі як стіл або частина підлоги або стіни) або в предметі, який можна закрити (книга, свиток або скриня зі скарбами), сховавши таким чином, руну.<br/><br/>'+

'Якщо ви вибрали поверхню, руна може покривати площу діаметром не більше 10 футів. Якщо ви вибрали предмет, він має бути нерухомим; якщо предмет перемістять більш ніж на 10 футів від місця, де ви активуєте закляття, руна зламається, і закляття закінчиться, не спрацьовуючи. Руна практично невидима, і для її виявлення потрібна успішна перевірка Розслідування проти Складності ваших заклять.<br/><br/>'+

'Ви вирішуєте, що викличе спрацювання руни, коли накладаєте закляття. Руни, написані на поверхні, зазвичай спрацьовують від торкання, вставання на неї, знімання предмета, що стоїть на руні, наближення на певну відстань, або маніпулювання предметом, на якому написана руна.<br/><br/>'+

'Для рун, накреслених усередині предметів, найчастіше вибирають відкривання, наближення на певну відстань, погляд на руну чи спробу її прочитання. Після того, як руна спрацює, закляття закінчується. Ви можете внести уточнення, щоб закляття спрацьовувало лише у певних обставинах або за дотриманням зовнішніх ознак (таких як зростання і вага), вид істоти (наприклад, руна може захищати тільки від аберацій та дроу), або світогляд. Ви можете також визначити умови, за яких істота не активуватиме руну, наприклад, вимова пароля.<br/><br/>'+

'Коли ви наносите руну, виберіть Вибухову руну або Заклинальну руну.<br/><br/>'+

'• Вибухова руна. Коли ця руна спрацьовує, вона випускає магічну енергію сферою з радіусом 20 футів із центром на руні. Ця сфера огинає кути. Усі істоти у цій галузі мають пройти Випробування Спритності. Істота отримує пошкодження Звуком, Кислотою, Вогнем, Холодом або Струмом 5d8 при провалі (вигляд пошкодження ви вибираєте при накресленні руни), або половину цього пошкодження при успіху.<br/><br/>'+

'• Заклинальна руна. Ви можете зберегти підготоване закляття з рівнем не вище 3 у руні, наклавши його частиною накреслення руни. Закляття має націлюватися на одну істоту чи область. При цьому закляття тимчасово не набирає чинності. Коли руна спрацьовує, закляття, що зберігається, активується. Якщо закляття має ціль, ціллю стає істота, що викликала спрацьовування руни. Якщо закляття діє на область, центр цієї області буде на цій істоті. Якщо закляття викликає ворожих істот або створює шкідливі предмети чи пастки, вони зʼявляються якомога ближче до порушника та атакують його. Якщо закляття вимагає концентрації, воно триває до кінця своєї максимальної тривалості.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 4 рівня або вище, пошкодження від вибухової руни збільшується на 1d8 за кожен рівень слоту вище третього. Якщо ви накладали заклинальну руну, ви можете зберігати в ній закляття з рівнем, що дорівнює рівню слоту, використаної для руни.';

export const phantom_steed = 'Примарний скакун';

export const phantom_steed_details = 'Створити скакуна вибраного конеподібного вигляду. Швидкість 100ф або 10-13 миль/година. Зникає при отриманні пошкодження.';

export const phantom_steed_expanded = 'На підлозі у вільному просторі на ваш вибір у межах дистанції зʼявляється Велика напівреальна схожа на коня істота. Ви самі визначаєте його зовнішність, але в нього є сідло, вудила та вуздечка. Все спорядження, створене цим закляттям, зникає в клубах диму, якщо його віднести на 10 футів від скакуна.<br/><br/>'+

'Поки закляття активне, ви або вибране вами істота можете їхати на скакуні верхи. Використовуються характеристики їздового коня, за винятком того, що швидкість істоти 100 футів, і вона може проскакати 10 миль за годину або 13 миль у швидкому темпі.<br/><br/>'+

'Коли закляття закінчується, скакун повільно зникає, даючи їзду 1 хвилину на поспішання. Закляття закінчується, якщо ви закінчите його Основною дією або якщо скакун отримає будь-яке пошкодження.';

export const vampiric_touch = 'Вампірський дотик';

export const vampiric_touch_details = 'Наносити пошкодження своїми дотиками, і отримувати лікування на половину від цього нанесеного пошкодження.';

export const vampiric_touch_expanded = 'Торкання вашою оповитою тінями рукою може витягувати життєві сили в інших, заліковуючи ваші рани. Здійсніть ближню атаку закляттям по істоті в межах вашої досяжності. При попаданні ціль отримує пошкодження Некротичною енергією 3d6, а ви відновлюєте кількість здоровʼя, що дорівнює половині заподіяного пошкодження. Поки закляття активне, ви в кожному ходу можете здійснювати цю атаку.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 4 рівня або вище, шкода збільшується на 1d6 за кожен рівень слоту вище третього.';

export const feign_death = 'Вдавана смерть';

export const feign_death_details = 'Занурити союзника в стан, який зовні не відрізняється від смерті. Ціль отримує <span class="buff">стійкість</span> до будь-якого пошкодження крім Психічного, та <span class="buff">імунітет</span> проти ефекту від хвороб та отрут.';

export const feign_death_expanded = 'Ви доторкаєтеся до згодної істоти і занурюєте її в каталептичний стан, який не відрізняється від смерті. Поки закляття активно, або поки ви не торкнетесь цілі Основною дією, знімаючи закляття, ціль виглядає мертвою для будь-якого обстеження та заклинань, що визначають стан.<br/><br/>'+

'Ціль засліплена і недієздатна, а швидкість дорівнює 0. Ціль отримує опір усім видам шкоди, крім психічної енергії. Якщо ціль була хвора або отруєна, коли ви накладаєте це закляття, або стає хворою або отруєною, перебуваючи під його дією, хвороби та отрути не мають жодного ефекту, поки закляття не закінчиться.<br/><br/>'+

'Фокус для створення закляття можна замінити на щіпку цвинтарної землі.';

export const remove_curse = 'Зняття прокляття';

export const remove_curse_details = 'Зняти всі прокляття з істоти чи предмета.';

export const remove_curse_expanded = 'Після вашого дотику всі прокльони, що діють на одну істоту чи предмет, закінчуються. Якщо предмет був проклятим магічним предметом, прокляття залишається, але закляття розриває налаштування з власником предмета, тому його можна зняти або викинути.';

export const elemental_weapon = 'Стихийна зброя';

export const elemental_weapon_details = 'Зробити зброю магічною:<br/>'+
'• +1 до Влучності<br/>'+
'• Бонусне пошкодження вибраного стихійного виду';

export const elemental_weapon_details_5 = 'Зробити зброю магічною:<br/>'+
'• +2 до Влучності<br/>'+
'• Бонусне пошкодження вибраного стихійного виду';

export const elemental_weapon_details_7 = 'Зробити зброю магічною:<br/>'+
'• +3 до Влучності<br/>'+
'• Бонусне пошкодження вибраного стихійного виду';

export const elemental_weapon_expanded = 'Немагічна зброя, до якої ви доторкаєтеся, стає магічною. Виберіть один із таких видів пошкодження: Звук, Кислота, Вогонь, Холод або Струм. Поки закляття активне, зброя отримує бонус +1 до кидків атаки і завдає при попаданні додаткове пошкодження обраного виду 1d4.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 5 або 6 рівня, бонус до кидка атаки збільшується до +2, а додаткове пошкодження збільшується до 2d4. Якщо ви використовуєте слот мани 7 рівня або вище, бонус збільшується до +3, а додаткове пошкодження збільшується до 3d4.';

export const fear = 'Жах';

export const fear_details = 'Зробити всіх істот в області, що провалили Випробування, 😱 Переляканими та тікаючими.';

export const fear_expanded = 'Ви створюєте примарне зображення найгірших кошмарів інших істот. Всі істоти в 30-футовому конусі повинні досягти успіху в Випробуванні Мудрості, інакше вони кидають все, що тримають в руках, і стають Зляканими на час дії закляття. Будучи Переляканим цим закляттям, істота повинна кожен свій хід здійснювати дію Ривок, і переміщатися геть від вас найбезпечнішим маршрутом, поки в неї є куди переміщатися. Якщо істота закінчує хід у місці, звідки у нього немає лінії огляду до вас, вона може пройти Випробування Мудрості. У разі успіху закляття перестає діяти на цю істоту.<br/><br/>'+

'Фокус для створення закляття можна замінити на біле перо або серце курки.';

export const tongues = 'Балачка';

export const tongues_details = 'Дати цілі здатність розуміти всі мови, які вона чує, і говорити зрозуміло для всіх тих, хто слухає.';

export const tongues_expanded = 'Це закляття дарує істоті, до якої ви доторкаєтеся, здатність розуміти всі мови, які вона чує. Більше того, коли ціль говорить, всі істоти, які знають хоча б одну мову, і ті, хто чує ціль, розуміють, що вона сказала.<br/><br/>'+

'Фокус для створення закляття можна замінити на маленьку глиняну модель зіккурату.';

export const empowered_evocation = 'Посилене Втілення';

export const empowered_evocation_details = 'Бонусне пошкодження від заклять Втілення.';

export const empowered_evocation_expanded = 'При кидку пошкодження від закляття школи Втілення, створеного вами, ви додаєте до одного кидку пошкодження свій модифікатор Інтелекту.';

export const aura_of_purity = 'Аура очищення';

export const aura_of_purity_details = 'Аура, що дає <span class="buff">стійкість</span> до пошкодження 🧪 Отрутою, <span class="buff">імунітет</span> проти хвороб, і <span class="buff">перевагу</span> у Випробуваннях від глухоти, переляку, засліплення, отруєння, зачарування, оглушення та паралічу.';

export const aura_of_purity_expanded = 'Від вас виходить аура, що очищує, з радіусом 30 футів. Поки закляття активне, аура переміщається разом з вами, залишаючись із центром на вас. Всі неворожі істоти в аурі (включаючи вас) не можуть захворіти, мають стікість до пошкодження Отрутою і здійснюють з перевагою Випробування від ефектів, що викликають такі стани: глухота, переляк, засліплення, отруєння, зачарування, оглушення та параліч.';

export const mordenkainens_faithful_hound = 'Відданий пес Морденкайнена';

export const mordenkainens_faithful_hound_details = 'Створити невидмого невразливого сторожового пса, що гавкає при появі ворожих істот в області і кусає ворогів поряд із собою.';

export const mordenkainens_faithful_hound_expanded = 'Ви створюєте примарного сторожового пса у вільному просторі, видимому в межах дистанції, де він і залишається на час тривалості заклинання, поки ви не відпустите його Основною дією, або поки ви не відійдете від нього більш ніж на 100 футів. Пес невидимий для всіх істота крім вас, і йому не можна завдати пошкодження.<br/><br/>'+

'Якщо в межах 30 футів від нього опиняється істота розміром Маленьке або більше, не вимовляючи при цьому пароль, вказаний вами при накладанні цього закляття, пес починає голосно гавкати.<br/><br/>'+

'Пес бачить невидимих істот і його зір розповсюджується на Ефірний План. Він ігнорує ілюзії. На початку кожного вашого ходу пес намагається вкусити одну істоту в межах 5 футів від себе, ворожу вам. Влучність пса дорівнює вашому модифікатору базової характеристики + ваш бонус майстерності. При попаданні він завдає Колоте пошкодження 4d8.<br/><br/>'+

'Фокус для створення закляття можна замінити на крихітний срібний свисток, шматочок кістки та нитку.';

export const phantasmal_killer = 'Фантомний вбивця';

export const phantasmal_killer_details = 'Створити ілюзорний прояв кошмарів цілі, видимий тільки їй. При провалі Випробування при касті — 😱 Переляк. Потім у кінці кожного свого ходу при провалі Випробування — пошкодження. При успіху — чари припиняються.';

export const phantasmal_killer_expanded = 'Ви заглядаєте в кошмари істоти, видимої в межах дистанції, і створюєте ілюзорний прояв його страхів, видимий тільки їй. Ціль має пройти Випробування Мудрості. При провалі ціль стає Переляканою до кінця дії закляття.<br/><br/>'+

'У кінці кожного свого ходу, поки закляття активне, ціль має досягати успіху в Випробуванні Мудрості, інакше вона отримує пошкодження Психічною енергією 4d10. При успіху закляття закінчується.<br/><br/>'+

'Якщо ви накладаєте це закляття, використовуючи слот мани 5 рівня або вище, пошкодження збільшується на 1d10 за кожен рівень слоту вище за 4.';

export const conjure_woodland_beings = "Виклик лісових створінь";

export const conjure_woodland_beings_details = 'Викликати фейських істот з особистою небезпечністю до 2. Вони дружні до вас та союзників та виконують команди.';

export const conjure_woodland_beings_expanded = 'Ви викликаєте фейських істот, які зʼявляються у вільному просторі, який ви бачите у межах дистанції. Виберіть один із варіантів того, хто зʼявляється:<br/><br/>'+

'• Одна фейська істота з небезпечністю 2 або нижче<br/>'+
'• Дві фейські істоти з небезпечністю 1 або нижче<br/>'+
'• Чотири фейські істоти з небезпечністю 1/2 або нижче<br/>'+
'• Вісім фейських істот із небезпечністю 1/4 або нижче<br/><br/>'+

'Здійсніть перевірку ініціативи за викликаних істот як за одну групу, і вони здійснюватимуть свої власні ходи. Вони виконують віддані вами усно команди (дія не потрібна). Якщо ви не віддаєте команду, вони захищаються від ворожих істот, але дій не роблять. Параметри істот є у Майстра. Викликана істота зникає, коли її здоровʼя опускається до 0 або коли закляття закінчується. Викликані істоти дружні до вас та ваших супутників.<br/><br/>'+

'Якщо ви накладаєте це заклинання, використовуючи слоти мани більшого рівня, ви вибираєте один з описаних вище варіантів, але зʼявляється вдвічі більше істот при слоті 6 рівня і втричі більше при слоті 8 рівня.<br/><br/>'+

'Фокус для створення закляття можна замінити на по одній ягоді падуба за кожну викликану істоту.';

export const conjure_minor_elementals = "Виклик малих елементалів";

export const conjure_minor_elementals_details = 'Викликати елементалів з особистою небезпечністю до 2. Вони дружні до вас та союзників та виконують команди.';

export const conjure_minor_elementals_expanded = 'Ви викликаєте елементалів, які зʼявляються у вільному просторі, який ви бачите у межах дистанції. Виберіть один із варіантів того, хто зʼявляється:<br/><br/>'+

'• Один елементал з небезпечністю 2 або нижче<br/>'+
'• Два елементали з небезпечністю 1 або нижче<br/>'+
'• Чотири елементали з небезпечністю 1/2 або нижче<br/>'+
'• Вісім елементалів із небезпечністю 1/4 або нижче<br/><br/>'+

'Здійсніть перевірку ініціативи за викликаних істот як за одну групу, і вони здійснюватимуть свої власні ходи. Вони виконують віддані вами усно команди (дія не потрібна). Якщо ви не віддаєте команду, вони захищаються від ворожих істот, але дій не роблять. Параметри істот є у Майстра. Викликана істота зникає, коли її здоровʼя опускається до 0 або коли закляття закінчується. Викликані істоти дружні до вас та ваших супутників.<br/><br/>'+

'Якщо ви накладаєте це заклинання, використовуючи слоти мани більшого рівня, ви вибираєте один з описаних вище варіантів, але зʼявляється вдвічі більше істот при слоті 6 рівня і втричі більше при слоті 8 рівня.';

export const giant_insect = "Гігантська комаха";

export const giant_insect_details = 'Зробити декількох комах або одного скорпіона поряд гігантським. Вони дружні до вас та союзників та виконують команди.';

export const giant_insect_expanded = 'Ви перетворюєте до 10 багатоніжок, 3 павуків, 5 ос або 1 скорпіона у межах відстані на гігантську версію їхньої природної форми, поки діють чари. Багатоніжка стає гігантською багатоніжкою, павук — гігантським павуком, оса — гігантською осою, а скорпіон — гігантським скорпіоном.<br/><br/>'+

'Кожна істота підкорюється вашим усним наказам, а в бою вони діють у ваш хід в кожному раунді. Майстер має параметри цих істот і визначає їхні дії та переміщення.<br/><br/>'+

'Істота зберігає свій гігантський розмір, поки діє закляття, поки її здоров’я не впаде до 0 або поки ви не використаєте Основну дію, що завершити ефект.<br/><br/>'+

'Майстер може дозволити вам вибрати інші цілі. Наприклад, якщо ви перетворюєте бджолу, її гігантська версія може мати таку саму статистику, як гігантська оса.';

export const fabricate = "Виготовлення";

export const fabricate_details = 'Перетворити сиру матерію на предмети з того ж матеріалу. Для виготовлення з металічної, камʼяної тощо матерії — область куб 5ф.';

export const fabricate_expanded = 'Ви перетворюєте сиру матерію на предмети з того ж матеріалу. Наприклад, ви можете виготовити дерев’яний міст із дерев’яної колоди, мотузку із клаптя прядива, одяг із льону чи вовни.<br/><br/>'+

'Виберіть сировину, яку бачите в межах відстані. Ви можете виготовити предмет розміром “великий” або менший (що вміщується у 10-футовий куб, або вісім пов’язаних 5-футових кубів), за умови, що маєте достатньо сировини. Однак якщо ви працюєте із металом, каменем чи іншою мінеральною речовиною, виготовлений предмет може бути не більш ніж “середнім” (вміщується в один 5-футовий куб). Якість предмета, виготовленого закляттям, збігається із якістю сировини.<br/><br/>'+

'Це закляття не може створювати чи перетворювати істот чи магічні предмети. Ви також не можете використовувати його для створення предметів, які зазвичай потребують високого рівня майстерності, таких як ювелірні прикраси, зброя, скло, обладунки, хіба що ви опанували відповідні ремісничі інструменти й використовуєте їх для створення таких предметів.';

export const mordenkainens_private_sanctum = "Приватне святилище Морденкайнена";

export const mordenkainens_private_sanctum_details = 'Створити область, захищену від проходу, звуку, огляду, телепортів, зʼявлення сенсорів, та заклять Віщування.';

export const mordenkainens_private_sanctum_expanded = 'Ви магічно убезпечуєте область в межах відстані. Ця область може бути кубом із розміром сторони від 5 до 100 футів. Це закляття існуює усю тривалість або поки ви не відпустите їх Основною дією. Коли ви створюєте це закляття, то вирішуєте, які гарантії безпеки надають чари, обираючи будь-які або всі з наступних властивостей:<br/><br/>'+

'• Звук не може пройти крізь бар’єр на краю захищеної області.<br/>'+
'• Бар’єр захищеної області виглядає темним і туманним, запобігаючи огляду (в тому числі темним зором).<br/>'+
'• Сенсори, створені закляттями віщування, не можуть з’явитися в захищеній області чи пройти крізь бар’єр по її периметру.<br/>'+
'• На істот в області неможливо націлити закляття Віщування.<br/>'+
'• Ніщо не може телепортуватися всередину області або назовні з неї.<br/>'+
'• В захищеній області заблоковані планарні подорожі.<br/><br/>'+

'Створюючи закляття в тому самому місці щодня упродовж року, ви робите цей ефект постійним.<br/><br/>'+

'Коли ви створюєте це закляття з використанням слоту мани 5 рівня або вище, ви можете збільшити розмір куба на 100 футів за кожний слот мани вище четвертого.<br/><br/>'+

'Фокус для створення закляття можна замінити на тонкий свинцевий лист, шматок матового скла, кулька з вати або тканини та товчений хризоліт.';

export const leomunds_secret_chest = "Схована скриня Леомунда";

export const leomunds_secret_chest_details = 'Сховати коштовну скриню в Етерний План за допомогою її зменшеної копії.';

export const leomunds_secret_chest_expanded = 'Ви ховаєте скриню та весь її вміст на Етерному плані. Ви повинні торкатися цієї скрині та мініатюрної копії, зробленої з тих самих матеріалів, яка слугує матеріальним компонентом для чарів. Скриня може вміщувати до 12 кубічних футів неживої матерії (3 фути на 2 фути на 2 фути).<br/><br/>'+

'Поки скриня залишається на Етерному плані, ви можете використати Основну дію і торкнутися копії, щоб відкликати скриню. Вона з’являється у вільному просторі на землі в межах 5 футів від вас. Ви можете відправити скриню назад на Етерний план, витративши Основну дію та торкнувшись скрині та її копії.<br/><br/>'+

'Через 60 днів є сукупний 5-відсотковий шанс на день, що ефект закляття завершиться. Цей ефект завершується, якщо ви створюєте це закляття знову, якщо менша копія скрині зруйнована, або якщо ви вирішуєте припинити закляття Основною дією. Якщо закляття завершується і більша скриня в цей час на Етерному плані, вона безповоротно втрачена.';

export const staggering_smite = "Оглушальна кара";

export const staggering_smite_details = 'Додаткове пошкодження від наступної влучної ближньої атаки зброєю. При провалі Випробування, ціль отримує <span class="buff">перешкоду</span> на влучність та перевірки, а також не може здійснювати Реакції.';

export const staggering_smite_expanded = 'Наступного разу, коли ви влучите по цілі ближньою атакою зброєю, поки активно це закляття, ваша атака пронизує не тільки його тіло, але й свідомість, і атака завдає цілі додаткове пошкодження 4d6 Психічною енергією.<br/><br/>'+

'Ціль має пройти Випробування Мудрості. При провалі вона до кінця свого наступного ходу здійснює з перешкодою кидки влучності та перевірки характеристик, а також не може здійснювати Реакції.'

export const otilukes_resilient_sphere = "Пружна сфера Отилюка";

export const otilukes_resilient_sphere_details = 'Оточити істоту чи предмет легкою ізолюючою сферою з мерехтливої сили. Оточена істота може штовхати стіни сфери і переміщатися зі швидкістю, зменшеною вдвічі.';

export const otilukes_resilient_sphere_expanded = 'Сфера з мерехтливої сили оточує істоту, що знаходиться в межах дистанції, або предмет з розміром не більше Великого. Незгодна істота має пройти Випробування Спритності. При провалі істота оточується сферою на час дії закляття.<br/><br/>'+

'Ніщо — ні фізичні предмети, ні енергія, ні ефекти інших заклять не можуть проходити через цей барʼєр, як усередину, так і назовні, хоча істота у сфері може дихати нормально. Сфера володіє імунітетом до всіх видів пошкодження, і істота, що знаходиться всередині, або предмет не може отримувати пошкодження від атак і ефектів, що виходять зовні, але й істота, що знаходиться в сфері, не може завдати пошкодження нічому, що знаходиться зовні.<br/><br/>'+

'Сфера нічого не важить, і вона досить велика, щоб умістити істоту чи предмет. Оточена істота може штовхати стіни сфери і таким чином переміщатися зі швидкістю, зменшеною вдвічі. Так само, сферу можуть підняти і переносити інші істоти.<br/><br/>'+

'Заклинання Розпад, націлене на сферу, знищує її, не завдаючи пошкодження тому, хто перебуває усередині.<br/><br/>'+

'Фокус для створення закляття можна замінити на напівсферичний шматочок чистого кристала і таку ж за розмірами напівсферу з гуміарабіку.';

export const swift_quiver = "Швидкий сагайдак";

export const swift_quiver_details = 'Перетворити свій сагайдак у нескінченного постачальника боєприпасів, шо самі стрибають у руку для пострілу.';

export const swift_quiver_expanded = 'Ви так модифікуєте свій сагайдак, що він починає виробляти нескінченний запас немагічних боєприпасів, які самі стрибають у вашу руку, коли ви тягнетесь за ними.Сагайдак для цього повинен мати хоча б один боєприпас.<br/><br/>'+

'Поки закляття активне, ви в кожному своєму ході можете Бонусною дією зробити дві атаки зброєю, яка використовує боєприпаси з сагайдака. Щоразу, коли ви робите таку далекобійну атаку, ваш сагайдак за допомогою магії замінює використаний вами боєприпас таким же немагічним боєприпасом. Усі боєприпаси, створені цим закляттям, розпадаються, коли закляття закінчується. Якщо сагайдак перестає бути з вами, закляття закінчується.';

export const animate_objects = "Оживлення предметів";

export const animate_objects_details = 'Оживити предмети, які ніхто не несе, та змусити їх допомагати вам.';

export const animate_objects_expanded = 'Предмети оживають за вашою командою. Виберіть до 10 немагічних предметів у межах дистанції, які ніхто не несе і не носить. Цілі Середнього розміру вважаються двома предметами, цілі Великого розміру - чотирма предметами, цілі Величезного розміру вважаються вісьмома предметами. Ви не можете оживити предмет, розмір якого більший за Величезний. Кожна ціль оживає і стає істотою під вашим контролем, поки закляття активне або доки їхнє здоров’я не зменшиться до 0.<br/><br/>'+

'Бонусною дією ви можете віддати ментальний наказ створеній цими чарами істоті, якщо істота перебуває у межах 500 футів від вас (якщо ви контролюєте кількох істот, ви можете наказувати будь-якій із них або всім одночасно, віддаючи кожній істоті той самий наказ). Ви вирішуєте, яку дію виконуватиме істота і куди вона рухатиметься у свій наступний хід, або ж ви можете віддати загальний наказ, наприклад, вартувати певну кімнату або коридор. Якщо ви не віддаєте наказів, істота тільки захищається від ворожих істот. Щойно віддано наказ, істота продовжує виконувати його, поки задачу не буде виконано. Оживлений предмет — це конструкт із бронею, здоров’ям, атаками, Силою та Спритністю, визначеними його розміром.<br/><br/>'+

'• Крихітний (1 слот за предмет)<br/>'+
'<table><tr><td>Здоровʼя:</td><td> 20 </td></tr>'+
'<tr><td>Броня:</td><td> 18 </td></tr>'+
'<tr><td>Сила базова:</td><td> 4 </td></tr>'+
'<tr><td>Спритність базова:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td> 18 </td></tr>'+
'<tr><td>Влучність:</td><td> +8 </td></tr>'+
'<tr><td>Пошкодження:</td><td> 1d4+4 </td></tr></table><br/>'+

'• Малий (1 слот за предмет)<br/>'+
'<table><tr><td>Здоровʼя:</td><td> 25 </td></tr>'+
'<tr><td>Броня:</td><td> 16 </td></tr>'+
'<tr><td>Сила базова:</td><td> 6 </td></tr>'+
'<tr><td>Спритність базова:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td> 14 </td></tr>'+
'<tr><td>Влучність:</td><td> +6 </td></tr>'+
'<tr><td>Пошкодження:</td><td> 1d8+2 </td></tr></table><br/>'+

'• Середній (2 слоти за предмет)<br/>'+
'<table><tr><td>Здоровʼя:</td><td> 40 </td></tr>'+
'<tr><td>Броня:</td><td> 13 </td></tr>'+
'<tr><td>Сила базова:</td><td> 10 </td></tr>'+
'<tr><td>Спритність базова:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td> 12 </td></tr>'+
'<tr><td>Влучність:</td><td> +5 </td></tr>'+
'<tr><td>Пошкодження:</td><td> 2d6+1 </td></tr></table><br/>'+

'• Великий (4 слоти за предмет)<br/>'+
'<table><tr><td>Здоровʼя:</td><td> 50 </td></tr>'+
'<tr><td>Броня:</td><td> 10 </td></tr>'+
'<tr><td>Сила базова:</td><td> 14 </td></tr>'+
'<tr><td>Спритність базова:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td> 10 </td></tr>'+
'<tr><td>Влучність:</td><td> +6 </td></tr>'+
'<tr><td>Пошкодження:</td><td> 2d10+2 </td></tr></table><br/>'+

'• Величезний (8 слотів за предмет)<br/>'+
'<table><tr><td>Здоровʼя:</td><td> 80 </td></tr>'+
'<tr><td>Броня:</td><td> 10 </td></tr>'+
'<tr><td>Сила базова:</td><td> 18 </td></tr>'+
'<tr><td>Спритність базова:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td> 6 </td></tr>'+
'<tr><td>Влучність:</td><td> +8 </td></tr>'+
'<tr><td>Пошкодження:</td><td> 2d12+4 </td></tr></table><br/>'+

'Інші характеристики (однакові для всіх предметів):<br/>'+
'<table><tr><td>Статура:</td><td> 10 </td></tr>'+
'<tr><td>Інтелект:</td><td> 3 </td></tr>'+
'<tr><td>Мудрість:</td><td> 3 </td></tr>'+
'<tr><td>Харизма:</td><td> 1 </td></tr>'+
'<tr><td>Швидкість:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td> 30ф </td></tr>'+
'<tr><td>Сліпий зір:</td><td> 30ф </td></tr></table><br/>'+

'Якщо у предмета немає ніг або інших кінцівок, які він міг би використовувати для пересування, він натомість має швидкість польоту 30 футів і може зависати. Якщо цей предмет надійно прикріплений на поверхні більшого предмета, наприклад, ланцюг, прикручений до стіни, його швидкість дорівнює 0. Він не бачить далі 30 футів. Коли пункти здоров’я оживленого предмета знижуються до 0, він повертається до початкової форми, будь-які надмірні пошкодження переносяться на початкову форму предмета.<br/><br/>'+

'Якщо ви наказуєте предмету атакувати, він виконує одну ближню атаку проти істоти в межах 5 футів від себе. Він завдає удару із бонусом атаки та Дробильним пошкодженням, визначеними його розміром. Майстер може вирішити, що конкретний предмет завдає Колоті або Рубані пошкодження на основі його форми.<br/><br/>'+

'Коли ви створюєте це закляття з використанням слоту мани 6 рівня або вище, ви можете оживляти два додаткові предмети за кожний слот мани вище п’ятого.';

export const antilife_shell = "Оболонка проти живих";

export const antilife_shell_details = 'Створити бар’єр, який заважає наблизитися до вас усім істотам, крім невмерлих та конструктів.';

export const antilife_shell_expanded = 'Мерехтливий бар’єр виникає навколо вас у межах 10 футів і рухається разом із вами, залишаючись відцентрованим на вас і захищає від всіх істот, окрім невмерлих та конструктів. Бар’єр існує, поки закляття активне. Бар’єр заважає істоті пройти чи дотягнутися наскрізь. Істота може створювати закляття чи виконувати крізь бар’єр атаки далекобійною зброєю чи зброєю із досяжністю.<br/><br/>'+

'Якщо ви рухаєтеся так, що істота мусить пройти крізь бар’єр, закляття закінчується.';

export const awaken = "Пробудження";

export const awaken_details = 'Надати звірові або рослині свідомість та 😍 Зачарувати. Створіння отримує збільшення Інтелекту, та здатність розмовляти і діяти.';

export const awaken_expanded = 'Ви проводите час створення закляття, відстежуючи магічні шляхи у дорогоцінному камені, а потім торкаєтеся звіра або рослину, чий розмір “величезний” або менший. Ціль повинна не мати значення Інтелекту або мати Інтелект 3 або менше. Ціль набуває значення Інтелекту 10. Ціль також набуває здібності говорити однією мовою, яку ви знаєте. Якщо ціль є рослиною, вона здобуває здібність рухати своїми кінцівками, корінням, гілками, ліанами тощо, і має відчуття, схожі з людськими. Ваш Майстер обирає здібності, що найбільше відповідає пробудженій рослині, таку як здібності пробудженого куща чи пробудженого дерева.<br/><br/>'+

'Пробуджений звір чи рослина зачаровані вами на 30 днів або поки ви чи хтось із ваших супутників не завдадуть йому пошкодження. Коли стан зачарування завершується, пробуджена істота обирає, чи залишитися дружньою до вас, залежно від того, як ви поводилися з нею, поки вона була зачарованою.';

export const banishing_smite = "Кара вигнанням";

export const banishing_smite_details = 'Наступним влучанням нанести пошкодження на змусити істоту переміститись у інший план існування.';

export const banishing_smite_expanded = 'Наступного разу, коли ви влучите по істоті зброєю до завершення дії закляття, зброя вкривається чистою енергією, атака завдає цілі додатково 5к10 пошкодження Силою. Окрім цього, якщо ця атака знизила пункти здоров’я цілі до 50 або нижче, ви виганяєте її. Якщо ціль родом не з того плану існування на котрому ви зараз знаходитесь, ціль зникає, повертаючись на свій рідний план існування. Якщо ціль родом с того плану, на котрому ви зараз знаходитесь, вона зникає в безпечному кишеньковому вимірі. Знаходячись там, ціль недієздатна. Вона лишається там, доки не закінчаться чари, після чого повертається у простір, з котрого зникла, або в найближчий вільний простір, якщо те місце зайняте.';

export const bigbys_hand = "Рука Бігбі";

export const bigbys_hand_details = 'Створити велику руку з прозорої енергії яка може бити, хапати, штовхати ворогів, або захищати вас. Броня 20, Здоровʼя рівне вашому, Сила +8, Спритність +0, Швидкість 10ф.';

export const bigbys_hand_expanded = 'Ви створюєте велику руку з мерехтливої, прозорої енергії у незайнятому просторі, який ви можете бачити в межах відстані. Рука існує, поки закляття активне, і рухається за вашою командою, повторюючи рухи вашої власної руки. Рука є предметом із Бронею 20 та здоров’ям, що дорівнюють максимуму вашого здоровʼя. Якщо її здоров’я знижується до 0, закляття завершується. Вона має Силу 26 (+8) і Спритність 10 (+0). Рука не заповнює свій простір. Під час створення закляття та Бонусною дією в наступні ходи ви можете переміщувати руку на відстань до 60 футів і потім виконати з нею один із наступних ефектів.<br/><br/>'+

'Стиснутий кулак.<br/>'+
'Рука б’є одну істоту чи предмет у межах 5 футів від себе. Виконайте ближню атаку закляттям для руки, використовуючи свої здібності. У разі влучання ціль отримує 4d8 пошкодження Силою.<br/><br/>'+

'Сильна рука.<br/>'+
'Рука намагається штовхнути істоту в межах 5 футів від себе в напрямку, який ви обрали. Зробіть перевірку Сили руки проти перевірки Атлетики цілі. Якщо ціль має розмір “середній” або менший, вона робить цю перевірку з перевагою. Якщо ви досягаєте успіху, рука штовхає істоту на 5 футів плюс кількість футів, що дорівнює модифікатору вашої складності заклять, помноженому на 5. Рука рухається разом із ціллю, щоб залишатися у межах 5 футів від неї.<br/><br/>'+

'Хапальна рука.<br/>'+
'Рука намагається схопити істоту, чий розмір “величезний” або менший, у межах 5 футів від себе. Ви використовуєте значення Сили руки для здійснення захвату. Якщо ціль має розмір “середній” або менший, вона робить свою перевірку із перевагою. Поки рука тримає ціль, ви можете стискати ціль, використовуючи бонусну дію. Коли ви це робите, ціль отримує дробильні ушкодження, що дорівнюють 2d6 + модифікатор вашої складності заклять.<br/><br/>'+

'Обмежувальна рука.<br/>'+
'Рука зупиняється між вами там істотою за вашим вибором, поки ви не дасте руці іншу команду. Рука переміщується, щоб залишатися між вами та ціллю, даючи вам половинне укриття проти цілі. Ціль не може пройти крізь простір руки, якщо її значення Сили менше або дорівнює значенню Сили руки. Якщо значення Сили цілі більше, ніж значення Сили руки, ціль може переміститися до вас крізь простір руки, але цей простір вважатиметься для цілі важкопрохідною місцевістю.<br/><br/>'+

'Коли ви створюєте закляття з використанням слоту мани 6 рівня або вищої, ушкодження від команди “стиснутий кулак” зростають на 2d8, і ушкодження від хапаючої руки зростають на 2d6 за кожний слот мани вище п’ятого.<br/><br/>'+

'Фокус для створення закляття можна замінити на яєчну шкарлупу та рукавичку зі зміїної шкіри.';

export const circle_of_power = "Аура Сили";

export const circle_of_power_details = 'Створити ауру яка, наділить союзників <span class="buff">перевагою</span> до Випробувань проти заклять та інших магічних ефектів, та при успіху зводить пошкодження до 0.';

export const circle_of_power_expanded = 'Ви випромінюєте божественну енергію, яка викривляє та розсіює магію на дистанції 30 футів від Вас. Поки закляття активне, сфера рухається разом з вами, залишаючись з центром на вас. Всі дружні істоти в цій області (включно з вами) отримують перевагу на Випробування проти заклять та інших магічних ефектів. Окрім цього, коли така істота має успіх у Випробуванні проти закляття або магічного ефекту, що дозволяє здійснити Випробування для отримання лише половини пошкодження, вона взагалі не отримує пошкодження.';

export const conjure_volley = "Виклик залпу";

export const conjure_volley_details = 'Викликати залп сотень екземплярів кинутого боєприпасу або зброї в область висотою 20ф.';

export const conjure_volley_expanded = 'Ви жбурляєте немагічну зброю або стріляєте немагічним боєприпасом в повітря та вибираєте точку в межах дистанції. Сотні екземплярів такої зброї або боєприпаса залпом падають і потім зникають. Кожна істота в межах циліндру радіусом 40 футів і висотою 20 футів мусить пройти Випробування Спритності. Істота отримує 8d8 пошкодження у разі невдалого Випррбування або половину пошкодження за умови успіху. Тип пошкодження такий же, як у зброї або боєприпасу, використаного в якості компоненту.<br/><br/>'+

'Фокус для створення цих чарів можна замінити на один боєприпас або зброю.';

export const contact_other_plane = "Звʼязок з іншим Планом";

export const contact_other_plane_details = 'Зв’язатися з потойбічною істотою та поставити їй п’ять коротких запитань, із <span class="debuff">ризиком</span> отримати 6d6 Психічного пошкодження та збожеволіти до тривалого відпочинку.';

export const contact_other_plane_expanded = 'Ви подумки зв’язуєтеся з напівбогом, духом давно померлого мудреця або іншою таємничою сутністю з іншого Плану. Зв’язок із цією позаплановою свідомістю може перенапружити або навіть пошкодити ваш розум. Коли ви створюєте це закляття, пройдіть Випробування Інтелекту зі складністю 15. У разі невдачі ви отримуєте 6d6 Психічного пошкодження та божеволієте, доки не закінчите тривалий відпочинок. Поки ви божевільні, ви не можете здійснювати дії, не розумієте, що говорять інші істоти, не можете читати і говорите тарабарщиною. Створення на вас чарів великого відновлення закінчує цей ефект.<br/><br/>'+

'У разі успішного Випробування ви можете поставити сутності до п’яти запитань. Ви мусите поставити свої запитання до того, як закінчиться закляття. Майстер відповідає на кожне запитання одним словом, таким як “так”, “ні”, “ніколи”, “недоречно” або “незрозуміло” (якщо сутність не знає відповіді на запитання). Якщо однослівна відповідь може виявитися оманливою, то майстер натомість може запропонувати коротку фразу.';

export const contagion = "Зараження";

export const contagion_details = 'Наслати на ціль одну хворобу на вибір:<br/>'+
'• Лихоманка та <span class="debuff">перешкода</span> на всі перевірки Сили;<br/>'+
'• Судоми та <span class="debuff">перешкода</span> на всі перевірки Спритності;<br/>'+
'• Кровотеча та <span class="debuff">перешкода</span> на всі перевірки Статури;<br/>'+
'• Помутніння та <span class="debuff">перешкода</span> на всі перевірки Інтелекту;<br/>'+
'• Сліпота та <span class="debuff">перешкода</span> на всі перевірки Мудрості;<br/>'+
'• Гниття, <span class="debuff">перешкода</span> на всі перевірки Харизми,<br/><span class="debuff">вразливість</span> до пошкоджень.';

export const contagion_expanded = 'Ваш дотик спричиняє хворобу. Здійсніть ближню атаку закляттям по істоті в межах своєї досяжності. При влучанні ви заражаєте істоту хворобою за вашим вибором з описаних нижче. У кінці кожного ходу ціль мусить пройти Випробування Статури. Після трьох провалів цих Випробувань ефекти хвороби продовжуються протягом всієї тривалості, а істота перестає проходити ці Випробування. Після трьох успішних Випробувань істота одужує, а закляття закінчується. Оскільки це закляття викликає природну хворобу у цілі, можливо застосувати будь-який ефект, який лікує хворобу або полегшує її ефекти іншим чином.<br/><br/>'+

'Огидна лихоманка.<br/>'+
'Люта лихоманка швидко поширюється по тілу істоти. Істота має перешкоду на перевірки та рятівні кидки Сили, а також на кидки атаки, що використовують Силу.<br/><br/>'+

'Судоми.<br/>'+
'Істоту охоплює тремтіння. Вона має перешкоду на перевірки та рятівні кидки Спритності, а також на кидки атаки, які використовують Спритність.<br/><br/>'+

'Спливний фатум.<br/>'+
'Істота починає неконтрольовано спливати кров’ю. Вона має перешкоду на перевірки і рятівні кидки Статури. Крім цього, коли істота отримує ушкодження, вона приголомшена до кінця свого наступного ходу.<br/><br/>'+

'Обпалений розум.<br/>'+
'Свідомість істоти починає лихоманити. Вона має перешкоду на перевірки та рятівні кидки Інтелекту і поводиться, ніби під впливом чарів спантеличення під час бою.<br/><br/>'+

'Осліплювальна недуга.<br/>'+
'Біль охоплює розум істоти, і її очі стають молочно-білими. Істота має перешкоду на перевірки та рятівні кидки Мудрості та є осліпленою.<br/><br/>'+

'Гниття плоті.<br/>'+
'Плоть істоти гниє. Вона має перешкоду на перевірки Харизми і вразлива до всіх типів ушкоджень.';

export const creation = "Творення";

export const creation_details = 'Тимчасово створити предмет, який ви раніше бачили.';

export const creation_expanded = 'Ви притягуєте жмутки тіньової матерії з Царства тіней, щоб створити неживий предмет із рослинної сировини у межах відстані: м’які речі, мотузки, деревину чи щось подібне. Ви також можете застосувати це закляття для створення мінеральних предметів, таких як камінь, кристал або метал. Створений предмет не може бути більшим, ніж 5-футовий куб, і цей предмет повинен мати форму та бути створений з того, що ви раніше бачили. Тривалість залежить від сировини предмета. Якщо предмет створений із багатьох матеріалів, використовуйте найкоротшу тривалість.<br/><br/>'+

'Рослинна матерія — 1 день<br/>'+
'Камінь або кристал — 12 годин<br/>'+
'Дорогоцінні метали — 1 година<br/>'+
'Дорогоцінне каміння — 10 хвилин<br/>'+
'Адамантин або мітрил — 1 хвилина<br/><br/>'+

'Використання будь-яких матеріалів, створених цим закляттям, як матеріальної складової для інших заклять, призводить до провалу закляття.<br/><br/>'+

'Коли ви створюєте це закляття з використанням слоту мани 6 рівня або вищої, куб збільшується на 5 футів за кожний слот мани вище п’ятої.<br/><br/>'+

'Фокус для створення закляття можна замінити на крихітний шматочок сировини того самого типу що й предмет, який ви плануєте створити.';

export const dispell_evil_and_good = "Розвіювання зла та добра";

export const dispell_evil_and_good_details = 'Створити для небожителів, елементалів, фей, погані та невмерлих <span class="buff">перешкоду</span> на влучання по вас. Також можна виконати одне на вибір:<br/>'+
'• Зняти потойбчний вплив з союзника<br/>'+
'• Вигнати ворога на його рідний План';

export const dispell_evil_and_good_expanded = 'Мерехтлива енергія оточує вас і захищає вас від фей, невмерлих та істот, що походять з-поза матеріального плану. На час дії закляття небожителі, елементали, феї, погань та невмерлі мають перешкоду на кидки атаки проти вас. Ви можете завершити дію закляття завчасно, вибравши один із наступних особливих варіантів:<br/><br/>'+

'Зірвати причарування.<br/>'+
'Ви Основною дією торкаєтеся істоти в межах своєї досяжності, яка є причарованою, наляканою або одержимою небожителем, елементалом, феєю, поганню або невмерлим. Істота, якої ви торкаєтеся, більше не причарована, не налякана та не одержима такими істотами.<br/><br/>'+

'Вигнання.<br/>'+
'Основною дією здійсніть ближню атаку закляттям проти небожителя, елементала, феї, погані або невмерлого, який перебуває у межах вашої досяжності. У разі влучання ви намагаєтеся вигнати істоту на її рідний план. Істота має пройти Випробування Харизми, інакше вона буде відправлена на свій рідний План (якщо тільки вона вже не знаходиться там). Невмерлі відправляються до Царства Тіней, а феї - до Фейвальду.<br/><br/>'+

'Фокус для створення закляття можна замінити на святу воду або порошок срібла та заліза.';

export const geas = "Примушування";

export const geas_details = 'Змусити істоту, яка вас розуміє, виконувати ваші побажання під страхом жахливих страждань.';

export const geas_expanded = 'Ви віддаєте магічний наказ істоті, яку ви можете бачити в межах відстані, примушуючи її виконати якісь послуги або відмовитися від певних дій або активності залежно від вашого рішення. Якщо істота вас розуміє, вона повинна пройти Випробування Мудрості, інакше стане Зачарованою вами на час дії закляття. Поки істота вами Зачарована, вона отримує 5d10 пошкодження Психічною енергією кожен раз, коли діє, прямо порушуючи ваші інструкції, але не більше одного разу за день. На істоту, яка вас не розуміє, ваше закляття не мають впливу. Ви можете віддати будь-який наказ за власним вибором, крім дій, які точно спричинять смерть. Як тільки ви віддаєте суїцидальний наказ, закляття закінчується. Ви можете завершити закляття раніше, використавши Основну дію, щоб скасувати їх. Зняття прокляття, Більше відновлення чи Бажання також завершують це закляття.<br/><br/>'+

'Коли ви створюєте це закляття з використанням слоту мани 7 або 8 рівня, тривалість збільшується до 1 року. Коли ви створюєте це закляття з використанням слоту мани 9 рівня, заклття триває, поки не буде завершене одними зі згаданих вище заклять.';

export const greater_restoration = "Більше відновлення";

export const greater_restoration_details = 'Могутня магія відновлення, яка знімає частину серйозних шкідливих станів.';

export const greater_restoration_expanded = 'Ви наповнюєте істоту, котрої торкаєтеся, позитивною енергією, щоб скасувати виснажливий ефект. Ви можете завершити на цілі один із наступних ефектів:<br/><br/>'+

'• Один рівень виснаження цілі<br/>'+
'• Один ефект, що робить ціль причарованою або скам’янілою<br/>'+
'• Одне прокляття, включно з налаштуванням цілі на проклятий магічний предмет<br/>'+
'• Будь-яке зниження значення однієї зі здібностей цілі<br/>'+
'• Один ефект, що знижує максимум пунктів здоров’я цілі';

export const mislead = "Омана";

export const mislead_details = 'Стати невидимим, а на своєму місці залишити підконтрольну вам ілюзію себе.';

export const mislead_expanded = 'Ви стаєте невидимим, і в цей же час ваш ілюзорний двійник виникає в місці, де ви стоїте. Двійник існує, поки діє закляття, але невидимість завершується, якщо ви атакуєте або створюєте закляття. Ви можете використати свою Основну дію, щоб переміщувати свого ілюзорного двійника на свою подвійну швидкість і зробити жест, говорити чи поводитися так, як ви хочете.<br/><br/>'+

'Ви можете бачити його очима й чути його вухами, ніби ви перебуваєте там, де й він. Бонусною дією в кожен свій хід ви можете перемикатися між своїми чуттями та чуттями двійника, і навпаки. Поки ви використовуєте чуття двійника, ви осліплені та оглушені відносно власного оточення.';

export const planar_binding = "Планарне поневолення";

export const planar_binding_details = 'Спробувати змусити небожителя, стихійника, фею чи погань виконувати вашу волю.';

export const planar_binding_expanded = 'За допомогою цього закляття ви намагаєтеся змусити небожителя, стихійника, фею чи погань виконувати вашу волю. Ця істота має бути в межах дії закляття протягом усього касту. (Зазвичай істоту спершу прикликають у центр інвертованого магічного кола, щоб утримувати її в пастці під час створення закляття). Після завершення касту ціль повинна пройти Випробування Харизми. У випадку провалу істота мусить служити вам протягом тривалості закляття. Якщо істоту було прикликано або створено іншим закляттям, тривалість того закляття подовжується відповідно до тривалості цього закляття.<br/><br/>'+

'Підкорена істота повинна коритися вашим командам, наскільки це можливо. Ви можете змусити істоту супроводжувати вас у пригоді, охороняти місце або доставити повідомлення. Істота чітко виконує ваші накази, однак, якщо вона налаштована вороже до вас, то шукатиме способу використати ваші слова проти вас. Якщо істота повністю виконає ваші вказівки до закінчення дії закляття, вона переміститься до вас аби повідомити про цей факт, якщо ви обоє знаходитеся на тому самому плані існування. Якщо ви перебуваєте на іншому плані, істота повертається до місця, де ви підкорили її, і залишається там, доки дія закляття не закінчиться.<br/><br/>'+

'Ви можете бачити його очима й чути його вухами, ніби ви перебуваєте там, де й він. Бонусною дією в кожен свій хід ви можете перемикатися між своїми чуттями та чуттями двійника, і навпаки. Поки ви використовуєте чуття двійника, ви осліплені та оглушені відносно власного оточення.<br/><br/>'+

'Коли ви створюєте це закляття за допомогою слоту мани вищого рівня, тривалість збільшується до 10 днів з 6-го рівня, до 30 днів — зі слотом мани 7-го рівня, до 180 днів — зі слотом мани 8-го рівня, і до року з одним днем — зі слотом мани 9-го рівня.';

export const reincarnate = "Реінкарнація";

export const reincarnate_details = 'Повернути до живих згодного вмерлого не більше 10 днів назад гуманоїда, створивши йому нове тіло. Потрібно мати хоча б частку його початкового тіла.';

export const reincarnate_expanded = 'Ви доторкаєтеся до мертвого гуманоїда або решток мертвого гуманоїда. За умови, що істота була мертвою не довше 10 днів, закляття формуює для неї нове доросле тіло і потім прикликають до цього тіла душу. Якщо душа цілі невільна або незгодна зробити це, закляття провалюється.<br/><br/>'+

'Магія створює нове тіло, щоб його населила істота, і це, ймовірно, призводить до зміни раси істоти. Майстер обирає цю форму, або кидає d100 та звіряється з наведеною таблицею, щоб визначити, якої форми набуває істота, коли її повертають до життя.<br/><br/>'+

'<table>'+
'<tr><td>01-04&nbsp;</td><td>Драконороджений&nbsp;&nbsp;</td><td>47-52&nbsp;</td><td>Гном скельний</td></tr>'+
'<tr><td>05-13</td><td>Дворф пагорбовий</td><td>53-56</td><td>Напівельф</td></tr>'+
'<tr><td>14-21</td><td>Дворф гірський</td><td>57-60</td><td>Напіворк</td></tr>'+
'<tr><td>22-25</td><td>Ельф темний</td><td>61-68</td><td>Хафлінг прудконогий</td></tr>'+
'<tr><td>26-34</td><td>Ельф вищий</td><td>69-76</td><td>Хафлінг жилавий</td></tr>'+
'<tr><td>35-42</td><td>Ельф лісовий</td><td>77-96</td><td>Людина</td></tr>'+
'<tr><td>43-46</td><td>Гном лісовий</td><td>97-100</td><td>Тифлінґ</td></tr>'+
'</table><br/>'+

'Реінкарнована істота пригадує своє минуле життя та досвід. Вона зберігає можливості, які мала оригінальна форма, однак замінює риси початкової раси на риси нової раси відповідно.';

export const telepatic_bond = "Телепатичний звʼязок";

export const telepatic_bond_details = 'Створити телепатичну мережу зв’язку між згодними істотами.';

export const telepatic_bond_expanded = 'Ви обираєте до 8 згодних істот у межах відстані та утворюєте між ними телепатичний зв’язок, психічно поєднуючи кожну істоту з усіма іншими на час дії закляття. Істоти зі значенням інтелекту 2 або менше не піддаються впливу цього закляття. Поки закляття активне, цілі можуть спілкуватися телепатично через цей зв’язок, незалежно від того, чи знають вони спільну мову. Спілкування можливе на будь-якій відстані, хоча воно не досягає інших планів існування.<br/><br/>'+

'Фокус для створення цих чарів можна замінити на шматочки шкарлупи від яєць двох різних типів істот.';

export const teleportation_circle = "Коло телепортації";

export const teleportation_circle_details = 'Відкрити короткостроковий портал, з’єднаний з постійним порталом, який ви знаєте.';

export const teleportation_circle_expanded = 'Ви малюєте на землі коло радіусом 10 футів, написане сигілами, які з’єднують ваше розташування із постійним колом телепортації за вашим вибором, чию послідовність сигілів ви знаєте і яке розташоване на тому ж плані існування, що й ви. Мерехтливий портал відкривається в межах намальованого кола й залишається відкритим до кінця вашого наступного ходу. Будь-яка істота, що входить у портал, миттєво з’являється в межах 5 футів від кінцевого кола чи в найближчому вільному просторі, якщо простір зайнятий.<br/><br/>'+

'Багато великих храмів, гільдій та інших важливих місць мають постійні кола телепортації, написані десь в межах їхніх володінь. Кожне таке коло має унікальну послідовність сигілів — рядок магічних рун, розташованих за певним шаблоном. Коли ви вперше набуваєте здатності створювати це закляття, ви вивчаєте послідовність сигілів двох розташувань на Матеріальному плані, визначених вашим Майстром. Ви вивчаєте додаткові послідовності сигілів під час своїх пригод. Ви можете запам’ятати нову послідовність сигілів, вивчаючи її упродовж 1 хвилини.<br/><br/>'+

'Ви можете створити постійне коло телепортації, створюючи це закляття в тому самому місці щодня упродовж одного року. Ви не повинні користуватися цим колом для телепортації, поки створюєте чари в цей спосіб.';

export const wall_of_force = "Стіна енергії";

export const wall_of_force_details = 'Створити невидиму стіну/поверхню з чистої енергії.';

export const wall_of_force_expanded = 'Невидима стіна чистої енергії утворюється в точці, яку ви обираєте в межах відстані. Стіна з’являється в будь-якій орієнтації за вашим вибором, як горизонтальний або вертикальний бар’єр, або під кутом. Вона може вільно зависнути або стояти на твердій поверхні. Ви можете сформувати її як напівсферичний купол або як сферу радіусом до 10 футів, або ви можете створити пласку поверхню, зібрану з 10 панелей розміром 10 на 10 футів. Кожна панель повинна дотикатися до іншої панелі. У будь-якій формі стіна має товщину 1/4 дюйма. Вона існує, поки діє закляття. Якщо стіна під час створення проходить крізь простір істоти, істоту виштовхує на один бік стіни (за вашим вибором).<br/><br/>'+

'Ніщо не може фізично пройти крізь цю стіну. Вона має імунітет до всіх ушкоджень і не може бути розвіяна чарами розвіювання магії. Закляття Розщеплення, однак, миттєво знищують стіну. Стіна також поширюється в Етерний план, блокуючи наскрізні етерні подорожі.';