const ua_names = {
  male: [

    "Байрактар",
    "Енлав",
    "Донат",
    "Гур",
    "Птур",
    "Дрон",
    "Донбас",
    "Мавік",
    "Cунак",
    "Притул",
    "Стерн",
    "Маркус",
    "Ковід",
    "Каранʼтін",
    "Рошен",
    "Фестал",
    "Спартак",
    "Йаарік",
    "Назар",
    "Артур",
    "Єгор",
    "Захар",
    "Валерій",
    "Євгеній",
    "Керіл",
    "Арсен",
    "Оксен",
    "Ігнат",
    "Фен",
    "Талон",
    "Аркан",
    "Вус",
    "Касір",
    "Тіль",
    "Честер", 
    "Самюель",
    "Мікель",
    "Олаф",
    "Емануель",
    "По", 
    "Азіль",
    "Дрог",
    "Шу",
    "Мерч",
    "Контік",
    "Остін",
    "Лойд",
    "Джозеф",
    "Йенс",
    "Акім",
    "Гео",
    "Лерос",
    "Рікі",
    "Люк",
    "Гена Цидрусні",
    "Лорак",
    "Новус",
    "Лесільпон",
    "Півоній",
    "Фікус",
    "Джастін",
    "Трюдо",
    "Віват",
    "Галаген",
    "Тімоті",
    "Снайдер",
    "Живчик",
    "Рево",
    "Чамбо",
    "Хачапур",
    "Хінкал",
    "Кебаб",
    "Ясос Біба",
    "Кек",
    "Бубалех",
    "Етіл",
    "Метіл",
    "Глід",
    "Вілат",
    "Орест",
    "Кавун",
    "Аґрус",
    "Комміт",
    "Дайхард",
    "Бірмікс",
    "Шейк",
    "Бітнер",
    "Мезим",
    "Мотиліум",
    "Ібупрофенн",
    "Гекседин",
    "Парацій Тамол",
    "Калій",
    "Блекбокс",
    "Гофман",
    "Еос",
    "Гачі",
    "Мучі",
    "Біллі",
    "Геррінтгтон",
    "Рапан",
    "Равлик",
    "Стівен",
    "Джейк",
    "Салліван",
    "Довбуш",
    "Степан",
    "Вуйко",
    "Кен",
    "Ентоні",
    "Блінкен",
    "Аосік",
    "Черезос",
    "Бетон",
    "Леопард",
    "Гепард",
    "Хаймарс",
    "Насамс",
    "Ріши",
    "Лачен",
    "Штормшедоу",
    "Скалп",
    "Стормер",
    "Нептун",
    "Корсар",
    "Малюк",
    "Шрайбер",
    "Лієв",
    "Марк",
    "Стронг",
    "Скот",
    "Сахно",
    "Вінник",
    "Азанавічус",
    "Колінз",
    "Усик",
    "Гаррі",
    "Слінгер",
    "Бушмастер",
    "Меллой",
    "Таурус",
    "Атакамс",
    "Ефсикстін",
    "Грипен",
    "Клаусс",
    "Йоханніс",
    "Альфред",
    "Сімоніс",
    "Марчел", 
    "Чолакус",
    "ДеКроо",
    "Терахоук",
    "Бандвагн",
    "Сатком",
    "Маріо",
    "Банозич",
    "Базович",
    "Хорват",
    "Американ",
    "Брітіш",
    "Пол",
    "Литуан",
    "Естон",
    "Лат",
    "Герман",
    "Румун",
    "Кий",
    "Щек",
    "Хорив",
    "Аскольд",
    "Дір",
    "Бівер",
    "Норвег",
    "Швед",
    "Фін",
    "Чех",
    "Троельс",
    "Люнд",
    "Поульсен",
    "Даній",
    "Еммер",
    "Фредеріксен",
    "Грант",
    "Шеппс",
    "Реб",
    "Шарль",
    "Оллонгрен",
    "Пісторіус",
    "Лупанчук",
    "Плутон",
    "Скайнекс",
    "Мардер",
    "Бібер",
    "Вінсент",
    "Франкенсем",
    "Аасм",
    "Хаммер",
    "Себастʼян",
    "Лекорн",
    "Федеріксен",
    "Стрідсбат",
    "Грюпбат",
    "Ербіес",
    "Роберт",
    "Деніро",
    "Деньов",
    "Денрейнолд",
    "Боно",
    "Вігго",
    "Мортенсен",
    "Томпсон",
    "Стівен",
    "Фрай",
    "Каллас",
    "Тʼєррі",
    "Буркхард",
    "Вонг",
    "Євлаш",
    "Лінкс",
    "Алькмаар",
    "Мадрид",
    "Стубб",

  ],
  female: [

    "Лунет",
    "Зіфіра",
    "Аурелія",
    "Сіара",
    "Ліанора",
    "Ніколь",
    "Келліана",
    "Ауреліна",
    "Віонна",
    "Корділія",
    "Сендра",
    "Азура",
    "Катаріна",
    "Кімберлі",
    "Іммортелла",
    "Лоренна",
    "Мікелла",
    "Олівія",
    "Призма",
    "Ревенна",
    "Сарафіна",
    "Талесса",

    "Джавеліна",
    "Стефанія",
    "Стугна",
    "Кива",
    "Дія",
    "Субота",
    "Горлівка",
    "Іранія",
    "Ксенія",
    "Наталія",
    "Дарія",
    "Зіта", 
    "Сюзанна", 
    "Матільда", 
    "Тільда", 
    "Брумхільда", 
    "Фрінгілія",
    "Ентеросжерміна",
    "Ельвіна",
    "Алфія",
    "Тілінь",
    "Боша",
    "Тереша",
    "Барва",
    "Шанель",
    "Ані",
    "Майа",
    "Тиша",
    "Абоба",
    "Акула",
    "Паляниця",
    "Хортиця",
    "Рада",
    "Дарниця",
    "Валеріанна",
    "Злата",
    "Бавовна",
    "Фігма",
    "Смекта",
    "Галувана Баля",
    "Салатеіра",
    "Сушия",
    "Бавовна",
    "Фета",
    "Анджеліна",
    "Урсула",
    "Фіала",
    "Шарлотта",
    "Амелі",
    "Барбі",
    "Бандера",
    "Ваха",
    "Церезіт",
    "Тікурілла",
    "Джорджа",
    "Мелоні",
    "Харлан",
    "Лелека",
    "Фурія",
    "Сібейбі",
    "Іванна",
    "Келлі",
    "Кетрін",
    "Мішель",
    "Міша",
    "Маґура",
    "Кулеба",    
    "Малюська",
    "Рютте",
    "Мівісім",
    "Хорватія",
    "Америка",
    "Британія",
    "Полька",
    "Литуанія",
    "Естонія",
    "Латвія",
    "Германія",
    "Румунія",
    "Україна",
    "Либідь",
    "Норвегія",
    "Фінка",
    "Швеція",
    "Чехія",
    "Данія",
    "Метте",
    "Прімоко",
    "Кайса",
    "Аврора",
    "Фондерляйн",
    "Покрова",
    "Фуміо",
    "Кішіда",
    "Японія",
    "Айріс Ті",
    "Екола",
    "Метте",
    "Тоу",
    "Катрін",
    "Деньов",
    "Гіларі",
    "Суонк",
    "Емма",
    "Діта",
    "Юкка",
    "Габріелла",
    "Атталь",
    "Кая",
    "Лагода",
    "Ремі",
    "Міша",
    "Стрнада",
    "Бельгія",
    "Івеко",
    
  ],
}

export default {
  
  ua: ua_names,
  ua_inrium: ua_names,

  ru: {
    male: [
      "Байрактар",
    ],
    female: [
      "Стугна",
    ],
  },

  en: {
    male: [
      "Bayraktar",
    ],
    female: [
      "Stugna",
    ],
  },
};
