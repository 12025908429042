export default {
	arrow_down: '<path fill-rule="evenodd" clip-rule="evenodd" d="M4.41421 5L9 9.58579L13.5858 5L15 6.41421L10.4142 11L9 12.4142L7.58579 11L3 6.41421L4.41421 5Z"/>',

	arrow_down_small: '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.70711 7L9.00001 9.2929L11.2929 7L12 7.70711L9.70711 10L9.00001 10.7071L8.2929 10L6 7.70711L6.70711 7Z"/>',
	
  arrow_right: '<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2929 13.5858L14.8787 9L10.2929 4.41421L11.7071 3L16.2929 7.58579L17.7071 9L16.2929 10.4142L11.7071 15L10.2929 13.5858Z"/>',

  arrow_right_small: '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.14645 11.1464L9.43935 8.85351L7.14645 6.56061L7.85356 5.8535L10.1465 8.1464L10.8536 8.85351L10.1465 9.56062L7.85356 11.8535L7.14645 11.1464Z"/>',

  arrow_top_small: '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 10.707L8.99999 8.41413L6.70709 10.707L5.99999 9.99992L8.29289 7.70702L8.99999 6.99992L9.7071 7.70702L12 9.99992L11.2929 10.707Z"/>',

  arrow_left: '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.70711 4.41421L3.12132 9L7.70711 13.5858L6.29289 15L1.70711 10.4142L0.292893 9L1.70711 7.58579L6.29289 3L7.70711 4.41421Z"/>',

  arrow_left_small: '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.85356 6.56062L3.56066 8.85352L5.85356 11.1464L5.14646 11.8535L2.85356 9.56063L2.14645 8.85352L2.85356 8.14642L5.14646 5.85352L5.85356 6.56062Z"/>',

  arrow_slider_rl: '<path fill-rule="evenodd" clip-rule="evenodd" d="M11 11.2929L13.2929 8.99999L11 6.70709L11.7071 5.99999L14 8.29289L14.7071 8.99999L14 9.7071L11.7071 12L11 11.2929Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M7 6.70711L4.7071 9.00001L7 11.2929L6.29289 12L3.99999 9.70711L3.29289 9.00001L3.99999 8.2929L6.29289 6L7 6.70711Z" /><rect x="4.5" y="8.5" width="9" height="1" />',
  
  arrow_slider_tb: '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.04045 11L9.33335 13.2929L11.6262 11L12.3334 11.7071L10.0405 14L9.33335 14.7071L8.62624 14L6.33334 11.7071L7.04045 11Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.6262 7L9.33334 4.7071L7.04044 7L6.33333 6.29289L8.62623 3.99999L9.33334 3.29289L10.0404 3.99999L12.3333 6.29289L11.6262 7Z"/><rect x="9.83334" y="4.5" width="9" height="1" transform="rotate(90 9.83334 4.5)"/>',

  check: '<path d="M4.98798 8.0843L5.95903 6.99688L8.12088 9.11627L12.1499 5.22283L13.1421 6.27603L8.06646 11.0731L4.98798 8.0843Z" />',

  check_green: '<path d="M4.98823 8.08439L5.95928 6.99697L8.12112 9.11637L12.1501 5.22292L13.1424 6.27612L8.0667 11.0732L4.98823 8.08439Z" fill="#05FF00"/>',

  plus: '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 5H8V8L5 8V10H8V13H10V10L13 10V8L10 8V5Z" />',

  minus: '<path d="M13 10L9 10L5 10L5 8L9 8L13 8L13 10Z" />',

  delete: '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5355 6.87831L11.1213 5.46409L9 7.58541L6.87868 5.46409L5.46447 6.87831L7.58579 8.99963L5.46447 11.1209L6.87868 12.5352L9 10.4138L11.1213 12.5352L12.5355 11.1209L10.4142 8.99963L12.5355 6.87831Z" fill="#FF0000"/>',

  download: '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.70711 9L9.00001 11.2929L11.2929 9L12 9.70711L9.70711 12L9.00001 12.7071L8.2929 12L6 9.70711L6.70711 9Z"/><rect x="8.5" y="6" width="1" height="6"/>',

  return: '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.12602V5V4.10002V4H11H14V5H12.0005C13.2147 5.91223 14 7.3644 14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.79464 5.42779 4.92276 7.40942 4.25831C7.70913 4.15781 8 4.3956 8 4.71171V4.73841C8 4.96655 7.84418 5.16268 7.62984 5.24083C6.09552 5.8002 5 7.27217 5 9C5 11.2091 6.79086 13 9 13C11.2091 13 13 11.2091 13 9C13 7.51943 12.1956 6.22675 11 5.53513V8H10V5.12602Z"/>',
  
  close: '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5355 6.87868L11.1213 5.46447L9 7.58579L6.87868 5.46447L5.46447 6.87868L7.58579 9L5.46447 11.1213L6.87868 12.5355L9 10.4142L11.1213 12.5355L12.5355 11.1213L10.4142 9L12.5355 6.87868Z" />',

  link: '<path d="M10.6213 8.08579V10.6213H11.6213V6.37867H7.37867L7.37867 7.37867L9.91422 7.37867L6.52513 10.7678L7.23223 11.4749L10.6213 8.08579Z" />',
  
  donate: '<path d="M10.6213 8.08579V10.6213H11.6213V6.37867H7.37867L7.37867 7.37867L9.91422 7.37867L6.52513 10.7678L7.23223 11.4749L10.6213 8.08579Z" />',

  link_main: '<path fill-rule="evenodd" clip-rule="evenodd" d="M9 11.2929L11.2929 8.99999L9 6.70709L9.70711 5.99999L12 8.29289L12.7071 8.99999L12 9.7071L9.70711 12L9 11.2929Z" /><rect x="6" y="9.5" width="1" height="6" transform="rotate(-90 6 9.5)"/>',

  edit: '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.52595 9.9481L11.4741 5L13 6.52595L8.0519 11.4741L6.52595 13L5 11.4741L6.52595 9.9481ZM9 13L10 12H13V13H9Z"/>',
  
  upload: '<path d="M12 5H6.00001V6H12V5Z"/> <path d="M11.2929 10.7071L9.50001 8.91422V13.7071H8.50001V8.91421L6.70711 10.7071L6 10L9.00001 7L12 10L11.2929 10.7071Z"/>',
  
  photo_fit: '<path d="M11.5714 5.14293H6.42857V12.8572H11.5714V5.14293Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.21428 5.14293C3.21428 4.07781 4.07774 3.21436 5.14286 3.21436H12.8571C13.9223 3.21436 14.7857 4.07781 14.7857 5.14293V12.8572C14.7857 13.9223 13.9223 14.7858 12.8571 14.7858H5.14286C4.07774 14.7858 3.21428 13.9223 3.21428 12.8572V5.14293ZM5.14286 3.85721H12.8571C13.5672 3.85721 14.1429 4.43285 14.1429 5.14293V12.8572C14.1429 13.5673 13.5672 14.1429 12.8571 14.1429H5.14286C4.43278 14.1429 3.85714 13.5673 3.85714 12.8572V5.14293C3.85714 4.43285 4.43278 3.85721 5.14286 3.85721Z"/>',

  photo_fill: '<path d="M14.7857 2.57119V0H3.21428L3.21428 2.57119C3.75149 2.16768 4.41925 1.92857 5.14285 1.92857H12.8571C13.5807 1.92857 14.2485 2.16768 14.7857 2.57119Z"/><path d="M14.7857 15.4288C14.2485 15.8323 13.5807 16.0714 12.8571 16.0714H5.14285C4.41925 16.0714 3.75149 15.8323 3.21428 15.4288V18H14.7857V15.4288Z"/><path d="M12.8571 5.14286H5.14285V12.8571H12.8571V5.14286Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.21428 5.14286C3.21428 4.07774 4.07773 3.21429 5.14285 3.21429H12.8571C13.9223 3.21429 14.7857 4.07774 14.7857 5.14286V12.8571C14.7857 13.9223 13.9223 14.7857 12.8571 14.7857H5.14285C4.07773 14.7857 3.21428 13.9223 3.21428 12.8571V5.14286ZM5.14285 3.85714H12.8571C13.5672 3.85714 14.1429 4.43278 14.1429 5.14286V12.8571C14.1429 13.5672 13.5672 14.1429 12.8571 14.1429H5.14285C4.43277 14.1429 3.85714 13.5672 3.85714 12.8571V5.14286C3.85714 4.43278 4.43277 3.85714 5.14285 3.85714Z" />',



};
