export const tools = {

    blacksmith: "коваля",
    brewer: "пивовара",
    mason: "муляра",
    thief: "крадійскі",

    alchemists_supplies: "алхіміка",
    potters_tools: "гончара",
    tinkers_tools: "бляхаря",
    calligraphers_supplies: "каліграфа",
    cartographers_tools: "картографа",
    leatherworkers_tools: "шкіряника",
    navigators_tools: "навігатора",
    carpenters_tools: "тесляра",
    cooks_tools: "кухаря",
    woodcarvers_tools: "різьбяра по дереву",
    cobblers_tools: "чоботаря",
    glassblowers_tools: "склодува",
    weavers_tools: "кравця",
    painters_supplies: "художника",
    jewelers_tools: "ювеліра",
    music_drums: "барабани",
    music_viol: "віоли",
    music_bagpipes: "волинки",
    music_lyre: "ліри",
    music_lute: "лютні",
    music_horn: "ріжки",
    music_pan_flute: "сопілки",
    music_flute: "флейти",
    music_dulcimer: "цимбали",
    music_shawm: "шалмії",
    herbalists_tools: "травника",
}